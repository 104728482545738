import { Workbook } from '@flatfile/api'
import { Icon, Tooltip } from '@flatfile/design-system'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { InjectResource } from '@/api/resources/InjectResource'
import { ErrorState } from '@/components/EmptyState'
import { BlocksContext } from '@/contexts/BlocksContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { SheetView } from '../SheetView'
import { SnapshotView } from '../VersionHistory/SnapshotView/SnapshotView'
import { useShowSnapshotView } from '../hooks/useShowSnapshotView'
import {
  EmbeddedSheetContainer,
  EmbeddedSheetHeader,
  EmbeddedSheetViewContainer,
} from './elements'

type EmbeddedSheet = {
  id: string
  workbookId: string
  name?: string
  height?: string | number
  defaultExpanded?: boolean
}

type EmbeddedSheetViewProps = EmbeddedSheet & {
  workbook: Workbook
}

const EmbeddedSheetView = ({
  id,
  height = 600,
  workbook,
}: EmbeddedSheetViewProps) => {
  const { showSnapshotView, toggleSnapshotView, closeSnapshotView } =
    useShowSnapshotView()
  const sheet = useMemo(
    () => workbook.sheets?.find((x) => x.id === id),
    [workbook.sheets, id]
  )

  return (
    <>
      <SheetView
        embedded
        sheetId={id}
        sheet={sheet}
        workbook={workbook}
        height={height}
        toggleSnapshotView={toggleSnapshotView}
        pinningEnabled={true}
        showColumnMenus={true}
      />
      {/* c8 ignore start */}
      {showSnapshotView ? (
        <SnapshotView
          {...showSnapshotView}
          workbook={workbook}
          onClose={closeSnapshotView}
        />
      ) : null}
      {/* c8 ignore stop */}
    </>
  )
}

const EmbeddedSheetLoader = (props: EmbeddedSheet) => {
  const { t } = useTypedTranslation()

  return (
    <EmbeddedSheetViewContainer data-testid='embedded-sheetview-container'>
      <InjectResource<Workbook>
        id={props.workbookId}
        errorContent={() => <ErrorState message={t('sheet.errors.loading')} />}
      >
        {(workbook) => <EmbeddedSheetView {...props} workbook={workbook} />}
      </InjectResource>
    </EmbeddedSheetViewContainer>
  )
}

export const EmbeddedSheet = (props: EmbeddedSheet) => {
  const { openSheetIds, setOpenSheetIds, maxOpenSheets } =
    useContext(BlocksContext)
  const { t } = useTypedTranslation()
  const [expanded, setExpanded] = useState(props.defaultExpanded)
  const canExpand = openSheetIds.length < maxOpenSheets

  useEffect(() => {
    if (props.defaultExpanded) {
      setOpenSheetIds([...openSheetIds, props.id])
    }
  }, [props.defaultExpanded])

  const handleToggle = useCallback(() => {
    if (expanded) {
      setExpanded(false)
      setOpenSheetIds(openSheetIds.filter((sheetId) => sheetId !== props.id))
    } else if (canExpand) {
      setExpanded(true)
      setOpenSheetIds([...openSheetIds, props.id])
    }
  }, [openSheetIds, props.id, setOpenSheetIds])

  return (
    <EmbeddedSheetContainer>
      <EmbeddedSheetHeader
        $unknown={props.name === undefined}
        $expanded={expanded}
        $canExpand={canExpand}
        onClick={handleToggle}
        data-testid='embedded-sheet-toggle'
        data-tooltip-id={`embedded-sheet-${props.id}`}
      >
        <Icon name='chevronDown' size={16} />
        {props.name ?? t('sheet.embedded.untitled')}
        {!canExpand && !expanded && (
          <Tooltip id={`embedded-sheet-${props.id}`}>
            {t('sheet.embedded.maxOpenReached', { maxOpenSheets })}
          </Tooltip>
        )}
      </EmbeddedSheetHeader>
      {expanded && <EmbeddedSheetLoader {...props} />}
    </EmbeddedSheetContainer>
  )
}
