import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ActiveJobs,
  Job,
  JobOperationEnum,
  JobsContext,
  getCompletedJobs,
  getCurrentlyRunningJobs,
  getFailedJobs,
} from '../contexts/JobsContext'

export type JobStatus = 'active' | 'complete' | 'failed' | 'none'

interface JobStatusTotals {
  complete: number
  failed: number
}
interface CurrentDisplayState {
  status: JobStatus
  operation?: string
  text?: string
}
//TODO: rewrite this component to more conceptually express the combination of
// a permanent display for running jobs combined with a temporary display for
// completed or failed jobs.
// Rewrite it in a more declarative way instead of with effects.
export const useJobStatus = () => {
  const { activeJobs, latestUpdatedJob, latestUpdatedRunningJob } =
    useContext(JobsContext)
  const [status, setStatus] = useState<JobStatus>('none')
  const [currentDisplayState, setCurrentDisplayState] =
    useState<CurrentDisplayState>()
  const [jobStatusTotals, setJobStatusTotals] = useState<JobStatusTotals>({
    complete: getCompletedJobs(activeJobs).length,
    failed: getFailedJobs(activeJobs).length,
  })
  const currentlyRunningJobs = useMemo(
    () => getCurrentlyRunningJobs(activeJobs),
    [activeJobs]
  )

  const dontUpdate = useRef(false)

  const onUpdateJobs = useCallback(
    (jobStatusTotals: JobStatusTotals) => {
      const display = getDisplayState(
        latestUpdatedRunningJob,
        statusForJobs(activeJobs, jobStatusTotals)
      )
      setTimeout(() => {
        setJobStatusTotals(jobStatusTotals)
        setStatus(isJobRunningStatus(activeJobs))
        setCurrentDisplayState(display)
        dontUpdate.current = true
      }, 3000)
    },
    [activeJobs, status]
  )

  useEffect(() => {
    setStatus(statusForJobs(activeJobs, jobStatusTotals, onUpdateJobs))
  }, [activeJobs, jobStatusTotals, onUpdateJobs])

  useEffect(() => {
    if (dontUpdate.current) {
      dontUpdate.current = false
      return
    }
    setCurrentDisplayState(getDisplayState(latestUpdatedJob, status))
  }, [latestUpdatedJob, status])

  return { status, currentDisplayState, currentlyRunningJobs, onUpdateJobs }
}

export const statusForJobs = (
  activeJobs: ActiveJobs,
  knownJobStatuses: JobStatusTotals,
  onUpdateJobs?: (totals: JobStatusTotals) => void
) => {
  const completeJobsTotal = getCompletedJobs(activeJobs).length
  const failedJobsTotal = getFailedJobs(activeJobs).length
  if (completeJobsTotal > knownJobStatuses.complete) {
    onUpdateJobs?.({ ...knownJobStatuses, complete: completeJobsTotal })
    return 'complete'
  } else if (failedJobsTotal > knownJobStatuses.failed) {
    onUpdateJobs?.({ ...knownJobStatuses, failed: failedJobsTotal })
    return 'failed'
  } else {
    return isJobRunningStatus(activeJobs)
  }
}

export const isJobRunningStatus = (activeJobs: ActiveJobs) => {
  return getCurrentlyRunningJobs(activeJobs).length > 0 ? 'active' : 'none'
}

export const getDisplayState = (
  latestUpdatedJob: Job | undefined,
  status: JobStatus
): CurrentDisplayState | undefined => {
  return latestUpdatedJob
    ? {
        text: jobStatusAndOperationToString(status, latestUpdatedJob.operation),
        status,
        operation: latestUpdatedJob.operation,
      }
    : undefined
}

export const jobStatusAndOperationToString = (
  status: JobStatus,
  operation?: JobOperationEnum
) => {
  switch (status) {
    case 'active':
      switch (operation) {
        case JobOperationEnum.DeleteRecords:
          return 'jobs.pill.status.delete.inProgress'
        case JobOperationEnum.Extract:
          return 'jobs.pill.status.extract.inProgress'
        case JobOperationEnum.Map:
          return 'jobs.pill.status.mapping.inProgress'
      }
      return 'jobs.pill.status.default.inProgress'
    case 'complete':
      switch (operation) {
        case JobOperationEnum.DeleteRecords:
          return 'jobs.pill.status.delete.complete'
        case JobOperationEnum.Extract:
          return 'jobs.pill.status.extract.complete'
        case JobOperationEnum.Map:
          return 'jobs.pill.status.mapping.complete'
      }
      return 'jobs.pill.status.default.complete'
    case 'failed':
      switch (operation) {
        case JobOperationEnum.DeleteRecords:
          return 'jobs.pill.status.delete.failed'
        case JobOperationEnum.Extract:
          return 'jobs.pill.status.extract.failed'
        case JobOperationEnum.Map:
          return 'jobs.pill.status.mapping.failed'
      }
      return 'jobs.pill.status.default.failed'
    case 'none':
      break
  }
}
