import {
  Button,
  Modal,
  ModalButtonRow,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import styled from 'styled-components'

const StyledSpan = styled.span`
  color: var(--color-primary);
`

export function SpaceEmpty() {
  return (
    <Modal
      content={
        <>
          <ModalHeader data-testid='modal-header-content'>
            Empty space created
          </ModalHeader>
          <ModalText>
            A space was created, but no configuration was applied. Set up a{' '}
            <StyledSpan>space:created</StyledSpan> listener to add structure and
            functionality to this space.
          </ModalText>
          <ModalButtonRow>
            <Button
              type='submit'
              onPress={() =>
                window.open(
                  'https://flatfile.com/docs/concepts/events',
                  '_blank'
                )
              }
              label='Learn more'
            />
          </ModalButtonRow>
        </>
      }
    />
  )
}
