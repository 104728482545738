import { Auth } from '@/auth/Auth'
import {
  DiffRecordsResponseData,
  Filter,
  GeneratedMutationResponse,
  GeneratedQueryResponse,
  JobResponse,
} from '@flatfile/api'
import { fromMaybe, safeGet } from '@flatfile/shared-ui'
import { BulkRowSelection, BulkRowSelectionType } from '@flatfile/turntable'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SearchFields } from '../apps/WorkbookApp/types'

type MutationWithId = {
  mutateRecord: string
  mutationId?: string
  searchFields?: SearchFields
  selection?: BulkRowSelection
  snapshotLabel?: string
  snapshotId?: string
}

// The assistant can do three things:
// 1. generate a mutation, by hitting the /assistant/generate-mutation endpoint
// 2. apply a mutation, by hitting the /jobs endpoint
// 3. generate a ffql query, by hitting the /assistant/generate-query endpoint
export const useAssistant = ({ sheetId }: { sheetId: string }) => {
  // Here is all the logic related to generating a mutateRecord function.
  const {
    mutate: generateMutation,
    mutateAsync: generateMutationAsync,
    isError: mutationIsError,
    isSuccess: mutationIsSuccess,
    data: mutationResponse,
    error: mutationError,
    reset: resetMutation,
  } = useMutation<GeneratedMutationResponse, Error, string>({
    mutationFn: async (command: string): Promise<GeneratedMutationResponse> => {
      const headers = {
        Authorization: `Bearer ${Auth.TOKEN}`,
      }
      const config = {
        sheetId,
        command,
      }
      try {
        const { data } = await axios.post(
          `${import.meta.env.VITE_BASE_API_URL}/assistant/generate-mutation`,
          config,
          { headers }
        )
        return data
      } catch (error: any) {
        // We don't want to expose the AxiosError to the client of useAssistant, so we just grab the first error message
        const data: any = fromMaybe(safeGet(error.response, ['data']), {})
        const errors = fromMaybe(data.errors, [])
        const message = safeGet<string>(errors[0], ['message'])
        throw new Error(message)
      }
    },
  })

  // Here is all the logic related to previewing a mutation.
  const {
    mutate: previewMutation,
    isError: previewIsError,
    error: previewError,
    isSuccess: previewIsSuccess,
    data: previewResponse,
    reset: resetPreview,
  } = useMutation<DiffRecordsResponseData, Error, MutationWithId>({
    mutationFn: async (
      mwid: MutationWithId
    ): Promise<DiffRecordsResponseData> => {
      const { mutateRecord, mutationId, searchFields, selection } = mwid

      const headers = {
        Authorization: `Bearer ${Auth.TOKEN}`,
      }

      const config = {
        sheetId,
        mutateRecord,
        mutationId,
        ...searchFields,
      } as unknown as any

      // If we have selected rows, set the ids to the row ids
      if (selection && selection.exceptions.length > 0) {
        config['ids'] = selection.exceptions
      }

      // If the selection is type None, the ids are a whitelist of rows to apply the mutation to.
      // That means that we need to remove all the non-id filtering q params from the config, otherwise the backend
      // will treat the ids as a blacklist of rows to exclude. (If the selection is type All, the ids are
      // a blacklist of rows to exclude, so we don't need to do anything.)
      if (
        selection &&
        selection.type === BulkRowSelectionType.NONE &&
        selection.exceptions.length > 0
      ) {
        delete config['filter']
        delete config['filterField']
        delete config['searchValue']
        delete config['searchField']
        delete config['q']
      }

      // This is a weird edge case where the front end and the backend behave differently.
      // The front-end implicitly treats a non-empty filterField as requesting all rows where that field is in error.
      // And it treats filter as applying at a record level. So that e.g.
      //   { filterField: 'name', filter: 'valid' }
      // means "show me all records where name is an error and where the row is valid", which is no rows.
      // The backend would treat that as "show me all records where name is valid", which is likely some rows.
      // So we need to handle this edge case by explicitly throwing the "no records" error that preview-mutation would throw.
      if (config.filterField && config.filter === Filter.Valid) {
        throw new Error('No records found matching the given filters')
      }

      try {
        const { data } = await axios.post(
          `${import.meta.env.VITE_BASE_API_URL}/jobs/preview-mutation`,
          config,
          {
            headers,
          }
        )
        const records = await data.data
        const after = records.map((diffRecord: any) => ({
          ...diffRecord,
          values: Object.fromEntries(
            Object.entries(diffRecord.values).map(([k, v]) => {
              const snapshotValue = (v as any).snapshotValue
              const value = (v as any).value
              return [k, { value: snapshotValue, snapshotValue: value }]
            })
          ),
        }))
        return after
      } catch (error: any) {
        // We don't want to expose the AxiosError to the client of useAssistant, so we just grab the first error message
        const data: any = fromMaybe(safeGet(error.response, ['data']), {})
        const errors = fromMaybe(data.errors, [])
        const message = safeGet<string>(errors[0], ['message'])
        throw new Error(message)
      }
    },
  })

  // Here is all the logic related to applying a generated mutateRecord function.
  const {
    mutate: applyMutation,
    mutateAsync: applyMutationAsync,
    isError: applyIsError,
    error: applyError,
    isSuccess: applyIsSuccess,
    data: applyResponse,
    reset: resetApply,
  } = useMutation({
    mutationFn: async (mwid: MutationWithId): Promise<JobResponse> => {
      const {
        mutateRecord,
        mutationId,
        searchFields,
        selection,
        snapshotLabel,
      } = mwid

      const headers = {
        Authorization: `Bearer ${Auth.TOKEN}`,
      }

      const config = {
        sheet: sheetId,
        mutateRecord,
        mutationId,
        snapshotLabel,
        ...searchFields,
      } as unknown as any

      // If we have selected rows, set the ids to the row ids
      if (selection && selection.exceptions.length > 0) {
        config['ids'] = selection.exceptions
      }

      // If the selection is type None, the ids are a whitelist of rows to apply the mutation to.
      // That means that we need to remove all the non-id filtering q params from the config, otherwise the backend
      // will treat the ids as a blacklist of rows to exclude. (If the selection is type All, the ids are
      // a blacklist of rows to exclude, so we don't need to do anything.)
      if (
        selection &&
        selection.type === BulkRowSelectionType.NONE &&
        selection.exceptions.length > 0
      ) {
        delete config['filter']
        delete config['filterField']
        delete config['searchValue']
        delete config['searchField']
        delete config['q']
      }

      const jobRequest = {
        type: 'sheet',
        operation: 'mutate-records',
        source: sheetId,
        config,
        trigger: 'immediate',
        mode: 'foreground',
        progress: 100,
      }

      const { data } = await axios.post(
        `${import.meta.env.VITE_BASE_API_URL}/jobs`,
        jobRequest,
        {
          headers,
        }
      )
      return data.data
    },
  })

  // Here is all the logic related to generating ffql.
  const {
    mutate: generateQuery,
    isError: queryIsError,
    isSuccess: queryIsSuccess,
    data: queryResponse,
    reset: resetQuery,
  } = useMutation({
    mutationFn: async (command: string): Promise<GeneratedQueryResponse> => {
      const headers = {
        Authorization: `Bearer ${Auth.TOKEN}`,
      }
      const config = {
        sheetId,
        command,
      }

      const { data } = await axios.post(
        `${import.meta.env.VITE_BASE_API_URL}/assistant/generate-query`,
        config,
        { headers }
      )
      return data
    },
  })

  const ask = (command: string) => generateMutation(command)

  const apply = (
    mutateRecord: string,
    mutationId?: string,
    searchFields?: SearchFields,
    selection?: BulkRowSelection
  ) =>
    applyMutation({
      mutateRecord,
      mutationId,
      searchFields,
      selection,
    })

  const preview = (
    mutateRecord: string,
    mutationId?: string,
    searchFields?: SearchFields,
    selection?: BulkRowSelection
  ) => previewMutation({ mutateRecord, mutationId, searchFields, selection })

  /* c8 ignore next 6 */
  const reset = () => {
    resetMutation()
    resetPreview()
    resetApply()
    resetQuery()
  }

  const emptyMutation = mutationResponse && !mutationResponse.data?.mutateRecord

  // Consult the AI
  return {
    ask,
    apply,
    preview,
    generateQuery,
    mutationIsSuccess,
    mutationIsError,
    mutationResponse,
    mutationError,
    previewIsSuccess,
    previewIsError,
    previewError,
    previewResponse,
    applyIsSuccess,
    applyIsError,
    applyResponse,
    queryIsSuccess,
    queryIsError,
    queryResponse,
    isError:
      mutationIsError ||
      previewIsError ||
      applyIsError ||
      queryIsError ||
      emptyMutation,
    generateMutationAsync,
    applyMutationAsync,
    reset,
  }
}
