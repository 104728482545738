import { useContext } from 'react'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ErrorState } from '../EmptyState'
import { useRouteParams } from '../../hooks/useRouteParams'
import { Document } from './Document'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { ContentSkeleton } from '../SpacesUISkeleton'

export function DocumentView() {
  const { documentId } = useRouteParams()
  const { sidebarCollapsed } = useContext(SpaceContext)

  const {
    data: document,
    isLoading,
    isFetching,
    isError,
  } = useFlatfileQuery(
    'getDocumentById',
    { documentId: documentId! },
    { enabled: !!documentId }
  )
  const { t } = useTypedTranslation()

  sidebarCollapsed?.set(false)
  if (isLoading && isFetching) {
    return ContentSkeleton()
  }
  if (!documentId || !document?.data || isError) {
    return (
      <ErrorState
        title={t('document.error.title')}
        message={t('document.error.description')}
        refresh={false}
      />
    )
  }
  return <Document document={document.data} />
}
