import styled from 'styled-components'

export enum Justify {
  start = 'flex-start',
  end = 'flex-end',
  around = 'space-around',
  between = 'space-between',
  center = 'center',
}

export const Row = styled.div<{ $justify?: Justify; $gap?: string }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.$justify ? props.$justify : 'normal')};
  gap: ${(props) => (props.$gap ? props.$gap : '2em')};
`
