import { DependencyList, EffectCallback, useEffect } from 'react'

/**
 * Only run this effect if the dependency list is all fully present and non-null
 *
 * @param cb
 * @param deps
 */
export function usePresentEffect(cb: EffectCallback, deps: DependencyList) {
  useEffect(() => {
    if (!deps.some((v) => v === undefined || v === null)) {
      cb()
    }
  }, deps)
}
