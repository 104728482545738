import Skeleton from 'react-loading-skeleton'
import { MainContent } from '../../elements/MainContent'
import { ContentSkeletonMessage, ContentSkeletonOverlay } from './elements'

export const ContentSkeleton = (name?: string, message?: string) => (
  <MainContent data-testid='loading-skeleton'>
    <h1>
      <Skeleton width='20em' baseColor='var(--color-skeleton)' />
    </h1>
    <div>
      <Skeleton count={3} baseColor='var(--color-skeleton)' />
      <ContentSkeletonOverlay>
        {name && <h1>{name}</h1>}
        {message && <ContentSkeletonMessage>{message}</ContentSkeletonMessage>}
      </ContentSkeletonOverlay>
    </div>
  </MainContent>
)
