import { getMenuOptionFromAction } from '@/components/Jobs/utils'
import { ActionsModalContext } from '@/contexts/ActionsModalContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { renderMarkdownAsHtml } from '@/utils/markdown'
import {
  BasePropertyAppearanceSizeEnum,
  JobModeEnum,
  JobSubjectType,
  JobTriggerEnum,
  JobTypeEnum,
  Property,
  Sheet,
  Workbook,
} from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { ColumnConfigProps, EColumnType } from '@flatfile/turntable'
import { MutableRefObject, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { JobInput } from './useCustomActions'
import { JobQuery } from './useJobQuery'

interface UseFieldDataProps {
  sheet?: Sheet
  workbook: Workbook
  getJobQueryRef?: MutableRefObject<() => JobQuery>
}

const DEFAULT_FIELD_DATA: ColumnConfigProps[] = []

export const useFieldData = ({
  sheet,
  workbook,
  getJobQueryRef,
}: UseFieldDataProps) => {
  const { httpClient } = useContext(SpaceContext)

  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const { setCurrentAction } = useContext(ActionsModalContext)

  const getOnClickAction =
    (sheetId: string, fieldKey: string) =>
    (operation: string, mode?: JobModeEnum) =>
    async (input?: JobInput) => {
      const query =
        getJobQueryRef && getJobQueryRef.current ? getJobQueryRef.current() : {}

      await httpClient.createJob({
        jobConfig: {
          type: JobTypeEnum.Sheet,
          operation: operation,
          trigger: JobTriggerEnum.Immediate,
          source: sheet?.id!,
          input: input,
          mode,
          subject: {
            type: JobSubjectType.Collection,
            resource: 'records',
            query,
            params: {
              sheetId,
              columnKey: fieldKey,
            },
          },
        },
      })
    }

  const getFieldOptions = useCallback(
    (field: Property) => {
      if (
        field.type !== 'reference' &&
        field.type !== 'enum' &&
        field.type !== 'enum-list' &&
        field.type !== 'reference-list'
      ) {
        return undefined
      }

      if (field.type === 'enum' || field.type === 'enum-list') {
        return field.config?.options?.map(({ value, label, description }) => ({
          value,
          label: label ?? value,
          description,
          key: value,
        }))
      }

      const sheet = workbook.sheets?.find(
        (s) => s.config?.slug === field.config?.ref
      )
      const fieldKey = field.config?.key

      if (!sheet || !fieldKey) return undefined

      return async (
        searchValue?: string,
        pageNumber?: number,
        pageSize?: number
      ) => {
        const params = {
          sheetId: sheet.id,
          fieldKey,
          pageNumber,
          pageSize,
          distinct: true,
        }
        // TODO replace with useFlatfileQuery
        const options = await httpClient.getCellValues(
          searchValue ? { ...params, searchValue } : params
        )
        return (
          options?.data?.[fieldKey]?.map((item) => ({
            value: item.value ?? '',
            label: (item.value ?? '').toString(),
            key: item.value ?? '',
          })) || []
        )
      }
    },
    [workbook.sheets]
  )

  const columnConfig = useMemo(() => {
    const fields = sheet?.config?.fields
    if (!fields || !fields.length) {
      return DEFAULT_FIELD_DATA
    }
    return fields.reduce(
      (memo: ColumnConfigProps[], field: Property, index) => {
        const sheetId = sheet.id
        const fieldKey = field.key
        const fieldLabel = field.label ?? field.key
        const fieldType = getFieldType(field)

        const html = renderMarkdownAsHtml(fromMaybe(field.description, ''))
        memo.push({
          value: fieldKey,
          label: fieldLabel,
          description: html,
          width: getWidthFromAppearanceSize(field.appearance?.size),
          disabled: field.readonly,
          type: fieldType as EColumnType,
          required: !!field.constraints?.find((c) => c?.type === 'required'),
          options: getFieldOptions(field),
          menuOptions: (field.actions || []).map((action) =>
            getMenuOptionFromAction(
              action,
              getOnClickAction(sheetId, fieldKey),
              setCurrentAction,
              customT,
              t
            )
          ),
          index,
        })
        return memo
      },
      []
    )
  }, [sheet])

  return { columnConfig }
}

function getWidthFromAppearanceSize(size?: BasePropertyAppearanceSizeEnum) {
  switch (size) {
    case 'xs':
      return 100
    case 's':
      return 140
    case 'l':
      return 300
    case 'xl':
      return 400
    case 'm':
    default:
      return 200
  }
}

const getFieldType = (field: Property) => {
  if (field.type === 'reference') {
    return 'enum'
  }

  if (field.type === 'reference-list') {
    return 'enum-list'
  }

  return field.type ?? 'string'
}
