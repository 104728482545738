import { useCallback, useEffect, useState } from 'react'

type SidebarOptions = { sheet?: boolean }
type GetSidebarCollapsed = (options?: SidebarOptions) => boolean
type SetSidebarCollapsed = (newValue: boolean, options?: SidebarOptions) => void

export interface SidebarCollapsed {
  get: GetSidebarCollapsed
  set: SetSidebarCollapsed
}

export function useSidebarCollapsed(
  override?: boolean,
  isGuest?: boolean
): SidebarCollapsed {
  const getInitalValue = useCallback(
    () => (isGuest ? !!override : false),
    [override, isGuest]
  )
  const [collapsed, setCollapsed] = useState(getInitalValue())
  const [sheetCollapsed, setSheetCollapsed] = useState(collapsed)

  useEffect(() => {
    if (override !== undefined) {
      setCollapsed(getInitalValue())
    }
  }, [getInitalValue])

  const get: GetSidebarCollapsed = (options) => {
    return options?.sheet ? sheetCollapsed : collapsed
  }

  const set: SetSidebarCollapsed = (newValue, options) => {
    const canSet = !(override === true && isGuest)
    if (!canSet) {
      return
    }
    setTimeout(() => {
      if (options?.sheet) {
        setSheetCollapsed(newValue)
      }
      if (collapsed !== newValue) {
        setCollapsed(newValue)
      }
    })
  }

  return { get, set }
}
