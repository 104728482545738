import {
  Counts,
  WorkbookCountsContext,
} from '@/apps/WorkbookApp/contexts/WorkbookCountsContext'
import EllipsisTooltip from '@/components/Shared/EllipsisTooltip'
import useScrollToLink from '@/hooks/useScrollToLink'
import { useSheetAccess } from '@/hooks/useSheetAccess'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { formatNumber } from '@/utils/formatNumber'
import { isConnectedWorkbook, isPinnedWorkbook } from '@/utils/workbooks'
import { Sheet, Workbook } from '@flatfile/api'
import { Icon } from '@flatfile/design-system'
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ElementOrSpinner } from '../../ElementOrSpinner'
import { ReadOnlyIcon } from '../../ReadOnly'
import {
  SidebarCountPill,
  SidebarItemEmptyText,
  SidebarItemText,
  SidebarList,
  SidebarListItem,
  SidebarSheetIconWrapper,
} from '../elements'
import { ConnectionIcon } from './ConnectionIcon'

export enum SheetStatus {
  Empty = 'empty',
  Error = 'error',
}

export const SidebarItem = forwardRef(
  (
    {
      active,
      to,
      children,
      list,
      onClick,
      ...props
    }: {
      active?: boolean
      to?: string
      children?: React.ReactNode
      list?: React.ReactNode
      title?: string
      onClick?: () => void
    },
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    return (
      <SidebarListItem
        {...props}
        className={active ? 'active' : ''}
        onClick={(e) => {
          e.stopPropagation()
          onClick?.()
        }}
      >
        {to ? (
          <NavLink ref={ref} to={to} className={list ? 'header-link' : ''}>
            {children}
          </NavLink>
        ) : (
          children
        )}
        {list}
      </SidebarListItem>
    )
  }
)

SidebarItem.displayName = 'forwardRef(SidebarItem)'

const getSheetStatus = (hasRecords?: boolean, hasErrors?: boolean) => {
  if (hasErrors) return SheetStatus.Error
  if (!hasRecords) return SheetStatus.Empty
}

const SidebarCount = ({ total }: { total: string }) => (
  <SidebarCountPill empty={total === '0'} data-testid='sidebar-count'>
    {total}
  </SidebarCountPill>
)

const emptyCounts = {
  total: 0,
  valid: 0,
  error: 0,
  isFetching: false,
}

function getDisplayCount(counts?: Counts | typeof emptyCounts) {
  if (counts) {
    if (counts.isFetching) {
      return '-'
    } else {
      return formatNumber(counts.total)
    }
  }
  return '-'
}

export const SidebarSheetItem = ({
  sheet,
  to,
  icon,
}: {
  sheet: Sheet
  to: string
  icon?: JSX.Element
}) => {
  const linkRef = useScrollToLink(to, 'horizontal')
  const { workbookCounts } = useContext(WorkbookCountsContext)
  const counts = workbookCounts ? workbookCounts[sheet.id] : emptyCounts
  const { readOnlySheet } = useSheetAccess(sheet)
  const [memoizedSidebarCount, memoizedIcon] = useMemo(() => {
    const hasRecords = counts && counts?.total > 0
    const hasErrors = counts && counts?.error > 0
    const sheetStatus = getSheetStatus(hasRecords, hasErrors)
    const iconName = hasErrors ? 'tableDot' : 'table'
    const displayCount = getDisplayCount(counts)

    const memoizedSidebarCount = <SidebarCount total={displayCount} />
    const memoizedIcon = (
      <ElementOrSpinner id={sheet.id}>
        {!counts?.isFetching ? (
          <SidebarSheetIconWrapper status={sheetStatus}>
            {icon || <Icon name={iconName} />}
          </SidebarSheetIconWrapper>
        ) : (
          <SidebarSheetIconWrapper>
            {icon || <Icon name={'table'} />}
          </SidebarSheetIconWrapper>
        )}
      </ElementOrSpinner>
    )
    return [memoizedSidebarCount, memoizedIcon]
  }, [counts])

  return (
    <EllipsisTooltip value={sheet.name} lineClamp={1} place='top'>
      <SidebarItem ref={linkRef} to={to}>
        {memoizedIcon}
        <SidebarItemText>{sheet.name}</SidebarItemText>
        {readOnlySheet && <ReadOnlyIcon />}
        {memoizedSidebarCount}
      </SidebarItem>
    </EllipsisTooltip>
  )
}

export const SidebarWorkbookItem = ({
  workbook,
  spaceRoot,
  single,
}: {
  workbook: Workbook
  spaceRoot: string
  single?: boolean
}) => {
  const { t } = useTypedTranslation()
  const baseLink = `${spaceRoot}/workbook/${workbook.id}`
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const isActive = (p: string) => {
    return location.pathname.startsWith(p)
  }

  const handleSidebarItemClick = useCallback(() => {
    // Toggle collapsed state if active, or set to false if not active
    setCollapsed(isActive(baseLink) ? (prev) => !prev : false)
  }, [baseLink, isActive])

  const getWorkbookIcon = () => {
    switch (true) {
      case isConnectedWorkbook(workbook):
        return <ConnectionIcon workbook={workbook} />
      case isPinnedWorkbook(workbook):
        return <Icon name='pin' data-testid='pin-icon' />
      default:
        return <Icon name='database' />
    }
  }

  const getSheetIcon = () => {
    if (single && isConnectedWorkbook(workbook)) {
      return <ConnectionIcon workbook={workbook} />
    } else return undefined
  }

  if (single) {
    return (
      <>
        {workbook.sheets?.map((s) => (
          <SidebarSheetItem
            key={s.id}
            to={`${baseLink}/sheet/${s.id}`}
            sheet={s}
            icon={getSheetIcon()}
          />
        ))}
      </>
    )
  }

  if (!workbook.sheets?.length) {
    return (
      <SidebarItem key={workbook.id}>
        <SidebarItemEmptyText>
          {t('sidebar.emptyWorkbook')}
        </SidebarItemEmptyText>
      </SidebarItem>
    )
  }

  return (
    <SidebarItem
      data-testid={`${workbook.name}-list-item`}
      onClick={handleSidebarItemClick}
      active={isActive(baseLink)}
      to={`${baseLink}/sheet/${workbook.sheets![0].id}`}
      list={
        isActive(baseLink) && !collapsed ? (
          <SidebarList>
            {workbook.sheets?.map((s) => (
              <SidebarSheetItem
                key={s.id}
                to={`${baseLink}/sheet/${s.id}`}
                sheet={s}
                icon={getSheetIcon()}
              />
            ))}
          </SidebarList>
        ) : (
          <></>
        )
      }
    >
      <ElementOrSpinner id={workbook.id}>{getWorkbookIcon()}</ElementOrSpinner>
      <SidebarItemText>{workbook.name}</SidebarItemText>
    </SidebarItem>
  )
}
