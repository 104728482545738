import { HTTPService } from '@/components/HttpClient'
import {
  DeleteViewRequest,
  UpdateViewOperationRequest,
  ViewConfig,
} from '@flatfile/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getViewsKey } from '../queries/views.queries'

const createViewKey = 'createView'
const updateViewKey = 'updateView'
const deleteViewKey = 'deleteView'

/**
 * Wrapper around the mutation needed to add an AI Assist prompt to a user's account.
 * Upon adding a new prompt, any queries for prompts should automatically be
 * invalidated
 *
 * @returns react query mutation object
 */
export const useCreateViewMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [createViewKey],
    async (createViewRequest: {
      name?: string
      sheetId: string
      config: ViewConfig
    }) => {
      const name = createViewRequest.name
        ? createViewRequest.name
        : `My View ${Date.now()}`
      return await HTTPService.createView({
        createViewRequest: { ...createViewRequest, name },
      })
    },
    { onSuccess: () => queryClient.invalidateQueries([getViewsKey]) }
  )
}

export const useUpdateViewMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [updateViewKey],
    async (updateViewOperationRequest: UpdateViewOperationRequest) => {
      const { viewId, updateViewRequest } = updateViewOperationRequest
      return await HTTPService.updateView({ viewId, updateViewRequest })
    },
    { onSuccess: () => queryClient.invalidateQueries([getViewsKey]) }
  )
}

export const useDeleteViewMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [deleteViewKey],
    async (deleteViewRequest: DeleteViewRequest) => {
      return await HTTPService.deleteView({ viewId: deleteViewRequest.viewId })
    },
    { onSuccess: () => queryClient.invalidateQueries([getViewsKey]) }
  )
}
