import { useCallback, useRef } from 'react'

import { DiffTableContent } from './DiffTableContent'
import {
  DiffViewEmbeddedContainer,
  DiffViewFullScreenContainer,
  DiffViewOverlay,
} from './elements'
import { DiffAction, DiffTableProps } from './types'

export const DiffTable = (props: DiffTableProps) => {
  const overlayRef = useRef(null)

  const handleOverlayClick = useCallback(
    (e: React.MouseEvent) => {
      if (props.action === DiffAction.ACCEPT) {
        return
      }
      const target = e?.target
      const overlay = overlayRef?.current
      if (target && overlay && target === overlay) {
        props.onClose()
      }
    },
    [props.onClose]
  )

  return (
    <>
      {props.embedded ? (
        <DiffViewEmbeddedContainer data-testid='embedded-diff-container'>
          <DiffTableContent {...props} />
        </DiffViewEmbeddedContainer>
      ) : (
        <DiffViewOverlay
          ref={overlayRef}
          onClick={handleOverlayClick}
          data-testid='diff-table-overlay'
        >
          <DiffViewFullScreenContainer>
            <DiffTableContent {...props} />
          </DiffViewFullScreenContainer>
        </DiffViewOverlay>
      )}
    </>
  )
}
