import { PageHeader } from '@/components/PageHeader'
import { MainContent } from '@/elements/MainContent'
import { List, Pagination } from '@flatfile/design-system'

import { ACTIVITY_LOGS_PAGE_SIZE, useActivityView } from './useActivityView'
import styled from 'styled-components'
import React from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ErrorState } from '../../components/EmptyState'

const Container = styled.div`
  min-width: 800px;
`

export const ActivityView = () => {
  const {
    listData,
    activityListHeaders,
    dropdownFilter,
    dateDropdown,
    onChangePage,
    currentPage,
    totalCount,
    isLoading,
    isError,
  } = useActivityView()

  return (
    <MainContent data-testid='admin-view'>
      <PageHeader title='Activity logs' />
      <Container>
        <List
          headers={activityListHeaders}
          data={listData}
          dropdownFilter={dropdownFilter}
          dateDropdown={dateDropdown}
          loading={isLoading}
          error={isError}
          components={{
            empty: <> No activity logs found</>,
            error: <ErrorState title='Error fetching activity logs' />,
            loading: <LoadingSpinner />,
          }}
        ></List>
        <Pagination
          onPageChange={onChangePage}
          currentPage={currentPage}
          testId='spaces-pagination'
          totalCount={totalCount}
          pageSize={ACTIVITY_LOGS_PAGE_SIZE}
        />
      </Container>
    </MainContent>
  )
}
