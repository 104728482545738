import { logger } from '@/utils/logging'
import { DefaultApi, EventTopic } from '@flatfile/api'
import {
  PopoverContext,
  PopoverMessageFullWidth,
  WarningIcon,
} from '@flatfile/shared-ui'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FileController,
  MapToSheetProps,
} from '../api/controllers/FileController'
import { useController } from '../api/controllers/useController'
import { useAction } from '../api/observable'
import { useEventSubscriber } from '../hooks/useEventSubscriber'
import { ActiveJobs, JobsContext } from './JobsContext'
import { SpaceContext } from './SpaceContext'
import { UserContext } from './UserContext'

interface FileUploadRedirectContextType {
  importToSheet?: ImportToSheet
}

interface ImportToSheet {
  workbookId: string
  sheetId: string
}

export const FileUploadRedirectContext =
  createContext<FileUploadRedirectContextType>({})

export const FileUploadRedirectProvider = (props: {
  children: JSX.Element
  importToSheet?: ImportToSheet
}) => {
  useFileUploadRedirect(props.importToSheet)
  return (
    <FileUploadRedirectContext.Provider
      value={{
        importToSheet: props.importToSheet,
      }}
    >
      {props.children}
    </FileUploadRedirectContext.Provider>
  )
}

export const useFileUploadRedirect = (importToSheet?: ImportToSheet) => {
  const navigate = useNavigate()
  const [navigateOnUpload, setNavigateOnUpload] = useState<boolean>(false)
  const { actor } = useContext(UserContext)
  const [fileId, setFileId] = useState<string>()
  const { activeJobs } = useContext(JobsContext)
  const { space, httpClient } = useContext(SpaceContext)
  const { showPopover } = useContext(PopoverContext)

  useEventSubscriber(
    [EventTopic.Filecreated],
    (event: any, message: Record<string, any>) => {
      if (message && message.context.spaceId === space.id) {
        /**
         * Temporary fix for lack of UserId within the /init endpoint
         * used for embedded portals.
         *
         * TODO: Update this logic once the userId is confidently available
         */
        if (message.context.actorId === actor?.id || !actor?.id) {
          setNavigateOnUpload(true)
          if (message.context?.fileId) {
            setFileId(message.context?.fileId)
          }
        }
      }
    }
  )

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [mapToSheet, mapToSheetObservable] = useAction(
    fileController.mapToSheet(),
    (job) => {
      navigate(`/space/${space.id}/job/${job.id}`)
    }
  )

  const goToImport = (fileId: string) =>
    navigate(`/space/${space.id}/files/${fileId}/import`)

  useEffect(() => {
    handleNavigate(
      importToSheet,
      navigateOnUpload,
      fileId,
      activeJobs,
      mapToSheet,
      goToImport,
      httpClient
    )
  }, [importToSheet, navigateOnUpload, fileId, activeJobs])

  useEffect(() => {
    if (mapToSheetObservable.error?.message) {
      showPopover({
        icon: <WarningIcon name='alertTriangle' />,
        message: (
          <PopoverMessageFullWidth>
            {mapToSheetObservable.error?.message}
          </PopoverMessageFullWidth>
        ),
      })
    }
  }, [mapToSheetObservable.error])

  return { navigateOnUpload }
}

export const handleNavigate = async (
  importToSheet: ImportToSheet | undefined,
  navigateOnUpload: boolean,
  fileId: string | undefined,
  activeJobs: ActiveJobs,
  mapToSheet: (props: MapToSheetProps) => void,
  goToImport: (fileId: string) => void,
  httpClient: DefaultApi
) => {
  const relevantJob = Object.values(activeJobs ?? []).find(
    (j) => !(j.status === 'executing') && j.fileId === fileId
  )
  if (importToSheet && navigateOnUpload && fileId && relevantJob) {
    try {
      const fileResponse = await httpClient.getFile({ fileId })
      const file = fileResponse.data
      // TODO replace with useQueryGetWorkbookById
      const { data: workbook } = await httpClient.getWorkbookById({
        workbookId: file.workbookId!,
      })
      if (!workbook || !workbook.sheets) return
      if (workbook.sheets.length === 1) {
        mapToSheet({
          sourceWorkbookId: workbook.id,
          sourceSheetId: workbook.sheets[0].id,
          destinationSheetId: importToSheet.sheetId,
          destinationWorkbookId: importToSheet.workbookId,
        })
      }
      // navigate to sheet selection for multisheet files
      if (workbook.sheets.length > 1) {
        goToImport(fileId)
      }
    } catch (error) {
      logger.warn('error navigating to file upload redirect', error)
    }
  }
}
