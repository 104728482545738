import { JobController } from '@/api/controllers/JobController'
import { useController } from '@/api/controllers/useController'
import { useObservable } from '@/api/observable'
import { guard } from '@/api/resources/GuardResources'
import DevBanner from '@/components/DevBanner/DevBanner'
import { EnvironmentsContext } from '@/contexts/EnvironmentsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { Container } from '@/elements/Container'
import { Job } from '@flatfile/api'
import { useContext } from 'react'
import { MapRouter } from './MapRouter'

export function MapGuard({
  job,
  onLoadJob,
}: {
  job: Job
  onLoadJob: (jobId: string) => void
}) {
  const spaceContext = useContext(SpaceContext)
  const controller = useController(JobController, job, spaceContext.httpClient)
  const { environment } = useContext(EnvironmentsContext)

  const [plan] = useObservable(controller.getPlan(), { jobId: job.id }, 'plan')
  const [sheets] = useObservable(controller.getSheets(), job.id, 'sheets')
  const [data] = useObservable(
    controller.getPreviewRecords(),
    job.id,
    'preview'
  )

  const [headerData] = useObservable(
    controller.getHeaderRecords(),
    job.id,
    'preview-headers'
  )

  const handleResetMapping = (jobId: string) => {
    onLoadJob(jobId)
    plan.setData()
    sheets.setData()
    data.setData()
  }

  // todo - fix this loading UI
  return (
    <Container>
      {guard([plan, sheets, data, headerData]) ?? (
        <>
          {environment && <DevBanner environment={environment} />}
          <MapRouter
            spaceUrl={`/space/${spaceContext.space.id}`}
            plan={plan.ensured.data}
            sheets={sheets.ensured.data}
            recordData={data.ensured.data}
            headerRecordData={headerData.ensured.data}
            job={job}
            environment={environment}
            onResetMapping={handleResetMapping}
          />
        </>
      )}
    </Container>
  )
}
