import { storage } from '@flatfile/shared-ui'
import { ColumnConfigProps } from '@flatfile/turntable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ColumnStateProps,
  ColumnsState,
  UnhideColumns,
  UseColumnManagementProps,
} from './types'
import { logger } from '@/utils/logging'

export const getStorageId = (sheetId: string) => `${sheetId}:columns`

const safeJsonParse = ({
  jsonString,
  defaultValue = {},
  sheetId,
}: {
  jsonString?: string
  defaultValue?: ColumnsState
  sheetId: string
}) => {
  if (!jsonString) return defaultValue
  try {
    return JSON.parse(jsonString) as ColumnsState
  } catch (error) {
    logger.error(
      `Unable to parse localStorage state for Sheet: ${sheetId}`,
      error
    )
    return defaultValue
  }
}
/**
 * This hook takes the column config for a sheet in a workbook, manages
 * a user's preferences for how the columns are displayed, and then combines
 * those preferences with the config.
 */
export const useColumnManagement = ({
  columnConfig,
  sheetId,
  workbookId,
}: UseColumnManagementProps) => {
  const storageId = getStorageId(sheetId)

  const getStartingState = (): ColumnsState => {
    //sets all fields to visible
    const startingState = columnConfig.reduce<ColumnsState>((memo, column) => {
      memo[column.value] = {
        value: column.value,
        hidden: false,
      } as unknown as ColumnStateProps
      return memo
    }, {} as ColumnsState)
    return startingState
  }

  const getInitColumnsState = (): ColumnsState => {
    const startingState = getStartingState()
    const columns = storage(storageId).get()
    const initialState = safeJsonParse({
      jsonString: columns,
      defaultValue: startingState,
      sheetId,
    })
    storage(storageId).set(JSON.stringify(initialState))

    return initialState
  }

  const [columns, setColumns] = useState(() => getInitColumnsState())

  const setColumnsState = useCallback(
    (state: ColumnsState) => {
      storage(storageId).set(JSON.stringify(state))
      setColumns(state)
    },
    [storageId]
  )

  const unhideColumns: UnhideColumns = useCallback(
    (keys) => {
      const newState = { ...columns }

      keys.forEach((key) => {
        const column = newState[key]
        if (column) {
          newState[key] = {
            ...column,
            hidden: false,
          }
        }
      })

      setColumnsState(newState)
    },
    [columns, setColumnsState]
  )

  const { columnConfigWithState, hiddenColumnsCount, allColumnsHidden } =
    useMemo(() => {
      let hiddenColumns = 0
      const filtered = columnConfig.reduce((memo, c, index) => {
        const state = columns?.[c.value]
        const hidden = state?.hidden
        if (hidden) {
          hiddenColumns++
        } else {
          memo.push({
            ...c,
            index,
          })
        }
        return memo
      }, [] as ColumnConfigProps[])
      return {
        columnConfigWithState: filtered,
        hiddenColumnsCount: hiddenColumns,
        allColumnsHidden: hiddenColumns === columnConfig.length,
      }
    }, [columns, columnConfig, storageId])

  useEffect(() => {
    const handleStorageChange = () => {
      const localState = storage(storageId).get()
      const parsedLocalState = safeJsonParse({
        jsonString: localState,
        defaultValue: undefined,
        sheetId,
      })
      if (parsedLocalState && localState !== JSON.stringify(columns)) {
        setColumnsState(parsedLocalState)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [setColumnsState, storageId, columns, sheetId])

  return {
    allColumnsHidden,
    columnsState: columns,
    columnConfigWithState,
    hiddenColumnsCount,
    setColumnsState,
    unhideColumns,
  }
}
