import React from 'react'
import { CommandItem, Explain } from './elements'
import { CommandMenuPill } from './CommandMenuPill'
import { CommandMenuItemProps } from './types'

export const CommandMenuItem = ({
  children,
  onMenuItemSelection,
  displayCommand = false,
  id,
}: CommandMenuItemProps) => {
  return (
    <>
      <CommandItem key={id} value={id} onSelect={onMenuItemSelection}>
        {displayCommand && <CommandMenuPill id={id} />}
        <Explain>{children}</Explain>
      </CommandItem>
    </>
  )
}
