import { Space } from '@flatfile/api'
import { Button, getFont } from '@flatfile/design-system'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  DownloadFileProps,
  FILES_PAGE_SIZE,
  Files,
} from '@/api/controllers/FileController'
import { PageHeader } from '@/components/PageHeader'
import { TabStrip } from '@/components/TabStrip'
import { UsageBanner } from '@/components/UsageBanner'
import { ButtonRow } from '@/elements/ButtonRow'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { EmptyWorkbookAlert } from '../../WorkbookApp/EmptyWorkbookAlert'
import { FileMode, FileModeObject } from '../types'
import { FileUploadButton } from './FileUploadButton'
import { ConnectionsButton } from './ConnectionsButton'

const FilesCount = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  flex-grow: 1;
  text-align: right;
  color: var(--color-text-light);
`

const FilesTabStrip = ({
  fileUrl,
  fileMode,
  fileModeObject,
}: {
  fileUrl: string
  fileMode: string
  fileModeObject: FileModeObject
}) => {
  return (
    <TabStrip>
      {Object.entries(fileModeObject).map((mode) => (
        <NavLink
          className={(isActive) =>
            fileMode === mode[0] && isActive ? 'active' : undefined
          }
          key={mode[0]}
          to={`${fileUrl}?mode=${mode[0]}`}
        >
          {mode[1]}
        </NavLink>
      ))}
    </TabStrip>
  )
}

export const FilesHeader = ({
  fileMode,
  fileUrl,
  filesResponse,
  fileModeObject,
  selectedFileIds,
  setFileIdsToDelete,
  onClickDownload,
  space,
  isLoading,
}: {
  fileMode: FileMode
  fileUrl: string
  filesResponse: Files
  fileModeObject: FileModeObject
  selectedFileIds: string[]
  setFileIdsToDelete: React.Dispatch<React.SetStateAction<string[]>>
  onClickDownload: (props?: DownloadFileProps[] | undefined) => void
  space: Space
  isLoading: boolean
}) => {
  const { t } = useTypedTranslation()
  const filesCount = useMemo(() => {
    if (!filesResponse?.pagination?.totalCount || !filesResponse?.files) return

    const current = filesResponse?.files.length // Files currently in view
    const total = filesResponse?.pagination.totalCount // Total number of files
    const page = filesResponse?.pagination.currentPage // Current page -- starts at 1
    const offset = (page - 1) * FILES_PAGE_SIZE

    return t('files.counts', {
      start: offset + 1,
      end: offset + current,
      total,
    })
  }, [filesResponse])

  return (
    <>
      <PageHeader title={t('files.title')} />
      <EmptyWorkbookAlert />
      <UsageBanner />

      <FilesTabStrip
        fileMode={fileMode}
        fileUrl={fileUrl}
        fileModeObject={fileModeObject}
      />

      <ButtonRow>
        <Button
          variant='tertiary'
          iconLeft='trash'
          onPress={() => setFileIdsToDelete(selectedFileIds)}
          label={t('files.buttons.delete')}
          isDisabled={selectedFileIds.length < 1}
          size='sm'
        />
        <Button
          variant='tertiary'
          iconLeft='download'
          onPress={() =>
            onClickDownload(selectedFileIds.map((id) => ({ fileId: id })))
          }
          label={t('files.buttons.download')}
          isDisabled={selectedFileIds.length < 1}
          size='sm'
        />
        {fileMode === 'import' && (
          <>
            <FileUploadButton
              space={space}
              variant='tertiary'
              isDisabled={isLoading}
              multiple
            />
            <ConnectionsButton variant='tertiary' isDisabled={isLoading} />
          </>
        )}
        {filesCount ? <FilesCount>{filesCount}</FilesCount> : null}
      </ButtonRow>
    </>
  )
}
