import { RefObject, useEffect } from 'react'

/**
 * Utility hook meant to be used in components which need to perform state / behavior adjustments based on the size
 * of another element. The passed in elementRef will established as the "observed" entity - and the "entries" obtained
 * from ResizeObserver will then be passed along for use in the supplied callback
 *
 * Example Usage
 *
 * // MyComponent
 *
 * const ref = useRef(null)
 *
 * const myCallback = useCallback((element, entries) => {
 *  // Perform logic using element and/or entries
 * })
 *
 * useResizeObserver(myCallback)
 *
 * return <div ref={ref}>
 *
 * @param elementRef Reference to HTML element which will be observed for size changes
 * @param callback Callback function which will receive the element, and ResizeObserver entries as arguments
 */
function useResizeObserver<T extends HTMLElement>(
  elementRef: RefObject<T>,
  callback: (target: T, entry: ResizeObserverEntry) => void
) {
  useEffect(() => {
    const element = elementRef?.current
    let observer: ResizeObserver

    if (element) {
      // Creates the resize observer instance and passes monitored entries to callback
      observer = new ResizeObserver((entries) => {
        callback(element, entries[0])
      })

      // Observes the ElementRef for changes
      observer.observe(element)
    }

    return () => {
      observer && observer.disconnect && observer.disconnect()
    }
  }, [callback])
}

export default useResizeObserver
