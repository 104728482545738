import { Workbook } from '@flatfile/api'
import { useMemo, useState } from 'react'
import { sortWorkbooks } from '../utils/workbooks'

export const useChecklistView = (workbooks?: Workbook[]) => {
  const [selectedWorkbookKey, setSelectedWorkbookKey] = useState<
    React.Key | undefined
  >(undefined)
  const [selectedSheetKey, setSelectedSheetKey] = useState<
    React.Key | undefined
  >(undefined)

  const { hasNonFileWorkbooks, nonFileWorkbooks } = useMemo(() => {
    if (!workbooks) {
      return { hasNonFileWorkbooks: undefined, nonFileWorkbooks: undefined }
    }

    const hasNonFile = workbooks.some((wb) => !wb.labels?.includes('file'))
    const sortedWorkbooks = sortWorkbooks(workbooks)

    return {
      hasNonFileWorkbooks: hasNonFile,
      nonFileWorkbooks: sortedWorkbooks,
    }
  }, [workbooks])

  return {
    selectedWorkbookKey,
    setSelectedWorkbookKey,
    selectedSheetKey,
    setSelectedSheetKey,
    hasNonFileWorkbooks,
    nonFileWorkbooks,
  }
}
