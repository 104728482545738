import { EntitlementKey, useEntitlements } from '@/hooks/useEntitlements'

type EntitlementGuardProps = {
  children: (isEntitled: boolean) => React.ReactNode
  entitlements: EntitlementKey[]
  requireAll?: boolean // if false will check for some instead of every
  shouldRender?: boolean // for additional render logic
}

export type CheckedEntitlements = {
  checkedEntitlements: boolean
}

const EntitlementGuard: React.FC<EntitlementGuardProps> = ({
  children,
  entitlements,
  requireAll = true,
  shouldRender = true,
}) => {
  const { hasEntitlement } = useEntitlements()

  const isEntitled = requireAll
    ? entitlements.every((entitlement) => hasEntitlement(entitlement))
    : entitlements.some((entitlement) => hasEntitlement(entitlement))

  return isEntitled && shouldRender ? <>{children(isEntitled)}</> : null
}

export default EntitlementGuard
