import { MappingCertainty, Property } from '@flatfile/api'
import { Primitive } from '../../utils/types'
import { PlanController } from './PlanController'

export class RuleController {
  constructor(public rule: Rule, public readonly plan: PlanController) {}

  /**
   * Get the source property details for any rule
   */
  get srcProp(): Property | undefined {
    const prop = this.plan.src.getProperty(this.rule.src)
    if (!prop) {
      return undefined
    }
    return prop
  }

  /**
   * Get the destination property for this rule.
   */
  get destProp(): Property | null {
    return this.plan.dest.getProperty(this.rule.dest)
  }

  /**
   * Get the property used for mapping preview by matching the a key to either a fieldRef or itself
   */
  get previewProp(): string | undefined {
    const property = this.plan.src.getProperty(this.rule.src)
    const prop = property?.metadata?.['fieldRef'] ?? property?.key
    if (!prop) {
      return undefined
    }
    return prop
  }

  /**
   * Given a source enum value, update the enums section of a rule to
   * include the change.
   *
   * @param srcVal
   * @param destVal
   */
  mergeEnumMappingChange(srcVal: Primitive, destVal?: Primitive): Rule {
    const enumRules = this.rule.enums!
    const matchedRule = this.rule.enums!.findIndex((r) => r.src === srcVal)

    if (matchedRule !== -1) {
      enumRules[matchedRule].dest = destVal
    } else {
      enumRules.push({ src: srcVal, dest: destVal })
    }

    return (this.rule = { ...this.rule, enums: enumRules })
  }

  /**
   * Get a list of enumRules based on source values not the rules.
   */
  enumRules(): EnumRule[] {
    return this.rule.enums!
  }
}

export type Rule = {
  src?: string
  dest?: string | null
  metadata?: {
    source?: string
    certainty?: MappingCertainty
  }
  preview?: string[]
  enums?: EnumRule[]
  isNewField?: boolean
}

export type EnumRule = { src: Primitive; dest?: Primitive }
