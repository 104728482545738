import { WorkbookCountsContext } from '@/apps/WorkbookApp/contexts/WorkbookCountsContext'
import { HorizontalListItem } from '@/components/SheetSelector'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Sheet } from '@flatfile/api'
import { useContext } from 'react'

export function SheetCounts({ sheet }: { sheet: Sheet }) {
  const { t } = useTypedTranslation()
  const { workbookCounts } = useContext(WorkbookCountsContext)
  const counts = workbookCounts?.[sheet.id]
  return (
    <div>
      {counts?.total ? (
        <>
          <HorizontalListItem>
            <span>
              {t('mapping.sheetSelection.records', {
                count: counts?.total,
              })}
            </span>
          </HorizontalListItem>
          <HorizontalListItem>
            <span>
              {t('mapping.sheetSelection.columns', {
                count: sheet.config?.fields?.length,
              })}
            </span>
          </HorizontalListItem>
          {counts && (
            <span>
              {t('mapping.sheetSelection.errors', {
                count: counts?.error,
              })}
            </span>
          )}
        </>
      ) : (
        <span>{t('mapping.sheetSelection.sheetHasNoData')}</span>
      )}
    </div>
  )
}
