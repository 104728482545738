import { JobOperationEnum } from '@/contexts/JobsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  CreateJobRequest,
  GetRecordsRequest,
  JobModeEnum,
  JobTriggerEnum,
  JobTypeEnum,
} from '@flatfile/api'
import { ColumnConfigProps, EColumnType } from '@flatfile/turntable'
import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { SearchFields } from '../types'
import { JobQuery } from './useJobQuery'

type FilterType = Omit<SearchFields, 'sortDirection' | 'sortField'>

const useFindAndReplace = ({
  workbookId,
  sheetId,
  refetchRows,
  columnConfig,
  searchFields,
  getJobQuery,
  isFiltered,
}: {
  workbookId: string
  sheetId: string
  refetchRows: () => Promise<void>
  columnConfig: ColumnConfigProps[]
  searchFields: SearchFields
  getJobQuery: () => JobQuery
  isFiltered: boolean
}) => {
  const { httpClient } = useContext(SpaceContext)
  const { t } = useTypedTranslation()
  const { mutateAsync: findCellCounts } = useMutation(
    ['findCellCounts'],
    // TODO replace with useQueryGetCounts
    async (payload: GetRecordsRequest) => await httpClient.getCounts(payload),
    { useErrorBoundary: false }
  )
  const { mutateAsync: replaceCellValues } = useMutation(
    ['replaceCellValues'],
    async (payload: CreateJobRequest) => await httpClient.createJob(payload)
  )

  const getColumnType = (fieldKey: string) =>
    columnConfig.find((column) => column.value === fieldKey)?.type

  const prepareBackendValue = (
    searchValue: string,
    exactMatchesOnly: boolean,
    columnType?: EColumnType
  ) => {
    if (columnType === 'string' && !exactMatchesOnly) {
      return searchValue
    }
    return `"${searchValue}"`
  }

  const getFindAndReplaceProps = async ({
    searchValue,
    fieldId,
    exactMatchesOnly,
    ignoreFilter,
  }: {
    searchValue: string
    fieldId: string
    exactMatchesOnly: boolean
    ignoreFilter?: boolean
  }) => {
    const columnType = getColumnType(fieldId)
    const {
      // ids are not passed to the counts endpoint yet, but should be
      // https://github.com/FlatFilers/X/issues/3019
      ids: unusedIds,
      ...query
    } = getJobQuery()

    // ignoreFilter comes from checkbox in find and replace modal labeled 'Run on all records' when a filter is applied
    if (ignoreFilter) {
      delete query.filter
      delete query.filterField
      delete query.searchValue
      delete query.searchField
    }

    if (query.searchField || query.searchValue || query.q) {
      /*
      Counts are incorrect when a search filter is applied, so we return nothing
      to prevent as much confusion as possible until this can be fixed.
      https://github.com/FlatFilers/X/issues/2008
      */
      return { count: '', error: undefined }
    }

    const preparedSearchValue = prepareBackendValue(
      searchValue,
      exactMatchesOnly,
      columnType
    )

    try {
      const { data: countData } = await findCellCounts({
        ...query,
        sheetId: sheetId!,
        searchField: fieldId,
        searchValue: preparedSearchValue,
      })
      return {
        count: countData?.counts?.total || 0,
        error: undefined,
      }
    } catch (err) {
      return {
        count: 0,
        error: t(
          'sheet.table.contextMenu.findAndReplace.modal.errors.findError'
        ),
      }
    }
  }

  const handleFindAndReplace = async ({
    replaceValue,
    searchValue,
    fieldId,
    exactMatchesOnly,
    ignoreFilter,
  }: {
    replaceValue: string
    searchValue: string
    fieldId: string
    exactMatchesOnly: boolean
    ignoreFilter?: boolean
  }) => {
    const columnType = getColumnType(fieldId)
    const query = getJobQuery()
    // ignoreFilter comes from checkbox in find and replace modal labeled 'Run on all records' when a filter is applied
    if (ignoreFilter) {
      delete query.filter
      delete query.filterField
      delete query.searchValue
      delete query.searchField
      delete query.ids
    }

    try {
      await replaceCellValues({
        jobConfig: {
          type: JobTypeEnum.Sheet,
          operation: JobOperationEnum.FindReplace,
          trigger: JobTriggerEnum.Immediate,
          source: sheetId!,
          mode: JobModeEnum.ToolbarBlocking,
          config: {
            ...query,
            fieldKey: fieldId,
            replace: replaceValue,
            find: prepareBackendValue(
              searchValue,
              exactMatchesOnly,
              columnType
            ),
          },
        },
      })
      return refetchRows()
    } catch (error) {
      return {
        error: t(
          'sheet.table.contextMenu.findAndReplace.modal.errors.replaceError'
        ),
      }
    }
  }

  return { getFindAndReplaceProps, handleFindAndReplace }
}

export default useFindAndReplace
