import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useCallback, useContext } from 'react'

export type EntitlementKey =
  | 'snapshots'
  | 'limitPdv'
  | 'limitFiles'
  | 'largeFilesEnabled'
  | 'whitelabeling'
  | 'projectsEnabled'
  | 'versionHistory'
  | 'dataRetention'
  | 'aiAssist'
  | 'billingEnabled'
  | 'manageUsers'
  | 'googleDrive'
  | 'box'
  | 'intercom'
  | 'savedViews'
  | 'sourceEditor'
  | 'headerSelection'

export const useEntitlements = () => {
  const { space } = useContext(SpaceContext)
  const { data } = useFlatfileQuery(
    'getEntitlementsForResource',
    { resourceId: space?.id },
    { enabled: !!space }
  )

  const hasEntitlement = useCallback(
    (key: EntitlementKey) => !!data?.data?.some((ent) => ent.key === key),
    [data?.data]
  )

  return {
    hasEntitlement,
  }
}
