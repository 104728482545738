import { WorkbookCountsContext } from '@/apps/WorkbookApp/contexts/WorkbookCountsContext'
import { SheetIconInvert } from '@/assets/icons/SheetIcon'
import { HorizontalListItem } from '@/components/SheetSelector'
import { DetailRow } from '@/elements/DetailRow'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { validDate } from '@/utils/validDate'
import { Workbook } from '@flatfile/api'
import { useContext, useMemo } from 'react'

export function WorkbookCounts({ workbook }: { workbook: Workbook }) {
  const { t } = useTypedTranslation()
  const { workbookCounts } = useContext(WorkbookCountsContext)
  const sheets = workbook?.sheets
  const result = useMemo(
    () =>
      sheets?.reduce(
        (prev, curr) => {
          const counts = workbookCounts?.[curr.id]
          const rowsCount =
            Number(prev.workbookRowsCount) + (counts?.total || 0)
          const colCount =
            Number(prev.workbookColumnsCount) +
            (curr?.config?.fields?.length || 0)

          return {
            workbookRowsCount: rowsCount.toString(),
            workbookColumnsCount: colCount.toString(),
          }
        },
        { workbookRowsCount: '0', workbookColumnsCount: '0' }
      ),
    [workbookCounts, sheets]
  )

  return (
    <DetailRow>
      <SheetIconInvert />
      <p>{workbook.name}</p>
      {result?.workbookRowsCount && (
        <HorizontalListItem>
          <span>
            {t('mapping.sheetSelection.records', {
              count: Number(result.workbookRowsCount),
            })}
          </span>
        </HorizontalListItem>
      )}
      {result?.workbookColumnsCount && (
        <HorizontalListItem>
          <span>
            {t('mapping.sheetSelection.columns', {
              count: Number(result.workbookColumnsCount),
            })}
          </span>
        </HorizontalListItem>
      )}
      {workbook.sheets && (
        <HorizontalListItem>
          <span>
            {t('mapping.sheetSelection.sheets', {
              count: workbook.sheets.length,
            })}
          </span>
        </HorizontalListItem>
      )}
      {workbook.updatedAt && (
        <span>
          {t('mapping.sheetSelection.lastUpdated', {
            date: validDate(
              workbook.updatedAt,
              t('mapping.sheetSelection.updatedAtDateFormat') ?? 'yyyy-MM-dd'
            ),
          })}
        </span>
      )}
    </DetailRow>
  )
}
