import { FlatfileRequestType } from './types'

/**
 * Registry of queries used to fetch data from the API. Each entry contains:
 * 1. generateQueryKey: a function used to generate the cache entry for this query based on params passed
 * 2. influencingEvents: the Flatfile event topics which may indicate that the cached value for this query is no
 *    longer valid
 */
export const QueryMap = {
  getAccessToken: {
    generateQueryKey: () => ['getAccessToken'],
    // TODO add events
    influencingEvents: [],
  },
  getAllSpaces: {
    generateQueryKey: (params: FlatfileRequestType<'getAllSpaces'>) => {
      if (params) {
        const { isCollaborative, environmentId, ...otherParams } = params
        return [
          'getAllSpaces',
          environmentId,
          isCollaborative,
          ...Object.values(otherParams),
        ]
      }
      return ['getAllSpaces']
    },
    // TODO add events
    influencingEvents: [],
  },
  getAllWorkbooks: {
    generateQueryKey: (params: FlatfileRequestType<'getAllWorkbooks'>) => [
      'getAllWorkbooks',
      params,
    ],
    // TODO add events
    influencingEvents: [],
  },
}
