import { FlatfileRequestType } from './types'

export const entitlementQueries = {
  getEntitlementsForResource: {
    generateQueryKey: (
      params: FlatfileRequestType<'getEntitlementsForResource'>
    ) => ['getEntitlementsForResource', params],
    influencingEvents: {},
  },
}
