import styled from 'styled-components'

export const DetailRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 12px;
    position: relative;
  }

  p {
    color: var(--color-title);
    margin-bottom: 0;
    margin-top: 0;
    margin-right: var(--size-base);
  }
`
