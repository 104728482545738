import { EventSubscriber } from '@/components/EventSubscriber'
import { SpacesUISkeleton } from '@/components/SpacesUISkeleton'

import { useQuery } from '@tanstack/react-query'
import { PubNubProvider as Provider } from 'pubnub-react'
import { Navigate, useParams } from 'react-router-dom'
import { PubNubConnectionProvider } from './PubNubConnectionProvider'

export const PubNubProvider = ({
  children,
}: {
  children: React.ReactNode
}): any => {
  const { spaceId } = useParams()

  const { data, isFetching, isError } = useQuery(
    ['getPubNubClient', spaceId],
    () => (spaceId ? EventSubscriber.getClient(spaceId) : undefined),
    {
      enabled: !!spaceId,
      staleTime: Infinity,
    }
  )

  if (isFetching && spaceId) {
    return <SpacesUISkeleton />
  } else if (isError) {
    return <Navigate to={'/unauthorized'} />
  } else if (!data?.pubnub && !isFetching) {
    return <Navigate to={'/unauthorized'} />
  } else if (spaceId) {
    return (
      <Provider client={data?.pubnub!}>
        <PubNubConnectionProvider spaceId={spaceId}>
          {children}
        </PubNubConnectionProvider>
      </Provider>
    )
  }
}

export default PubNubProvider
