import styled from 'styled-components'

export const MappingSidebar = styled.div`
  background-color: var(--color-page);
  flex-basis: 80%;
  min-width: 40em;
  padding: var(--spacing-actions-gutter) var(--spacing-page-gutter);
  height: -webkit-fit-content;
`

export const DataPreviewPanel = styled.div`
  background-color: var(--color-page-100);
  flex-basis: 60%;
  padding: var(--spacing-actions-gutter) var(--spacing-page-gutter);
  position: sticky;
  top: 0;
`

export const DataPreviewWrapper = styled.div`
  background-color: var(--color-page);
  box-shadow: 0px 0.3px 0.5px rgba(0, 0, 0, 0.035),
    0px 2px 4px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--color-border);
  border-radius: var(--layout-border-radius);
  padding: calc(var(--spacing-page-gutter) / 3)
    calc(var(--spacing-page-gutter) / 2);
`

export const MappingDivider = styled.hr`
  background-color: var(--color-border);
  border: 0;
  height: 1px;
  margin-bottom: 1em;
`

export const MappingHeaderRow = styled.div`
  color: var(--color-text-light);
  font-weight: 600;
  line-height: 2;
`
