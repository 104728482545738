import {
  Button,
  Modal,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface ExitConfirmationModalProps {
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onConfirm: () => void
}

const ExitConfirmationModal: React.FC<ExitConfirmationModalProps> = ({
  open,
  onOpenChange,
  onConfirm,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        event.preventDefault()
        onOpenChange(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open, onOpenChange])

  useEffect(() => {
    if (open) {
      const closeButton = document.querySelector(
        '[data-testid="modal-close-button"]'
      ) as HTMLButtonElement
      closeButton?.focus()
    }
  }, [open])

  if (!open) return null

  const translations = {
    header: t('aiTransform.exitConfirmation.header'),
    body: t('aiTransform.exitConfirmation.body'),
    label: t('aiTransform.exitConfirmation.label'),
  }

  return (
    <Modal
      content={
        <>
          <ModalHeader>{translations.header}</ModalHeader>
          <ModalText>{translations.body}</ModalText>
          <ModalButtonRow>
            <ModalCloseButton
              data-testid='modal-close-button'
              onClose={() => onOpenChange(false)}
            />
            <Button
              variant='primary'
              onPress={() => onConfirm()}
              label={translations.label}
            />
          </ModalButtonRow>
        </>
      }
    />
  )
}

export default ExitConfirmationModal
