import getI18n from '@/i18n'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { SpacesUISkeleton } from '../SpacesUISkeleton'

export const DefaultTranslationsProvider = ({
  children,
}: {
  children: any
}) => {
  const [i18nInstance, setI18nInstance] = useState<i18n | null>()
  useEffect(() => {
    setI18nInstance(getI18n())
  }, [])
  return (
    <>
      {i18nInstance ? (
        <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
      ) : (
        <SpacesUISkeleton />
      )}
    </>
  )
}
