import styled from 'styled-components'

import {
  Button,
  Colors,
  Icon,
  Typography,
  ZIndex,
} from '@flatfile/design-system'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  gap: 16px;
  height: 104px;
  justify-content: space-between;
  padding: 32px 40px;
  background-color: var(--color-page);
  z-index: ${ZIndex.level100};
`

const Header = styled.div`
  align-items: center;
  display: flex;

  > div {
    font-family: var(--text-font);
  }
`

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const IconWrapper = styled.div`
  align-items: center;
  background-color: var(--color-action);
  border-radius: 2px;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  width: 40px;
`

type SheetAction = {
  disabled?: boolean
  label: string
  loading?: boolean
  onPress: () => void
}

type SheetActionBarProps = {
  back?: SheetAction
  exit?: SheetAction
  headerText: string
  next?: SheetAction
  headers?: SheetAction
}

export const SheetActionBar = ({
  headerText,
  back,
  exit,
  next,
  headers,
}: SheetActionBarProps) => {
  return (
    <Container>
      <Header>
        <IconWrapper>
          <Icon name='table' color={Colors.White} />
        </IconWrapper>
        <Typography type='h4'>{headerText}</Typography>
      </Header>
      <ActionButtonsContainer>
        {headers && (
          <Button
            variant='ghost'
            onPress={headers.onPress}
            label={headers.label}
          />
        )}
        {exit && (
          <Button
            isDisabled={exit.disabled}
            variant='ghost'
            loading={exit.loading}
            onPress={exit.onPress}
            label={exit.label}
            data-testid='action-button-exit'
          />
        )}
        {back && (
          <Button
            isDisabled={back.disabled}
            variant='secondary'
            loading={back.loading}
            onPress={back.onPress}
            label={back.label}
          />
        )}
        {next && (
          <Button
            isDisabled={next.disabled}
            loading={next.loading}
            onPress={next.onPress}
            label={next.label}
          />
        )}
      </ActionButtonsContainer>
    </Container>
  )
}
