import { useMutation } from '@tanstack/react-query'
import { HTTPService } from '../components/HttpClient'

export const useCancelJob = () => {
  const { mutate: cancelJob } = useMutation<unknown, unknown, string>(
    ['cancelJob'],
    async (id: string) => {
      await HTTPService.cancelJob({
        jobId: id,
        jobCancelDetails: { info: 'Cancelled by user' },
      })
    }
  )
  return { cancelJob }
}
