import { Sheet, Space } from '@flatfile/api'
import { useMemo } from 'react'
import {
  SheetEmptyState,
  SheetEmptyStateOverlay,
} from '../SheetViewEmptyStates'

export interface UseEmptyStateProps {
  space: Space
  sheet?: Sheet
  tableError?: boolean
  isLoading?: boolean
  readOnlySheet: boolean
  canAddToSheet: boolean
  canDeleteFromSheet: boolean
  canImportIntoSheet: boolean
  noAccessLimitations: boolean
  sheetIsLocked: boolean
  hideEmptyOverlay: () => void
  allColumnsHidden?: boolean
}

export const useEmptyState = ({
  sheet,
  tableError,
  isLoading,
  readOnlySheet,
  canAddToSheet,
  canImportIntoSheet,
  noAccessLimitations,
  sheetIsLocked,
  hideEmptyOverlay,
  allColumnsHidden,
}: UseEmptyStateProps): {
  emptyState: JSX.Element
  emptyStateOverlay: JSX.Element
} => {
  const emptyState = useMemo(
    () => (
      <SheetEmptyState
        hasError={tableError}
        hasSheet={!!sheet}
        isFirstLoad={isLoading}
        readOnlySheet={readOnlySheet}
        canAddToSheet={canAddToSheet}
        canImportIntoSheet={canImportIntoSheet}
        sheetIsLocked={sheetIsLocked}
        allColumnsHidden={allColumnsHidden}
      />
    ),
    [tableError, sheet, isLoading, allColumnsHidden]
  )

  const emptyStateOverlay = useMemo(() => {
    if (readOnlySheet || sheetIsLocked) {
      return <></>
    }
    return (
      <SheetEmptyStateOverlay
        hideOverlay={hideEmptyOverlay}
        canAddToSheet={canAddToSheet}
        canImportIntoSheet={canImportIntoSheet}
        noAccessLimitations={noAccessLimitations}
      />
    )
  }, [readOnlySheet, hideEmptyOverlay])

  return { emptyState, emptyStateOverlay }
}
