import { formatTurntableRows } from '@/apps/WorkbookApp/utils/tableUtils'
import { ActionContent } from '@/elements/ActionContent'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Records, Sheet } from '@flatfile/api'
import { Typography } from '@flatfile/design-system'
import {
  ColumnConfigProps,
  DataBufferProvider,
  RowData,
  Table,
  TableProviders,
  useBulkRowSelection,
  useDataBufferContext,
} from '@flatfile/turntable'
import { Property } from 'posthog-js'
import { useEffect } from 'react'
import { ColumnContent, MappingSubtitle } from '../elements'

interface IMapHeadersPage {
  sheet: Sheet
  records: Records
  onChangeHeaderRow: (headerRow: number) => void
}

export const MapHeadersPage = ({
  sheet,
  records,
  onChangeHeaderRow,
}: IMapHeadersPage) => {
  const config = sheet.config.fields.map((field, index) => {
    return {
      index,
      value: field.key,
      label: field.key,
      type: field.type,
    } as ColumnConfigProps
  })
  const { t } = useTypedTranslation()
  const tableData = formatTurntableRows(records, 0, config)
  const meta = (sheet?.metadata as { [key: string]: any }) ?? {}
  const rowHeaders = meta?.rowHeaders as Property[]
  /* Feature currently only supports selecting a single header row (despite the value being an array) */
  const singleHeaderSelection = rowHeaders?.[0] ?? 1
  const initialRowSelectionIndex = singleHeaderSelection - 1
  const initialRowSelection = tableData?.[initialRowSelectionIndex]

  return (
    <ActionContent>
      <DataBufferProvider
        onLoadRows={() => {
          return Promise.resolve(tableData)
        }}
        onUpdateRows={() => {}}
      >
        <TableProviders>
          <ColumnContent>
            <MappingSubtitle>
              <Typography type='h5'>
                {t('mapping.mapHeaders.subheading')}
              </Typography>
            </MappingSubtitle>
            <TableContent
              sheet={sheet}
              config={config}
              count={records.length}
              initialRowSelection={initialRowSelection}
              onChangeHeaderRow={onChangeHeaderRow}
            />
          </ColumnContent>
        </TableProviders>
      </DataBufferProvider>
    </ActionContent>
  )
}

const TableContent = ({
  sheet,
  config,
  count = 0,
  initialRowSelection,
  onChangeHeaderRow,
}: {
  sheet: Sheet
  config: ColumnConfigProps[]
  count?: number
  initialRowSelection?: RowData
  onChangeHeaderRow: (headerRow: number) => void
}) => {
  const { buffer, initLoad } = useDataBufferContext()
  const { selection, toggleSingleRow } = useBulkRowSelection()

  useEffect(() => {
    const initTable = async () => {
      await initLoad()
      if (initialRowSelection) {
        toggleSingleRow(count, initialRowSelection, 'single')
      }
    }
    buffer.initialize({
      bufferId: sheet.id,
      params: { searchFields: {} },
      totalRecords: count,
      pageSize: count,
      columnConfig: config,
    })

    initTable()
  }, [])

  useEffect(() => {
    const { exceptions } = selection
    const exceptionEntries = Object.entries(exceptions)
    if (exceptionEntries?.length > 0) {
      /* Feature currently only supports selecting a single header row, so for now we only take the first exception */
      const [_, exception] = exceptionEntries[0]
      if (exception?.rowIndex) {
        onChangeHeaderRow(exception.rowIndex)
      }
    }
  }, [selection, onChangeHeaderRow])

  return (
    <Table
      readOnly={true}
      additionalRows={0}
      columnConfig={config}
      totalRowCount={count}
      rowSelectionType={'single'}
      pinningEnabled={false}
      showColumnMenus={false}
    />
  )
}
