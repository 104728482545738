import { DefaultApi, Environment, Job, Property, Sheet } from '@flatfile/api'
import { HTTPService } from '../../components/HttpClient'
import { typedT } from '../../utils/typedTranslation'
import { Primitive } from '../../utils/types'
import { Observable } from '../observable'
import { resources } from '../resources'

export interface FieldOption {
  label: JSX.Element | string
  value: string
  description?: string
  required?: boolean
  isAddField?: boolean
}

export class SheetController {
  /* Source Sheets (during mapping) could have their header properties defined directly in the field.config OR within metadata.headers */

  constructor(
    private sheet: Sheet,
    private job: Job,
    private environment: Environment | undefined,
    private httpClient: DefaultApi
  ) {}

  /**
   * Get a specific property from a blueprint by key. If the blueprint
   * does not have a label yet, make the key the label.
   *
   * @note it's questionable to make the key the label here - blueprint
   *       does not require label so our UI shold probably not either
   *
   * @param key Property key
   */
  getProperty(key?: string | null): Property | null {
    const prop = this.getConfigFields().find((field) => field.key === key)

    return prop ? this.__normalizeProperty(prop) : null
  }

  getConfigFields() {
    const meta = (this.sheet?.metadata as { [key: string]: any }) ?? {}
    const data = meta?.headers as Property[]
    return data ?? this.sheet.config.fields
  }

  /**
   * Return the number of fields in this sheet, always an int
   */
  get fieldCount(): number {
    return this.sheet.config?.fields.length || 0
  }

  /**
   * Return the fields in this sheet
   */
  get fields(): Property[] {
    return this.sheet.config?.fields || []
  }

  /**
   * Return the required fields in this sheet
   */
  get requiredFields(): Property[] {
    return (
      this.sheet.config?.fields.filter(
        (field) =>
          field.constraints?.some((c) => c.type === 'required') || false
      ) || []
    )
  }

  /**
   * Given an enum property key, return a list of options compatible with
   * react-select
   *
   * @todo clean up API return types - for some reason there's a stub
   *       EnumPropertyOptionValue in the API with no typing
   *
   * @param key
   */
  getEnumOptions(
    key: string
  ): Array<{ label: string; value: Primitive; description?: string }> {
    const field = this.sheet.config?.fields.find((f) => f.key === key)

    if (field?.type !== 'enum') {
      throw new Error('Trying to read options from a non enum type')
    }

    return (
      field.config.options.map((option) => ({
        value: option.value as Primitive,
        label: option.label || (option.value as string),
        description: option?.description,
      })) || []
    )
  }

  /**
   * Return a list of fields formatted for react-select.
   */
  getFieldOptions(fieldAdded?: boolean): Array<FieldOption> {
    const fieldOptions =
      this.sheet.config?.fields
        .filter((f) => !f.constraints?.some((c) => c.type === 'computed'))
        .map((field) => ({
          value: field.key,
          label: field.label || field.key,
          description: field.description,
          required: field.constraints?.some((f) => f.type === 'required'),
          isAddField: false,
        })) || []
    if (this.sheet.config.allowAdditionalFields && !fieldAdded) {
      fieldOptions.push({
        label: typedT('mapping.mapFields.destinationFields.addNewField.label'),
        value: 'add',
        description: typedT(
          'mapping.mapFields.destinationFields.addNewField.description'
        ),
        required: false,
        isAddField: true,
      })
    }
    return fieldOptions
  }

  /**
   * If given a row number, will set the RowHeaders values in metadata. This value
   * is used on "File" workbooks which are sources during mapping. The header row
   * determines where extraction begins and which values will be considered the
   * "source" fields
   */
  public updateHeaderRow() {
    try {
      return new Observable<Sheet, { headerRow: number }>(
        async ({ headerRow }) => {
          return HTTPService.updateSheet({
            sheetId: this.sheet.id,
            sheetUpdateRequest: {
              metadata: {
                rowHeaders: [headerRow],
              },
            },
          })
        }
      )
    } catch (error) {
      throw new Error(`Failed to update header row: ${error}`)
    }
  }

  public async addFieldToSheet(src: string) {
    const addResponse = await this.httpClient.addField({
      sheetId: this.sheet.id,
      fieldConfig: {
        key: src,
        label: src,
        type: 'string',
        treatments: ['user-defined'],
      },
    })
    return addResponse
  }

  public async refetchSheetConfig() {
    const newSheetConfig = await this.httpClient.getSheet({
      sheetId: this.sheet.id,
    })
    if (newSheetConfig.data) {
      this.sheet = newSheetConfig.data
    }
    resources.clearCache()
    return newSheetConfig
  }

  /**
   * Make sure label is populated if it's not yet
   * @param prop
   * @private
   */
  private __normalizeProperty(
    prop: Property
  ): Property & Required<{ label: string }> {
    return { ...prop, label: prop.label || prop.key }
  }
}

type UniqueFieldValues = Record<string, Primitive[]>
