import { Button } from '@flatfile/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { VerticalDivider } from '../elements/VerticalDivider'

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .styled-button {
    padding: 0;
    min-width: unset;
    height: unset;
  }

  .styled-button:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }

  ${VerticalDivider} {
    background-color: var(--color-title);
  }
`

interface SelectButtonsProps {
  selectNoneLabel?: string
  selectAllLabel?: string
  onSelectAll?: () => void
  onSelectNone?: () => void
}

export const SelectButtons = ({
  selectNoneLabel,
  selectAllLabel,
  onSelectNone,
  onSelectAll,
}: SelectButtonsProps) => {
  const { t } = useTranslation()
  return (
    <ButtonsRow>
      <Button
        variant='tertiary'
        onPress={onSelectAll}
        isDisabled={!onSelectAll}
        label={selectAllLabel ?? t('utils.buttons.selectAllLabel')}
        data-testid='button-selectall'
        size='sm'
      />
      <VerticalDivider />
      <Button
        variant='tertiary'
        onPress={onSelectNone}
        isDisabled={!onSelectNone}
        label={selectNoneLabel ?? t('utils.buttons.clearAllLabel')}
        data-testid='button-clearall'
        size='sm'
      />
    </ButtonsRow>
  )
}
