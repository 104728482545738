import {
  Colors,
  IconButton,
  Tooltip,
  Typography,
} from '@flatfile/design-system'
import styled from 'styled-components'
import VersionsStatus from '../../apps/WorkbookApp/VersionHistory/VersionsStatus'
import {
  ContentContainer,
  KeyPill,
  KeyPillInner,
  TooltipCopyButton,
} from '../../elements/TooltipCopyElements'
import { useRouteParams } from '../../hooks/useRouteParams'
import { JobsStatus } from '../Jobs/JobsStatus'
// import EventLogsStatus from '../../default-apps/WorkbookApp/EventLogs/EventLogsStatus'
import { OnTriggerSnapshotView } from '../../apps/WorkbookApp/VersionHistory/SnapshotView/types'
import { PrimaryHeading } from '../../elements/PrimaryHeading'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { usePanelsContext } from '@/contexts/PanelsContext'
import { useEntitlements } from '@/hooks/useEntitlements'

export const PageHeaderContainer = styled.div<{ $height?: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  /* c8 ignore next */
  ${({ $height }) => ($height ? `min-height: ${$height}px;` : '')}
`

export const Spacer = styled.div`
  flex-grow: 1;
`

const InfoTooltipContentContainer = styled.div`
  width: 100%;
`

interface PageHeaderInfoTooltipProps {
  data: Record<string, string>
}

interface PageHeaderInfoProps extends PageHeaderInfoTooltipProps {
  onCopy: () => void
}

interface PageHeaderTitleProps {
  title?: string
  titleInfo?: PageHeaderInfoProps
}

interface PageHeaderProps extends PageHeaderTitleProps {
  action?: JSX.Element | null
  height?: number
  toggleSnapshotView?: OnTriggerSnapshotView
  openJobsPanel?: () => void
}

export const InfoTooltipContent = ({ data }: PageHeaderInfoTooltipProps) => {
  return (
    <InfoTooltipContentContainer>
      {Object.entries(data).map(([key, value]) => (
        <ContentContainer
          key={`${key}:${value}`}
          data-testid='tooltip-info-item'
        >
          <KeyPill>
            <KeyPillInner>{key}</KeyPillInner>
          </KeyPill>
          <Typography type='b1' color={Colors.White} truncate>
            {value}
          </Typography>
        </ContentContainer>
      ))}
    </InfoTooltipContentContainer>
  )
}

const PageHeaderInfo = ({ data, onCopy }: PageHeaderInfoProps) => {
  const { t } = useTypedTranslation()
  return (
    <>
      <IconButton
        data-testid='ff-sheet-info-icon'
        data-tooltip-id='ff-sheet-info'
        name='info'
        color={Colors.Pigeon600}
        onPress={onCopy}
      />

      <Tooltip $maxWidth={450} id='ff-sheet-info' clickable>
        <InfoTooltipContent data={data} />
        <TooltipCopyButton data-testid='ff-sheet-info-button' onClick={onCopy}>
          {t('sheet.info.copyButton')}
        </TooltipCopyButton>
      </Tooltip>
    </>
  )
}

const PageHeaderTitle = ({ title, titleInfo }: PageHeaderTitleProps) => {
  if (!title && !titleInfo) return null

  return (
    <PrimaryHeading data-testid='page-header-title'>
      {title}
      {titleInfo && <PageHeaderInfo {...titleInfo} />}
    </PrimaryHeading>
  )
}

export const PageHeader = ({
  title,
  titleInfo,
  action,
  height = 40,
  toggleSnapshotView,
  openJobsPanel,
}: PageHeaderProps) => {
  const { hasEntitlement } = useEntitlements()
  const versionHistoryEnabled = hasEntitlement('versionHistory')
  const { openPanel, handleOpenPanel, handleClosePanel } = usePanelsContext()
  const { sheetId = '' } = useRouteParams()

  return (
    <PageHeaderContainer
      data-testid='page-header-container'
      className='page-header-container'
      $height={height}
    >
      <PageHeaderTitle title={title} titleInfo={titleInfo} />
      <Spacer />
      <JobsStatus
        isActive={openPanel === 'Jobs'}
        onOpen={() => handleOpenPanel('Jobs')}
        onClose={handleClosePanel}
      />
      {/* showEventLogs && (
        <EventLogsStatus
          isActive={openPanel === 'Logs'}
          onOpen={handleOpenPanel('Logs')}
          onClose={handleClosePanel}
        />
      )*/}
      {sheetId && versionHistoryEnabled && toggleSnapshotView && (
        <VersionsStatus
          isActive={openPanel === 'Versions'}
          onOpen={() => handleOpenPanel('Versions')}
          onClose={handleClosePanel}
          toggleSnapshotView={toggleSnapshotView}
        />
      )}
      {action}
    </PageHeaderContainer>
  )
}
