import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Sheet, Snapshot, Workbook } from '@flatfile/api'
import { Spinner } from '@flatfile/design-system'
import { useFieldData } from '../../hooks/useFieldData'
import { DiffView } from './DiffView'
import useSnapshotData from './hooks/useSnapshotData'
import { DiffAction, DiffDirection } from './types'

type EmbeddedDiff = {
  snapshot: Snapshot
  workbook: Workbook
  sheet: Sheet
  diffDirection: DiffDirection
}

type EmbeddedDiffLoader = {
  snapshotId: string
  sheetId: string
  workbookId: string
  diffDirection: DiffDirection
}

export const EmbeddedDiffView = ({
  snapshotId,
  sheetId,
  workbookId,
  diffDirection,
}: EmbeddedDiffLoader) => {
  const { t } = useTypedTranslation()
  const {
    data: sheet,
    isLoading: sheetLoading,
    isError: sheetError,
  } = useFlatfileQuery('getSheet', { sheetId })
  const {
    data: workbook,
    isLoading: workbookLoading,
    isError: workbookError,
  } = useFlatfileQuery('getWorkbookById', { workbookId })
  const {
    data: snapshot,
    isLoading: snapshotLoading,
    isError: snapshotError,
  } = useFlatfileQuery(
    'getSnapshot',
    {
      snapshotId,
      includeSummary: true,
    },
    { retry: false }
  )

  if (sheetLoading || workbookLoading || snapshotLoading) {
    return <Spinner />
  } else if (sheetError || workbookError || snapshotError) {
    return <>{t('snapshot.none.errorMessage')}</>
  }
  return (
    <EmbeddedDiff
      snapshot={snapshot.data!}
      workbook={workbook.data!}
      sheet={sheet.data!}
      diffDirection={diffDirection}
    />
  )
}

export const EmbeddedDiff = ({
  snapshot,
  workbook,
  sheet,
  diffDirection,
}: EmbeddedDiff) => {
  /* c8 ignore start */
  const refetchTable = () => Promise.resolve()
  const onSubmit: () => Promise<void> = () => new Promise(() => {})
  const onClose = () => {}
  /* c8 ignore end */
  const { columnConfig } = useFieldData({
    workbook,
    sheet,
  })

  const { fetchSnapshotRecords } = useSnapshotData({
    snapshot,
    columnConfig,
    action: DiffAction.NONE,
    direction: diffDirection,
  })
  return (
    <DiffView
      showFilters={true}
      action={DiffAction.NONE}
      sheet={sheet}
      description={''}
      snapshot={snapshot}
      summary={snapshot.summary}
      isLoadingSummary={false}
      isErrorSummary={false}
      onCancel={undefined}
      workbook={workbook}
      onSubmit={onSubmit}
      onLoadRows={fetchSnapshotRecords}
      columnConfig={columnConfig}
      refetchTable={refetchTable}
      onClose={onClose}
      diffDirection={diffDirection}
      embedded
    />
  )
}
