import {
  CellValue,
  Filter,
  Record as Record_,
  RecordCounts,
  Records,
  SortDirection,
  ValidationMessage,
  ValidationMessageTypeEnum,
} from '@flatfile/api'
import {
  CellChangeDetail,
  CellData,
  ColumnConfigProps,
  getCellValueIsEmpty,
  RowChangeDetail,
  RowData,
} from '@flatfile/turntable'
import i18next from 'i18next'
import { DiffAction, DiffDirection } from '../VersionHistory/SnapshotView/types'
import { RecordDataPayload } from '../hooks/useRecordMutation'
import { SearchFields, ToolbarRecordCounts } from '../types'

const SortDirectionEnum = {
  ascending: SortDirection.Asc,
  descending: SortDirection.Desc,
}

export const defaultMessages = {
  reference: {
    message: 'Value does not exist in the reference table',
    key: 'sheet.table.cells.messages.reference',
    type: 'exact',
  },
  invalidValue: {
    message: 'Value is not a valid option',
    key: 'sheet.table.cells.messages.invalidValue',
    type: 'exact',
  },
  invalidOption: {
    message: 'Invalid option',
    key: 'sheet.table.cells.messages.invalidOption',
    type: 'exact',
  },
  unique: {
    message: 'Value is not unique',
    key: 'sheet.table.cells.messages.unique',
    type: 'exact',
  },
  autogen: {
    message: '✨ Automatically generated',
    key: 'sheet.table.cells.messages.autogen',
    type: 'exact',
  },
  transformed: {
    message: '🪄️ Transformed from',
    key: 'sheet.table.cells.messages.transformed',
    type: 'startsWith',
  },
  required: {
    message: ' is required',
    key: 'sheet.table.cells.messages.req',
    type: 'endsWith',
  },
  compositeUnique: {
    message: `🧑‍🍳 Composite ${'fieldName'} is not unique`,
    key: 'sheet.table.cells.messages.compositeUnique',
    type: 'compositeUnique',
  },
}

export const translateMessage = (
  message: string,
  value: string,
  label: string | undefined
) => {
  let translatedMessage = i18next.t(message)
  Object.values(defaultMessages).forEach((defaultMessage) => {
    switch (defaultMessage.type) {
      case 'exact':
        if (message === defaultMessage.message) {
          translatedMessage = i18next.t(defaultMessage.key)
        }
        break
      case 'startsWith':
        if (message.startsWith(defaultMessage.message)) {
          const messageSuffix = message.substring(defaultMessage.message.length)
          translatedMessage = i18next.t(defaultMessage.key, {
            originalValue: messageSuffix,
          })
        }
        break
      case 'endsWith':
        if (
          message.endsWith(defaultMessage.message) &&
          (message === `${value}${defaultMessage.message}` ||
            message === `${label}${defaultMessage.message}`)
        ) {
          const messagePrefix = message.substring(
            0,
            message.length - defaultMessage.message.length
          )
          translatedMessage = i18next.t(defaultMessage.key, {
            field: messagePrefix,
          })
        }
        break
      case 'compositeUnique':
        const dynamicPartPattern = /🧑‍🍳 Composite (.+) is not unique/
        const match = message.match(dynamicPartPattern)
        if (match && match[1]) {
          const name = match[1]
          translatedMessage = i18next.t(defaultMessage.key, {
            name: name,
          })
        }
        break
    }
  })

  return translatedMessage
}

export const getCurrentRowCount = (
  searchFields: SearchFields,
  counts?: ToolbarRecordCounts
): number => {
  if (!counts) {
    return 0
  }
  if (searchFields.filterField || searchFields.searchValue || searchFields.q) {
    return counts.filtered ?? 0
  }
  return getFilteredCount(searchFields, counts)
}

const getFilteredMessages = (
  value: CellValue,
  filter: ValidationMessageTypeEnum,
  columnVal: string,
  columnLabel: string | undefined
): { message: string; path?: string }[] | undefined => {
  const messages: { message: string; path?: string }[] =
    value?.messages?.reduce(
      (
        memo: { message: string; path?: string }[],
        { message, type, path }: ValidationMessage
      ) => {
        if (type === filter && message) {
          const translatedMessage = translateMessage(
            message,
            columnVal,
            columnLabel
          )
          memo.push({ message: translatedMessage, path })
        }
        return memo
      },
      []
    ) ?? []
  return messages.length ? messages : undefined
}

const getValueMessage = (value: any): string => {
  const isEmpty = getCellValueIsEmpty(value)
  return isEmpty ? i18next.t('snapshot.cells.messages.empty') : value
}

const getDiffMessages = (
  value: CellValue,
  snapshotValue: CellValue,
  action?: DiffAction
) => {
  if (!action || value === snapshotValue) {
    return undefined
  }
  return [
    {
      message: i18next.t('snapshot.cells.messages.current', {
        value: getValueMessage(value),
      }),
    },
    {
      message: i18next.t('snapshot.cells.messages.snapshot', {
        value: getValueMessage(snapshotValue),
      }),
    },
    { message: i18next.t(`snapshot.cells.messages.${action}`) },
  ]
}

/*
  Takes cell data from the API and formats it as a Turntable cell collection
*/
interface CellValueWithSnapshot extends CellValue {
  snapshotValue?: any
}
export const formatTurntableCells = (
  record: Record_,
  columnConfig?: ColumnConfigProps[],
  action?: DiffAction,
  direction?: DiffDirection
): CellData[] => {
  return (
    columnConfig?.map((column, index) => {
      const cellData = record.values?.[column.value] as CellValueWithSnapshot
      /*
        In snapshot data, 'value' always represents the current value in the sheet,
        and 'snapshotValue' always represents the value in the snapshot. However, when the action
        is ACCEPT, or when the direction of the diff is SNAPSHOT_TO_SHEET, we're pretending that
        a snapshot hasn't yet been merged. In this mode, the 'snapshotValue' is what the user
        thinks of as the current value, and so we display it as such.
      */
      const reverseSnapshotValues =
        action === DiffAction.ACCEPT ||
        direction === DiffDirection.SNAPSHOT_TO_SHEET
      let value = reverseSnapshotValues
        ? cellData?.snapshotValue
        : cellData?.value
      let snapshotValue = reverseSnapshotValues
        ? cellData?.value
        : cellData?.snapshotValue

      return {
        value,
        snapshotValue,
        column: index,
        disabled: cellIsReadOnly(column.value, record),
        mutated: cellData?.messages?.some((m) => m.source === 'is-artifact'),
        status: {
          error: getFilteredMessages(
            cellData,
            ValidationMessageTypeEnum.Error,
            column.value,
            column.label
          ),
          info: getFilteredMessages(
            cellData,
            ValidationMessageTypeEnum.Info,
            column.value,
            column.label
          ),
          diff: getDiffMessages(value, snapshotValue, action),
          warning: getFilteredMessages(
            cellData,
            ValidationMessageTypeEnum.Warn,
            column.value,
            column.label
          ),
        },
      }
    }) ?? []
  )
}

/*
  Takes record data from the API and formats it as a Turntable row collection
*/
export const formatTurntableRows = (
  records: Records,
  skip: number,
  columnConfig?: ColumnConfigProps[],
  action?: DiffAction,
  direction?: DiffDirection
): RowData[] | undefined => {
  return records?.map((record: Record_, index: number) => ({
    id: record.id,
    cells: formatTurntableCells(record, columnConfig, action, direction),
    position: index + skip,
    rowIndex: index + skip + 1,
  }))
}

/*
  Takes a Turntable patch object and converts it into a payload for API requests
*/
export const recordData = (
  change: RowChangeDetail,
  columnConfig: ColumnConfigProps[]
): RecordDataPayload => {
  return change.patch.reduce(
    (memo: RecordDataPayload, change: CellChangeDetail<unknown>) => {
      // change.column is the index in the columnConfig array
      const key = columnConfig[change.column].value
      memo[key] = { value: change.value }
      return memo
    },
    {}
  )
}

export const getSortParams = ({
  columnConfig,
  columnIndex,
  order,
}: {
  columnConfig: ColumnConfigProps[]
  columnIndex: number | undefined
  order: 'ascending' | 'descending' | 'noOrder'
}): {
  sortField: string | undefined
  sortDirection: string | undefined
} => {
  const field = columnConfig[columnIndex ?? -1]?.value
  if (!field || order === 'noOrder') {
    return { sortDirection: undefined, sortField: undefined }
  }
  return { sortField: field, sortDirection: SortDirectionEnum[order] }
}

export function getFilteredCount(
  searchFields: SearchFields,
  counts: RecordCounts
): number {
  let filtered
  switch (searchFields.filter) {
    case Filter.Valid:
      filtered = counts.valid
      break
    case Filter.Error:
      filtered = counts.error
      break
    case Filter.All:
    case Filter.None:
    case undefined:
      filtered = counts.total
      break
    default:
      assertUnreachable(searchFields.filter)
  }
  return filtered ?? 0
}

export function cellIsReadOnly(
  key: string,
  record: Record_
): boolean | undefined {
  const config: RecordConfig = record.config || {}
  const fields = config.fields || {}
  return config.readonly || fields[key]?.readonly ? true : undefined
}

export function getToolbarCounts(
  searchFields: SearchFields,
  counts: RecordCounts,
  filteredCounts: RecordCounts
): ToolbarRecordCounts {
  const filtered = getFilteredCount(searchFields, filteredCounts)
  return { ...counts, filtered }
}

export function assertUnreachable(_x: never): never {
  throw new Error('Should never reach this code')
}

export interface RecordConfig {
  readonly?: boolean
  fields?: Record<string, CellConfig>
}
export interface CellConfig {
  readonly?: boolean
}
