import { JobModeEnum, JobStatusEnum } from '@flatfile/api'
import { useBlockingJobs } from './useBlockingJobs'

export const useSheetHasJob = (
  sheetId: string,
  workbookId: string,
  modes: JobModeEnum[] = [JobModeEnum.Foreground]
) => {
  const jobs = useBlockingJobs([sheetId, workbookId], modes).filter(
    (j) => j.status === JobStatusEnum.Executing
  )
  const hasJob = jobs.length > 0
  return hasJob
}
