import { useState } from 'react'
import { StatusButton } from '../StatusButton'
import VersionsPanel from './VersionsPanel'
import { VersionStatusProps } from './types'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

const VersionsStatus = ({
  isActive,
  onClose,
  onOpen,
  toggleSnapshotView,
}: VersionStatusProps) => {
  const [showHoverState, setShowHoverState] = useState<boolean>(false)
  const { t } = useTypedTranslation()

  const getBgColor =
    showHoverState || isActive ? 'var(--color-page-100)' : 'transparent'

  return (
    <div>
      <StatusButton
        showHoverState={showHoverState}
        setShowHoverState={setShowHoverState}
        onClick={onOpen}
        isActive={isActive}
        label={t('versionHistory.pill.hoverText')}
        icon='history'
        name='versions'
        $bgColor={getBgColor}
      />
      {isActive && (
        <VersionsPanel
          onClose={onClose}
          toggleSnapshotView={toggleSnapshotView}
        />
      )}
    </div>
  )
}

export default VersionsStatus
