import { useCallback, useState } from 'react'
import { SnapshotViewProps } from '../VersionHistory/SnapshotView/types'

export const useShowSnapshotView = () => {
  const [showSnapshotView, setShowSnapshotView] = useState<
    SnapshotViewProps | undefined
  >()

  const handleCloseSnapshot = useCallback(
    () => setShowSnapshotView(undefined),
    []
  )

  return {
    showSnapshotView,
    toggleSnapshotView: setShowSnapshotView,
    closeSnapshotView: handleCloseSnapshot,
  }
}
