import { useCallback } from 'react'
import { DiffView } from './DiffView'

import useSnapshotData from './hooks/useSnapshotData'
import { DiffAction, SnapshotViewComponentProps } from './types'

export const SnapshotView = (props: SnapshotViewComponentProps) => {
  const {
    restoreSnapshot,
    fetchSnapshotRecords,
    summary,
    isLoadingSummary,
    isErrorSummary,
  } = useSnapshotData(props)

  const handleCancel = useCallback(async () => {
    if (props.action === DiffAction.ACCEPT) {
      await restoreSnapshot()
      await props.refetchTable()
    }
    props.onCancel?.()
  }, [])

  const handleSubmit = useCallback(async () => {
    if (props.action === DiffAction.ACCEPT) {
      await props.refetchTable()
    } else {
      await restoreSnapshot()
      await props.refetchTable()
    }
  }, [])

  return (
    <DiffView
      {...props}
      summary={summary}
      isLoadingSummary={isLoadingSummary}
      isErrorSummary={isErrorSummary}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onLoadRows={fetchSnapshotRecords}
      showFilters={true}
    />
  )
}
