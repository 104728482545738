import { Params, useParams } from 'react-router-dom'
import { MOCKS } from '../test/mocks'

export function useRouteParams<T extends string[]>(): Readonly<Params<string>>
export function useRouteParams<T extends string[]>(
  ...keys: T
): ObjectFromList<T>

/**
 * Helper function for accessing route parameters in a way that can also be mocked
 * easily with tests as well as provides validation and strong typing when desired
 *
 * @example ```ts
 * // my-url?foo=bar
 * const {foo} = useRouteParams('foo') // => foo = bar
 *
 *
 * // my-url?baz=bar
 * const {foo} = useRouteParams('foo') // => Error("Url param foo not set...")
 *
 * // my-url?baz=bar
 * const params = useRouteParams() // => Error("Url param foo not set...") // => { baz: 'bar' }
 * ```
 *
 * @param keys
 */
export function useRouteParams<T extends string[]>(
  ...keys: T
): Readonly<Params<string>> | ObjectFromList<T> {
  let params = useParams()

  if (MOCKS['ROUTE_PARAMS']) {
    params = MOCKS['ROUTE_PARAMS']
  }

  if (keys.length) {
    return keys.reduce((acc, k) => {
      if (typeof params[k] === 'undefined') {
        throw new Error(`URL param ${k} not set in URL before accessing.`)
      }
      return { ...acc, [k]: params[k] }
    }, {} as ObjectFromList<T>)
  }
  return params
}

// todo: should verify this is not provided by ts-utils or something
type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
  [K in T extends ReadonlyArray<infer U> ? U : never]: V
}
