import { DefaultApi, Workbook, WorkbookResponse } from '@flatfile/api'
import { HTTPService } from '../../components/HttpClient'
import { Observable } from '../observable'

export class WorkbookController {
  constructor(private httpClient: DefaultApi) {}

  /**
   * Get a workbook
   *
   * @todo this should be refactored to return an observable
   * @param id
   */
  public getWorkbookById(id: string): Promise<WorkbookResponse> {
    // TODO refactor this using useQueryGetWorkbookById
    return this.httpClient.getWorkbookById({ workbookId: id })
  }

  public getAllWorkbooksWithCounts(): Observable<
    Workbook[],
    { spaceId: string }
  > {
    return new Observable(({ spaceId }: { spaceId: string }) =>
      // TODO refactor this using useQueryGetWorkbookById
      HTTPService.getAllWorkbooks({
        spaceId,
        includeCounts: true,
      })
    )
  }
}
