import { Environment } from '@flatfile/api'
import { BannerTab, BannerTop } from './elements'

interface IDevBanner {
  environment: Environment
}
const DevBanner = ({ environment }: IDevBanner) => {
  return !environment?.isProd ? (
    <BannerTop>
      <BannerTab data-testid='dev-banner'>Developer mode</BannerTab>
    </BannerTop>
  ) : null
}

export default DevBanner
