import { PlanController } from '@/api/controllers/PlanController'
import { Rule, RuleController } from '@/api/controllers/RuleController'
import { updateObservable, useAction } from '@/api/observable'
import { MappingSidebar } from '@/elements/MappingComponents'
import { useEventCallbacks } from '@/hooks/useEventCallbacks'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Typography } from '@flatfile/design-system'
import { useMemo } from 'react'
import { EnumCard } from '../components/EnumCard'
import { MappingTitle } from '../elements'

export const MapEnumsPage = ({
  controller,
  onFocus,
}: {
  controller: PlanController
  onFocus?: (rule: RuleController) => void
}) => {
  const [updateRule] = useAction(controller.updateRule(), (data) => {
    updateObservable('plan', data)
  })
  const { t } = useTypedTranslation()

  const rules = useMemo(
    () => controller.getRules({ enum: true }),
    [controller.plan]
  )

  const eventHandlers = useEventCallbacks('EnumCard', {
    onFocus(rule: RuleController) {
      onFocus?.(rule)
    },
    onChange(rule: Rule) {
      updateRule({ rule })
    },
  })

  return (
    <MappingSidebar>
      <MappingTitle>
        <Typography type='h5' color='var(--color-text)'>
          {t('mapping.mapEnums.subheading')}
        </Typography>
      </MappingTitle>
      {rules.map((rule: Rule, index) => {
        return (
          <EnumCard
            key={`field-card-${index}`}
            rule={rule}
            planController={controller}
            {...eventHandlers}
          />
        )
      })}
    </MappingSidebar>
  )
}
