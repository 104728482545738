import { AMPWrapper } from '@flatfile/design-system'
import { PopoverProvider, logPaintMetrics } from '@flatfile/shared-ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import posthog from 'posthog-js'
import { createRoot } from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter } from 'react-router-dom'
import Routing from './routing'
import { logger } from './utils/logging'

if (import.meta.env.VITE_VERSION_NUMBER) {
  // eslint-disable-next-line no-console
  console.log(`Version ${import.meta.env.VITE_VERSION_NUMBER}`)
  logger.setContext({
    version: import.meta.env.VITE_VERSION_NUMBER,
  })
}

if (
  import.meta.env.VITE_POST_HOG_API_KEY &&
  import.meta.env.VITE_POST_HOG_HOST
) {
  posthog.init(import.meta.env.VITE_POST_HOG_API_KEY, {
    api_host: import.meta.env.VITE_POST_HOG_HOST,
    enable_recording_console_log: true,
  })
}
performance.mark('app_start')
logPaintMetrics(posthog, 'spaces-ui')

export async function main() {
  const hostElement = document?.getElementById('spaces-ui-main')!
  const root = createRoot(hostElement)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: (error) => {
          logger.error('Error caught in react query onError:', error)
        },
      },
    },
  })

  const appBaseName = import.meta.env.VITE_APP_BASE_NAME ?? '/'

  root.render(
    <BrowserRouter basename={appBaseName}>
      <QueryClientProvider client={queryClient}>
        <AMPWrapper>
          <PopoverProvider>
            <>
              <Routing />
              <ReactQueryDevtools initialIsOpen={false} />
            </>
          </PopoverProvider>
        </AMPWrapper>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

main()
