import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { AMP_TOOLTIP_ID, Icon, IconButton } from '@flatfile/design-system'
import { PopoverContext, copyToClipboard } from '@flatfile/shared-ui'
import { useContext } from 'react'
export const CopyIdButton = ({ jobId }: { jobId: string }) => {
  const { showPopover } = useContext(PopoverContext)
  const { t } = useTypedTranslation()
  return (
    <IconButton
      name='documentCopy'
      size={16}
      onPress={(e: any) => {
        copyToClipboard(jobId)
        showPopover(
          {
            icon: <Icon name='clipboardCheck' />,
            message: <div>{t('jobs.info.copiedPopover')}</div>,
          },
          true
        )
      }}
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content={t('jobs.info.copyIdTooltip')}
    />
  )
}
