import { HTTPService } from '@/components/HttpClient'
import {
  getRuntimeParameters,
  setRuntimeParameters,
} from '@/components/RuntimeParameters'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Auth } from './Auth'

export const runtimeStorageKey = ':runtime'
const shortCodeMap: { [key: string]: string } = {
  r: 'returnUrl',
  t: 'accessToken',
}

const hashToRuntimeParams = (hash: string) => {
  let runtimeParams: { [key: string]: string } = {}
  hash.split('&').forEach(function (item) {
    const parts = item.split('=')
    const code = shortCodeMap[parts[0]]
    if (code) {
      runtimeParams[code] = parts[1]
    }
  })
  return runtimeParams
}

export enum AuthOutcome {
  EMAIL = 'EMAIL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  AUTHORIZED = 'AUTHORIZED',
}

export const useAuth = () => {
  const [authorized, setAuthorized] = useState(false)
  const [isAuthorizing, setIsAuthorizing] = useState(true)
  const [authOutcome, setAuthOutcome] = useState<AuthOutcome | null>(null)
  const { spaceId } = useParams()
  const [search] = useSearchParams()
  const guestEmail = search.get('email') || null
  const guestToken = search.get('token') || null
  const { hash } = useLocation()
  const { accessToken: userToken, returnUrl } = hashToRuntimeParams(
    hash.substring(1)
  )
  const hasNoUrlTokens = !guestEmail && !guestToken && !userToken

  const storedRuntimeParameters = getRuntimeParameters()
  const {
    spaceId: storedSpaceId,
    accessToken: storedAccessToken,
    guestEmail: storedGuestEmail,
    isGuest: storedIsGuest,
  } = storedRuntimeParameters

  const { mutate: exchangeToken } = useMutation(
    ['exchangeInvitation'],
    ({ token, email }: { token: string; email: string }) =>
      HTTPService.exchangeInvitation({
        exchangeInvitationRequest: {
          token: token,
          email: email,
          spaceId,
        },
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.token) {
          const newRuntime = {
            ...storedRuntimeParameters,
            accessToken: data.data.token,
          }
          setRuntimeParameters(newRuntime)
          Auth.initiateToken(data.data.token)
          setIsAuthorizing(false)
          setAuthorized(true)
          setAuthOutcome(AuthOutcome.AUTHORIZED)
        } else {
          setIsAuthorizing(false)
          setAuthorized(false)
          setAuthOutcome(AuthOutcome.EMAIL)
        }
      },
    }
  )

  const { isFetching: fetchingGuestAuth, refetch: fetchGuestAuth } = useQuery(
    ['getSpaceAuth', spaceId],
    () => HTTPService.getSpaceAuth({ spaceId: spaceId ?? '' }),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.data?.accessToken) {
          const newRuntime = {
            ...storedRuntimeParameters,
            accessToken: data.data.accessToken,
          }
          setRuntimeParameters(newRuntime)
          Auth.initiateToken(data.data.accessToken)
          setIsAuthorizing(false)
          setAuthorized(true)
          setAuthOutcome(AuthOutcome.AUTHORIZED)
        }
      },
      onError: () => {
        setIsAuthorizing(false)
        setAuthorized(false)
        setAuthOutcome(AuthOutcome.EMAIL)
      },
    }
  )

  /* Handle consideration hash parameter which represents access being made by a User from dashboard*/
  useEffect(() => {
    if (userToken) {
      const newRuntime = {
        accessToken: userToken,
        isGuest: false,
        returnUrl,
      }
      setRuntimeParameters(newRuntime)
      Auth.initiateToken(userToken)
      setAuthorized(true)
      setIsAuthorizing(false)
      setAuthOutcome(AuthOutcome.AUTHORIZED)
    }
  }, [hash])

  useEffect(() => {
    if (guestEmail) {
      const newRuntime = {
        guestEmail: guestEmail,
        isGuest: true,
        spaceId: spaceId,
      }
      setRuntimeParameters(newRuntime)
      setIsAuthorizing(false)
      setAuthorized(false)
      setAuthOutcome(AuthOutcome.EMAIL)
    }
  }, [guestEmail])

  useEffect(() => {
    if (guestToken) {
      const newRuntime = {
        ...storedRuntimeParameters,
        accessToken: guestToken,
        isGuest: true,
      }
      const tokenExpired = Auth.getSecondsUntilExpiration(guestToken) === 0

      if (tokenExpired && storedGuestEmail) {
        exchangeToken({
          token: guestToken,
          email: storedGuestEmail,
        })
      } else if (tokenExpired) {
        setIsAuthorizing(false)
        setAuthorized(false)
        setAuthOutcome(AuthOutcome.SESSION_EXPIRED)
      } else {
        setRuntimeParameters(newRuntime)
        Auth.initiateToken(newRuntime.accessToken)
        setAuthorized(true)
        setIsAuthorizing(false)
        setAuthOutcome(AuthOutcome.AUTHORIZED)
      }
    }
  }, [guestToken])

  useEffect(() => {
    if (
      hasNoUrlTokens &&
      storedIsGuest &&
      spaceId &&
      storedSpaceId &&
      spaceId !== storedSpaceId &&
      storedAccessToken
    ) {
      Auth.initiateToken(storedAccessToken)
      const newRuntime = {
        ...storedRuntimeParameters,
        spaceId: spaceId,
      }
      setRuntimeParameters(newRuntime)
      setAuthorized(false)
      setIsAuthorizing(true)
      fetchGuestAuth()
    }
  }, [spaceId])

  /*  Handle consideration of stored accessToken if no URL parameter is passed */
  useEffect(() => {
    if (hasNoUrlTokens && !fetchingGuestAuth) {
      if (storedAccessToken) {
        Auth.initiateToken(storedAccessToken)
        setAuthorized(true)
        setIsAuthorizing(false)
        setAuthOutcome(AuthOutcome.AUTHORIZED)
      } else {
        setAuthorized(false)
        setIsAuthorizing(false)
        setAuthOutcome(AuthOutcome.UNAUTHORIZED)
      }
    }
  }, [])

  return { authorized, isAuthorizing, authOutcome }
}
