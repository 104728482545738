import styled from 'styled-components'

export const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .spinner {
    color: var(--color-primary);
    height: 2.5rem;
    width: 2.5rem;
  }
`
