import EntitlementGuard from '@/components/Shared/EntitlementGuard'
import ParagonContext from '@/contexts/ParagonContext'
import { ButtonSize, ButtonType } from '@flatfile/design-system'
import { useContext } from 'react'
import { ProtectedConnectionsButton } from './ProtectedConnectionsButton'

export const ConnectionsButton = ({
  variant,
  size,
  isDisabled,
}: {
  variant?: ButtonType
  size?: ButtonSize
  isDisabled?: boolean
}) => {
  const token = useContext(ParagonContext)

  return (
    <EntitlementGuard entitlements={['box', 'googleDrive']} requireAll={false}>
      {(isEntitled) => (
        <ProtectedConnectionsButton
          variant={variant ?? 'tertiary-border'}
          size={size ?? 'sm'}
          isDisabled={isDisabled}
          checkedEntitlements={isEntitled}
        />
      )}
    </EntitlementGuard>
  )
}
