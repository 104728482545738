import { getFont, FontType } from '@flatfile/design-system'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface LinkStyleProps {
  type?: FontType
}

const LinkStyles = css<LinkStyleProps>`
  ${({ type }) => type && getFont(type)}
  color: var(--color-action);
`

export const AnchorLink = styled.a<LinkStyleProps>`
  ${LinkStyles}
`

export const ActionLink = styled(Link)<LinkStyleProps>`
  ${LinkStyles}
`
