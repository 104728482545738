import {
  NavigateFunction,
  useNavigate as useNavigateCore,
} from 'react-router-dom'
import { MOCKS } from '../test/mocks'

/**
 * Wrapper that allows for mocking as the react-router-dom library is
 * nearly impenetrable
 */
export function useNavigate(): NavigateFunction {
  if (MOCKS['USE_NAVIGATE']) {
    return MOCKS['USE_NAVIGATE']
  }
  return useNavigateCore()
}
