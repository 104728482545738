const magnitudes = ['k', 'm', 'b', 't']

const roundToFirstDecimal = (int: number): number => {
  return Math.round(int * 10) / 10
}

const roundToCharacterLimit = (int: number, order: number): string => {
  const rounded = roundToFirstDecimal(int)
  const roundedString = rounded.toString()
  const initCharacters = int.toString().split('.')
  const magnitude = magnitudes[order]

  if (roundedString.length > 3) {
    const twiceRounded = Math.round(rounded)
    const twiceRoundedString = twiceRounded.toString()
    if (twiceRoundedString.length === initCharacters[0].length) {
      return twiceRoundedString + magnitude
    } else {
      /*
        This is to ensure that if a number rounds above its order of magnitude
        we increment the order.
      */
      return twiceRounded / 1000 + magnitudes[order + 1]
    }
  }

  return roundedString + magnitude
}

export const formatNumber = (int: number): string => {
  if (int >= 1e9) {
    return roundToCharacterLimit(int / 1e9, 2)
  }
  if (int >= 1e6) {
    return roundToCharacterLimit(int / 1e6, 1)
  }
  if (int >= 1e3) {
    return roundToCharacterLimit(int / 1e3, 0)
  }

  return int.toString()
}
