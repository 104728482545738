import { Auth } from '@/auth/Auth'
import {
  DefaultApi,
  FileMode,
  FileOrigin,
  Job,
  JobsResponse,
  JobTriggerEnum,
  JobTypeEnum,
  ModelFile,
  Pagination,
  ResponseError,
  SuccessResponse,
  Workbook,
} from '@flatfile/api'
import axios from 'axios'
import { JobOperationEnum } from '../../contexts/JobsContext'
import { LazyObservable, Observable } from '../observable'

export const FILES_PAGE_SIZE = 15
export class FileController {
  constructor(
    private spaceId: string,
    private environmentId: string,
    private httpClient: DefaultApi
  ) {}

  public getFiles(): Observable<Files> {
    return new Observable<Files>(
      async ({ pageNumber, mode }: { pageNumber?: number; mode: FileMode }) => {
        const response = await this.httpClient.getFiles({
          spaceId: this.spaceId,
          pageNumber,
          pageSize: pageNumber ? FILES_PAGE_SIZE : undefined,
          mode: mode,
        })
        return {
          data: { files: response.data!, pagination: response.pagination! },
        }
      }
    )
  }

  public getFile(
    fileId: string
  ): Observable<ModelFile & { workbook?: Workbook }> {
    return new Observable(async () => {
      const fileResponse = await this.httpClient.getFile({ fileId })
      if (!fileResponse.data.workbookId) {
        return fileResponse
      }
      // TODO refactor this using useQueryGetWorkbookById
      const wb = await this.httpClient.getWorkbookById({
        workbookId: fileResponse.data.workbookId,
      })
      return { data: { ...fileResponse.data, workbook: wb.data } }
    })
  }

  public getWorkbook(workbookId: string): Observable<Workbook> {
    return new Observable(() =>
      this.httpClient.getWorkbookById({
        workbookId: workbookId,
      })
    )
  }

  public getFileJobs(fileId: string): Promise<JobsResponse> {
    return this.httpClient.getJobs({ fileId })
  }

  public deleteFiles(): LazyObservable<SuccessResponse[], DeleteFilesProps> {
    return new Observable<SuccessResponse[], DeleteFilesProps>(
      async ({ fileIds }) => {
        const data = await Promise.all(
          fileIds.map((fileId) => this.httpClient.deleteFile({ fileId }))
        )
        return { data }
      }
    ).lazy
  }

  public downloadFiles(): LazyObservable<
    DownloadedFile[],
    DownloadFileProps[]
  > {
    return new Observable<DownloadedFile[], DownloadFileProps[]>(
      async (props) => {
        const files = await Promise.all(
          props.map((downloadFileProps) =>
            this.downloadFile({ ...downloadFileProps })
          )
        )
        return {
          data: files,
        }
      }
    ).lazy
  }

  async downloadFile(props: DownloadFileProps): Promise<DownloadedFile> {
    let fileName = props.fileName
    if (!fileName) {
      const file = await this.httpClient.getFile({ fileId: props.fileId })
      fileName = file?.data?.name
    }
    const file = await this.httpClient.downloadFile({ fileId: props.fileId })
    return {
      file,
      fileName: fileName || '',
    }
  }

  /**
   * Prepare a multipart form upload to Flatfile for a specific file
   *
   * @todo handle an upload error properly
   * @param file
   * @param setUploadProgress
   */
  public async uploadFileToSpace(
    file: File,
    setUploadProgress: (p: UploadProgress) => void,
    origin?: FileOrigin
  ) {
    // this should probably be a utility
    const formData = new FormData()
    formData.append('spaceId', this.spaceId)
    formData.append('environmentId', this.environmentId)
    formData.append('file', file, file.name)
    if (origin) {
      formData.append('origin', origin)
    }

    const fileUrl = `${import.meta.env.VITE_BASE_API_URL}/files`
    const start = Date.now()

    // raw axios seems wrong here but maybe necessary
    const uploadResult = await axios.post(fileUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${Auth.TOKEN}`,
      },
      onUploadProgress: (progressEvent) => {
        const uploadProgress = this.calculateUploadProgress(
          progressEvent,
          start
        )
        setUploadProgress(uploadProgress)
      },
    })
    return uploadResult.data.data
  }

  /**
   * Create a new job for matching this file to a specific sheet
   */
  mapToSheet(): LazyObservable<Job, MapToSheetProps> {
    return new Observable<Job, MapToSheetProps>(
      async ({
        sourceWorkbookId,
        sourceSheetId,
        destinationWorkbookId,
        destinationSheetId,
      }) => {
        if (!sourceWorkbookId) {
          throw new Error('Must provide sourceWorkbookId')
        }
        if (!sourceSheetId) {
          throw new Error('Must provide sourceSheetId')
        }
        if (!destinationWorkbookId) {
          throw new Error('Must provide destinationWorkbookId')
        }
        if (!destinationSheetId) {
          throw new Error('Must provide destinationSheetId')
        }

        try {
          return await this.httpClient.createJob({
            jobConfig: {
              type: JobTypeEnum.Workbook,
              operation: JobOperationEnum.Map,
              trigger: JobTriggerEnum.Manual,
              source: sourceWorkbookId,
              destination: destinationWorkbookId,
              mode: 'foreground',
              config: {
                sourceSheetId,
                destinationSheetId,
              },
            },
          })
        } catch (err) {
          if (!(err instanceof ResponseError)) {
            throw err
          }
          const res = await err.response.json()
          const errorMessages =
            res.errors?.map((e: { message: string }) => e.message) || []
          throw new Error(errorMessages.join(', '))
        }
      }
    ).lazy
  }

  /**
   * Calculate the upload progress and speed in KB/s
   *
   * @param progressEvent
   * @param startTime
   */
  public calculateUploadProgress(
    progressEvent: any,
    startTime: number
  ): UploadProgress {
    const currentDurationSeconds = (Date.now() - startTime) / 1000
    const currentLoadedK = progressEvent.loaded / 1000
    const speed = Math.floor(currentLoadedK / currentDurationSeconds)
    const progress = Math.floor(
      (progressEvent.loaded / progressEvent.total) * 100
    )
    return {
      progress,
      speed,
    }
  }
}

export type MapToSheetProps = {
  sourceWorkbookId: string
  sourceSheetId: string
  destinationWorkbookId: string
  destinationSheetId: string
}

interface DeleteFilesProps {
  fileIds: string[]
}

export interface DownloadFileProps {
  fileId: string
  fileName?: string
}

interface DownloadedFile {
  fileName: string
  file: Blob
}
export interface Files {
  files: ModelFile[]
  pagination?: Pagination
}

export interface UploadProgress {
  progress: number
  speed: number
}
