import {
  GetSnapshotRecordsChangeTypeEnum as ChangeType,
  SnapshotSummary,
  SnapshotSummaryUpdatedSince,
} from '@flatfile/api'
import { ColumnConfigProps } from '@flatfile/turntable'
import { DiffAction } from './types'

interface SnapshotFieldCounts {
  [ChangeType.UpdatedSince]?: number
  [ChangeType.CreatedSince]?: number
  [ChangeType.DeletedSince]?: number
}

interface SnapshotCounts {
  total: number
  fields: {
    [key: string]: SnapshotFieldCounts
  }
}

/**
 *
 * @param summary
 * @returns The summary of a snapshot in a readable string.
 */
export const getSnapshotSummary = (
  summary: SnapshotSummary,
  action: DiffAction,
  t: any,
  columnConfig?: ColumnConfigProps[]
) => {
  const totals: SnapshotCounts = Object.keys(summary).reduce(
    (memo, changeType) => {
      const changes = summary[
        changeType as ChangeType
      ] as SnapshotSummaryUpdatedSince
      if (changes.total) {
        memo.total += changes.total
      }
      if (changeType === ChangeType.UpdatedSince && changes.byField) {
        const fields = Object.keys(changes.byField)
        fields.forEach((fieldKey: string) => {
          const count = changes.byField?.[fieldKey]
          if (count) {
            const fieldCounts = memo.fields[fieldKey] ?? {}
            fieldCounts[changeType as ChangeType] = count
            memo.fields[fieldKey] = fieldCounts
          }
        })
      }
      return memo
    },
    { total: 0, fields: {} } as SnapshotCounts
  )

  let text = ''

  const hasAddedOrDeleted =
    summary.deletedSince.total || summary.createdSince.total
  const fields = Object.keys(totals.fields)
  const fieldsCount = hasAddedOrDeleted
    ? columnConfig?.length ?? 0
    : fields.length
  const recordCountType = totals.total === 1 ? 'singular' : 'plural'
  const fieldsCountType = !fieldsCount
    ? 'noFields'
    : fieldsCount === 1
    ? 'oneField'
    : 'multipleFields'

  text += t(
    `snapshot.${action}.summary.total.${recordCountType}.${fieldsCountType}`,
    {
      records: totals.total.toLocaleString(),
      fields: fieldsCount.toLocaleString(),
    }
  )

  if (fields.length) {
    text += t(`snapshot.${action}.summary.fields.start`)

    fields.forEach((fieldKey, index) => {
      const column = columnConfig?.find((c) => c.value === fieldKey)
      const cellCounts = totals.fields[fieldKey][ChangeType.UpdatedSince]
      const cellCountType = cellCounts === 1 ? 'singular' : 'plural'
      const placeType =
        index === 0 ? 'first' : index === fields.length - 1 ? 'last' : 'middle'
      text += t(
        `snapshot.${action}.summary.fields.${cellCountType}.${placeType}`,
        { cells: cellCounts?.toLocaleString(), name: column?.label ?? fieldKey }
      )
    })

    text += '.'
  }

  return text
}
