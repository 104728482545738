import { EventTopic } from '@flatfile/api'
import { useEffect, useState } from 'react'
import { Observable, useObservable } from '../api/observable'
import { Resource, getTargetIdFromEvent, resources } from '../api/resources'
import { useForceUpdate } from '../api/resources/useForceUpdate'
import { useEventSubscriber } from './useEventSubscriber'
import { usePresentEffect } from './usePresentEffect'

/**
 * Given any Observable that can return an array of known resources, keep it up to date
 * as new items are created and current items are modified.
 *
 * Listens to
 * - {resource}:created
 * - {resource}:updated
 * - {resource}:deleted
 *
 * @todo add support for pagination without losing stream
 *
 * @param resource Full name of the resource we're observing
 * @param collection Observable collection of resources
 */
export function useResourceStream<T extends { id: string; updatedAt: Date }>(
  resource: string,
  collection: Observable<T[]>
): Array<T> | undefined {
  const [stream, setStream] = useState<Array<Resource<T>> | undefined>(
    undefined
  )
  const [_, items] = useObservable(collection)

  usePresentEffect(() => {
    setStream(items.map((item) => resources.exchangeForResource(item)))
  }, [items])

  const forceUpdate = useForceUpdate()
  const dataListener = () => {
    forceUpdate()
  }

  useEffect(() => {
    stream?.forEach((res) => {
      res.observable.addEventListener('data', dataListener)
    })

    return () => {
      stream?.forEach((res) => {
        res.observable.removeEventListener('data', dataListener)
      })
    }
  }, [stream])

  useEventSubscriber([`${resource}:created` as EventTopic], async (ev) => {
    const event = JSON.parse(ev.message)
    const targetId = getTargetIdFromEvent(resource, event)
    const resProm = resources.getWithPromise<T>(targetId)
    // todo only set this if the resource is available
    const res = await resProm
    setStream((prev) => {
      return [res, ...(prev ?? [])]
    })
  })

  return stream
    ?.map((res) => res.maybeData)
    .filter((maybeData) => !!maybeData)
    .map((maybeData) => maybeData as T)
}
