import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { EventTopic } from '@flatfile/api'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { QueryMap } from '../queries/flatfileQuery'
import { QueryEventHandler } from '../queries/types'
/**
 * Initializes a listener for each Flatfile event topic that invalidates the proper queries accoring to the event
 * query registry
 *  */
export const useEvents = () => {
  const queryClient = useQueryClient()

  // Build a map of EventTopics -> EventHandler for each query from the map
  const buildEventsMap = useMemo(() => {
    const map: Partial<Record<EventTopic, QueryEventHandler[]>> = {}
    Object.entries(QueryMap).forEach(([_, queryRegistry]) => {
      Object.entries(queryRegistry.influencingEvents).forEach(
        ([topic, handler]) => {
          const eventHandlers = map[topic as EventTopic]
          if (eventHandlers) {
            map[topic as EventTopic] = [
              ...eventHandlers,
              handler as QueryEventHandler,
            ]
          } else {
            map[topic as EventTopic] = [handler as QueryEventHandler]
          }
        }
      )
    })
    return map
  }, [QueryMap])

  // Build a mapping from Flatfile event topics to queries that those topics may invalidate
  const eventsMap: Partial<Record<EventTopic, QueryEventHandler[]>> =
    buildEventsMap

  // Iterate over the events and create a handler for each which invalidates the queries specified
  Object.entries(eventsMap).forEach(([topic, queryEventHandlers]) => {
    useEventSubscriber([topic as EventTopic], (_, event) => {
      queryEventHandlers?.forEach((queryHandler) => {
        queryHandler(event, queryClient)
      })
    })
  })
}
