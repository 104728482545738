import {
  Button,
  ButtonSize,
  ButtonType,
  ContextMenu,
  MenuOptionProps,
} from '@flatfile/design-system'
import { useContext, useState } from 'react'
import { SpaceContext } from '@/contexts/SpaceContext'
import { usePaymentAccess } from '@/hooks/usePaymentAccess'
import { useEntitlements } from '@/hooks/useEntitlements'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import ParagonContext from '@/contexts/ParagonContext'
import { useParagonConnect } from '../hooks/useParagonConnect'
import { BoxFilePicker } from './BoxFilePicker'
import { ConnectionsModal } from './ConnectionsModal'
import { CheckedEntitlements } from '@/components/Shared/EntitlementGuard'

type ConnectionsButtonProps = {
  variant?: ButtonType
  size?: ButtonSize
  isDisabled?: boolean
} & CheckedEntitlements

export const ProtectedConnectionsButton = (props: ConnectionsButtonProps) => {
  const { space } = useContext(SpaceContext)
  const [loading, setLoading] = useState(false)
  const [showConnectionsModal, setShowConnectionsModal] = useState(false)
  const { hasEntitlement } = useEntitlements()
  const { fileUploadLocked } = usePaymentAccess()
  const { t } = useTypedTranslation()
  const token = useContext(ParagonContext)
  const google = hasEntitlement('googleDrive')
  const box = hasEntitlement('box')
  const {
    handleFileSelection,
    showBoxFilePicker,
    setShowBoxFilePicker,
    boxToken,
    handleGoogleClick,
    handleBoxClick,
    connectWithGoogle,
    connectWithBox,
  } = useParagonConnect({ space: space, token: token, setLoading: setLoading })

  const options: MenuOptionProps[] = [
    ...(google
      ? [
          {
            key: 'googleDrive',
            label: t('files.buttons.addFileFrom.googleDrive'),
            onAction: handleGoogleClick,
          },
        ]
      : []),
    ...(box
      ? [
          {
            key: 'box',
            label: t('files.buttons.addFileFrom.box'),
            onAction: handleBoxClick,
          },
        ]
      : []),
    {
      key: 'manage',
      label: 'Manage Connections',
      onAction: () => setShowConnectionsModal(true),
    },
  ]

  return (
    <>
      <ContextMenu options={options}>
        {(open) => (
          <Button
            iconLeft='documentAdd'
            label={t('files.buttons.addFileFrom.text')}
            onPress={open}
            loading={loading}
            variant={props.variant}
            isDisabled={fileUploadLocked || props.isDisabled}
            size={props.size}
            data-testid='connections-button'
          />
        )}
      </ContextMenu>
      {boxToken.current && showBoxFilePicker && (
        <BoxFilePicker
          boxToken={boxToken.current}
          setShowBox={setShowBoxFilePicker}
          handleFileSelection={handleFileSelection}
          setLoading={setLoading}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          showModal={setShowConnectionsModal}
          connectWithGoogle={google ? connectWithGoogle : undefined}
          connectWithBox={box ? connectWithBox : undefined}
        />
      )}
    </>
  )
}
