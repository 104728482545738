import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { logger } from '@/utils/logging'
import {
  AddFieldRequest,
  DefaultApi,
  Job,
  Plan,
  SheetConfig,
} from '@flatfile/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import Postmate from 'postmate'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FullSizeContainer, IframeContainer } from '../elements'
import { NavigationState } from '../types'
import {
  isHeaderRowSelectionEnabled,
  transformSourceFields,
  transformSourceRecords,
} from '../utils'

const MAPPING_BASE_URL = import.meta.env.VITE_MAPPING_BASE_URL
const BASE_API_URL = import.meta.env.VITE_MAPPING_BASE_API_URL

type OnAddAdditionalFieldData = {
  key: string
  label: string
  rule: any
  id: string
  accessToken: string
  baseUrl: string
}

enum QueryKeys {
  getSource = 'getSource',
  getDestination = 'getDestination',
  getProgram = 'getProgram',
  getSample = 'getSample',
}

enum CallbackKeys {
  OnDestinationEnumSelectResponse = 'onDestinationEnumSelectResponse',
  OnAddAdditionalFieldResponse = 'onAddAdditionalFieldResponse',
  OnAddAdditionalField = 'onAddAdditionalField',
  OnDestinationEnumSelect = 'onDestinationEnumSelect',
  OnComplete = 'onComplete',
  Initialize = 'initialize',
  Navigate = 'navigate',
}

type MappingProgramPageProps = {
  httpClient: DefaultApi
  job: Job
  plan: Plan & { programId: string }
  onComplete: () => void
  onNavigationChange?: (state: NavigationState) => void
  handshake?: Postmate | null
  setHandshake?: (handshake: Postmate) => void
  setIframeLoading?: (loading: boolean) => void
}

export const MappingProgramPage = ({
  httpClient,
  job,
  plan,
  onComplete,
  onNavigationChange,
  handshake,
  setHandshake,
  setIframeLoading,
}: MappingProgramPageProps) => {
  const isConnected = useRef(false)
  const [shouldAttemptHandshake, setShouldAttemptHandshake] = useState(false)
  const [isSampleLoaded, setIsSampleLoaded] = useState(false)
  const [processedSample, setProcessedSample] = useState<Record<string, any>[]>(
    []
  )
  const [destinationEnumSelect, setDestinationEnumSelect] = useState<{
    sourceField: string | null
    destinationField: string | null
    destinationSheet: any
    ruleId: string
    id: string
    accessToken: string
    baseUrl: string
  } | null>(null)
  const programId: string = plan.programId
  const sourceSheetId = (job.config as { sourceSheetId: string })[
    'sourceSheetId'
  ]
  const destinationSheetId = (job.config as { destinationSheetId: string })[
    'destinationSheetId'
  ]

  const subject = job.subject
  const query = subject && subject.type === 'collection' ? subject.query : {}

  const addFieldMutation = useMutation((payload: AddFieldRequest) =>
    httpClient.addField(payload)
  )

  const { data: source, isLoading: sourceLoading } = useQuery(
    [QueryKeys.getSource, programId],
    () =>
      httpClient.getSheet({
        sheetId: sourceSheetId,
      })
  )

  const {
    data: dest,
    isLoading: destLoading,
    refetch: refetchDest,
  } = useQuery([QueryKeys.getDestination, programId], () =>
    httpClient.getSheet({
      sheetId: destinationSheetId,
    })
  )

  const { data: program, isLoading: programLoading } = useQuery(
    [QueryKeys.getProgram, programId],
    () =>
      httpClient.getProgram({
        programId,
      })
  )

  const { data: sample, isLoading: sampleLoading } = useQuery(
    [QueryKeys.getSample, programId],
    () =>
      httpClient.getRecords({
        sheetId: sourceSheetId,
        pageSize: 100,
        pageNumber: 1,
        ...query,
      })
  )

  const { refetch: refetchCellValues } = useFlatfileQuery(
    'getCellValues',
    {
      sheetId: sourceSheetId,
      fieldKey: destinationEnumSelect?.sourceField!,
      pageNumber: 1,
      pageSize: 50,
      distinct: true,
      includeCounts: false,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        handshake?.then((child) => {
          const sourceValues = data.data?.[
            destinationEnumSelect?.sourceField!
          ].map((x) => x.value)

          child.call(CallbackKeys.OnDestinationEnumSelectResponse, {
            ...destinationEnumSelect,
            sourceValues,
          })
        })
      },
    }
  )

  const { data: fieldCountsData } = useFlatfileQuery(
    'getCounts',
    {
      sheetId: sourceSheetId,
      byField: true,
    },
    {
      staleTime: Infinity,
    }
  )

  const emptySourceFields = useMemo(() => {
    if (fieldCountsData?.data?.counts?.byField) {
      return Object.entries(fieldCountsData.data.counts.byField)
        .filter(
          ([_, field]) =>
            'empty' in field && 'total' in field && field.empty === field.total
        )
        .map(([key, _]) => key)
    } else {
      return []
    }
  }, [fieldCountsData])

  useEffect(() => {
    if (destinationEnumSelect) {
      refetchCellValues().then(() => {
        setDestinationEnumSelect(null)
      })
    }
  }, [destinationEnumSelect])

  const handleAddField = async (data: OnAddAdditionalFieldData) => {
    const { key, label, rule } = data
    try {
      const addFieldResponse = await addFieldMutation.mutateAsync({
        sheetId: destinationSheetId,
        fieldConfig: {
          key,
          label,
          type: 'string',
          treatments: ['user-defined'],
        },
      })

      const updatedDestConfig = await refetchDest()

      return {
        destinationSheet: updatedDestConfig?.data?.data?.config,
        sourceSheet: source?.data?.config,
        oldRule: rule,
        newField: addFieldResponse.data,
        ...data,
      }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  const allDataLoaded = useMemo(() => {
    return !sourceLoading && !destLoading && !programLoading && !sampleLoading
  }, [sourceLoading, destLoading, programLoading, sampleLoading])

  const transformData = useCallback(
    (
      sample: Record<string, any>[],
      sourceConfig: SheetConfig,
      metadata: any
    ) => {
      if (!isHeaderRowSelectionEnabled(metadata)) {
        return {
          transformedSample: sample,
          transformedSourceSheet: sourceConfig,
        }
      }

      const headers = metadata.headers || []
      const transformedSample = transformSourceRecords(sample, headers)
      const transformedFields = transformSourceFields(
        sourceConfig.fields,
        headers
      )
      const transformedSourceSheet = {
        ...sourceConfig,
        fields: transformedFields,
      }

      return { transformedSample, transformedSourceSheet }
    },
    []
  )

  useEffect(() => {
    if (sample?.data?.records && source?.data?.metadata) {
      const processedRecords = sample.data.records
        .filter(
          (r) => !(source.data as any).metadata.excludedRowIds?.includes(r.id)
        )
        .map((r) =>
          Object.fromEntries(
            Object.entries(r.values).map(([k, v]) => [k, v.value])
          )
        )

      setIsSampleLoaded(true)
      setProcessedSample(processedRecords)
    }
  }, [sample, source])

  useEffect(() => {
    if (
      allDataLoaded &&
      isSampleLoaded &&
      source?.data?.config &&
      dest?.data?.config &&
      emptySourceFields
    ) {
      setShouldAttemptHandshake(true)
    }
  }, [
    allDataLoaded,
    isSampleLoaded,
    source?.data?.config,
    dest?.data?.config,
    emptySourceFields,
  ])

  useEffect(() => {
    let handshake: Postmate | null = null

    const attemptHandshake = () => {
      const container = document.getElementById('mapping-iframe-container')

      if (container) {
        logger.info('[platform] Found iframe container, attempting handshake')

        if (isConnected.current) {
          logger.error('[platform] Handshake already successful')
          return
        }

        handshake = new Postmate({
          container: container,
          url: MAPPING_BASE_URL!,
        })

        setHandshake?.(handshake)

        handshake
          .then((child) => {
            setIframeLoading?.(false)
            isConnected.current = true

            const { transformedSample, transformedSourceSheet } = transformData(
              processedSample || [],
              source?.data?.config!,
              source?.data?.metadata
            )

            logger.info('[platform] Handshake successful')

            child.call(CallbackKeys.Initialize, {
              id: programId,
              accessToken: program?.data?.accessToken,
              sourceSheet: transformedSourceSheet as SheetConfig,
              destinationSheet: dest?.data?.config,
              sourceRecords: transformedSample as Record<string, any>[],
              emptySourceFields,
              baseUrl: BASE_API_URL,
            })

            child.on(CallbackKeys.Navigate, (data) => {
              logger.info('[platform] onNavigate', data)
              onNavigationChange?.(data)
            })

            child.on(CallbackKeys.OnComplete, () => {
              logger.info('[platform] onComplete')
              onComplete()
            })

            child.on(CallbackKeys.OnDestinationEnumSelect, (data) => {
              logger.info('[platform] onDestinationEnumSelect', data)
              setDestinationEnumSelect(data)
            })

            child.on(CallbackKeys.OnAddAdditionalField, async (data) => {
              logger.info('[platform] onAddAdditionalField', data)
              try {
                const response = await handleAddField(data)

                child.call(CallbackKeys.OnAddAdditionalFieldResponse, {
                  data: response,
                })
              } catch (error) {
                logger.error('[platform] onAddAdditionalField error', error)
                child.call(CallbackKeys.OnAddAdditionalFieldResponse, {
                  error: {
                    message: (error as Error).message,
                  },
                })
              }
            })
          })
          .catch((error) => {
            logger.error('[platform] Handshake failed', error)
            setIframeLoading?.(false)
          })
      } else {
        logger.error('[platform] Container not found')
      }
    }

    if (shouldAttemptHandshake) {
      attemptHandshake()
    }

    return () => {
      if (handshake && !isConnected.current) {
        logger.info('[platform] Destroying handshake')
        handshake.then((child) => child.destroy())
      }
    }
  }, [shouldAttemptHandshake])

  return (
    <FullSizeContainer>
      {sourceLoading || destLoading || programLoading || sampleLoading ? (
        <span data-testid={'embedded-mapping-loader'}>Loading...</span>
      ) : (
        <IframeContainer
          data-testid={'embedded-mapping'}
          id='mapping-iframe-container'
        />
      )}
    </FullSizeContainer>
  )
}
