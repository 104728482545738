import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { SpacesUISkeleton } from '@/components/SpacesUISkeleton'
import { Sheet, Workbook } from '@flatfile/api'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ErrorState } from '../../components/EmptyState'
import { FileUploadRedirectProvider } from '../../contexts/FileUploadRedirectContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { SheetView } from './SheetView'
import { TransformPreview } from './TransformPreview/TransformPreview'
import { TransformPreviewProps } from './TransformPreview/types'
import { SnapshotView } from './VersionHistory/SnapshotView/SnapshotView'
import { SnapshotViewProps } from './VersionHistory/SnapshotView/types'

type MainSheetViewProps = {
  sheetId?: string
  workbookId?: string
  workbook: Workbook
  sheet?: Sheet
}

const MainSheetView = ({ sheetId, workbook, sheet }: MainSheetViewProps) => {
  const { space, sidebarCollapsed } = useContext(SpaceContext)
  const [showSnapshotView, setShowSnapshotView] = useState<
    SnapshotViewProps | undefined
  >()

  const [previewDiff, setPreviewDiff] = useState<
    Omit<TransformPreviewProps, 'onClose'> | undefined
  >()

  const handleClosePreviewDiff = useCallback(
    () => setPreviewDiff(undefined),
    []
  )

  const handleCloseSnapshot = useCallback(
    () => setShowSnapshotView(undefined),
    []
  )

  useDocumentTitle(sheet?.name, space.name)

  useEffect(() => {
    if (sidebarCollapsed) {
      /* Set the sidebar state to whatever is stored for sheets */
      const sheetSidebarCollapsed = sidebarCollapsed.get({ sheet: true })
      sidebarCollapsed.set(sheetSidebarCollapsed)
    }
  }, [])

  return (
    <>
      <SheetView
        sheetId={sheetId!}
        sheet={sheet}
        workbook={workbook!}
        toggleSnapshotView={setShowSnapshotView}
        setPreviewDiff={setPreviewDiff}
        pinningEnabled={true}
        showColumnMenus={true}
      />

      {showSnapshotView ? (
        <SnapshotView
          {...showSnapshotView}
          onClose={handleCloseSnapshot}
          workbook={workbook}
        />
      ) : null}
      {previewDiff ? (
        <TransformPreview {...previewDiff} onClose={handleClosePreviewDiff} />
      ) : null}
    </>
  )
}

export const SheetRoute = () => {
  const { workbookId, sheetId } = useRouteParams()
  const { t } = useTypedTranslation()
  const {
    data: workbookData,
    isError: isGetWorkbookError,
    isLoading: isWorkbookLoading,
  } = useFlatfileQuery('getWorkbookById', { workbookId: workbookId! })
  const {
    data: sheetData,
    isError: isGetSheetError,
    isLoading: isSheetLoading,
  } = useFlatfileQuery('getSheet', { sheetId: sheetId! }, { retry: false })
  const importToSheet = useMemo(
    () => (sheetId && workbookId ? { workbookId, sheetId } : undefined),
    [sheetId, workbookId]
  )

  const workbook = isGetWorkbookError ? undefined : workbookData?.data
  const sheet = isGetSheetError ? undefined : sheetData?.data

  if (!isWorkbookLoading && workbook === undefined) {
    return <ErrorState message={t('sheet.errors.loading')} />
  }

  return isSheetLoading || isWorkbookLoading ? (
    <SpacesUISkeleton />
  ) : (
    <FileUploadRedirectProvider importToSheet={importToSheet}>
      <MainSheetView sheetId={sheetId} workbook={workbook!} sheet={sheet} />
    </FileUploadRedirectProvider>
  )
}
