import { ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;

  & + & {
    margin-top: 1.5em;
  }

  & > * {
    position: relative;
    z-index: ${ZIndex.level100};
  }

  &:after {
    background-color: var(--color-hover);
    border-radius: var(--layout-border-radius);
    content: '';
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all 0.1s ease-out;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    z-index: ${ZIndex.level0};
  }

  :hover:after {
    opacity: 1;
  }

  & p {
    margin-top: 0px;
    margin-bottom: 0.5em;
  }

  & p span {
    color: var(--color-primary);
  }
`
