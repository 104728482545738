import { usePermissions } from '@/contexts/PermissionsContext'
import { useBillingAndUsageAccess } from '@flatfile/shared-ui'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { getRuntimeParameters } from '../components/RuntimeParameters'
import { EnvironmentsContext } from '../contexts/EnvironmentsContext'
import { SpaceContext } from '../contexts/SpaceContext'
import { useEntitlements } from './useEntitlements'

const { isGuest } = getRuntimeParameters()

export const usePaymentAccess = () => {
  const { environment } = useContext(EnvironmentsContext)
  const { hasEntitlement } = useEntitlements()
  const { httpClient } = useContext(SpaceContext)
  const { hasPermission } = usePermissions()
  const billing = hasEntitlement('billingEnabled')
  const limitFiles = hasEntitlement('limitFiles')
  const limitPdv = hasEntitlement('limitPdv')
  const permission = hasPermission('account:manage-billing')

  const billingEnabled = billing && environment?.isProd && permission
  const limitsEnabled =
    (limitPdv || limitFiles) && permission && environment?.isProd

  const { data: limitsData } = useQuery(
    ['getLimits'],
    () => httpClient.getLimits(),
    {
      enabled: !!limitsEnabled,
    }
  )

  const { data: planData } = useQuery(['getPlan'], () => httpClient.getPlan(), {
    enabled: !!billingEnabled,
  })

  const {
    fileUploadLocked,
    sheetIsLocked,
    filesUsage,
    pdvUsage,
    freeFileLimit,
    freePdvLimit,
    usageBannerProps,
  } = useBillingAndUsageAccess({
    limitsData,
    planData,
    environment,
    isGuest,
  })

  return {
    fileUploadLocked,
    sheetIsLocked,
    filesUsage,
    pdvUsage,
    freeFileLimit,
    freePdvLimit,
    usageBannerProps,
    billingEnabled,
    limitsEnabled,
  }
}
