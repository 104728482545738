import { Document, Environment, Workbook } from '@flatfile/api'
import { JobsContextType } from '../contexts/JobsContext'

export const MOCKS: Record<string, any> = {}

export const mockPlan = {
  numFiles: 0,
  pdv: 0,
  plan: 'pro-discount' as any,
}
export const mockSubscriptions = [{ id: '1', status: 'active' }]

export const mockWorkbook: Workbook = {
  id: 'us_wb_1',
  name: 'workbook1',
  sheets: [
    {
      id: 'us_sh_1',
      name: 'sheet1',
      workbookId: 'us_wb_1',
      config: { name: 'sheet1', fields: [] },
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: 'us_sh_2',
      name: 'sheet2',
      workbookId: 'us_wb_1',
      config: { name: 'sheet2', fields: [] },
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ],
  spaceId: 'space1',
  environmentId: 'env1',
  createdAt: new Date(),
  updatedAt: new Date(),
}

// use as a base and then override any values needed for testing
export const mockEnv: Environment = {
  accountId: 'acc_123',
  features: {},
  guestAuthentication: [],
  metadata: {},
  id: '123',
  name: 'dev',
  isProd: false,
}

export const mockJobsContext: JobsContextType = {
  activeJobs: [],
  addOriginatedJobId: () => {},
  isOriginatedJob: (id: string) => true,
}

export const mockDocuments: Document[] = [
  {
    id: '123',
    title: 'Welcome',
    body: 'something',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: '456',
    title: 'How to',
    body: 'something',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
]

export const mockBulkRowAction = {
  key: 'download',
  label: 'Download',
  disabled: false,
  action: () => {},
  preAction: () => {},
  icon: 'download',
  primary: true,
  tooltip: {
    active: 'Records selected',
    disabled: 'No records to download',
  },
  modalText: {
    heading: 'Downloading records',
    description: 'I am a description',
    confirmButtonText: 'I am a confirmation text',
    loadingText: 'I am loading text',
    errorMessage:
      'Unable to download records. Please try again or modify your selection.',
    cancelButtonText: 'cancel',
  },
}
