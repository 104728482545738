import { CustomTheme } from '@flatfile/turntable'

export const CustomTurntableTheme: CustomTheme = {
  indexColumn: {
    backgroundColor: 'var(--color-page-100)',
    borderColor: 'var(--color-border)',
    color: 'var(--color-text-light)',
    fontFamily: 'var(--table-font-family)',
    selected: {
      color: 'var(--color-primary)',
      backgroundColor: 'var(--color-primary-light)',
    },
  },
  inputs: {
    radio: {
      color: 'var(--color-primary)',
    },
    checkbox: {
      color: 'var(--color-action)',
      borderRadius: 'var(--checkbox-border-radius)',
      borderColor: 'var(--checkbox-border-color)',
    },
    search: {
      borderColor: 'var(--interactive-border-color)',
    },
  },
  table: {
    backgroundColor: 'var(--color-page-100)',
    borderColor: 'var(--color-border)',
  },
  cell: {
    borderColor: 'var(--color-border)',
    mutatedUnderlineColor: 'var(--color-primary)',
    borderRadius: 'var(--badge-border-radius)',
    selected: {
      backgroundColor: 'var(--color-primary-light)',
    },
    active: {
      borderColor: 'var(--color-action)',
      spinnerColor: 'var(--color-action)',
    },
    error: {
      backgroundColor: 'var(--color-danger-light)',
      color: 'var(--color-danger)',
      borderColor: 'var(--color-danger)',
    },
    warning: {
      backgroundColor: 'var(--color-warning-light)',
      color: 'var(--color-warning)',
      borderColor: 'var(--color-warning)',
    },
  },
  lookupEditor: {
    option: {
      borderRadius: {
        active: 'var(--badge-border-radius)',
      },
    },
  },
  boolean: {
    toggleError: 'var(--color-danger)',
    toggleWarning: 'var(--color-warning)',
    toggleChecked: 'var(--color-primary)',
  },
  loading: {
    color: 'var(--color-action)',
  },
  column: {
    header: {
      fontFamily: 'var(--table-font-family)',
      backgroundColor: 'var(--color-page-100)',
      borderColor: 'var(--color-border)',
      color: 'var(--color-text-light)',
      dragHandle: {
        idle: 'var(--color-action)',
        dragging: 'var(--color-action-lighter)',
      },
    },
  },
  filters: {
    color: 'var(--color-text)',
    backgroundColor: '#fff',
    active: {
      backgroundColor: 'var(--color-action)',
      secondaryBackgroundColor: 'var(--color-action-lighter)',
    },
    error: {
      color: 'var(--color-danger)',
      secondaryColor: 'var(--color-danger-less-light)',
      activeBackgroundColor: 'var(--color-danger)',
      secondaryBackgroundColor: 'var(--color-danger-less-light)',
    },
    innerBorderRadius: 'var(--pill-border-radius)',
    outerBorderRadius: 'var(--pill-border-radius)',
  },
  buttons: {
    pill: {
      borderRadius: 'var(--pill-border-radius)',
      dropdown: {
        borderColor: 'var(--interactive-border-color)',
      },
    },
  },
  tooltip: {
    borderRadius: 'var(--badge-border-radius)',
  },
}
