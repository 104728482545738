import { Justify, Row } from '@/elements/Row'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  Button,
  Icon,
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  padding: 16px 0px;
  gap: 16px;
  flex-direction: column;
`

const Name = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const ConnectionsModal = ({
  connectWithGoogle,
  connectWithBox,
  showModal,
}: {
  connectWithGoogle: ((showPicker: boolean) => Promise<void>) | undefined
  connectWithBox: ((showPicker: boolean) => Promise<void>) | undefined
  showModal: (show: boolean) => void
}) => {
  const { t } = useTypedTranslation()
  const content = (
    <>
      <ModalHeader>{t('files.connectionsModal.heading')}</ModalHeader>
      <ModalText>{t('files.connectionsModal.subtext')}</ModalText>
      <Content>
        {connectWithGoogle && (
          <Row $justify={Justify.between}>
            <Name>
              <Icon name='googleDrive' size={16}></Icon>
              <> {t('files.connectionsModal.googleDrive')}</>
            </Name>
            <Button
              label={t('files.connectionsModal.manage')}
              onPress={async () => await connectWithGoogle(false)}
              size='sm'
            ></Button>
          </Row>
        )}
        {connectWithBox && (
          <Row $justify={Justify.between}>
            <Name>
              <Icon name='boxLogo' size={16}></Icon>
              <>{t('files.connectionsModal.box')}</>
            </Name>
            <Button
              label={t('files.connectionsModal.manage')}
              onPress={async () => await connectWithBox(false)}
              size='sm'
            ></Button>
          </Row>
        )}
      </Content>
      <Row $justify={Justify.end}>
        <ModalCloseButton
          label={t('files.connectionsModal.close')}
          onClose={() => showModal(false)}
        ></ModalCloseButton>
      </Row>
    </>
  )

  return <Modal data-testid='connections-modal' content={content}></Modal>
}
