import { logger } from '@/utils/logging'
import { EventTopic } from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { usePubNub } from 'pubnub-react'
import { useEffect } from 'react'

export type EventCallback = (event: any, message: Record<string, any>) => void

export const useEventSubscriber = (
  topics: '*' | EventTopic[],
  callback: EventCallback
) => {
  try {
    const pubnub = usePubNub()
    useEffect(() => {
      const cb = (event: any) => {
        try {
          const msg = JSON.parse(fromMaybe(event?.message, '{}'))
          if (topics === '*' || topics.includes(msg.topic)) {
            callback(event, msg)
          }
        } catch (e) {
          logger.warn('PubNub Error: Unable to handle PubNub callback: ' + e)
        }
      }
      const listener = { message: cb }
      pubnub.addListener(listener)
      return () => {
        pubnub.removeListener(listener)
      }
    }, [pubnub, callback, topics])
  } catch (e) {
    logger.warn(
      'PubNub Error: Unable to subscribe to PubNub event stream: ' + e
    )
  }
}
