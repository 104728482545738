import { Filter, SortDirection } from '@flatfile/api'
import { Icon } from '@flatfile/design-system'
import {
  PopoverContext,
  WarningIcon,
  getQueryFields,
  isQueryValid,
} from '@flatfile/shared-ui'
import {
  ColumnConfigProps,
  OnSearchValue,
  OnSort,
  useBulkRowSelection,
} from '@flatfile/turntable'
import { Key, useCallback, useContext, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchFields } from '../types'

import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { ColumnsState, UnhideColumns } from '../ColumnManagement/types'
import { FilterByValueFields, buildValueFilters } from '../utils/filters'
import { getSortParams } from '../utils/tableUtils'

type SearchParam = (string | undefined)[]

export type OnClickFilter = (filter?: Filter) => void
export type OnClickFilterField = (errorFilter?: string) => void
export type OnFilterByValue = (field: string, values: Key[]) => void
export type OnSearchParams = (params: SearchParam[]) => void

export interface UseSearchFiltersProps {
  columnConfig: ColumnConfigProps[]
  columnsState: ColumnsState
  embedded?: boolean
  unhideColumns: UnhideColumns
}

export const DEFAULT_FILTERS = {
  filter: undefined,
  filterField: undefined,
  q: undefined,
  searchField: undefined,
  searchValue: undefined,
  sortDirection: undefined,
  sortField: undefined,
}

export const DEFAULT_SORT_OVERRIDE = {
  sortOrder: undefined,
  sortColumn: undefined,
}

export const DEFAULT_FILTER_BY_VALUE = {}

export const useSearchFilters = ({
  columnConfig,
  columnsState,
  embedded,
  unhideColumns,
}: UseSearchFiltersProps) => {
  const [search, setSearchParams] = useSearchParams()
  const [searchByValueLoading, setSearchByValueLoading] = useState(false)
  const [filteredResultsLoading, setFilteredResultsLoading] = useState(false)
  const [filterByValueFields, setFilterByValueFields] =
    useState<FilterByValueFields>(DEFAULT_FILTER_BY_VALUE)

  const [searchFieldsState, setSearchFieldsState] =
    useState<SearchFields>(DEFAULT_FILTERS)

  const { t } = useTypedTranslation()
  const { exitSelectionState } = useBulkRowSelection()
  const { showPopover } = useContext(PopoverContext)

  const searchFields = useMemo(() => {
    if (embedded) return searchFieldsState
    return {
      sortDirection:
        (search.get('sortDirection') as SortDirection) || undefined,
      sortField: search.get('sortField') || undefined,
      filter: (search.get('filter') as Filter) || undefined,
      filterField: search.get('filterField') || undefined,
      searchValue: search.get('searchValue') || undefined,
      searchField: search.get('searchField') || undefined,
      q: search.get('q') || undefined,
    }
  }, [embedded, search, searchFieldsState])

  //TODO: update & remove when turntable allows for initial values
  const sortOverride = useMemo(() => {
    const columnIndex = columnConfig.findIndex(
      (column) => column.value === searchFields.sortField
    )
    if (columnIndex === -1) {
      return DEFAULT_SORT_OVERRIDE
    }
    return {
      sortOrder: searchFields.sortDirection,
      sortColumn: columnIndex,
    }
  }, [searchFields, columnConfig])

  const getParamsChanged = useCallback(
    (params: SearchParam[]): boolean => {
      for (let i = 0; i < params.length; i++) {
        const type = params[i][0]
        const value = params[i][1] ?? ''
        const currentValue = searchFields[type as keyof SearchFields] ?? ''

        if (type && value !== currentValue) {
          return true
        }
      }
      return false
    },
    [searchFields]
  )

  const handleSearchParams: OnSearchParams = useCallback(
    (searchParams) => {
      /* 
        If the sheet is embedded, we handle filtering in the state, because the
        URL params do not necessarily correspond to one individual sheet.
        
        When looking an individual sheet at the sheet route, we can rely
        on the URL params.
      */
      if (embedded) {
        const updatedSearchFields: SearchFields = { ...searchFieldsState }
        searchParams.map((params: any) => {
          updatedSearchFields[params[0] as keyof SearchFields] =
            params[1] || undefined
        })
        setSearchFieldsState(updatedSearchFields)
      } else {
        let updatedSearchParams = new URLSearchParams(search.toString())
        searchParams.map((params: any) => {
          if (!params[1]) {
            return updatedSearchParams.delete(params[0])
          }
          updatedSearchParams.set(params[0], params[1])
        })
        setSearchParams(updatedSearchParams)
      }
    },
    [embedded, search, searchFieldsState]
  )

  const handleClickFilter: OnClickFilter = useCallback(
    (filter) => {
      exitSelectionState(true)

      const params = [['filter', filter]]

      if (getParamsChanged(params)) {
        handleSearchParams(params)

        if (searchFields.searchValue || searchFields.searchField) {
          setFilteredResultsLoading(true)
        }
      }
    },
    [searchFields]
  )

  const handleClickFilterField: OnClickFilterField = useCallback(
    (errorFilter) => {
      exitSelectionState(true)

      const params = [['filterField', errorFilter]]

      if (getParamsChanged(params)) {
        handleSearchParams(params)
        setFilteredResultsLoading(true)

        if (errorFilter) {
          unhideColumns([errorFilter])
        }
      }
    },
    [searchFields, unhideColumns]
  )

  const handleSort: OnSort = useCallback(
    ({ columnIndex, order }) => {
      const { sortDirection, sortField } = getSortParams({
        columnConfig,
        columnIndex,
        order,
      })
      const params = [
        ['sortDirection', sortDirection],
        ['sortField', sortField],
      ]
      if (getParamsChanged(params)) {
        handleSearchParams(params)
      }
    },
    [searchFields]
  )

  const getInvalidFFQLPopover = useCallback(
    () => ({
      icon: <WarningIcon name='alertTriangle' />,
      message: <>{t('sheet.popovers.errors.invalidFFQLSearch')}</>,
      action: {
        label: t('sheet.popovers.errors.learnMore'),
        onClick: () =>
          window.open(
            'https://flatfile.com/docs/developer-tools/flatfile_query_language',
            '_blank'
          ),
      },
    }),
    []
  )

  const handleFFQLValidity = useCallback(async (q: string) => {
    const isValid = await isQueryValid(q)
    if (isValid) {
      return true
    }

    showPopover(getInvalidFFQLPopover())

    return false
  }, [])

  const handleHiddenFFQLColumns = useCallback(
    async (q: string) => {
      const fields = await getQueryFields(q)
      const hiddenFields = fields.filter(
        (field: string) => columnsState[field]?.hidden
      )

      if (hiddenFields.length) {
        unhideColumns(hiddenFields)
        showPopover({
          icon: <Icon name='columns' />,
          message: (
            <>
              {t('sheet.columnManagement.unhideToast.message', {
                count: hiddenFields.length,
              })}
            </>
          ),
        })
      }
    },
    [columnsState, unhideColumns]
  )

  const handleSearchByValue: OnSearchValue = useCallback(
    async ({ searchValue, searchField, q }, isFilterByValue) => {
      let params = []

      if (q) {
        params = [['q', q], ['searchValue'], ['searchField']]
      } else {
        params = [
          ['searchField', searchField],
          ['searchValue', searchValue],
          ['q'],
        ]
      }

      if (!isFilterByValue) {
        setFilterByValueFields(DEFAULT_FILTER_BY_VALUE)
      }

      if (getParamsChanged(params)) {
        exitSelectionState(true)
        if (q && !isFilterByValue) {
          const isValid = await handleFFQLValidity(q)
          if (!isValid) return
        }

        setSearchByValueLoading(true)
        setFilteredResultsLoading(true)
        handleSearchParams(params)

        if (q) {
          handleHiddenFFQLColumns(q)
        }
      }
    },
    [searchFields, handleHiddenFFQLColumns]
  )

  const handleFilterByValue: OnFilterByValue = useCallback(
    (field, keys) => {
      const newFilterByValueFields = { ...filterByValueFields, [field]: keys }
      const params = buildValueFilters(newFilterByValueFields)

      handleSearchByValue(params, true)

      setFilterByValueFields(newFilterByValueFields)
    },
    [filterByValueFields, searchFields]
  )

  const isFiltered: boolean = useMemo(() => {
    return !!(
      searchFields.filter ||
      searchFields.searchValue ||
      searchFields.searchField ||
      searchFields.filterField ||
      searchFields.q
    )
  }, [searchFields])

  return {
    searchFields,
    sortOverride,
    filterByValueFields,
    filteredResultsLoading,
    searchByValueLoading,
    isFiltered,
    setSearchByValueLoading,
    setFilteredResultsLoading,
    getInvalidFFQLPopover,
    handleSearchParams,
    handleClickFilter,
    handleClickFilterField,
    handleFilterByValue,
    handleSearchByValue,
    handleSort,
  }
}
