import { Row } from '@/elements/Row'
import styled from 'styled-components'

export const FieldCardContainer = styled.div`
  border: 1px solid var(--color-border);
  background-color: var(--color-page);
  //box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 20px;
  border-radius: 5px;
  margin-bottom: 1.5em;
`
export const CardHeader = styled.div`
  background: var(--color-page-100);
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  padding: 1em 1.25em;
`
export const Content = styled.div`
  padding: 1.25em;
`
export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 8px;
  text-transform: uppercase;
  line-height: 8px;
`

export const CountPill = styled.span<{ invalid?: boolean }>`
  background: ${(props) =>
    props.invalid ? 'var(--color-danger-light)' : 'var(--color-skeleton)'};
  border-radius: 'var(pill-border-radius)';
  margin-left: 10px;
  display: inline-block;
  padding: 1px 13px 0 12px;
  font-size: 80%;
  vertical-align: middle;
  text-transform: none;
  color: ${(props) => (props.invalid ? 'var(--color-danger)' : 'inherit')};
`

export const IconWrapper = styled.span`
  font-size: 20px;

  svg {
    vertical-align: middle;
    margin: -6px 3px -3px -7px;
  }
`

export const MappingTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--size-h3);
`

export const MappingSubtitle = styled.div`
  margin-bottom: 18px;
`

export const RuleHeader = styled(Row)`
  margin-bottom: 1.2em;
  font-size: var(--size-small);
  font-weight: 500;
  color: var(--color-text-light);
`

export const Code = styled.code`
  font-size: 85%;
  background-color: aliceblue;
  padding: 0 6px;
`

export const IframeContainer = styled.div`
  width: 100%;
  height: 100%;

  > iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`

export const FullSizeContainer = styled.div`
  height: 100%;
  width: 100%;
`
