import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { Logger } from '@flatfile/shared-ui'

export function initializeDatadogLogs() {
  try {
    datadogLogs.init({
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: 'spaces',
      env: import.meta.env.VITE_FLATFILE_ENV ?? 'test',
      version: import.meta.env.VITE_VERSION_NUMBER,
      forwardErrorsToLogs: true,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error initializing Datadog Logs:', error)
  }
}

export function initializeDatadogRum() {
  try {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APP_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      sessionSampleRate: 100,
      service: 'spaces',
      env: import.meta.env.VITE_FLATFILE_ENV ?? 'test',
      version: import.meta.env.VITE_VERSION_NUMBER,
      allowedTracingUrls: [import.meta.env.VITE_BASE_API_URL],
      defaultPrivacyLevel: 'mask',
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error initializing Datadog RUM:', error)
  }
}

export function initializeLogging() {
  if (import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
    initializeDatadogLogs()
    initializeDatadogRum()
  } else {
    console.error('Datadog client token is missing')
  }
}

const isDev = import.meta.env.DEV

function getLogger() {
  initializeLogging()
  const logger = new Logger(
    isDev,
    datadogLogs.getLogger('spaces') || datadogLogs.createLogger('spaces')
  )
  return logger
}

export const logger = getLogger()
