import {
  GetSnapshotRecordsChangeTypeEnum as ChangeType,
  GetSnapshotRecordsRequest,
  Sheet,
  Snapshot,
  SnapshotSummary,
  Workbook,
} from '@flatfile/api'
import { ColumnConfigProps, OnDataLoad } from '@flatfile/turntable'
import { OnRefetchTable } from '../../types'

export enum DiffAction {
  RESTORE = 'restore',
  ACCEPT = 'accept',
  PREVIEW = 'preview',
  NONE = 'none',
}

export enum DiffDirection {
  SNAPSHOT_TO_SHEET = 'snapshot_to_sheet',
  SHEET_TO_SNAPSHOT = 'sheet_to_snapshot',
}

export type SnapshotViewProps = {
  snapshot: Snapshot
  sheet?: Sheet
  action: DiffAction
  onCancel?: () => void
  refetchTable: OnRefetchTable
  columnConfig: ColumnConfigProps[]
}

export type SnapshotViewComponentProps = SnapshotViewProps & {
  onClose: () => void
  workbook: Workbook
}

export type UseSnapshotDataProps = {
  action: DiffAction
  snapshot: Snapshot
  columnConfig: ColumnConfigProps[]
  direction?: DiffDirection
}

export type DiffTableProps = SnapshotViewComponentProps & {
  summary?: SnapshotSummary
  description?: string
  onCancel?: () => Promise<void>
  onSubmit: () => Promise<void>
  isErrorSummary?: boolean
  isLoadingSummary?: boolean
  showFilters: boolean
  diffDirection?: DiffDirection
  embedded?: boolean
  searchFields?: any
  selection?: any
  prompt?: string
  setPrompt?: (prompt: string) => void
  isPreview?: boolean
  filterByChanged?: boolean
  setFilterByChanged?: (filter: boolean) => void
  changedColumns?: any[]
  onUpdateRecords?: (records: any[]) => void
  usedRecords?: any[]
  allRecords?: any[]
  counts?: any
  hiddenColumnKeys?: string[]
}

export type DiffViewProps = DiffTableProps & {
  onLoadRows: OnDataLoad
}

export type DiffTableFilterProps = {
  activeTab: ChangeType
  setActiveTab: (tab: ChangeType) => void
  action: DiffAction
  counts?: SnapshotSummary
  isLoadingCounts?: boolean
  diffDirection?: DiffDirection
}

export type DiffTableEmptyProps = {
  isLoading?: boolean
  isErrored?: boolean
  action: DiffAction
  changeType: ChangeType
}

export type DiffTableTitleProps = {
  action: DiffAction
  snapshot: Snapshot
  sheet?: Sheet
  workbook: Workbook
}

export type OnToggleSnapshotView = (props?: SnapshotViewProps) => void
export type OnTriggerSnapshotView = (props?: {
  action: DiffAction
  snapshot: Snapshot
  onCancel?: () => void
}) => void

export type GetSnapshotRecordsParams = Omit<
  GetSnapshotRecordsRequest,
  'snapshotId'
>
