import { usePaymentAccess } from '@/hooks/usePaymentAccess'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { FileOrigin, Space } from '@flatfile/api'
import {
  AMP_TOOLTIP_ID,
  Button,
  ButtonSize,
  ButtonType,
} from '@flatfile/design-system'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useFileUpload } from '../hooks/useFileUpload'

const FileInput = styled.input`
  display: none;
`

export const FileUploadButton: React.FC<{
  space: Space
  hideIcon?: boolean
  variant?: ButtonType
  size?: ButtonSize
  label?: string
  isDisabled?: boolean
  onFileUpload?: () => void
  multiple?: boolean
}> = ({
  space,
  size = 'sm',
  variant,
  hideIcon,
  label,
  isDisabled,
  onFileUpload,
  multiple = false,
}) => {
  const { fileUploadLocked } = usePaymentAccess()

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = React.useState(false)
  const onClickUpload = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [fileInputRef])

  const { handleFileUpload } = useFileUpload(space)
  const { handleFileUpload: handleSilentFileUpload } = useFileUpload(
    space,
    true
  )
  const { t } = useTypedTranslation()

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setUploading(true)
      if (multiple) {
        const files = event.target?.files
        if (files) {
          await Promise.all(
            [...files].map((file, index) =>
              index === 0
                ? handleFileUpload(file, FileOrigin.Filesystem)
                : handleSilentFileUpload(file, FileOrigin.Filesystem)
            )
          )
        }
      } else {
        const file = event.target?.files?.[0]
        if (file) {
          await handleFileUpload(file)
        }
      }
      onFileUpload && onFileUpload()
      event.target.value = ''
      setUploading(false)
    },
    [handleFileUpload, onFileUpload]
  )

  return (
    <>
      <div
        data-tooltip-content={
          fileUploadLocked ? t('files.errors.uploadDisabledHeading') : undefined
        }
        data-tooltip-id={AMP_TOOLTIP_ID}
      >
        <Button
          variant={variant ?? 'tertiary-border'}
          iconLeft={hideIcon ? undefined : 'documentAdd'}
          onPress={onClickUpload}
          label={
            uploading
              ? t('files.buttons.addFile.inProgressText')
              : label ?? t('files.buttons.addFile.text')
          }
          data-testid='add-files-button'
          loading={uploading}
          size={size}
          isDisabled={isDisabled || fileUploadLocked}
        />
      </div>
      <FileInput
        ref={fileInputRef}
        type='file'
        onChange={onChange}
        data-testid='add-files-input'
        multiple={multiple}
      />
    </>
  )
}
