import { Action, JobTypeEnum, ModelFile, Workbook } from '@flatfile/api'
import {
  AMP_TOOLTIP_ID,
  Button,
  List,
  MenuCell,
  Spinner,
  combineClassnames,
} from '@flatfile/design-system'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { DownloadFileProps, Files } from '@/api/controllers/FileController'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { getMenuOptionFromAction } from '@/components/Jobs/utils'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { ActiveJobs, getCurrentlyRunningJobs } from '@/contexts/JobsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { ActionLink } from '@/elements/ActionLink'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { formatBytes } from '@/utils/formatBytes'
import { formatTime } from '@/utils/formatTime'
import { includesNonFileWorkbooks } from '@/utils/workbooks'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { BulkRowActionItem } from '../../WorkbookApp/hooks/useBulkRowActions'
import { JobInput } from '../../WorkbookApp/hooks/useCustomActions'
import { FileInfo, FileJobs, FileMode } from '../types'

const FileNameData = styled.div`
  word-break: break-all;
  display: flex;
  align-items: center;
  gap: 8px;
`

const FileStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
`

export const getFileUpload = (
  file: ModelFile,
  activeJobs: FileJobs | undefined
) => {
  return activeJobs?.find(
    (j) => j.source === file.id && j.type === JobTypeEnum.File
  )
}

export const getFileStatus = (
  file: ModelFile,
  activeJobs: FileJobs | undefined
) => {
  const fileUpload = getFileUpload(file, activeJobs)
  const fileInfo = fileUpload?.info ? i18next.t(fileUpload?.info) : ''

  if (fileUpload?.progress !== 100 && fileUpload?.status === 'executing') {
    const progress = fileUpload?.progress ? fileUpload?.progress + '% ' : ''
    return (
      <>
        {progress + fileInfo}
        <Spinner size={16} />
      </>
    )
  }

  return fileInfo
}

export const getFileNameField = (
  fileReady: boolean,
  file: ModelFile,
  fileFailed: boolean
) => {
  if (fileReady && file.workbookId && !fileFailed) {
    return (
      <ActionLink to={`/space/${file.spaceId}/workbook/${file.workbookId}`}>
        {file.name}
      </ActionLink>
    )
  } else {
    return file.name
  }
}
export const getSheetLink = (file: ModelFile, workbooks?: Workbook[]) => {
  const sheet = workbooks
    ?.flatMap((wb) => wb.sheets)
    .find((sheet) => sheet?.id === file.sheetId)

  if (file.sheetId && sheet?.workbookId) {
    return (
      <ActionLink
        to={`/space/${file.spaceId}/workbook/${sheet.workbookId}/sheet/${file.sheetId}`}
      >
        {sheet.name}
      </ActionLink>
    )
  }

  return <></>
}

export const getTooltipMessage = (
  isUpload: boolean,
  file: ModelFile,
  fileReady: boolean,
  hasNonFileWorkbooks: boolean | undefined
) => {
  if (!isUpload || hasNonFileWorkbooks === undefined) {
    return
  }
  if (!file.name.includes('.')) {
    return 'files.uploadsTables.tooltips.noExtension'
  }
  if (!(fileReady && file.workbookId && hasNonFileWorkbooks)) {
    return 'files.uploadsTables.tooltips.cannotImport'
  }
}

export const FilesTable = ({
  fileMode,
  filesResponse,
  activeJobs,
  setFileIdsToDelete,
  onClickDownload,
  onClickCustomAction,
  selectedFileIds,
  setSelectedFileIds,
  setCurrentFileAction,
}: {
  fileMode: FileMode
  filesResponse: Files
  activeJobs: ActiveJobs
  selectedFileIds: string[]
  setSelectedFileIds: React.Dispatch<React.SetStateAction<string[]>>
  setFileIdsToDelete: React.Dispatch<React.SetStateAction<string[]>>
  onClickDownload: (props?: DownloadFileProps[] | undefined) => void
  onClickCustomAction: (
    operation: string,
    fileId: string
  ) => (input?: JobInput) => void
  setCurrentFileAction: Dispatch<SetStateAction<BulkRowActionItem | null>>
}) => {
  const { t } = useTypedTranslation()
  const { space } = useContext(SpaceContext)
  const { t: customT } = useTranslation()
  const navigate = useNavigate()
  const isUpload = fileMode === 'import'
  const {
    data: workbooks,
    isLoading,
    isError,
  } = useQueryGetAllWorkbooks({ spaceId: space.id })

  const hasNonFileWorkbooks = useMemo(
    () => includesNonFileWorkbooks(workbooks?.data),
    [workbooks]
  )

  const isFileReady = useCallback(
    (file: ModelFile) => {
      if (file.size === null) {
        return false
      }
      return !getCurrentlyRunningJobs(activeJobs)?.find(
        (job) => job.source === file.id
      )
    },
    [activeJobs]
  )

  const isFileSelected = useCallback(
    (fileId: string) => selectedFileIds.includes(fileId),
    [selectedFileIds]
  )

  const goToImport = useCallback(
    (fileId: string) => navigate(`./${fileId}/import`),
    [filesResponse]
  )

  const getFileAction = (
    file: ModelFile,
    fileReady: boolean,
    fileStatusFailed: boolean
  ) => {
    const showImportBtn =
      fileReady && file.workbookId && hasNonFileWorkbooks && !fileStatusFailed
    if (!isUpload) {
      return fileReady
        ? formatBytes(file.size)
        : t('files.downloadsTable.processing')
    }
    if (showImportBtn) {
      return (
        <Button
          variant='tertiary-border'
          onPress={() => goToImport(file.id)}
          size='sm'
          label={t('files.uploadsTables.importButton')}
        />
      )
    }
    return null
  }

  const getFileMenu = (
    fileParams: FileInfo,
    fileReady: boolean,
    file: ModelFile
  ) => {
    if (!fileReady) {
      return null
    }
    const onClickAction = (operation: string) =>
      onClickCustomAction(operation!, fileParams.fileId)
    return (
      <MenuCell
        options={[
          {
            label: t('files.contextMenu.delete'),
            key: 'delete',
            onAction: () => setFileIdsToDelete([fileParams.fileId]),
          },
          {
            label: t('files.contextMenu.download'),
            key: 'download',
            onAction: () => onClickDownload([fileParams]),
          },
        ].concat(
          (file.actions || []).map((action: Action) =>
            getMenuOptionFromAction(
              action,
              onClickAction,
              setCurrentFileAction,
              customT,
              t
            )
          )
        )}
      />
    )
  }

  const headers = useMemo(
    () => [
      {
        key: 'name',
        content: isUpload
          ? t('files.uploadsTables.headings.name')
          : t('files.downloadsTable.headings.name'),
        width: '30%',
      },
      {
        key: 'time',
        content: isUpload
          ? t('files.uploadsTables.headings.uploadedDate')
          : t('files.downloadsTable.headings.createdDate'),
      },
      {
        key: 'info',
        content: isUpload
          ? t('files.uploadsTables.headings.status')
          : t('files.downloadsTable.headings.sourceSheet'),
        width: '25%',
      },
      {
        key: 'action',
        content: isUpload ? '' : t('files.downloadsTable.headings.size'),
      },
      { key: 'menu', content: '', width: '32px' },
    ],
    [isUpload]
  )

  const rows = filesResponse.files.map((file, i) => {
    const fileReady = isFileReady(file)
    const fileStatusFailed = file.status === 'failed' // isFileReady is used in too many places to throw this file status check inside of it
    const fileSelected = isFileSelected(file.id)
    const fileParams = { fileId: file.id, fileName: file.name }
    const data = {
      name: (
        <FileNameData>
          {getFileNameField(fileReady, file, fileStatusFailed)}
        </FileNameData>
      ),
      time: formatTime(file.createdAt!),
      info: isUpload ? (
        <FileStatus>{getFileStatus(file, activeJobs)}</FileStatus>
      ) : (
        getSheetLink(file, workbooks?.data)
      ),
      action: getFileAction(file, fileReady, fileStatusFailed),
      menu: getFileMenu(fileParams, fileReady, file),
    }
    const tooltip = getTooltipMessage(
      isUpload,
      file,
      fileReady,
      hasNonFileWorkbooks
    )

    return {
      data,
      hoverable: true,
      testId: 'file-row',
      key: file.id,
      className: combineClassnames({
        disabled: !fileReady,
        selected: fileSelected,
      }),
      'data-tooltip-id': AMP_TOOLTIP_ID,
      'data-tooltip-float': true,
      'data-tooltip-content': tooltip && t(tooltip),
    }
  })

  return (
    <List
      headers={headers}
      data={rows}
      loading={isLoading}
      error={isError}
      components={{
        loading: <LoadingSpinner />,
        error: 'Error fetching files',
      }}
      selectOptions={{
        selectedItems: selectedFileIds,
        setSelectedItems: setSelectedFileIds,
        allItems: rows.map((row) => row.key),
      }}
    />
  )
}
