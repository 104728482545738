import { Guest, User } from '@flatfile/api'
import { createContext, useMemo } from 'react'

interface UserContext {
  actor?: User | Guest
}

interface UserContextProviderProps {
  actor?: User | Guest
  children: React.ReactNode
}

export const UserContext = createContext<UserContext>({} as UserContext)

/**
 *************
 * IMPORTANT
 *************
 * The Portal flow currently does not reliably set User ID -
 * Therefore, this context should be used sparingly until the issue is resolved
 *
 * This warning can be removed as soon as the following issue is merged or closed
 * https://github.com/orgs/FlatFilers/projects/27/views/1?pane=issue&itemId=61606768
 */
export const UserContextProvider = (props: UserContextProviderProps) => {
  const { actor } = props

  const value = useMemo(() => {
    return {
      actor: actor,
    }
  }, [actor])

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}
