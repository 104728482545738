import i18next, { TOptions } from 'i18next'
import { TranslationsKeys } from '../i18n'

export function typedT(
  key: TranslationsKeys,
  options?: TOptions | string
): string {
  if (typeof options === 'string') {
    return i18next.t(key, options)
  } else if (options) {
    return i18next.t(key, options as TOptions)
  }
  return i18next.t(key)
}
