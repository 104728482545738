import { BulkRowActionItem } from '@/apps/WorkbookApp/hooks/useBulkRowActions'
import { JobInput } from '@/apps/WorkbookApp/hooks/useCustomActions'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from 'react'

export interface ActionsModalContextType {
  currentAction: BulkRowActionItem | null
  setCurrentAction: Dispatch<SetStateAction<BulkRowActionItem | null>>
  resetCurrentAction: () => void
  submitCurrentAction: (input: JobInput) => Promise<void>
}

export const ActionsModalContext = createContext<ActionsModalContextType>({
  currentAction: null,
  setCurrentAction: () => {},
  resetCurrentAction: () => {},
  submitCurrentAction: async () => {},
})

export const ActionsModalContextProvider = (props: {
  children: JSX.Element
}) => {
  const {
    currentAction,
    setCurrentAction,
    resetCurrentAction,
    submitCurrentAction,
  } = useActionsModalContext()
  return (
    <ActionsModalContext.Provider
      value={{
        currentAction,
        setCurrentAction,
        resetCurrentAction,
        submitCurrentAction,
      }}
    >
      {props.children}
    </ActionsModalContext.Provider>
  )
}

export const useActionsModalContext = () => {
  const [currentAction, setCurrentAction] = useState<BulkRowActionItem | null>(
    null
  )

  const resetCurrentAction = useCallback(
    () => setCurrentAction(null),
    [setCurrentAction]
  )
  const submitCurrentAction = useCallback(
    async (input: JobInput) => {
      setCurrentAction(null)
      if (currentAction) {
        await currentAction.action(input)
      }
    },
    [currentAction, setCurrentAction]
  )

  return {
    currentAction,
    setCurrentAction,
    resetCurrentAction,
    submitCurrentAction,
  }
}
