import styled from 'styled-components'

export const StatusText = styled.div`
  font-size: var(--size-h3);
  margin-bottom: var(--size-h3);
  font-weight: 500;

  a {
    border-bottom: 1px solid;
    text-decoration: none;
    height: 22px;
    line-height: 20px;
    vertical-align: 1px;
    position: relative;
    top: 1px;
  }
`
