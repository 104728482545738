import { Spinner } from '@flatfile/design-system'

import { ErrorState } from '@/components/EmptyState'
import { SpinnerContainer } from '@/elements/SpinnerContainer'
import { noActivityIcon } from '@/assets/icons/no-activity'
import { DiffTableEmptyProps } from './types'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

export const DiffTableEmptyStates = ({
  isLoading,
  isErrored,
  action,
  changeType,
}: DiffTableEmptyProps) => {
  const { t } = useTypedTranslation()

  if (isLoading) {
    return (
      <SpinnerContainer data-testid='diff-table-loading'>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (isErrored) {
    return <ErrorState dataTestId='diff-table-error' />
  }

  return (
    <ErrorState
      icon={noActivityIcon}
      dataTestId='diff-table-empty'
      title={t(`snapshot.${action}.empty.title`)}
      message={t(`snapshot.${action}.empty.${changeType}`)}
      refresh={false}
    />
  )
}
