import styled from 'styled-components'

const sharedFontStyles = `
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
`

const sharedMarginRight = `
  margin-right: 4px;
`

export const TooltipCopyButton = styled.button`
  ${sharedFontStyles}
  color: var(--color-white);
  text-align: left;
  background: transparent;
  border: none;
  margin-top: 8px;
  ${sharedMarginRight}
  border-radius: 2px;
  cursor: pointer;
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  gap: 8px;
  margin: 4px;
  line-height: 20px;
`

export const KeyPillInner = styled.div`
  ${sharedFontStyles}
  color: white;
  background: var(--color-pigeon-800);
  padding: 0 4px;
  height: max-content;
  border-radius: 2px;
`

export const KeyPill = styled.div`
  display: flex;
  justify-content: flex-start;
  ${sharedMarginRight}
`

export const Value = styled.div`
  display: flex;
`
