import { Sheet, Snapshot, Workbook } from '@flatfile/api'
import { SnapshotView } from '../../apps/WorkbookApp/VersionHistory/SnapshotView/SnapshotView'
import { DiffAction } from '../../apps/WorkbookApp/VersionHistory/SnapshotView/types'
import { useFieldData } from '../../apps/WorkbookApp/hooks/useFieldData'

export type SnapshotViewOutcomeProps = {
  snapshot: Snapshot
  sheet: Sheet
  workbook: Workbook
  action: DiffAction
  onCancel?: (() => void) | undefined
}

export const SnapshotViewWrapper = (
  props: SnapshotViewOutcomeProps & { onClose: () => void }
) => {
  const sheet = props.sheet
  const workbook = props.workbook

  const { columnConfig } = useFieldData({
    workbook,
    sheet,
  })

  const refetchTable = () => Promise.resolve()

  return (
    <SnapshotView
      {...props}
      columnConfig={columnConfig}
      refetchTable={refetchTable}
    />
  )
}
