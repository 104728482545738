import { Sheet } from '@flatfile/api'
import { createContext } from 'react'

interface SheetContextType {
  embedded?: boolean
  sheet?: Sheet
  sheetId: string
}

interface SheetContextProviderProps extends SheetContextType {
  children: React.ReactNode
}

export const SheetContext = createContext<SheetContextType>(
  {} as SheetContextType
)

export const SheetContextProvider = (props: SheetContextProviderProps) => {
  const { children, embedded, sheet, sheetId } = props
  const sheetIdValue = (sheetId || sheet?.id)!
  return (
    <SheetContext.Provider value={{ embedded, sheet, sheetId: sheetIdValue }}>
      {children}
    </SheetContext.Provider>
  )
}
