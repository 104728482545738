import { HTTPService } from '@/components/HttpClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getPromptsKey } from '../queries/prompts.queries'

const createPromptKey = 'createPrompt'

/**
 * Wrapper around the mutation needed to add an AI Assist prompt to a user's account.
 * Upon adding a new prompt, any queries for prompts should automatically be
 * invalidated
 *
 * @returns react query mutation object
 */
export const useCreatePromptMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [createPromptKey],
    async (createPromptRequest: {
      prompt: string
      environmentId: string
      spaceId: string
    }) => {
      return await HTTPService.createPrompt({
        promptConfig: { ...createPromptRequest },
      })
    },
    { onSuccess: () => queryClient.invalidateQueries([getPromptsKey]) }
  )
}
