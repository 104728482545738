import { getFont } from '@flatfile/design-system'
import { Command } from 'cmdk'
import styled from 'styled-components'

// CMDK Components
export const CommandMenuRoot = styled(Command.Dialog)`
  max-width: 640px;
  width: 100%;
  background: var(--color-page);
  border-radius: 2px;
  padding: 0px;
  box-shadow: 0px 0px 0px 1px var(--color-page-200),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
`

export const CommandMenuList = styled(Command.List)`
  padding: 8px 16px 16px;
  max-height: 50vh;
  overflow: auto;
`

export const CommandMenuInput = styled(Command.Input)`
  ${getFont('b1')};
  font-family: var(--text-font);
  border: none;
  width: 100%;
  padding: 6px 0px;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: var(--color-text-ultralight);
  }

  &::disabled {
    color: var(--color-text-disabled);
  }
`

export const CommandItem = styled(Command.Item)`
  content-visibility: auto;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  user-select: none;
  will-change: background, color;

  &[aria-selected='true'] {
    background: var(--color-hover);
  }

  &[aria-disabled='true'] {
    color: var(--color-text-disabled);
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--color-page-200);
  }
`

export const CommandMenuItemMessage = styled.span`
  flex-grow: 1;
  text-align: right;
`

// Custom Components
export const CommandMenuInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid var(--color-page-200);
  gap: 8px;
  height: 48px;
`

export const CommandMenuLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
`

export const Title = styled.div`
  ${getFont('actionMd')}
  font-family: var(--text-font);
  color: var(--color-title);
  display: flex;
  align-items: center;
`

export const Explain = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  color: var(--color-text-ultralight);
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 6px;
  a {
    font-family: var(--text-font);
  }
`

export const Warning = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  padding: 20px 16px;
  color: var(--color-danger);
`

export const ReturnIcon = styled.span`
  font-family: serif;
  font-size: 12px;
  -webkit-font-smoothing: auto;
`
