import useScrollToLink from '@/hooks/useScrollToLink'
import { useSheetAccess } from '@/hooks/useSheetAccess'
import { Sheet, Workbook } from '@flatfile/api'
import { NavLink } from 'react-router-dom'
import { ElementOrSpinner } from '../../components/ElementOrSpinner'
import { ReadOnlyIcon } from '../../components/ReadOnly'
import { TabStrip } from '../../components/TabStrip'
import {
  SheetTabIconContainer,
  SheetTabName,
  SheetTabsContainer,
} from './elements'

type SheetTabItemProps = {
  sheet: Sheet
  path: string
}

const SheetTabItem = ({ sheet, path }: SheetTabItemProps) => {
  const name = sheet.name
  const to = `${path}/${sheet.id}`
  const linkRef = useScrollToLink(to, 'vertical')
  const { readOnlySheet } = useSheetAccess(sheet)

  return (
    <NavLink key={sheet.id} ref={linkRef} to={to} title={name}>
      <SheetTabName>{name}</SheetTabName>
      {readOnlySheet && (
        <SheetTabIconContainer>
          <ReadOnlyIcon />
        </SheetTabIconContainer>
      )}
      <ElementOrSpinner color='var(--color-primary)' id={sheet.id} />
    </NavLink>
  )
}

export const SheetTabs = ({
  workbook,
  path,
}: {
  workbook: Workbook
  path: string
}) => {
  return (
    <SheetTabsContainer>
      <TabStrip>
        {workbook.sheets?.map((sheet) => (
          <SheetTabItem key={sheet.id} sheet={sheet} path={path} />
        ))}
      </TabStrip>
    </SheetTabsContainer>
  )
}
