import { getJobTooltip } from '@/components/Jobs/JobTooltipWrapper'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Workbook } from '@flatfile/api'
import { Button } from '@flatfile/design-system'
import i18next from 'i18next'
import { useMemo, useState } from 'react'
import { useIsWorkbookLocked } from '../../hooks/useIsWorkbookLocked'
import { PrimaryActionButtons } from './elements'
import { BulkRowActionItem } from './hooks/useBulkRowActions'
import { BulkRowActionTooltipText } from './utils/bulkRowUtils'

export const WorkbookActions = ({
  actions,
  workbook,
  workbookHasCommits,
  tableIsEditing,
}: {
  workbook: Workbook
  actions?: BulkRowActionItem[]
  workbookHasCommits: boolean
  tableIsEditing: boolean
}) => {
  if (!actions?.length) {
    return null
  }

  const locked = useIsWorkbookLocked(workbook)
  const hasUncompletedCommits = workbookHasCommits
  const { t } = useTypedTranslation()

  const lockedReason = getJobTooltip(
    locked,
    t('sheet.workbookActions.disabledTooltip.lockedUntilJobCompletes'),
    hasUncompletedCommits,
    t('sheet.workbookActions.disabledTooltip.lockedUntilHookCompletes'),
    tableIsEditing,
    t('sheet.workbookActions.disabledTooltip.lockedUntilEditingCompletes')
  )

  return (
    <PrimaryActions
      actions={actions}
      locked={locked || hasUncompletedCommits || tableIsEditing}
      lockedReason={lockedReason}
    />
  )
}

export const ActionButton = ({
  action,
  locked,
  tooltip,
  lockedTooltip,
}: {
  action: BulkRowActionItem
  locked?: boolean
  tooltip?: BulkRowActionTooltipText
  lockedTooltip?: BulkRowActionTooltipText
}) => {
  const [loading, setLoading] = useState(false)

  const handleButtonPress = async () => {
    //show a spinner if there is no confirm modal
    if (!action.confirm) {
      setLoading(true)
    }
    await action.preAction?.()
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setLoading(false)
  }
  return (
    <Button
      isDisabled={locked || action.disabled}
      variant={action.primary ? 'primary' : 'secondary'}
      data-testid={
        action.primary ? 'action-button-primary' : 'action-button-secondary'
      }
      onPress={handleButtonPress}
      label={i18next.t(action.label)}
      tooltip={locked ? lockedTooltip : tooltip}
      loading={loading}
    />
  )
}

export const PrimaryActions = ({
  actions,
  locked,
  lockedReason,
}: {
  actions?: BulkRowActionItem[]
  locked?: boolean
  lockedReason?: string
}) => {
  if (!actions?.length) {
    return null
  }
  const sortedActions = useMemo(() => actions.reverse(), [actions])
  return (
    <PrimaryActionButtons>
      {sortedActions.map((action, i) => {
        const tooltipTranslated = action.tooltip
          ? (Object.fromEntries(
              Object.entries(action.tooltip).map(([key, value]) => [
                key,
                value ? i18next.t(value) : value,
              ])
            ) as BulkRowActionTooltipText)
          : undefined

        const lockedTooltip = { active: lockedReason, disabled: lockedReason }

        return (
          <ActionButton
            key={i}
            action={action}
            locked={locked}
            tooltip={tooltipTranslated}
            lockedTooltip={lockedTooltip}
          />
        )
      })}
    </PrimaryActionButtons>
  )
}
