import { EventTopic } from '@flatfile/api'
import { QueryClient } from '@tanstack/react-query'
import { FlatfileRequestType } from './types'

export const sheetsQueries = {
  getCellValues: {
    generateQueryKey: (params: FlatfileRequestType<'getCellValues'>) => [
      'getCellValues',
      params,
    ],
    influencingEvents: {
      [EventTopic.Commitcreated]: (event: any, client: QueryClient) => {
        const sheetId = event.origin.id
        client.invalidateQueries(['getCellValues', { sheetId }])
      },
      [EventTopic.Layercreated]: (event: any, client: QueryClient) => {
        const sheetId = event.origin.id
        client.invalidateQueries(['getCellValues', { sheetId }])
      },
      [EventTopic.Sheetupdated]: (event: any, client: QueryClient) => {
        const sheetId = event.origin.id
        client.invalidateQueries(['getCellValues', { sheetId }])
      },
      [EventTopic.Recordsdeleted]: (event: any, client: QueryClient) => {
        const sheetId = event.origin.id
        client.invalidateQueries(['getCellValues', { sheetId }])
      },
    },
  },
  getCounts: {
    generateQueryKey: (params: FlatfileRequestType<'getCounts'>) => {
      const { sheetId, ...otherParams } = params
      return ['getCounts', sheetId, otherParams]
    },
    /**
     * Due to the cost of the getCounts call, we cannot simply fetch counts as new events are received
     * and instead need to take special precautions using strategies like debouncing, or hydration from events
     * themselves. Due to the non-uniform approach, we have moved the influencing event considerations for
     * getCounts into the SheetCountsContext, and WorkbookCountsContext and do not anticipate that new influencing
     * events for this request to be added in the future
     */
    influencingEvents: {},
  },
  getSheet: {
    generateQueryKey: (params: FlatfileRequestType<'getSheet'>) => [
      'getSheet',
      params,
    ],
    influencingEvents: {
      [EventTopic.Sheetupdated]: (event: any, client: QueryClient) => {
        const sheetId = event.origin.id
        client.invalidateQueries(['getSheet', { sheetId }])
      },
      [EventTopic.Sheetdeleted]: (event: any, client: QueryClient) => {
        const sheetId = event.context.sheetId
        client.invalidateQueries(['getSheet', { sheetId }])
      },
    },
  },
}
