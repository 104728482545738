import { TranslationsKeys } from '@/i18n'

export enum PanelState {
  ReadyToAsk,
  Asking,
  Previewing,
  Applying,
  DoneApplying,
}

export const messages: TranslationsKeys[] = [
  'commandMenu.aiAssist.transform.askingAI.stepOne',
  'commandMenu.aiAssist.transform.askingAI.stepTwo',
  'commandMenu.aiAssist.transform.askingAI.stepThree',
  'commandMenu.aiAssist.transform.askingAI.stepFour',
]
