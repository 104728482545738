import { Snapshot } from '@flatfile/api'
import { Spinner } from '@flatfile/design-system'
import { Panel, PanelContent, PanelSectionHeader } from '@flatfile/shared-ui'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { VersionAction } from '@/api/controllers/VersionsController'
import { useRouteParams } from '@/hooks/useRouteParams'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import useVersions from '../hooks/useVersions'
import { DiffAction } from './SnapshotView/types'
import VersionActionsModal from './VersionActionsModal'
import VersionItem from './VersionItem'
import { VersionsPanelProps } from './types'

const VersionsPanel = ({ onClose, toggleSnapshotView }: VersionsPanelProps) => {
  const { sheetId = '' } = useRouteParams()
  const { t, i18n } = useTypedTranslation()
  const { generateFetchVersions } = useVersions()
  const [promptVersionAction, setPromptVersionAction] = useState<
    VersionAction | undefined
  >(undefined)
  const [promptVersion, setPromptVersion] = useState<Snapshot | undefined>(
    undefined
  )
  const [onFetchVersions, fetchVersionObservable] = generateFetchVersions()
  const versionList = fetchVersionObservable?.data?.versions
  const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
    month: 'long',
    year: 'numeric',
  })

  /**
   * useEffect to fetch all versions for a given sheet on mount
   */
  useEffect(() => {
    onFetchVersions({ sheetId: sheetId })
  }, [])

  /**
   * Handles selection of a action for a specific version from a sheet's list of available
   * snapshots
   * @param version Snapshot version which has been selected
   * @param action Type which defines the selected action (i.e. Restore)
   */
  const handleVersionActionSelected = (
    version: Snapshot,
    action: VersionAction
  ) => {
    if (action === VersionAction.RESTORE) {
      toggleSnapshotView({
        snapshot: version,
        action: DiffAction.RESTORE,
        onCancel: onClose,
      })
    } else {
      setPromptVersion(version)
      setPromptVersionAction(action)
    }
  }

  /**
   * Handles close of the modal which resets current selections and actions
   */
  const handleCloseActionModal = () => {
    setPromptVersionAction(undefined)
    setPromptVersion(undefined)
  }

  /**
   * Render utility responsible for parsing the versions list into a list of
   * items grouped into sections based on Month and Year
   * @returns
   */
  const renderAvailableVersions = (versions: Snapshot[]) => {
    const todayFormatted = dateFormatter.format(Date.now())
    const versionContent = []

    let sectionDate = undefined

    for (let v = 0; v < versions.length; v++) {
      const version = versions[v]
      let versionDateFormatted = dateFormatter.format(version.createdAt)
      versionDateFormatted =
        versionDateFormatted === todayFormatted
          ? t('versionHistory.panel.thisMonth')
          : versionDateFormatted

      if (sectionDate !== versionDateFormatted) {
        sectionDate = versionDateFormatted
        versionContent.push(
          <VersionSection key={sectionDate} sectionDate={sectionDate} />
        )
      }

      versionContent.push(
        <VersionItem
          key={version.id}
          version={version}
          onActionSelected={handleVersionActionSelected}
        />
      )
    }

    return versionContent
  }

  return (
    <Panel heading={t('versionHistory.panel.heading')} onClose={onClose}>
      <PanelContent>
        {!versionList ? (
          <VersionsLoading data-testid='versions-loading-state'>
            <Spinner />
          </VersionsLoading>
        ) : !versionList.length ? (
          <div data-testid='versions-empty-state'>
            {t('versionHistory.panel.empty')}
          </div>
        ) : (
          renderAvailableVersions(versionList)
        )}
      </PanelContent>
      <VersionActionsModal
        isOpen={promptVersionAction !== undefined}
        action={promptVersionAction}
        promptVersion={promptVersion}
        onClosePanel={onClose}
        onCloseModal={handleCloseActionModal}
      />
    </Panel>
  )
}

/**
 * Small utility component which renders the date section of the version list
 * @param Props
 * @returns
 */
const VersionSection = function ({ sectionDate }: { sectionDate: string }) {
  return (
    <PanelSectionHeader
      data-testid='version-panel-section'
      $color='var(--color-text-ultralight)'
      $borderColor='var(--color-border-light)'
    >
      {sectionDate}
    </PanelSectionHeader>
  )
}

const VersionsLoading = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
`

export default VersionsPanel
