import { useMutation } from '@tanstack/react-query'
import { HTTPService } from '../components/HttpClient'

export const useRetryJob = () => {
  const { mutate: retryJob } = useMutation<unknown, unknown, string>(
    ['retryJob'],
    async (id: string) => {
      await HTTPService.retryJob({
        jobId: id,
      })
    }
  )
  return { retryJob }
}
