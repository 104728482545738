import { useEffect, useState } from 'react'
import { isImagePathValid } from '@/apps/SpaceApp/Theme/ThemeUtils'
import { SidebarLogoContainer } from '../elements'

export const SidebarLogo = ({ logo }: { logo?: string }) => {
  const [logoSrc, setLogoSrc] = useState<string | undefined>(undefined)

  useEffect(() => {
    const setLogo = async () => {
      const isValid = await isImagePathValid(logo)
      if (isValid) {
        setLogoSrc(logo)
      }
    }

    setLogo()
  }, [logo])

  if (!logoSrc) return null

  return (
    <SidebarLogoContainer data-testid='custom-logo-container'>
      <img src={logoSrc} data-testid='custom-logo' />
    </SidebarLogoContainer>
  )
}
