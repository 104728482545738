import styled from 'styled-components'

export const PrimaryHeading = styled.h1`
  align-items: center;
  color: var(--color-title);
  font-family: var(--text-font);
  font-size: var(--size-h1);
  margin: 0;
  line-height: 1;
  display: flex;
  justify-content: center;
  gap: 8px;
`
