import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { PAGE_SIZE } from '@/apps/WorkbookApp/hooks/useSheetViewData'
import { formatTurntableRows } from '@/apps/WorkbookApp/utils/tableUtils'
import { SpaceContext } from '@/contexts/SpaceContext'
import { GetSnapshotRecordsRequest } from '@flatfile/api'
import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import {
  DiffDirection,
  GetSnapshotRecordsParams,
  UseSnapshotDataProps,
} from '../types'

const restoreSnapshotQueryKey = 'restoreVersion'
const getSnapshotRecordsQueryKey = 'getSnapshotRecords'

const useSnapshotData = ({
  action,
  snapshot,
  columnConfig,
  direction = DiffDirection.SHEET_TO_SNAPSHOT,
}: UseSnapshotDataProps) => {
  const { httpClient } = useContext(SpaceContext)

  /**
   * Loads snapshot with summary since the summary isn't provided by default.
   * The summary is how we get the counts for the table.
   */
  const { data, isError, isRefetching, isLoading } = useFlatfileQuery(
    'getSnapshot',
    { snapshotId: snapshot.id, includeSummary: true },
    { retry: false }
  )
  const { mutateAsync: restoreSnapshot } = useMutation(
    [restoreSnapshotQueryKey],
    async () => await httpClient.restoreSnapshot({ snapshotId: snapshot.id! })
  )

  const { mutateAsync: getSnapshotRecords } = useMutation(
    [getSnapshotRecordsQueryKey],
    async (payload: GetSnapshotRecordsRequest) =>
      await httpClient.getSnapshotRecords(payload)
  )

  /**
   * Loads snapshot records and formats them for turntable.
   */
  const fetchSnapshotRecords = async (params: GetSnapshotRecordsParams) => {
    const page = params.pageNumber ?? 0
    const size = params.pageSize ?? PAGE_SIZE
    const changeType = params.changeType
    const result = await getSnapshotRecords({
      snapshotId: snapshot.id!,
      pageSize: size,
      pageNumber: page + 1,
      changeType: changeType,
    })
    const records = result?.data ?? []
    const skip = page * size
    return formatTurntableRows(records, skip, columnConfig, action, direction)
  }

  const isLoadingSummary = isLoading || isRefetching
  const summary = isLoadingSummary ? undefined : data?.data?.summary

  return {
    restoreSnapshot,
    fetchSnapshotRecords,
    summary,
    isLoadingSummary,
    isErrorSummary: isError,
  }
}

export default useSnapshotData
