import * as React from 'react'
import styled from 'styled-components'

export const TabStripOuter = styled.div`
  position: relative;
  margin-bottom: 1em;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 1px;
    background-color: var(--tabstrip-border-color);
  }
`

export const TabStripInner = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  a {
    display: flex;
    align-items: center;
    gap: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: var(--tabstrip-inactive-color);
    padding: 0.5em 0.75em;
    text-decoration: none;
    font-weight: 400;
    flex-shrink: 0;
    position: relative;
    max-width: 25%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a:hover {
    color: var(--tabstrip-hover-text-color);
    border-color: var(--tabstrip-hover-border-color);
  }

  a.active {
    border-color: 2px solid var(--tabstrip-active-color);
    color: var(--tabstrip-active-color);
    font-weight: 500;
  }
`

export const TabStrip = ({ children }: { children: React.ReactNode }) => {
  return (
    <TabStripOuter data-testid='tab-strip'>
      <TabStripInner>{children}</TabStripInner>
    </TabStripOuter>
  )
}
