import { useEffect } from 'react'
import { EventCallback } from '../../hooks/useEventSubscriber'
import { Observable } from '../observable'
import { resources } from './resources'
import { useForceUpdate } from './useForceUpdate'

/**
 * Given an ID return a resource. This will fail if the resource is not loaded
 * so ensure you only use this inside guarded components.
 *
 * @param id
 * @param ensure
 */
export function useResource<T extends { updatedAt: Date }>(
  id: string | undefined
): [T, Observable<T>]
export function useResource<T extends { updatedAt: Date }>(
  id: string | undefined,
  ensure: false
): [T | undefined, Observable<T>]
export function useResource<T extends { updatedAt: Date }>(
  id: string | undefined,
  ensure: boolean = true
): [T | undefined, Observable<T>] {
  const { observable } = resources.get<T>(id)
  useResourceObservable<T>(observable)
  return [ensure ? observable.ensured.data : observable.data, observable]
}

export function useResourceObservable<T>(
  observable: Observable<T>
): Observable<T> {
  const forceUpdate = useForceUpdate()
  /**
   * Shorthand for the event listener
   */
  const dataListener = () => {
    forceUpdate()
  }

  /**
   * attach and detach this component from the observable lifecycle
   */
  useEffect(() => {
    observable.addEventListener('data', dataListener)
    observable.addEventListener('error', dataListener)
    observable.addEventListener('loading', dataListener)

    if (!observable.isAwake) {
      observable.load(true)
    }

    return () => {
      observable.removeEventListener('data', dataListener)
      observable.removeEventListener('error', dataListener)
      observable.removeEventListener('loading', dataListener)
    }
  }, [observable])

  return observable
}

/**
 * Listen to an event related to a resource in scope
 *
 * @param id
 * @param event
 * @param callback
 */
export function useResourceEvent(
  id: string,
  event: string,
  callback: EventCallback
) {
  useEffect(() => {
    resources.get(id).on(event, callback)
    return () => {
      resources.get(id).off(event, callback)
    }
  }, [id, event])
}
