import {
  Button,
  getFont,
  MenuInput,
  MenuList,
  MenuListItem,
  MenuPopover,
  MenuSize,
  useFilter,
} from '@flatfile/design-system'
import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { SelectButtons } from '@/components/SelectButtons'
import { VerticalDivider } from '@/elements/VerticalDivider'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { SheetContext } from '../contexts/SheetContext'
import { ColumnManagementPanelProps, ColumnsState } from './types'

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 4px;
  gap: 5px;

  .styled-button {
    padding: 0;
    min-width: unset;
    height: unset;
  }

  .styled-button:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }

  > ${VerticalDivider} {
    background-color: var(--color-title);
    margin: 0px;
  }
`

const EmptyContent = styled.div`
  ${getFont('it2')}
  color: var(--color-text-ultralight);
  padding: 8px 16px;
  margin-bottom: 4px;
`

const ListContainer = styled.div`
  max-height: 433px;
  overflow-y: auto;
`
const noop = () => null

export const ColumnManagementPanel = ({
  columnConfig,
  columns,
  setColumns,
  onClose,
}: ColumnManagementPanelProps) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const { sheet } = useContext(SheetContext)
  const { t } = useTypedTranslation()
  const { contains } = useFilter({ sensitivity: 'base' })
  const { data: fieldCountsData, isFetching } = useFlatfileQuery(
    'getCounts',
    {
      sheetId: sheet?.id ?? '',
      byField: true,
    },
    {
      staleTime: Infinity,
    }
  )

  const emptyFieldsMap = useMemo(() => {
    if (
      fieldCountsData &&
      fieldCountsData.data &&
      fieldCountsData.data.counts &&
      fieldCountsData.data.counts.byField
    ) {
      return Object.entries(fieldCountsData.data.counts.byField)
        .filter(
          ([_, field]) =>
            'empty' in field && 'total' in field && field.empty === field.total
        )
        .reduce((acc, [key, _]) => {
          acc[key] = { ...columns[key] }
          return acc
        }, {} as ColumnsState)
    } else {
      return {}
    }
  }, [fieldCountsData])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value
    if (value !== undefined) {
      setInputValue(value)
    }
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onClose()
      noop() // Added for coverage
    }
  }

  const handlePressHideEmpty = () => {
    const newColumnState = { ...columns }
    Object.keys(emptyFieldsMap).forEach((key) => {
      const state = columns[key] ?? {}
      newColumnState[key] = {
        ...state,
        hidden: true,
      }
    })
    setColumns(newColumnState)
  }

  const handleClearInput = () => {
    setInputValue('')
    inputRef?.current?.focus()
  }

  const handlePressItem = (value: string) => {
    const item = columns[value] ?? {}
    const newColumnState = {
      ...columns,
      [value]: {
        ...item,
        value,
        hidden: !item.hidden,
      },
    }
    setColumns(newColumnState)
  }

  const handleSelectAll = (all: boolean) => {
    const newColumnState = columnConfig.reduce((memo, column) => {
      const state = columns[column.value] ?? {}
      memo[column.value] = {
        ...state,
        hidden: !all,
      }
      return memo
    }, {} as ColumnsState)

    setColumns(newColumnState)
  }

  const { allSelected, noneSelected } = useMemo(() => {
    let allSelected = true
    let noneSelected = true

    columnConfig.forEach((c) => {
      const state = columns[c.value]
      if (state?.hidden) {
        allSelected = false
      } else {
        noneSelected = false
      }
    })

    return { allSelected, noneSelected }
  }, [columns, columnConfig])

  const filteredColumns = useMemo(() => {
    return columnConfig.filter((c) => contains(c.label ?? c.value, inputValue))
  }, [inputValue, columnConfig])

  return (
    <MenuPopover isOpen onClose={noop} restoreFocus={false}>
      <MenuInput
        autoFocus
        inner
        inputRef={inputRef}
        onChange={handleInputChange}
        onClear={inputValue ? handleClearInput : undefined}
        onKeyDown={handleInputKeyDown}
        placeholder={t('sheet.columnManagement.placeholder', {
          count: columnConfig.length,
        })}
        size={MenuSize.sm}
        value={inputValue}
      />
      <MenuList size={MenuSize.sm}>
        <ButtonsRow>
          <SelectButtons
            selectNoneLabel={t('sheet.columnManagement.hideAll')}
            onSelectAll={allSelected ? undefined : () => handleSelectAll(true)}
            onSelectNone={
              noneSelected ? undefined : () => handleSelectAll(false)
            }
          />
          <VerticalDivider />
          <Button
            variant='tertiary'
            onPress={handlePressHideEmpty}
            isDisabled={isFetching || noneSelected}
            label={t('sheet.columnManagement.hideEmpty')}
            data-testid='button-clearEmpty'
            size='sm'
          />
        </ButtonsRow>
        <ListContainer>
          {filteredColumns.length ? (
            filteredColumns.map((column) => {
              const state = columns[column.value]
              return (
                <MenuListItem
                  key={column.value}
                  label={column.label ?? column.value}
                  selected={!state?.hidden}
                  onPress={() => handlePressItem(column.value)}
                  checkbox
                />
              )
            })
          ) : (
            <EmptyContent data-testid='column-management-empty'>
              {t(
                columnConfig.length
                  ? 'sheet.columnManagement.noMatches'
                  : 'sheet.columnManagement.noFields'
              )}
            </EmptyContent>
          )}
        </ListContainer>
      </MenuList>
    </MenuPopover>
  )
}
