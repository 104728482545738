import ReactMarkdown from 'react-markdown'
import { CodeSnippet, copyToClipboard } from '@flatfile/shared-ui'
import styled from 'styled-components'
import { Colors } from '@flatfile/design-system'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import EmbeddedSheet from '../../apps/WorkbookApp/EmbeddedSheet'
import { EmbeddedDiffView } from '@/apps/WorkbookApp/VersionHistory/SnapshotView/EmbeddedDiffView'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { DiffDirection } from '@/apps/WorkbookApp/VersionHistory/SnapshotView/types'

const StyledCode = styled.code`
  background-color: ${Colors.Pigeon100};
  padding: 0 3px;
  margin: 0 1px;
  border-radius: 4px;
  border: 1px solid ${Colors.Pigeon400};
  font-weight: 500;
  font-size: 0.9em;
`

export function Markdown({ body }: { body: string }) {
  const { t } = useTypedTranslation()
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw as any]}
      remarkPlugins={[remarkGfm as any]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return inline ? (
            <StyledCode
              {...props}
              className={className}
              data-testid='single-line-code'
            >
              {children}
            </StyledCode>
          ) : (
            <CodeSnippet
              code={String(children)}
              onCopy={() => {
                copyToClipboard(String(children))
              }}
              language={match ? match[1] : undefined}
            />
          )
        },
        embed(props) {
          const type = props['type']
          if (type === 'embedded-sheet') {
            const {
              sheetid: id,
              workbookid: workbookId,
              name,
              defaultexpanded: defaultExpanded,
            } = props as any
            if (id && workbookId) {
              return (
                <EmbeddedSheet
                  id={id}
                  workbookId={workbookId}
                  name={name}
                  defaultExpanded={defaultExpanded === 'true'}
                />
              )
            } else return <>{t('blocks.embeddedSheet.errors.missingIds')}</>
          } else if (type === 'embedded-diff') {
            const {
              sheetid: sheetId,
              workbookid: workbookId,
              snapshotid: snapshotId,
              direction: diffDirection,
            } = props as any
            if (
              diffDirection &&
              !Object.values(DiffDirection).includes(diffDirection)
            ) {
              return <>{t('blocks.embeddedDiff.errors.invalidDirection')}</>
            }
            if (sheetId && workbookId && snapshotId) {
              return (
                <EmbeddedDiffView
                  sheetId={sheetId}
                  workbookId={workbookId}
                  snapshotId={snapshotId}
                  diffDirection={diffDirection}
                />
              )
            } else return <>{t('blocks.embeddedDiff.errors.missingIds')}</>
          } else {
            return <embed {...props} />
          }
        },
      }}
    >
      {body}
    </ReactMarkdown>
  )
}
