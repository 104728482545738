function isIso8601Date(str: string): boolean {
  // This regex matches the ISO 8601 date format
  const iso8601Regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})$/
  return iso8601Regex.test(str)
}

/**
 * Takes a JSON blob and converts any ISO date string values to
 * Typescript Date objects. Use when querying the API directly (as
 * opposed to using the SDK) as JSON does not support date types.
 *
 * @param obj - The object to convert
 * @param keyExclusions - An array of keys to exclude from the conversion
 * @returns The converted object
 */
export function convertDates(obj: any, keyExclusions?: string[]): any {
  const excludedKeys = keyExclusions || []
  if (Array.isArray(obj)) {
    return obj.map((value) => convertDates(value, excludedKeys))
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string' && isIso8601Date(value)) {
        newObj[key] = new Date(value)
      } else if (typeof value === 'object') {
        if (!excludedKeys.includes(key)) {
          newObj[key] = convertDates(value, excludedKeys)
        } else {
          newObj[key] = value
        }
      } else {
        newObj[key] = value
      }
    }
    return newObj
  }
  return obj
}
