/**
 * Custom style object for the ReactSelect used in mapping.
 *
 * While this is an object made of functions, it is nonetheless
 * functionally just pure styles, and is therefore appropriate
 * to keep in the elements folder.
 */
export const ReactSelectStyles = {
  container: (provided: any) => ({
    borderRadius: 0,
    flex: 1,
  }),
  control: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    background: 'var(--color-page)',
    border: state.hasValue
      ? '1px solid transparent'
      : '1px solid var(--interactive-border-color)',
    borderRadius: 'var(--interactive-border-radius)',
    boxShadow: null,
    color: state.hasValue ? 'var(--color-text)' : 'var(--color-text-light)',
    fontSize: 'var(--size-p)',
    fontWeight: '400',
    zIndex: '1',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'var(--interactive-border-color)',
    },
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 500ms ease',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text-ultralight)',
  }),
  singleValue: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text)',
  }),
  valueContainer: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text)',
  }),
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    fontWeight: state.isSelected && !state.isDisabled ? 'bold' : 'unset',
    backgroundColor: state.isFocused ? 'var(--color-hover)' : 'transparent',
    color: state.isDisabled ? 'var(--color-pigeon-600)' : 'var(--color-text)',
    '&:hover': {
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    },
  }),
  menu: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: 'var(--color-page)',
    borderRadius: 'var(--interactive-border-radius)',
    color: 'var(--color-text)',
    border: '1px solid var(--interactive-border-color)',
    maxWidth: '100%',
    zIndex: '0',
  }),
  menuPortal: (defaultStyles: any) => ({
    ...defaultStyles,
    zIndex: 1000000000,
  }),
}
