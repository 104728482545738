import { FlatfileRequestType } from './types'

export const environmentsQueries = {
  getEnvironmentById: {
    generateQueryKey: (params: FlatfileRequestType<'getEnvironmentById'>) => [
      'getEnvironmentById',
      params,
    ],
    influencingEvents: {},
  },
}
