import {
  GuestAuthenticationEnum,
  GuestConfigSpacesInner,
  Space,
  Workbook,
} from '@flatfile/api'
import {
  CheckboxInput,
  DEFAULT_PAGE_SIZE,
  Icon,
  IconButton,
  useListPagination,
} from '@flatfile/design-system'
import {
  CheckmarkIcon,
  PopoverContext,
  PopoverMessage,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  IInviteGuests,
  ISpaceUser,
  useGuestManagement,
} from '../../hooks/useGuestManagement'

import { formatTime } from '../../utils/formatTime'
import { pluralize } from '../../utils/pluralize'
import { PendingUserCell, WorkbookAccessCell } from './components/ListElements'

export const PAGE_SIZE = DEFAULT_PAGE_SIZE

export const listHeaders = [
  { key: 'checkBox', content: '', width: '42px' },
  { key: 'name', content: 'Name', width: '28%' },
  { key: 'access', content: 'Access' },
  { key: 'joined', content: 'Joined' },
  { key: 'lastSeen', content: 'Last Seen' },
]

export const useManageUsers = ({
  space,
  billingEnabled,
  isCollaborative,
}: {
  space: Space
  billingEnabled?: boolean
  isCollaborative?: boolean
}) => {
  const { showPopover } = useContext(PopoverContext)
  const { currentPage, onChangePage, setCurrentPage } = useListPagination()

  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [isRemovingUsers, setIsRemovingUsers] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<ISpaceUser[]>([])
  const [editUser, setEditUser] = useState<ISpaceUser | undefined>()
  const [showCopyMessage, setShowCopyMessage] = useState<boolean>(false)

  const [magicLinkEnabled, sharedLinkEnabled] = useMemo(
    () => [
      space?.guestAuthentication?.includes(GuestAuthenticationEnum.MagicLink) ||
        !billingEnabled,
      space?.guestAuthentication?.includes(GuestAuthenticationEnum.SharedLink),
    ],
    [space.guestAuthentication, billingEnabled]
  )

  const guestInviteLink = sharedLinkEnabled ? space?.guestLink : undefined

  const openInviteModal = useCallback(() => {
    setShowInviteModal(true)
  }, [setShowInviteModal])

  const closeInviteModal = useCallback(() => {
    setShowInviteModal(false)
  }, [setShowInviteModal])

  const openRemoveModal = useCallback(() => {
    setShowRemoveModal(true)
  }, [setShowRemoveModal])

  const closeRemoveModal = useCallback(() => {
    setShowRemoveModal(false)
  }, [setShowRemoveModal])

  const openEditModal = useCallback(
    (user: ISpaceUser) => {
      setEditUser(user)
    },
    [setEditUser]
  )

  const closeEditModal = useCallback(() => {
    setEditUser(undefined)
  }, [setEditUser])

  const isUserSelected = useCallback(
    (user: ISpaceUser) => !!selectedUsers.find((u) => u.email === user.email),
    [selectedUsers]
  )

  const handleSelectUserToggle = useCallback(
    (user: ISpaceUser) => {
      if (isUserSelected(user)) {
        const newSelectedUsers = Array.from(selectedUsers).filter(
          (u) => user.id !== u.id
        )
        setSelectedUsers(newSelectedUsers)
      } else {
        setSelectedUsers([...selectedUsers, user])
      }
    },
    [selectedUsers, isUserSelected]
  )

  const handleRemoveUserError = useCallback(() => {
    const userCount = selectedUsers.length
    closeRemoveModal()
    setIsRemovingUsers(false)
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          There was an error deleting the {pluralize('guest', userCount)}
        </PopoverMessage>
      ),
    })
  }, [closeRemoveModal, setIsRemovingUsers, showPopover])

  const handleUpdateUserError = useCallback(() => {
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          There was an error updating the guest's workbook access.
        </PopoverMessage>
      ),
    })
  }, [showPopover])

  const handleInviteUserError = useCallback(() => {
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          There was an error sending the invitation(s).
        </PopoverMessage>
      ),
    })
  }, [showPopover])

  const onCopySuccess = () => {
    if (magicLinkEnabled) {
      setShowCopyMessage(true)
      setTimeout(() => setShowCopyMessage(false), 1200)
    } else {
      showPopover(
        {
          icon: <Icon name='clipboardCheck' />,
          message: <div>Copied to clipboard</div>,
        },
        true
      )
    }
  }

  const copyInviteLink = useCallback(async () => {
    if (!guestInviteLink) return
    try {
      await navigator.clipboard.writeText(guestInviteLink)
      onCopySuccess()
    } catch {
      return
    }
  }, [guestInviteLink])

  const {
    users,
    workbooks,
    addGuests,
    removeGuest,
    updateGuest,
    refetchUserData,
    isLoading,
    isError,
    isLoadingWorkbooks,
    isWorkbooksError,
  } = useGuestManagement({
    space,
    onRemoveError: handleRemoveUserError,
    onUpdateError: handleUpdateUserError,
    onInviteError: handleInviteUserError,
  })

  /*
    If billing is not enabled, the user is on a legacy Space and can invite
    as many guests as they like. Otherwise, a Space must be a Project in order
    to invite more than one guest.
  */
  const hasInviteLimit = billingEnabled && !isCollaborative
  const hasReachedInviteLimit = useMemo(() => {
    if (!hasInviteLimit) {
      return false
    }
    return !!users?.length
  }, [hasInviteLimit, users?.length])

  const handleRemoveUsers = useCallback(async () => {
    setIsRemovingUsers(true)
    try {
      await Promise.all(selectedUsers.map((user) => removeGuest(user.email)))
      await refetchUserData()
      setSelectedUsers([])
      closeRemoveModal()
      setIsRemovingUsers(false)
    } catch {
      handleRemoveUserError()
    }
  }, [selectedUsers, showPopover])

  const handleUpdateUser = useCallback(
    async (guest: ISpaceUser, guestWorkbooks: Workbook[]) => {
      try {
        if (guestWorkbooks.length) {
          const guestSpaces =
            guest.spaces?.filter(
              (s: GuestConfigSpacesInner) => s.id !== space.id
            ) ?? []
          guestSpaces.push({
            id: space.id,
            lastAccessed: guest.lastAccessed,
            workbooks: guestWorkbooks.map((w) => ({ id: w.id })),
          })
          await updateGuest({
            guestId: guest.id,
            guestConfigUpdate: {
              environmentId: guest.environmentId!,
              name: guest.name,
              email: guest.email,
              spaces: guestSpaces,
            },
          })
        } else {
          /*
            If all workbooks have been deselected, then we remove the user from the space
          */
          await removeGuest(guest.email)
        }
        await refetchUserData()
        closeEditModal()
      } catch (e) {
        handleUpdateUserError()
      }
    },
    [showPopover]
  )

  const sendInvites = useCallback(
    async (inviteGuests: IInviteGuests, onSuccess: () => void) => {
      const userCount = inviteGuests.guestEmails.length
      try {
        await addGuests(inviteGuests)
        showPopover({
          icon: <CheckmarkIcon name='checkmark' />,
          message: (
            <PopoverMessage>
              {pluralize('Invitation', userCount)} sent
            </PopoverMessage>
          ),
        })
        onSuccess()
      } catch {
        handleInviteUserError()
      }
    },
    [showPopover]
  )

  const handleInviteSuccess = useCallback(async () => {
    /* Only refetch user data when sending invitations the first time */
    await refetchUserData()
    closeInviteModal()
  }, [showInviteModal])

  const handleSendInvites = useCallback(
    (inviteGuests: IInviteGuests) => {
      sendInvites(inviteGuests, handleInviteSuccess)
    },
    [sendInvites, handleInviteSuccess]
  )

  const handleResendInvites = useCallback(
    (usersToInvite: ISpaceUser[]) => {
      const emails = usersToInvite.map((user) => user.email)
      sendInvites({ guestEmails: emails, message: '' }, () =>
        setSelectedUsers([])
      )
    },
    [selectedUsers]
  )

  const handleResendSelectedInvites = useCallback(
    () => handleResendInvites(selectedUsers),
    [selectedUsers]
  )

  const currentUsers = useMemo(() => {
    /*
      Mock pagination since the user and guest requests are not paginated. This
      is not currently a performance concern, as there is a 25 user limit for
      even large spaces. In the event that this changes, we will add true pagination.
    */
    if (!users?.length) return []

    const userArray = Array.from(users)
    const startIndex = PAGE_SIZE * (currentPage - 1)
    const endIndex = startIndex + PAGE_SIZE

    return userArray.slice(startIndex, endIndex)
  }, [users, currentPage])

  /* c8 ignore start */
  const listData = useMemo(() => {
    const disabled = false
    /*
      We've temporarily removed the conditions that would cause the list to be disabled.
      Leaving the disabled handling in so that it doesn't need to be rewritten when/if
      we need to add it back in.
    */
    const list = currentUsers.map((user: ISpaceUser) => {
      const onClickResend = disabled
        ? undefined
        : () => handleResendInvites([user])
      const onClickEdit = disabled ? undefined : () => openEditModal(user)
      const onClickSelect = () => handleSelectUserToggle(user)
      const data = {
        checkBox: (
          <CheckboxInput
            color='var(--color-primary)'
            disabled={disabled}
            checked={isUserSelected(user)}
            onChange={onClickSelect}
          />
        ),
        name: user.name ?? user.email,
        access: (
          <WorkbookAccessCell
            user={user}
            onClickEdit={onClickEdit}
            workbooksCount={workbooks?.length}
          />
        ),
        joined: user.isPending ? (
          <PendingUserCell disabled={disabled} onClickResend={onClickResend} />
        ) : (
          formatTime(user.createdAt)
        ),
        lastSeen: formatTime(user.lastAccessed),
      }
      return {
        data,
        key: user.id,
        className: disabled ? 'disabled-user-row' : '',
        onClick: disabled ? undefined : onClickSelect,
      }
    })
    if (magicLinkEnabled && !hasReachedInviteLimit) {
      list.push({
        data: {
          checkBox: (
            <IconButton name='plus' size={16} onPress={openInviteModal} />
          ),
          name: '',
          access: <></>,
          joined: '',
          lastSeen: '',
        },
        key: 'add-user',
        className: '',
        onClick: openInviteModal,
      })
    }

    return list
  }, [
    hasReachedInviteLimit,
    currentUsers,
    selectedUsers,
    openInviteModal,
    workbooks,
    handleResendInvites,
    openEditModal,
    handleSelectUserToggle,
    isUserSelected,
  ])
  /* c8 ignore stop */

  useEffect(() => {
    /* Reset the selection when the page changes */
    setSelectedUsers([])
  }, [currentPage])

  return {
    showInviteModal,
    showRemoveModal,
    openInviteModal,
    openRemoveModal,
    openEditModal,
    closeInviteModal,
    closeRemoveModal,
    closeEditModal,
    onSendInvites: handleSendInvites,
    onResendInvites: handleResendInvites,
    onResendSelectedInvites: handleResendSelectedInvites,
    onRemoveUsers: handleRemoveUsers,
    onUpdateUser: handleUpdateUser,
    onSelectUser: handleSelectUserToggle,
    isRemovingUsers,
    isLoadingUsers: isLoading,
    hasLoadError: isError,
    setSelectedUsers,
    users,
    workbooks,
    selectedUsers,
    editUser,
    currentPage,
    onChangePage,
    listData,
    magicLinkEnabled,
    sharedLinkEnabled,
    showCopyMessage,
    onCopySuccess,
    copyInviteLink,
    guestInviteLink,
    hasInviteLimit,
    hasReachedInviteLimit,
    isLoadingWorkbooks,
    isWorkbooksError,
  }
}
