import { usePanelsContext } from '@/contexts/PanelsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { AuditActivity } from '@flatfile/api'
import {
  IDateDropdownOptions,
  dateDropdownOptions,
  useListPagination,
} from '@flatfile/design-system'
import {
  DropdownProps,
  OnSelectionChange,
} from '@flatfile/design-system/lib/components/Dropdown/types'
import { DateDropdownProps } from '@flatfile/design-system/lib/components/List/types'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useContext, useMemo, useState } from 'react'
import {
  generateActivityResource,
  generateActorName,
  generateEventDetail,
  generateEventType,
} from './activityHelpers'

export const activityListHeaders = [
  { key: 'name', content: 'Name' },
  { key: 'eventType', content: 'Event type' },
  { key: 'resource', content: 'Resource', width: '20%' },
  { key: 'detail', content: 'Activity detail', width: '35%' },
  { key: 'timestamp', content: 'Timestamp', width: '15%' },
]

export const ACTIVITY_LOGS_PAGE_SIZE = 15

export const useActivityView = () => {
  const { space, httpClient } = useContext(SpaceContext)
  const { onChangePage, currentPage, setCurrentPage } = useListPagination()
  const { handleOpenPanel } = usePanelsContext()
  const [filters, setFilter] = useState<{
    activity?: AuditActivity
    since?: any
  }>({ activity: undefined, since: undefined })

  const { data, isLoading, isError, refetch } = useQuery(
    [
      'getSpaceActivity',
      space.id,
      filters.activity,
      filters.since,
      currentPage,
    ],
    () =>
      httpClient.getSpaceActivity({
        spaceId: space.id,
        pageSize: ACTIVITY_LOGS_PAGE_SIZE,
        activity: filters.activity,
        since: filters.since,
        pageNumber: currentPage,
        sortDirection: 'desc',
        sortField: 'created_at',
      })
  )

  const onFilterChange = useCallback(
    (e: AuditActivity | '') => {
      setCurrentPage(1)
      setFilter((filter) => ({
        ...filter,
        activity: e !== '' ? (e as AuditActivity) : undefined,
      }))
    },
    [setCurrentPage]
  )

  const dropdownFilter: DropdownProps = {
    optionsList: [
      { key: '', label: 'No filter' },
      ...Object.values(AuditActivity).map((value) => ({
        key: value,
        label: value,
      })),
    ],
    onSelectionChange: onFilterChange as OnSelectionChange,
    placeholder: 'Filter by Event type',
    searchable: true,
    loading: isLoading,
  }

  const onSelectDateFilter = useCallback(
    (e: IDateDropdownOptions['key']) => {
      const result = dateDropdownOptions.find((item) => item.key === e)
      if (result?.interval && result.interval.start) {
        setCurrentPage(1)
        setFilter({ ...filters, since: result.interval.start })
      }
    },
    [filters]
  )

  const dateDropdown: DateDropdownProps = {
    onChange: onSelectDateFilter,
    loading: isLoading,
  }

  useEventSubscriber(Object.values(AuditActivity), (topic, event) => {
    /* c8 ignore next */
    refetch()
  })

  const listData = useMemo(() => {
    return data?.data && data?.data.length
      ? data.data.map((log, index) => {
          return {
            data: {
              name: generateActorName(log),
              eventType: generateEventType(log),
              resource: generateActivityResource(log, space.id, () =>
                /* c8 ignore next */
                handleOpenPanel('Jobs')
              ),
              detail: generateEventDetail(log, space.id),
              timestamp: log.activityTime ? log.activityTime.toISOString() : '',
            },
            key: `activity-${index}`,
          }
        })
      : []
  }, [data])

  const totalCount = useMemo(
    () => data?.pagination?.totalCount ?? listData.length,
    [data?.pagination, listData]
  )

  return {
    listData,
    activityListHeaders,
    dateDropdown,
    isLoading,
    isError,
    onChangePage,
    currentPage,
    totalCount,
    dropdownFilter,
    filters,
  }
}
