import { useCallback, useContext, useState } from 'react'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useEntitlements } from '@/hooks/useEntitlements'

export const useManageProject = () => {
  const { space } = useContext(SpaceContext)
  const { hasEntitlement } = useEntitlements()

  const [showEndProjectModal, setShowEndProjectModal] = useState<boolean>(false)

  const openEndProjectModal = useCallback(() => {
    setShowEndProjectModal(true)
  }, [setShowEndProjectModal])

  const closeEndProjectModal = useCallback(() => {
    setShowEndProjectModal(false)
  }, [setShowEndProjectModal])

  const isCollaborative = !!space.isCollaborative
  const projectsEnabled = hasEntitlement('projectsEnabled')
  const billingEnabled = hasEntitlement('billingEnabled')

  const handleEndProject = useCallback(() => {
    /* Downgrading Projects can only be done manually for now. */

    const supportUrl = 'mailto:support@flatfile.io'
    const emailSubject = `Please close Project ID ${space.id}`
    const emailBody = `I'd like to request the Project associated with Space Id ${space.id} be closed.`

    window.location.href =
      supportUrl + '?subject=' + emailSubject + '&body=' + emailBody

    closeEndProjectModal()
  }, [space.id, closeEndProjectModal])

  const handleUpgradeSpace = useCallback(() => {
    /* Upgrading a Space to a Project has to be done manually. */

    const supportUrl = 'mailto:support@flatfile.io'
    const emailSubject = `Please transform Space ${space.id} to a project`
    const emailBody = `I'd like to request Space ${space.id} be transformed to a project.`

    window.location.href =
      supportUrl + '?subject=' + emailSubject + '&body=' + emailBody
  }, [space.id])

  return {
    billingEnabled,
    isCollaborative,
    hasProjectsEnabled: projectsEnabled,
    showEndProjectModal,
    openEndProjectModal,
    closeEndProjectModal,
    onEndProject: handleEndProject,
    onUpgradeToProject: handleUpgradeSpace,
  }
}
