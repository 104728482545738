import { DropdownPopover, ZIndex, truncated } from '@flatfile/design-system'
import styled, { css } from 'styled-components'

import { Badge } from '../../elements/Badge'

export const SidebarContainer = styled.div<{ collapsed?: boolean }>`
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: auto;
  position: relative;
  border-right: 1px solid var(--color-sidebar-border);
  transition: ease-in-out all 0.2s;
  width: var(--layout-sidebar-width);
  background: var(--color-sidebar-bg);
  z-index: ${ZIndex.level100};

  display: flex;
  flex-direction: column;

  ${({ collapsed }) =>
    collapsed &&
    `
    width: var(--layout-sidebar-width-collapsed);
  `}
`

export const SidebarContents = styled.div`
  padding: var(--spacing-sidebar-content-gutter);
  width: var(--layout-sidebar-width);
  overflow-y: auto;
  color: var(--color-sidebar-text);
  flex-grow: 1;
`

export const SidebarPill = styled(Badge)`
  border-radius: var(--pill-border-radius);
  margin-left: auto;
  border-color: var(--color-sidebar-border);
`

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sidebar-content-gutter);
  padding-top: var(--spacing-sidebar-gutter);
  padding-left: var(--spacing-sidebar-gutter);
  padding-right: 32px;
  padding-bottom: calc(var(--spacing-sidebar-gutter) * 2);
`

export const SidebarHeadingElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: default;
  color: var(--color-sidebar-title);
  font-family: var(--text-font);
  font-size: var(--size-h1);
  font-weight: bold;
  line-height: 1;
  gap: 4px;

  .heading-chevron,
  .spinner {
    margin-top: 4px;
  }
`

export const SidebarSpaceName = styled.span`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  text-align: center;
`

export const SidebarLogoContainer = styled.div`
  display: flex;

  img {
    min-height: 36px;
    max-height: 48px;
    max-width: 228px;
    height: auto;
    width: auto;
  }
`

export const SidebarCountPill = styled.div<{ empty: boolean }>`
  color: var(--color-sidebar-text);
  border-radius: 4px;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  padding: 4px;
  font-weight: 600;
  min-width: 18px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-left: auto;
  flex-shrink: 0;

  ${({ empty }) =>
    empty &&
    css`
      opacity: 0.5;
    `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-sidebar-text);
    opacity: 0.1;
    z-index: -1;
  }
`

export const SidebarItemText = styled.span`
  ${truncated}
`

export const SidebarItemEmptyText = styled.span`
  padding: calc(var(--spacing-sidebar-gutter) / 2) var(--spacing-sidebar-gutter);
`

const activeAndFocusState = css`
  font-weight: 600;
  background-color: var(--color-sidebar-focus-bg);
  color: var(--color-sidebar-focus-text);
  border-radius: var(--button-border-radius, 2px);
`

export const SidebarDivider = styled.li`
  &:after {
    content: '';
    height: 1px;
    display: block;
    background-color: var(--color-sidebar-border);
  }
  margin: 1.25em 0;
`

export const SidebarSheetIconWrapper = styled.div<{
  status?: 'empty' | 'error'
}>`
  display: flex;
  position: relative;
  ${({ status }) =>
    status === 'error' &&
    `
      position: relative;
      svg:first-of-type .table-dot-dot {
        color: var(--color-danger);
      }
    `}
  ${({ status }) =>
    status === 'empty' &&
    `
      svg {
        opacity: 0.5;
      }
    `}
`

export const SidebarListItem = styled.li`
  a {
    ${truncated}
    display: flex;
    align-items: center;
    padding: calc(var(--spacing-sidebar-gutter) / 2)
      var(--spacing-sidebar-gutter);
    text-decoration: none;
    width: 100%;
    background-color: transparent;
    transition: background-color 200ms;
    cursor: pointer;
    gap: 8px;

    svg {
      width: 20px;
      position: relative;
      flex-shrink: 0;
    }
  }

  a.active:not(.header-link) {
    ${activeAndFocusState}
  }

  &.active > a {
    font-weight: 600;
  }

  a.external-NavLink:after {
    content: '↗';
    font-size: 11px;
    font-weight: bold;
    margin-left: 0.5em;
    position: relative;
    top: 1px;
  }

  a:hover {
    ${activeAndFocusState}
    background-color: var(--color-sidebar-focus-bg-alpha);
  }
`

export const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul a {
    padding-left: calc(var(--spacing-sidebar-gutter) * 3);
  }
`

export const SidebarSwitcher = styled.div`
  position: relative;

  ${SidebarHeadingElement} {
    cursor: pointer;
  }

  ${DropdownPopover} {
    margin-top: var(--spacing-sidebar-gutter);
  }
`

export const SidebarFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--layout-sidebar-width);
  padding-right: var(--spacing-sidebar-content-gutter);
  padding-left: var(--spacing-sidebar-content-gutter);
  padding-top: var(--spacing-sidebar-content-gutter);
  padding-bottom: calc(
    var(--spacing-sidebar-content-gutter) + var(--spacing-sidebar-gutter)
  );
`

export const SidebarFooterLink = styled.a`
  color: var(--color-sidebar-footer-text);
  font-size: var(--size-small);
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  padding: 0 6px;
  border-radius: var(--layout-border-radius);
  line-height: 20px;

  svg {
    margin-right: 0.5em;
    top: -1px;
    position: relative;
    vertical-align: middle;
    height: 12px;
    width: 12px;
  }

  &:hover {
    color: var(--color-sidebar-focus-text);
    background-color: var(--color-sidebar-focus-bg);

    svg,
    b {
      color: var(--color-focus-text);
    }
  }
`
