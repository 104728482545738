import { GetSnapshotRecordsChangeTypeEnum as ChangeType } from '@flatfile/api'
import { FilterButton, FilterButtonsContainer } from '@flatfile/turntable'

import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { DiffAction, DiffDirection, DiffTableFilterProps } from './types'

/**
 * The list of change types in the strict order we want to display them.
 * Defining it here means we don't need to create it on every render.
 */
const changeTypes = [
  ChangeType.UpdatedSince,
  ChangeType.DeletedSince,
  ChangeType.CreatedSince,
]

export const DiffTableFilters = ({
  activeTab,
  setActiveTab,
  action,
  counts,
  isLoadingCounts,
  diffDirection,
}: DiffTableFilterProps) => {
  const { t } = useTypedTranslation()

  const usedAction =
    action === DiffAction.NONE
      ? diffDirection === DiffDirection.SNAPSHOT_TO_SHEET
        ? DiffAction.NONE
        : DiffAction.ACCEPT
      : action

  return (
    <FilterButtonsContainer>
      {changeTypes.map((changeType) => {
        const active = activeTab === changeType
        return (
          <FilterButton
            key={changeType}
            data-testid={`filter-${changeType}${active ? '-active' : ''}`}
            label={t(`snapshot.${usedAction}.filters.${changeType}`)}
            active={active}
            count={{
              count: counts?.[changeType]?.total,
              loading: isLoadingCounts,
            }}
            onClick={() => setActiveTab(changeType)}
          />
        )
      })}
    </FilterButtonsContainer>
  )
}
