export const checklistIcon = (
  <svg
    width='1.25em'
    height='1.25em'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.03034 11.1363C7.73744 10.8434 7.26257 10.8434 6.96967 11.1363C6.67678 11.4292 6.67678 11.9041 6.96967 12.197L8.03034 11.1363ZM9.16667 13.3333L8.63634 13.8637C8.92923 14.1566 9.40411 14.1566 9.697 13.8637L9.16667 13.3333ZM13.0303 10.5303C13.3232 10.2374 13.3232 9.76256 13.0303 9.46967C12.7374 9.17678 12.2626 9.17678 11.9697 9.46967L13.0303 10.5303ZM15.0833 5.83333V15.8333H16.5833V5.83333H15.0833ZM14.1667 16.75H5.83334V18.25H14.1667V16.75ZM4.91667 15.8333V5.83333H3.41667V15.8333H4.91667ZM5.83334 4.91667H7.50001V3.41667H5.83334V4.91667ZM12.5 4.91667H14.1667V3.41667H12.5V4.91667ZM5.83334 16.75C5.32708 16.75 4.91667 16.3396 4.91667 15.8333H3.41667C3.41667 17.168 4.49865 18.25 5.83334 18.25V16.75ZM15.0833 15.8333C15.0833 16.3396 14.6729 16.75 14.1667 16.75V18.25C15.5014 18.25 16.5833 17.168 16.5833 15.8333H15.0833ZM16.5833 5.83333C16.5833 4.49865 15.5014 3.41667 14.1667 3.41667V4.91667C14.6729 4.91667 15.0833 5.32707 15.0833 5.83333H16.5833ZM4.91667 5.83333C4.91667 5.32707 5.32708 4.91667 5.83334 4.91667V3.41667C4.49865 3.41667 3.41667 4.49865 3.41667 5.83333H4.91667ZM6.96967 12.197L8.63634 13.8637L9.697 12.803L8.03034 11.1363L6.96967 12.197ZM9.697 13.8637L13.0303 10.5303L11.9697 9.46967L8.63634 12.803L9.697 13.8637ZM9.16667 3.25H10.8333V1.75H9.16667V3.25ZM10.8333 5.08333H9.16667V6.58333H10.8333V5.08333ZM9.16667 5.08333C8.66041 5.08333 8.25 4.67293 8.25 4.16667H6.75001C6.75001 5.50135 7.83198 6.58333 9.16667 6.58333V5.08333ZM11.75 4.16667C11.75 4.67293 11.3396 5.08333 10.8333 5.08333V6.58333C12.168 6.58333 13.25 5.50135 13.25 4.16667H11.75ZM10.8333 3.25C11.3396 3.25 11.75 3.66041 11.75 4.16667H13.25C13.25 2.83198 12.168 1.75 10.8333 1.75V3.25ZM9.16667 1.75C7.83198 1.75 6.75001 2.83198 6.75001 4.16667H8.25C8.25 3.66041 8.66041 3.25 9.16667 3.25V1.75Z'
      fill='currentColor'
    />
  </svg>
)
