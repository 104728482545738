import { SheetCounts } from '@/components/SheetCounts'
import { WorkbookCounts } from '@/components/WorkbookCounts'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useSheetAccess } from '@/hooks/useSheetAccess'
import { sortWorkbooks } from '@/utils/workbooks'
import { Sheet, Workbook, WorkbooksResponse } from '@flatfile/api'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { SheetIconInvert } from '../assets/icons/SheetIcon'
import { DetailRow } from '../elements/DetailRow'
import { RadioInput } from '../elements/RadioInput'
import { SelectList, SelectListItem } from '../elements/SelectList'
import { StatusText } from '../elements/StatusText'
import { useTypedTranslation } from '../hooks/useTranslationWrappers'

export interface SheetSelectorProps {
  sourceWorkbook: Workbook
  onSourceChange: (workbookId: string, sheetId: string) => void
  onDestinationChange: (workbookId: string, sheetId: string) => void
  fileName: string
  workbooks: Workbook[]
  workbooksRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<WorkbooksResponse, unknown>>
}

const DotSeparator = styled.span`
  margin: 0 10px;
`

const DisabledParagraph = styled.p`
  opacity: 30%;
`

export const HorizontalListItem = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      {children}
      <DotSeparator>·</DotSeparator>
    </>
  )
}

export function SheetSelector({
  sourceWorkbook,
  onSourceChange,
  onDestinationChange,
  fileName,
  workbooks,
  workbooksRefetch,
}: SheetSelectorProps) {
  const [sourceSheet, setSourceSheet] = useState<string>()
  const [destSheet, setDestSheet] = useState<string>()
  const { t } = useTypedTranslation()
  const spaceContext = useContext(SpaceContext)
  useEffect(() => {
    workbooksRefetch()
  }, [])

  useEffect(() => {
    if (sourceWorkbook?.sheets?.[0]) {
      setSourceSheet(sourceWorkbook!.sheets?.[0]!.id)
    }
  }, [sourceWorkbook])

  const sortedWorkbooks = useMemo(
    () =>
      sortWorkbooks(
        workbooks,
        spaceContext.space.settings?.sidebarConfig?.workbookSidebarOrder
      ),
    [workbooks]
  )

  return (
    <ColWrapper>
      {sourceWorkbook?.sheets?.length! > 1 && (
        <div>
          <StatusText data-testid='source-info'>
            {t('mapping.sheetSelection.importFromHeading')}
          </StatusText>
          <SelectList>
            <SelectListItem key={sourceWorkbook.id}>
              <DetailRow>
                <SheetIconInvert />
                <p>{sourceWorkbook.name}</p>
              </DetailRow>
              {sourceWorkbook.sheets?.map((s) => (
                <RadioInput key={s.id}>
                  <label key={s.id}>
                    <input
                      type='radio'
                      data-testid={'select-sheet'}
                      checked={sourceSheet === s.id}
                      onChange={() => {
                        setSourceSheet(s.id)
                        onSourceChange(sourceWorkbook.id, s.id)
                      }}
                    />
                    <p>{s.name}</p>{' '}
                    <span>
                      {t('mapping.sheetSelection.columns', {
                        count: s.config?.fields?.length,
                      })}
                    </span>
                  </label>
                </RadioInput>
              ))}
            </SelectListItem>
          </SelectList>
        </div>
      )}
      <div>
        <StatusText data-testid='import-info'>
          {t('mapping.sheetSelection.importToHeading', {
            fileName: fileName,
          })}
        </StatusText>
        <SelectList>
          {sortedWorkbooks!
            .filter((w) => !w.name?.startsWith('[file]')) // @todo filter on backend?
            .map((w) => {
              return (
                <SelectListItem key={w.id}>
                  <WorkbookCounts workbook={w} />
                  {w.sheets?.map((s) => {
                    return (
                      <DestinationSheetOption
                        key={s.id}
                        id={s.id}
                        name={s.name}
                        checked={destSheet === s.id}
                        onChange={() => {
                          setDestSheet(s.id)
                          onDestinationChange(w.id, s.id)
                        }}
                        sheet={s}
                      />
                    )
                  })}
                </SelectListItem>
              )
            })}
        </SelectList>
      </div>
    </ColWrapper>
  )
}

interface RadioInputProps {
  id: string
  name: string
  checked: boolean
  onChange: () => void
  sheet: Sheet
}

const DestinationSheetOption: React.FC<RadioInputProps> = ({
  id,
  name,
  checked,
  onChange,
  sheet,
}) => {
  const { canImportIntoSheet } = useSheetAccess(sheet)
  const isDisabled = !canImportIntoSheet
  return (
    <RadioInput key={id}>
      <label key={id}>
        <input
          disabled={isDisabled}
          type='radio'
          data-testid={'select-sheet'}
          checked={checked}
          onChange={onChange}
        />
        {isDisabled ? (
          <DisabledParagraph>{name}</DisabledParagraph>
        ) : (
          <p>{name}</p>
        )}
        <SheetCounts sheet={sheet} />
      </label>
    </RadioInput>
  )
}
const ColWrapper = styled.div`
  display: flex;
  > div {
    flex-grow: 1;
  }
`
