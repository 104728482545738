import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ActivityView } from './apps/ActivityApp/ActivityView'
import { ChecklistView } from './apps/ChecklistApp/ChecklistView'
import { FilesView } from './apps/FilesApp/FilesView'
import { ImportFileView } from './apps/FilesApp/ImportFileView'
import { JobRouter } from './apps/JobsApp/JobRouter'
import { ManageUsersRoute } from './apps/ManageUsersApp/ManageUsersView'
import { SecretsView } from './apps/SecretsApp/SecretsView'
import { SpaceView } from './apps/SpaceApp'
import { SpaceEmpty } from './apps/SpaceApp/SpaceEmpty'
import { SpaceInitializer } from './apps/SpaceApp/SpaceInitializer'
import { SpaceRouteRedirector } from './apps/SpaceApp/SpaceRouteRedirector'
import { SheetRoute } from './apps/WorkbookApp/SheetRoute'
import { WorkbookRouteRedirector } from './apps/WorkbookApp/WorkbookRouteRedirector'
import { UnauthorizedView } from './auth/components/UnauthorizedView'
import { DocumentView } from './components/Documents/DocumentView'
import { ErrorState, UnauthErrorView } from './components/EmptyState'
import { ResourceRedirectorView } from './components/Resources/ResourceRedirector'
import { SpacesUISkeleton } from './components/SpacesUISkeleton'

export const Routing = () => {
  return (
    <Suspense fallback={<SpacesUISkeleton />}>
      <Routes>
        <Route path='/space-init' element={<SpaceInitializer />} />
        <Route
          path='/resource/:resourceId'
          element={<ResourceRedirectorView />}
        />
        <Route path='/space/:spaceId' element={<SpaceView />}>
          <Route path='checklist' element={<ChecklistView />} />
          <Route path='manage' element={<ManageUsersRoute />} />
          <Route path='files/'>
            <Route path='' element={<FilesView />} />
            <Route path=':fileId' element={<FilesView />} />
            <Route path=':fileId/import' element={<ImportFileView />} />
          </Route>
          <Route path='job/:jobId' element={<JobRouter />} />
          <Route path='document/:documentId' element={<DocumentView />} />
          <Route path='workbook/:workbookId'>
            <Route path='' element={<WorkbookRouteRedirector />} />
            <Route path='sheet/:sheetId' element={<SheetRoute />} />
            <Route
              path='*'
              element={<ErrorState title='Workbook not found' />}
            />
          </Route>
          <Route path='secrets/' element={<SecretsView />} />
          <Route path='activity/' element={<ActivityView />} />
          <Route path='' element={<SpaceRouteRedirector />} />
          <Route path='*' element={<ErrorState title='Space not found' />} />
        </Route>
        <Route path='*'>
          <Route path='empty' element={<SpaceEmpty />} />
          <Route path='unauthorized' element={<UnauthorizedView />} />
          <Route path='*' element={<UnauthErrorView />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default Routing
