import { PlanController } from '@/api/controllers/PlanController'
import { Rule, RuleController } from '@/api/controllers/RuleController'
import { updateObservable, useAction } from '@/api/observable'
import { MappingSidebar } from '@/elements/MappingComponents'
import { MatchField } from '@/elements/MatchField'
import { MatchIconSpacer, MatchIconWrap } from '@/elements/MatchIconWrap'
import { useEventCallbacks } from '@/hooks/useEventCallbacks'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  Tooltip as AMPTooltip,
  IconButton,
  Typography,
  ZIndex,
} from '@flatfile/design-system'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { RuleCard } from '../components/RuleCard'
import { CountPill, CountWrapper, MappingTitle, RuleHeader } from '../elements'

const Tooltip = styled(AMPTooltip)`
  z-index: ${ZIndex.level1000};
`

const maxMissingFieldsInTooltip = 10
/**
 * Render the UI for mapping fields and handle the updates to the mapping
 * ruleset
 *
 * @param controller
 * @param onFocus
 */
export const MapFieldsPage = ({
  onUpdateHeader,
  onClearRules,
  controller,
  onFocus,
}: {
  onClearRules: (toggle: boolean) => void
  controller: PlanController
  onFocus?: (rule: RuleController) => void
  onUpdateHeader?: () => void
}) => {
  const [updateRule, ruleUpdater] = useAction(
    controller.updateRule(),
    (data) => {
      updateObservable('plan', data)
      onClearRules(false)
    }
  )
  const [addedFields, setAddedFields] = useState<string[]>([])
  const [addFieldToSheet] = useAction(
    controller.addFieldToSheet(),
    async (data) => {
      updateObservable('sheets', {
        ...controller.sheets,
        dest: data.sheet,
      })
      updateRule({ rule: data.rule })
      if (data.rule.src) {
        setAddedFields([...addedFields, data.rule.src])
      }
    }
  )
  const { t } = useTypedTranslation()
  const groups = useMemo(() => controller.getDestinationOptions(), [controller])
  const rules = useMemo(() => controller.getRules(), [controller.plan])

  const destFields = useMemo(
    () =>
      controller.dest.fields.filter(
        (f) => !f.constraints?.some((c) => c.type === 'computed')
      ),
    [controller.destSheet]
  )

  const requiredFields = useMemo(
    () => controller.dest.requiredFields,
    [controller.destSheet]
  )

  const flattenedOptions = groups.flatMap((group) =>
    'options' in group ? group.options : group
  )

  const mappedDestinations = flattenedOptions.filter((field) =>
    rules.some(
      (rule) => !!(field as any).value && rule.dest === (field as any).value
    )
  )

  const missingFields = requiredFields.filter(
    (field) => !mappedDestinations.some((dest) => dest.value === field.key)
  )

  const missingMain = missingFields.slice(0, maxMissingFieldsInTooltip)
  const missingRest = missingFields.slice(maxMissingFieldsInTooltip)

  const fieldsAreMissing = missingFields.length > 0

  const countPillSourceContent = t(
    'mapping.mapFields.incomingFields.progressPill.text',
    {
      totalIncomingMapped: rules.filter((r) => r.dest).length,
      totalIncomingFields: rules.length,
    }
  )

  const headerSelectSourceContent = (
    <div>{t('mapping.mapFields.incomingFields.selectHeaders.tooltip')}</div>
  )

  const tooltipSourceContent = (
    <div>
      {t('mapping.mapFields.incomingFields.progressPill.tooltip', {
        mappedIncomingFields: rules.filter((r) => r.dest).length,
        totalIncomingFields: rules.length,
      })}
    </div>
  )

  const tooltipDestContent = (
    <div>
      {t('mapping.mapFields.destinationFields.progressPill.tooltip.progress', {
        mappedDestinationFields: mappedDestinations.length,
        totalDestinationFields: destFields.length,
      })}
      {fieldsAreMissing && (
        <div>
          {t(
            'mapping.mapFields.destinationFields.progressPill.tooltip.missingRequiredFields',
            { missingFieldsCount: missingFields.length }
          )}
          <ul>
            {missingMain.map((field) => (
              <li key={field.label}>{field.label}</li>
            ))}
          </ul>
          {missingRest.length > 0 && (
            <div>
              {t(
                'mapping.mapFields.destinationFields.progressPill.tooltip.greaterThanTenMissing',
                { missingFieldsAfterTen: missingRest.length }
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )

  const eventHandlers = useEventCallbacks('RuleCard', {
    onFocus(controller: RuleController) {
      onFocus?.(controller)
    },
    onChange(rule: Rule) {
      // Check if the option clicked was "Add as a new field"
      if (rule.isNewField) {
        addFieldToSheet({ rule })
      } else {
        updateRule({ rule })
      }
    },
  })

  return (
    <MappingSidebar>
      <MappingTitle>
        <Typography type='h5' color='var(--color-text)'>
          {t('mapping.mapFields.subheading')}
        </Typography>
      </MappingTitle>
      <RuleHeader>
        <MatchField>
          <CountWrapper>
            {t('mapping.mapFields.incomingFields.heading')}
            {onUpdateHeader && (
              <IconButton
                data-testid='updateHeader-open'
                data-tooltip-id='updateHeader-source'
                size={16}
                variant='tertiary'
                name='pen'
                onPress={() => onUpdateHeader()}
              />
            )}
          </CountWrapper>
          {onUpdateHeader && (
            <Tooltip id='updateHeader-source'>
              {headerSelectSourceContent}
            </Tooltip>
          )}
          <CountPill data-tooltip-id='countpill-source'>
            {countPillSourceContent}
            <Tooltip id='countpill-source'>{tooltipSourceContent}</Tooltip>
          </CountPill>
        </MatchField>
        <MatchIconWrap>
          <MatchIconSpacer>&nbsp;</MatchIconSpacer>
        </MatchIconWrap>
        <MatchField>
          <CountWrapper>
            {t('mapping.mapFields.destinationFields.heading')}
            <IconButton
              data-testid='clearMapping-open'
              data-tooltip-id='clearMapping-source'
              size={16}
              variant='tertiary'
              name='arrowRotateLeft'
              onPress={() => onClearRules(true)}
            />
          </CountWrapper>
          <Tooltip id='clearMapping-source'>
            {t('mapping.clearFields.modalTooltip')}
          </Tooltip>
          <CountPill
            invalid={fieldsAreMissing}
            data-tooltip-id='countpill-destination'
          >
            <>
              <>
                {t('mapping.mapFields.destinationFields.progressPill.text', {
                  totalDestinationsMapped: mappedDestinations.length,
                  totalDestinationFields: destFields.length,
                })}
              </>
              <>
                {missingFields.length > 0 &&
                  t(
                    'mapping.mapFields.destinationFields.progressPill.missingRequiredFields',
                    {
                      missingFieldsCount: missingFields.length,
                    }
                  )}
              </>
            </>
            <Tooltip id='countpill-destination'>{tooltipDestContent}</Tooltip>
          </CountPill>
        </MatchField>
      </RuleHeader>
      <Tooltip
        id='field-description'
        place='right'
        float
        positionStrategy='fixed'
      />
      <Tooltip
        id='field-description-clickable'
        place='right'
        float
        positionStrategy='fixed'
        clickable
      />
      {rules.map((r: Rule) => (
        <RuleCard
          observable={
            ruleUpdater.params?.rule?.src === r.src ? ruleUpdater : undefined
          }
          rule={r}
          // todo: this needs to be a rule ID
          key={`rule-${r.src}_${r.dest}`}
          planController={controller}
          disabled={ruleUpdater.isLoading}
          {...eventHandlers}
          fieldAdded={addedFields.includes(r.src!)}
        />
      ))}
    </MappingSidebar>
  )
}
