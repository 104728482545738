import { useDownloadFiles } from '@/apps/FilesApp/hooks/useDownloadFiles'
import { setColumnVisibility } from '@/apps/WorkbookApp/utils/setColumnVisibility'
import { TranslationsKeys } from '@/i18n'
import { preprocessMarkdown } from '@/utils/markdown'
import {
  Action,
  EventTopic,
  Job,
  JobOutcomeNextType,
  JobOutcomeNextView,
  JobOutcomeTrigger,
} from '@flatfile/api'
import { Typography } from '@flatfile/design-system'
import { PopoverContext } from '@flatfile/shared-ui'
import i18next from 'i18next'
import { useContext, useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { resources } from '../../api/resources'
import { JobsContext } from '../../contexts/JobsContext'
import { useEventSubscriber } from '../../hooks/useEventSubscriber'
import { useRetryJob } from '../../hooks/useRetryJob'
import { useRunningJobs } from '../../hooks/useRunningJobs'
import { downloadFromURL } from '../../utils/downloadFromURL'
import { typedT } from '../../utils/typedTranslation'
import { LinkContainer } from './LinkContainer'
import { LinkIcon } from './LinkIcon'

export const extractJobOutcomeDetails = (job: Job) => {
  const files =
    job?.outcome?.next?.type === 'files' ? job?.outcome?.next?.files : undefined
  const isViewType = job?.outcome?.next?.type === 'view'
  const hidden = isViewType
    ? (job?.outcome?.next as JobOutcomeNextView)?.hiddenColumns
    : undefined
  const sheetId = isViewType
    ? (job?.outcome?.next as JobOutcomeNextView)?.sheetId
    : undefined

  const trigger = job?.outcome?.trigger

  return { files, hidden, sheetId, trigger }
}

export const getResourceUrl = (job: Job) => {
  if (!(job?.outcome?.next && job?.outcome?.next.type === 'id')) {
    return '#'
  }
  const id = job?.outcome?.next.id
  const path = job?.outcome?.next.path
    ? encodeURIComponent(job?.outcome?.next.path)
    : ''
  const query = job?.outcome?.next.query
    ? encodeURIComponent(job?.outcome?.next.query)
    : ''

  const searchParams = new URLSearchParams()
  if (path) {
    searchParams.set('path', path)
  }
  if (query) {
    searchParams.set('query', query)
  }

  const searchParamsString = searchParams.toString()

  const url = searchParamsString
    ? `/resource/${id}?${searchParamsString}`
    : `/resource/${id}`
  return url
}

const renderActionLink = ({
  job,
  url,
  urlLabel,
  fileName,
  files,
  hidden,
  viewLabel,
  idLabel,
  retryLabel,
  hideToast,
  downloadFiles,
  handleViewClick,
  retryJob,
}: {
  job: Job
  url: string | undefined
  urlLabel: string | undefined
  fileName: string
  files: any
  hidden: string[] | undefined
  viewLabel: string
  idLabel: string
  retryLabel: string
  hideToast: () => void
  downloadFiles: (files: any) => void
  handleViewClick: () => void
  retryJob: (jobId: string) => void
}) => {
  const renderLink = (onClick: () => void, label: string, testId: string) => (
    <LinkContainer>
      <a href='#' onClick={onClick} data-testid={testId}>
        {label}
      </a>
    </LinkContainer>
  )

  if (!job?.outcome?.next?.type) {
    return undefined
  }

  switch (job.outcome.next.type) {
    case 'url':
      return url ? (
        <LinkContainer>
          <a
            href={url}
            onClick={hideToast}
            target='_blank'
            rel='noreferrer'
            data-testid='next-url'
          >
            {urlLabel} <LinkIcon size={16} name='arrowUpRight' />
          </a>
        </LinkContainer>
      ) : undefined
    case 'download':
      return url && urlLabel
        ? renderLink(
            () => {
              downloadFromURL(url, fileName)
              hideToast()
            },
            urlLabel,
            'next-download'
          )
        : undefined
    case 'files':
      return files && urlLabel
        ? renderLink(
            () => {
              downloadFiles(files)
              hideToast()
            },
            urlLabel,
            'next-files'
          )
        : undefined
    case 'view':
      return hidden
        ? renderLink(handleViewClick, viewLabel, 'next-view')
        : undefined
    case 'id':
      return (
        <LinkContainer>
          <a
            onClick={hideToast}
            href={getResourceUrl(job)}
            data-testid='next-id'
          >
            {idLabel}
          </a>
        </LinkContainer>
      )
    case 'retry':
      return renderLink(
        () => {
          retryJob(job.id)
          hideToast()
        },
        retryLabel,
        'next-retry'
      )
    default:
      return undefined
  }
}

const getTranslation = (
  label: string | undefined,
  translationKey: TranslationsKeys
) => (label ? i18next.t(label) : typedT(translationKey))

export const getJobOutcomeTexts = (job: Job, resource: any) => {
  const action: Action =
    job && resource && resource.actions
      ? resource.actions.find((a: Action) => a.operation === job.operation)
      : undefined

  const resourceName = getTranslation(
    resource?.name,
    'jobs.customActions.modals.defaultResourceName'
  )
  const jobName = action?.label
    ? i18next.t(action.label)
    : `${typedT('jobs.customActions.modals.defaultJobName')} ${i18next.t(
        job.operation
      )}`

  const outcomeMessage = job?.outcome?.message
    ? i18next.t(job?.outcome?.message)
    : typedT(
        job.status === 'failed'
          ? 'jobs.customActions.modals.outcomeModals.defaultMessage.failed'
          : 'jobs.customActions.modals.outcomeModals.defaultMessage.success',
        { jobName: jobName, resourceName: resourceName }
      )

  const outcomeHeading = i18next.t(
    job?.outcome?.heading ||
      (job.status === 'failed'
        ? 'jobs.customActions.modals.outcomeModals.defaultHeading.failed'
        : 'jobs.customActions.modals.outcomeModals.defaultHeading.success')
  )

  //collecting the summand types into one type with nullables
  const next = job?.outcome?.next as {
    type: string
    url?: string
    label?: string
    fileName?: string
  }

  const url = next?.url
  const urlLabel = getTranslation(
    next?.label,
    next?.type === 'url'
      ? 'jobs.customActions.modals.outcomeModals.defaultUrlLabel'
      : 'jobs.customActions.modals.outcomeModals.defaultDownloadLabel'
  )

  const fileName = getTranslation(
    next?.fileName,
    'jobs.customActions.modals.outcomeModals.defaultFileName'
  )
  const idLabel = getTranslation(
    next?.label,
    'jobs.customActions.modals.outcomeModals.defaultLabel'
  )
  const retryLabel = getTranslation(
    next?.label,
    'jobs.customActions.modals.outcomeModals.defaultRetryLabel'
  )
  const viewLabel = getTranslation(
    next?.label,
    'jobs.customActions.modals.outcomeModals.defaultViewLabel'
  )

  return {
    outcomeHeading,
    outcomeMessage,
    url,
    urlLabel,
    fileName,
    idLabel,
    retryLabel,
    viewLabel,
  }
}

const OutcomeMessageTypography = styled.div`
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`
const getDefaultTrigger = (type: JobOutcomeNextType | undefined) => {
  if (!type) {
    return JobOutcomeTrigger.Manual
  }
  switch (type) {
    case 'download':
    case 'files':
      return JobOutcomeTrigger.Automatic
    case 'url':
    case 'id':
    default:
      return JobOutcomeTrigger.Manual
  }
}

export const useJobsToast = () => {
  const runningJobsRef = useRef<Job[]>([])
  const { isOriginatedJob } = useContext(JobsContext)
  const runningJobs = useRunningJobs()
  const { retryJob } = useRetryJob()
  const { showToast, hideToast } = useContext(PopoverContext)
  const { downloadFiles } = useDownloadFiles()

  const navigate = useNavigate()

  useEffect(() => {
    runningJobsRef.current = runningJobs
  }, [runningJobs])

  useEventSubscriber(
    [EventTopic.Jobcompleted, EventTopic.Jobfailed],
    async (topic, event) => {
      const jobsResource = await resources.getWithPromise<any>(
        event.context.jobId,
        true
      )
      const job = jobsResource.maybeData as Job
      if (!job) {
        return
      }
      if (job.parentId) {
        return
      }

      let sourceResource
      try {
        sourceResource = await resources.getWithPromise<any>(job.source)
      } catch (error) {
        //do nothing
      }
      const resource = sourceResource?.maybeData

      const {
        outcomeHeading,
        outcomeMessage,
        url,
        urlLabel,
        fileName,
        idLabel,
        retryLabel,
        viewLabel,
      } = getJobOutcomeTexts(job, resource)

      const { files, hidden, sheetId, trigger } = extractJobOutcomeDetails(job)

      const auto = trigger
        ? trigger === JobOutcomeTrigger.Automatic ||
          trigger === JobOutcomeTrigger.AutomaticSilent
        : getDefaultTrigger(job?.outcome?.next?.type)
      const silent = trigger
        ? trigger === JobOutcomeTrigger.AutomaticSilent
        : false

      const handleViewClick = () => {
        if (hidden && sheetId) {
          setColumnVisibility({ fields: hidden, sheetId })
        }
        hideToast()
      }

      if (auto && isOriginatedJob(job.id)) {
        if (job?.outcome?.next?.type === 'view') {
          handleViewClick()
        }

        if (job?.outcome?.next?.type === 'id') {
          navigate(getResourceUrl(job))
        }

        if (job?.outcome?.next?.type === 'download') {
          downloadFromURL(url, fileName)
        }

        if (job?.outcome?.next?.type === 'files' && files) {
          downloadFiles(files)
        }
      }

      const shouldShowToast =
        !silent &&
        job.outcome &&
        !(
          job.outcome.acknowledge ||
          job.outcome?.next?.type === 'wait' ||
          job.outcome?.next?.type === 'snapshot'
        )

      if (shouldShowToast) {
        showToast(
          {
            heading: (
              <Typography type='h5' color='white'>
                {outcomeHeading}
              </Typography>
            ),
            message: (
              <OutcomeMessageTypography>
                <ReactMarkdown>
                  {preprocessMarkdown(outcomeMessage)}
                </ReactMarkdown>
              </OutcomeMessageTypography>
            ),
            action: renderActionLink({
              job,
              url,
              urlLabel,
              fileName,
              files,
              hidden,
              viewLabel,
              idLabel,
              retryLabel,
              hideToast,
              downloadFiles,
              handleViewClick,
              retryJob,
            }),
          },
          true
        )
      }
    }
  )
}
