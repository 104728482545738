import {
  addRecordsQueryKey,
  updateRecordsQueryKey,
} from '@/apps/WorkbookApp/hooks/useRecordMutation'
import { EventTopic, Workbook } from '@flatfile/api'
import { useDelayedBoolean } from '@flatfile/design-system'
import { fromMaybe } from '@flatfile/shared-ui'
import { useIsMutating, useQuery } from '@tanstack/react-query'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SpaceContext } from '../contexts/SpaceContext'
import { useEventSubscriber } from './useEventSubscriber'
import { useTimerRef } from './useTimerRef'

type Commit = {
  id: string
  createdAt: Date
}
export const useWorkbookHasCommits = (workbook: Workbook) => {
  const trackChanges = workbook.settings?.trackChanges ?? false

  //Locally keep track of an imminent commit to show a faster UI response
  const [expectingCommit, setExpectingCommit] = useState<boolean>(false)
  const { clearTimer, setTimer } = useTimerRef()

  const clearExpectedCommit = useCallback(() => {
    setExpectingCommit(false)
    clearTimer()
  }, [setExpectingCommit])

  const isMutatingUpdate = useIsMutating([updateRecordsQueryKey, workbook.id])
  const isMutatingAdd = useIsMutating([addRecordsQueryKey, workbook.id])

  const localMutationsHappening =
    trackChanges && (isMutatingUpdate > 0 || isMutatingAdd > 0)

  const detectedNewMutations = !expectingCommit && localMutationsHappening

  if (detectedNewMutations) {
    setExpectingCommit(true)
    clearTimer()
    setTimer(
      setTimeout(() => {
        setExpectingCommit(false)
      }, 10000)
    )
  }

  useEffect(() => {
    return () => {
      clearTimer()
    }
  }, [])

  const [commits, setCommits] = useState<Commit[]>([])

  const addCommit = (commit: Commit) => {
    setCommits((prev) => [...prev, commit])
  }
  const removeCommitById = (id: string) => {
    setCommits((prev) => prev.filter((c) => c.id !== id))
  }

  const sheetIds = trackChanges
    ? fromMaybe(
        workbook.sheets?.map((sheet) => sheet.id),
        []
      )
    : []

  const { httpClient } = useContext(SpaceContext)
  useQuery({
    queryKey: ['workbook-commits', { workbookId: workbook.id }],
    queryFn: async () => {
      const result = await (trackChanges
        ? httpClient.getWorkbookCommits({
            workbookId: workbook.id,
            completed: false,
          })
        : Promise.resolve({ data: [] }))
      const data = fromMaybe(result.data, []).map(
        (commit: Commit) =>
          ({ id: commit.id, createdAt: commit.createdAt } as Commit)
      )
      return data
    },
    onSuccess: (data) => {
      const initialCommits = data
      setCommits(initialCommits)
    },
  })

  useEventSubscriber(
    [EventTopic.Commitcreated, EventTopic.Commitcompleted],
    (_, event) => {
      if (trackChanges && event.topic === EventTopic.Commitcreated) {
        const commitSheet = event.origin.id
        if (sheetIds.includes(commitSheet) && event.context.versionId) {
          clearExpectedCommit()
          addCommit({
            id: event.context.versionId,
            createdAt: new Date(event.createdAt),
          })
        }
      }

      if (trackChanges && event.topic === EventTopic.Commitcompleted) {
        const commitSheet = event.origin.id
        if (sheetIds.includes(commitSheet)) {
          removeCommitById(event.context.versionId)
        }
      }
    }
  )

  const value = commits.length > 0 || expectingCommit || localMutationsHappening
  const delayedValue = useDelayedBoolean({
    value,
    suppressTime: 200,
    forceTime: 500,
  })
  return delayedValue
}
