import { Sheet, SheetAccess } from '@flatfile/api'
import { useMemo } from 'react'

export const useSheetAccess = (currentSheet?: Sheet) => {
  const noAccessLimitations =
    !currentSheet?.config?.access || currentSheet?.config?.access?.includes('*')

  const checkAccess = (access: SheetAccess) => {
    if (!currentSheet) return true
    return currentSheet?.config?.access?.includes(access) || noAccessLimitations
  }

  const readOnlySheet = useMemo(
    () =>
      currentSheet?.config?.readonly ||
      currentSheet?.config?.access?.length === 0 ||
      false,
    [currentSheet]
  )

  const canAddToSheet = useMemo(
    () => !readOnlySheet && checkAccess('add'),
    [currentSheet]
  )

  const canDeleteFromSheet = useMemo(
    () => !readOnlySheet && checkAccess('delete'),
    [currentSheet]
  )

  const canImportIntoSheet = useMemo(
    () => !readOnlySheet && checkAccess('import'),
    [currentSheet]
  )

  return {
    readOnlySheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet,
    noAccessLimitations: !readOnlySheet && noAccessLimitations,
  }
}
