import { logger } from '@/utils/logging'
import { fromMaybe } from '@flatfile/shared-ui'
import { useMutation } from '@tanstack/react-query'
import Pubnub, { FetchMessagesResponse, PubnubStatus } from 'pubnub'
import { usePubNub } from 'pubnub-react'

export function usePubnubMutations(spaceId: string) {
  const pubnub = usePubNub()

  const { mutate: fetchPubnubMessages } = useMutation(
    ['fetchPubNubMessages', spaceId],
    async (payload: PubNubFetchMessageRequest): Promise<void> => {
      if (payload.enableLogs) {
        logger.info('PubNub Log: Initializing fetch of prior messages', {
          spaceId,
        })
      }
      return new Promise((resolve, reject) => {
        pubnub.fetchMessages(
          payload.request,
          (status: PubnubStatus, response: FetchMessagesResponse) => {
            if (payload.enableLogs) {
              logger.info('PubNub Log: Fetch complete', {
                response,
                request: payload.request,
                responseRaw: JSON.stringify(response, null, 2) || 'No Response',
              })
            }
            if (!status.error && response.channels) {
              const messages = response.channels[payload.request.channels[0]]
              if (messages) {
                messages.forEach((event: any) => {
                  const msg = JSON.parse(fromMaybe(event?.message, '{}'))
                  payload.callback(event, msg)
                })
                if (payload.enableLogs) {
                  logger.info('PubNub Log: Prior messages fetched', {
                    response,
                    messages,
                  })
                }
                resolve()
              } else {
                reject(
                  new Error(
                    'PubNub Error: Prior message fetch returned no messages',
                    {
                      cause: { status, response, request: payload.request },
                    }
                  )
                )
              }
            } else {
              reject(
                new Error('PubNub Error: Failed to fetch prior messages', {
                  cause: { status, response, request: payload.request },
                })
              )
            }
          }
        )
      })
    },
    {
      retry(failureCount, error: Error) {
        logger.error(error.message, {
          failureCount,
          error: error?.cause ?? error,
        })
        return failureCount <= 3
      },
    }
  )

  return {
    fetchPubnubMessages,
  }
}

export type PubNubFetchMessageRequest = {
  request: Pubnub.FetchMessagesParameters
  callback: (event: any, msg: any) => void
  enableLogs?: boolean
}
