import { formatSearchTerm } from '@flatfile/turntable'
import { Key } from 'react'

export type FilterByValueFields = Record<string, Key[]>

const BASE_FFQL_FILTER = 'filter: '

export const buildValueFilters = (fields: FilterByValueFields) => {
  let filterQuery: string = ''

  Object.entries(fields).forEach(([field, keys]) => {
    if (keys.length) {
      if (filterQuery.length) {
        filterQuery += ' and '
      }
      filterQuery += '('
      keys.forEach((key, index) => {
        filterQuery += `'${field}' eq '${key}'`
        if (index < keys.length - 1) {
          filterQuery += ' or '
        }
      })
      filterQuery += ')'
    }
  })

  if (filterQuery.length) {
    filterQuery = BASE_FFQL_FILTER + filterQuery
  }
  return formatSearchTerm(filterQuery)
}
