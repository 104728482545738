import { BulkRowActionItem } from '@/apps/WorkbookApp/hooks/useBulkRowActions'
import { JobInput } from '@/apps/WorkbookApp/hooks/useCustomActions'
import { TranslationsKeys } from '@/i18n'
import { Action, Job, JobModeEnum } from '@flatfile/api'
import { Dispatch, SetStateAction } from 'react'

export const getEstimatedCompletionAt = (job: Job) => {
  const fallbackDate =
    // estimate completion date based on progress and time elapsed
    job.progress && job.progress > 0 && job.startedAt && job.updatedAt
      ? new Date(
          job.updatedAt.getTime() +
            (100 - job.progress) *
              ((job.updatedAt.getTime() - job.startedAt.getTime()) /
                job.progress)
        )
      : undefined

  const estimatedCompletionAt = job.estimatedCompletionAt || fallbackDate
  return estimatedCompletionAt
}

export const getMenuOptionFromAction = (
  action: Action,
  onClickAction: (
    operation: string,
    mode?: JobModeEnum
  ) => (input?: JobInput) => void,
  setCurrentAction: Dispatch<SetStateAction<BulkRowActionItem | null>>,
  customT: (key: string) => string,
  t: (k: TranslationsKeys, ...args: any) => string
) => {
  return {
    label: action.label,
    key: action.operation!,
    onAction:
      action.confirm || action.inputForm
        ? () =>
            setCurrentAction({
              ...action,
              ...{
                key: action.operation as string,
                action: onClickAction(action.operation!, action.mode),
                modalText: {
                  heading: customT(action.label),
                  description: action.description
                    ? customT(action.description)
                    : '',
                  confirmButtonText: t(
                    'jobs.customActions.modals.startModal.confirmButton'
                  ),
                  loadingText: t(
                    'jobs.customActions.modals.startModal.loadingText'
                  ),
                  errorMessage: t(
                    'jobs.customActions.modals.startModal.errorMessage'
                  ),
                },
                inputForm: action.inputForm,
                tooltip: {
                  active: action.tooltip ? customT(action.tooltip) : undefined,
                },
              },
            })
        : () => onClickAction(action.operation!)(undefined),
    tooltip: { active: action.tooltip ? customT(action.tooltip) : undefined },
  }
}
