import {
  Button,
  Modal,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalText,
  TextInput,
  Typography,
} from '@flatfile/design-system'
import { copyToClipboard } from '@flatfile/shared-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SearchFields } from '../types'
import { logger } from '@/utils/logging'

const FormLabel = styled.div`
  font-size: 14px;
  color: var(--color-title);
`

const HelpLabel = styled.div`
  margin-top: 5px;
  font-size: 11px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`

const SectionContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 4}px;
`

const FormGroup = styled.div`
  margin-bottom: 16px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
`

const SummaryBox = styled.div`
  padding: 10px;
  background-color: var(--color-page-100);
`

const SummaryTitle = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
`

const SummaryText = styled.div`
  font-family: 'Courier New', Courier, monospace;
`

const SummaryList = styled.ul`
  margin: 0;
`

const SummaryTextItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  > p {
    margin: 0;
  }
`

interface SaveViewProps {
  searchFields: SearchFields
  onCreateView: (args: any) => Promise<any>
  onClose: () => void
}

const SaveViewModal = ({
  searchFields,
  onCreateView,
  onClose,
}: SaveViewProps) => {
  const { filter, filterField, searchValue, searchField, q } = searchFields
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isError, setIsError] = useState(false)
  const [inputValue, setInputValue] = useState(
    t('sheet.toolbar.saveView.nameViewInput.placeholder', {
      timestamp: new Date().toISOString().slice(0, 10),
    })
  )

  const saveView = async () => {
    setIsSaving(true)
    setIsError(false)

    try {
      await onCreateView({
        name: inputValue,
        config: {
          q,
          filterField,
          searchValue,
          searchField,
          filter,
        },
      })
      setIsSaved(true)
      setIsSaving(false)
    } catch (error) {
      logger.error('Unable to save view for filters', {
        ...searchFields,
        error,
      })
      setIsSaving(false)
      setIsError(true)
    }
  }

  const handleInputChange = (value: string) => {
    setIsError(false)
    setInputValue(value)
  }

  const content = (
    <>
      <HeaderContainer>
        <div>
          <ModalHeader>
            {!isSaved ? (
              t('sheet.toolbar.saveView.headings.saveHeader')
            ) : (
              <>
                {t('sheet.toolbar.saveView.headings.shareHeader')}
                <em>{inputValue}</em>
              </>
            )}
          </ModalHeader>
          <ModalText>
            {t('sheet.toolbar.saveView.headings.saveSubheader')}
          </ModalText>
        </div>
      </HeaderContainer>
      <ContentContainer>
        <SectionContainer>
          {!isSaved && (
            <FormGroup>
              <FormLabel>
                {t('sheet.toolbar.saveView.nameViewInput.label')}
              </FormLabel>
              <TextInput value={inputValue} onChange={handleInputChange} />
              <HelpLabel>
                {t('sheet.toolbar.saveView.nameViewInput.helperText')}
              </HelpLabel>
            </FormGroup>
          )}
          <SummaryBox>
            <SummaryTitle>
              {t('sheet.toolbar.saveView.summary.title')}
            </SummaryTitle>
            <SummaryText>
              <SummaryList>
                {filter && (
                  <li>
                    <SummaryTextItem>
                      <p>{t('sheet.toolbar.saveView.summary.filter')}</p>
                      <b>
                        {filter === 'error'
                          ? t('sheet.toolbar.saveView.summary.invalid')
                          : t('sheet.toolbar.saveView.summary.valid')}
                      </b>
                    </SummaryTextItem>
                  </li>
                )}
                {filterField && (
                  <li>
                    <SummaryTextItem>
                      <p>
                        {t('sheet.toolbar.saveView.summary.filterFieldpt1')}
                      </p>
                      <b>{filterField}</b>
                      <p>
                        {t('sheet.toolbar.saveView.summary.filterFieldpt2')}
                      </p>
                    </SummaryTextItem>
                  </li>
                )}
                {searchValue && (
                  <li>
                    <SummaryTextItem>
                      <p>{t('sheet.toolbar.saveView.summary.searchValue')}</p>
                      <b>{searchValue}</b>
                    </SummaryTextItem>
                  </li>
                )}
                {q && (
                  <li>
                    <SummaryTextItem>
                      <p>{t('sheet.toolbar.saveView.summary.q')}</p>
                      <b>{q}</b>
                    </SummaryTextItem>
                  </li>
                )}
              </SummaryList>
            </SummaryText>
          </SummaryBox>
        </SectionContainer>
      </ContentContainer>
      {isError && (
        <Typography color='var(--color-danger)'>
          {t('sheet.toolbar.saveView.error.saveText')}
        </Typography>
      )}
      <ModalButtonRow>
        <ModalCloseButton
          onClose={onClose}
          label={t('sheet.toolbar.saveView.buttons.cancel')}
        />
        {isSaved ? (
          <CopyViewButton onCopyComplete={onClose} />
        ) : (
          <Button
            onPress={saveView}
            label={
              isSaving
                ? t('sheet.toolbar.saveView.buttons.save.labelActive')
                : t('sheet.toolbar.saveView.buttons.save.label')
            }
            variant='primary'
          />
        )}
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='save-view-modal' />
}

interface CopyViewButtonProps {
  onCopyComplete: () => void
}

const CopyViewButton = ({ onCopyComplete }: CopyViewButtonProps) => {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()
  const copyButtonText = t('sheet.toolbar.saveView.buttons.copy.label')
  const copySuccessButtonText = t(
    'sheet.toolbar.saveView.buttons.copy.successLabel'
  )
  const copyButtonWarningTooltip = t(
    'sheet.toolbar.saveView.buttons.copy.warningTooltip'
  )

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
        onCopyComplete()
      }, 2000)
    }
  }, [copied])

  const handleCopy = () => {
    const urlToCopy = window.location.href
    copyToClipboard(urlToCopy)
    setCopied(true)
  }

  return (
    <Button
      onPress={handleCopy}
      iconLeft={copied ? 'checkmark' : 'documentCopy'}
      label={copied ? copySuccessButtonText : copyButtonText}
      variant='primary'
      data-tooltip-content={copyButtonWarningTooltip}
      data-tooltip-id='copy-to-share'
    />
  )
}

export { SaveViewModal }
