import { JobConfig } from '@flatfile/api'
import { useContext, useState, useCallback } from 'react'
import { SpaceContext } from '../contexts/SpaceContext'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { EnvironmentsContext } from '../contexts/EnvironmentsContext'

export const useConnections = () => {
  const { account } = useContext(EnvironmentsContext)
  const { space, httpClient } = useContext(SpaceContext)
  const [linkToken, setLinkToken] = useState('')
  const onSuccess = useCallback(async (publicToken: string) => {
    // Initiate job to create connected workbook
    const jobConfig = {
      type: 'space',
      operation: 'createConnectedWorkbook',
      status: 'ready',
      source: space.id,
      environmentId: space.environmentId,
      input: {
        publicToken: publicToken,
      },
      trigger: 'immediate',
    } as JobConfig
    await httpClient.createJob({
      jobConfig: jobConfig,
    })
  }, [])
  const { open } = useMergeLink({
    linkToken: linkToken,
    onSuccess,
  })
  const openMerge = async () => {
    const endUserEmailAddress = account?.name || ''
    // TODO use team name when we have access to it
    const endUserOrganizationName = endUserEmailAddress
    const endUserOriginId = account?.id || ''
    const token = await httpClient.createConnectionLink({
      spaceId: space.id,
      connectionLinkBody: {
        endUserEmailAddress,
        endUserOrganizationName,
        endUserOriginId,
        categories: [
          'hris',
          'ats',
          'accounting',
          'ticketing',
          'crm',
          'mktg',
          'filestorage',
        ],
      },
    })
    setLinkToken(token.data!.token!)
    open()
  }
  return {
    openMerge,
  }
}
