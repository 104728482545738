import { EnvironmentsContext } from '@/contexts/EnvironmentsContext'
import { Icon } from '@flatfile/design-system'
import { Secret, SecretsList } from '@flatfile/shared-ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { PageHeader } from '../../components/PageHeader'
import { getRuntimeParameters } from '../../components/RuntimeParameters'
import { SpaceContext } from '../../contexts/SpaceContext'
import { MainContent } from '../../elements/MainContent'

const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    text-decoration: underline;
  }
`

const { returnUrl } = getRuntimeParameters()

export const SecretsView = () => {
  const [env_secrets, setEnvSecrets] = useState<Secret[]>([])
  const [space_secrets, setSpaceSecrets] = useState<Secret[]>([])
  const overridingKeys = space_secrets.map((x) => x.name)
  const { httpClient, space } = useContext(SpaceContext)
  const { environment } = useContext(EnvironmentsContext)
  const hasEnvAccess = environment ? environment.hasAccess !== false : false
  const params = {
    ...(hasEnvAccess && {
      environmentId: environment?.id,
    }),
    spaceId: space?.id,
  }

  const { data, isLoading, isError, refetch } = useQuery(
    ['getSecrets'],
    async () => {
      return await httpClient.getSecrets(params)
    },
    {
      retry: false,
      keepPreviousData: true,
    }
  )

  const { mutateAsync: deleteSecret } = useMutation(
    ['delete secret'],
    async (secretId: string) => {
      await httpClient.deleteSecrets({
        secretId,
      })
      await refetch()
    }
  )

  const { mutateAsync: upsertSecret } = useMutation(
    ['upsert secret'],
    async ({ name, value }: { name: string; value: string }) => {
      await httpClient.upsertSecret({
        writeSecret: {
          ...params,
          name: name,
          value: value,
        },
      })
      await refetch()
    }
  )

  const secretsEnv =
    data && data.data ? data.data.filter((x) => !x.spaceId) : []
  const secrets = data && data.data ? data.data.filter((x) => !!x.spaceId) : []

  return (
    <MainContent>
      <PageHeader title='Secrets' />

      <ListsContainer>
        {hasEnvAccess && (
          <SecretsList
            title={
              (
                <StyledLink href={returnUrl + '/developers'} target='_top'>
                  Environment Secrets <Icon size={16} name='arrowUpRight' />
                </StyledLink>
              ) as any
            }
            secrets={secretsEnv}
            loading={isLoading}
            error={isError}
            deleteSecret={deleteSecret}
            upsertSecret={upsertSecret}
            overridingKeys={overridingKeys}
            emptyStatePhrase={
              <>
                There are no environment level secrets. Add one{' '}
                <StyledLink href={returnUrl + '/developers'} target='_top'>
                  here
                </StyledLink>
                .
              </>
            }
          />
        )}
        <SecretsList
          key={JSON.stringify(secrets)}
          title='Space Secrets'
          secrets={secrets}
          loading={isLoading}
          error={isError}
          deleteSecret={deleteSecret}
          upsertSecret={upsertSecret}
          editable
          emptyStatePhrase='There are no space level secrets yet, click on the plus icon to add one.'
        />
      </ListsContainer>
    </MainContent>
  )
}
