import { FileController } from '@/api/controllers/FileController'
import { SheetMap } from '@/api/controllers/JobController'
import { PlanController } from '@/api/controllers/PlanController'
import { RuleController } from '@/api/controllers/RuleController'
import { WorkbookController } from '@/api/controllers/WorkbookController'
import { useController } from '@/api/controllers/useController'
import { updateObservable, useAction } from '@/api/observable'
import { MappingProgramPage } from '@/apps/JobsApp/map/pages/MappingProgramPage'
import { SheetActionBar } from '@/components/SheetActionBar'
import { Features } from '@/contexts/EnvironmentsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { ActionContainer } from '@/elements/ActionContainer'
import { DataPreview } from '@/elements/DataPreview'
import {
  DataPreviewPanel,
  DataPreviewWrapper,
} from '@/elements/MappingComponents'
import { PoweredByFlatfile } from '@/elements/PoweredByFlatfile'
import { SubHeader } from '@/elements/SubHeader'
import { useEntitlements } from '@/hooks/useEntitlements'
import { useEventCallbacks } from '@/hooks/useEventCallbacks'
import { useNavigate } from '@/hooks/useNavigate'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { logger } from '@/utils/logging'
import {
  Environment,
  GetRecordsResponseData,
  Job,
  Plan,
  RecordsWithLinks,
  Sheet,
} from '@flatfile/api'
import { forceArray } from '@flatfile/design-system'
import { uniq } from 'lodash'
import Postmate from 'postmate'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ClearRulesModal } from './components/ClearRulesModal'
import { MapEnumsPage } from './pages/MapEnumsPage'
import { MapFieldsPage } from './pages/MapFieldsPage'
import { MapHeadersPage } from './pages/MapHeadersPage'
import { NavigationState } from './types'

const NoData = styled.span`
  color: var(--color-midnight-400);
`

const buildPreview = (
  records: RecordsWithLinks,
  sourceSheet: Sheet
): Record<string, string[]> => {
  const preview: Record<string, string[]> = {}
  const meta = (sourceSheet?.metadata as { [key: string]: any[] }) ?? {}
  const exclusions = meta['excludedRowIds'] ?? []
  const recordsWithoutExclusions = records.filter(
    (r) => !exclusions.includes(r.id)
  )
  recordsWithoutExclusions.forEach((record) => {
    Object.entries(record.values).forEach(([key, { value }]) => {
      if (!preview[key]) {
        preview[key] = []
      }
      if (preview[key].length === 10) {
        return
      }
      if (value !== null && preview[key].length < 10) {
        preview[key] = uniq([...preview[key], value as string])
      }
    })
  })
  return preview
}

/**
 * Handle the different mapping steps and moving between them as well as the
 * final submission job.
 *
 * @param jobController
 * @param plan
 * @param sheets
 * @param spaceUrl
 */
export function MapRouter({
  job,
  plan,
  sheets,
  spaceUrl,
  recordData,
  headerRecordData,
  environment,
  onResetMapping,
}: Props_MapRouter) {
  const MAP_STEP = {
    FIELDS: 0,
    CATEGORIES: 1,
    HEADERS: 2,
  }
  const { httpClient, space } = useContext(SpaceContext)
  const [pendingRequests, setPendingRequests] = useState<number>(0)
  const [index, setIndex] = useState(MAP_STEP.FIELDS)
  const { hasEntitlement } = useEntitlements()
  const whitelabeling = hasEntitlement('whitelabeling')
  const headerSelection = hasEntitlement('headerSelection')
  const [navState, setNavState] = useState<NavigationState>({
    showBack: false,
    showContinue: false,
    showComplete: false,
  })
  const [handshake, setHandshake] = useState<Postmate | null>(null)
  const [iframeLoading, setIframeLoading] = useState(true)

  const workbookController = useController(WorkbookController, httpClient)
  const controller = useController(
    PlanController,
    plan,
    job,
    sheets,
    environment,
    httpClient,
    (n: number) => {
      setPendingRequests(n)
    }
  )
  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )
  const navigate = useNavigate()
  const { t } = useTypedTranslation()
  const [clearRulesConfirmation, setClearRulesConfirmation] = useState(false)
  const [clearRules, ruleClearer] = useAction(
    controller.clearRules(),
    (data) => {
      updateObservable('plan', data)
      setClearRulesConfirmation(false)
    },
    'clearRules'
  )
  const [generateNewMapping, generateNewMappingAction] = useAction(
    fileController.mapToSheet(),
    (job) => {
      onResetMapping(job.id)
      navigate(`/space/${space.id}/job/${job.id}`, {
        replace: true,
      })
      setIndex(MAP_STEP.FIELDS)
    },
    'updateMapping'
  )
  const [updateHeader, updateHeaderAction] = useAction(
    controller.src.updateHeaderRow().lazy,
    () => {
      generateNewMapping({
        sourceWorkbookId: controller.job.source,
        sourceSheetId: controller?.sheets.src.id,
        destinationWorkbookId: controller.job.destination!,
        destinationSheetId: controller?.sheets.dest.id,
      })
    },
    'updateHeader'
  )

  const rules = useMemo(() => controller.getRules(), [controller.plan])
  const [previewKey, setPreviewKey] = useState<string>()
  const [sourceKey, setSourceKey] = useState<string>()
  const [headerRow, setHeaderRow] = useState<number>()

  const hasPreview = index === MAP_STEP.FIELDS || index === MAP_STEP.CATEGORIES
  const stepConfig = {
    [MAP_STEP.FIELDS]: {
      header: t('mapping.mapFields.title'),
    },
    [MAP_STEP.CATEGORIES]: {
      header: t('mapping.mapEnums.title'),
    },
    [MAP_STEP.HEADERS]: {
      header: t('mapping.mapHeaders.title'),
    },
  }

  const emptyPreview = Array.from({ length: 10 }, (_, i) =>
    i === 0 ? (
      <NoData key={i}>{t('mapping.dataPreview.noData')}</NoData>
    ) : (
      <span key={i}>&nbsp;</span>
    )
  )

  const previewData = useMemo(() => {
    const previewMap = buildPreview(recordData.records, sheets.src)
    const items = previewKey ? forceArray(uniq(previewMap[previewKey])) : []
    const onlyEmptyValues = items.filter((v) => !!v).length === 0

    return onlyEmptyValues ? emptyPreview : items
  }, [previewKey, recordData, sheets.src, emptyPreview])

  const [getWorkbookCounts] = useAction(
    // TODO refactor this using useQueryGetWorkbookById
    workbookController.getAllWorkbooksWithCounts().lazy,
    (data) => {
      updateObservable('workbookWithCounts', data)
    }
  )

  const continueOnSuccess = useCallback(() => {
    const destSheet = sheets.dest.id
    navigate(`${spaceUrl}/workbook/${job.destination}/sheet/${destSheet}`)
    getWorkbookCounts({
      spaceId: space.id,
    })
  }, [job, sheets.dest.id, navigate, spaceUrl, getWorkbookCounts, space.id])

  const [executeJob, jobAction] = useAction(
    controller.execute(),
    continueOnSuccess
  )

  useEffect(() => {
    const handleError = () => {
      alert(jobAction.error)
    }
    jobAction.addEventListener('error', handleError)
    return () => {
      jobAction.removeEventListener('error', handleError)
    }
  }, [jobAction])

  const { onFocus } = useEventCallbacks('MapRouter', {
    onFocus(controller: RuleController) {
      setPreviewKey(controller.previewProp)
      setSourceKey(controller.srcProp?.key)
    },
  })

  const isRecomputing = pendingRequests > 0

  const mappingProgramsEnabled =
    (environment?.features as Features)?.mappingPrograms ?? false

  const handleClearRules = () => {
    clearRules({ rules })
  }

  const handleClearRulesConfirmation = (toggle: boolean) => {
    setClearRulesConfirmation(toggle)
  }

  const handleUpdateHeader = () => {
    setIndex(MAP_STEP.HEADERS)
  }

  const handleHeaderRowChange = (headerRow: number) => {
    setHeaderRow(headerRow)
  }

  const isMappingProgram = mappingProgramsEnabled && plan.programId

  const onBack = useCallback(() => {
    if (isMappingProgram) {
      if (navState.showBack && handshake) {
        return handshake
          .then((child) => child.call('onBack'))
          .catch((error) => {
            logger.error('Call to onBack failed', error)
          })
      } else {
        navigate(-1)
      }
    } else {
      if (index !== MAP_STEP.FIELDS) {
        setIndex(MAP_STEP.FIELDS)
      } else {
        navigate(-1)
      }
    }
  }, [isMappingProgram, navState.showBack, handshake, index, navigate])

  const onContinue = useCallback(() => {
    if (isMappingProgram) {
      if (navState.showContinue && handshake && index !== MAP_STEP.HEADERS) {
        return handshake
          .then((child) => child.call('onContinue'))
          .catch((error) => {
            logger.error('Call to onContinue failed', error)
          })
      } else if (index === MAP_STEP.HEADERS && headerRow !== undefined) {
        updateHeader({ headerRow })
        return Promise.resolve()
      } else {
        executeJob()
      }
    } else {
      const enumRules = controller.getRules({ enum: true })
      if (index === MAP_STEP.FIELDS && enumRules.length) {
        setIndex(MAP_STEP.CATEGORIES)
      } else if (index === MAP_STEP.HEADERS && headerRow !== undefined) {
        updateHeader({ headerRow })
      } else {
        executeJob()
      }
    }
  }, [
    isMappingProgram,
    navState.showContinue,
    handshake,
    index,
    headerRow,
    updateHeader,
    controller,
    executeJob,
  ])

  return mappingProgramsEnabled && plan.programId ? (
    <>
      <SheetActionBar
        headerText={t('mapping.mapFields.title')}
        headers={
          headerSelection
            ? {
                label: t('mapping.headerButtons.headers'),
                onPress: () => {
                  setIndex(MAP_STEP.HEADERS)
                },
                disabled: true,
              }
            : undefined
        }
        exit={{
          label: t('mapping.headerButtons.exit'),
          onPress: () => navigate(spaceUrl),
        }}
        back={{
          label: t('mapping.headerButtons.back'),
          onPress: onBack,
          disabled: iframeLoading,
        }}
        next={{
          label:
            index === MAP_STEP.HEADERS
              ? t('mapping.headerButtons.next')
              : navState.showContinue
              ? t('mapping.headerButtons.next')
              : t('mapping.headerButtons.complete'),
          onPress: onContinue,
          loading:
            jobAction.isLoading ||
            isRecomputing ||
            generateNewMappingAction.isLoading ||
            updateHeaderAction.isLoading ||
            (navState.showComplete && jobAction.isLoading),
        }}
      />

      <ActionContainer>
        {index === MAP_STEP.HEADERS ? (
          <MapHeadersPage
            sheet={sheets.src}
            records={recordData.records}
            onChangeHeaderRow={handleHeaderRowChange}
          />
        ) : (
          <MappingProgramPage
            httpClient={httpClient}
            job={job}
            plan={plan as Plan & { programId: string }}
            onComplete={executeJob}
            onNavigationChange={(state) => setNavState(state)}
            handshake={handshake}
            setHandshake={(handshake) => setHandshake(handshake)}
            setIframeLoading={setIframeLoading}
          />
        )}
      </ActionContainer>
    </>
  ) : (
    <>
      <SheetActionBar
        headerText={stepConfig[index].header}
        exit={{
          label: t('mapping.headerButtons.exit'),
          onPress: () => navigate(spaceUrl),
        }}
        back={{ label: t('mapping.headerButtons.back'), onPress: onBack }}
        next={{
          label: t('mapping.headerButtons.next'),
          onPress: onContinue,
          loading:
            jobAction.isLoading ||
            isRecomputing ||
            generateNewMappingAction.isLoading ||
            updateHeaderAction.isLoading,
        }}
      />
      <ActionContainer>
        {index === MAP_STEP.FIELDS && (
          <MapFieldsPage
            controller={controller}
            onFocus={onFocus}
            onClearRules={handleClearRulesConfirmation}
            onUpdateHeader={headerSelection ? handleUpdateHeader : undefined}
          />
        )}
        {index === MAP_STEP.CATEGORIES && (
          <MapEnumsPage controller={controller} onFocus={onFocus} />
        )}
        {index === MAP_STEP.HEADERS && (
          <MapHeadersPage
            sheet={sheets.src}
            records={headerRecordData.records}
            onChangeHeaderRow={handleHeaderRowChange}
          />
        )}
        {hasPreview && (
          <DataPreviewPanel>
            {previewKey && (
              <DataPreviewWrapper>
                <SubHeader data-testid={'data-preview'}>
                  {t('mapping.dataPreview.heading', { previewKey: sourceKey })}
                </SubHeader>
                {previewData && previewData.length ? (
                  previewData.map((x, i) => (
                    <DataPreview key={i}>{x}</DataPreview>
                    /* c8 ignore start */
                  ))
                ) : (
                  <DataPreview>
                    <NoData>{t('mapping.dataPreview.noData')}</NoData>
                  </DataPreview>
                  /* c8 ignore stop */
                )}
              </DataPreviewWrapper>
            )}
          </DataPreviewPanel>
        )}
        {!whitelabeling && <PoweredByFlatfile spaceId={space.id} />}
      </ActionContainer>
      {clearRulesConfirmation && (
        <ClearRulesModal
          onClose={() => setClearRulesConfirmation(false)}
          onConfirm={handleClearRules}
          loading={ruleClearer.isLoading}
        />
      )}
    </>
  )
}

type Props_MapRouter = {
  job: Job
  recordData: GetRecordsResponseData
  headerRecordData: GetRecordsResponseData
  sheets: SheetMap
  plan: Plan
  spaceUrl: string
  environment: Environment | undefined
  onResetMapping: (jobId: string) => void
}
