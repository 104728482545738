import { FlatfileRequestType } from './types'

export const spacesQueries = {
  getAllSpaces: {
    generateQueryKey: (params: FlatfileRequestType<'getAllSpaces'>) => {
      if (params) {
        const { isCollaborative, environmentId, ...otherParams } = params
        return [
          'getAllSpaces',
          environmentId,
          isCollaborative,
          ...Object.values(otherParams),
        ]
      }
      return ['getAllSpaces']
    },
    // TODO add events
    influencingEvents: {},
  },
  getSpaceById: {
    generateQueryKey: (params: FlatfileRequestType<'getSpaceById'>) => [
      'getSpaceById',
      params,
    ],
    // TODO add events
    influencingEvents: {},
  },
}
