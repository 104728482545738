import { ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--color-page);
  bottom: 0;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: ${ZIndex.level1200};
`
