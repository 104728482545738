import { documentsQueries } from '@/api/queries/documents.queries'
import { environmentsQueries } from '@/api/queries/environments.queries'
import { useQueryInitialResources } from '@/api/queries/resources/useQueryInitialResources'
import { sheetsQueries } from '@/api/queries/sheets.queries'
import { spacesQueries } from '@/api/queries/spaces.queries'
import { workbooksQueries } from '@/api/queries/workbooks.queries'
import { resources } from '@/api/resources'
import { SpacesUISkeleton } from '@/components/SpacesUISkeleton'
import { Features } from '@/contexts/EnvironmentsContext'
import { JobOperationEnum, JobsContext } from '@/contexts/JobsContext'
import ParagonContext from '@/contexts/ParagonContext'
import { PermissionsContext } from '@/contexts/PermissionsContext'
import { UserContextProvider } from '@/contexts/UserContext'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { usePubnubMutations } from '@/hooks/usePubNubMutations'
import { useTimerRef } from '@/hooks/useTimerRef'
import { PubNubConnectionContext } from '@/pubnub/PubNubConnectionProvider'
import { logger } from '@/utils/logging'
import { datadogRum } from '@datadog/browser-rum'
import {
  Document,
  EventTopic,
  JobStatusEnum,
  Sheet,
  Workbook,
} from '@flatfile/api'
import { useQueryClient } from '@tanstack/react-query'
import { paragon } from '@useparagon/connect'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const suppressDevWarnings =
  import.meta.env.VITE_SUPPRESS_DEV_WARNINGS === 'true'

/**
 * States a space can be in during first configuration (on create)
 *
 * - PENDING - App is mounted, but we check jobs to see if there are any configure Jobs in progress
 * - CONFIGURING - Jobs queue has at least 1 configure job that is still in progress
 * - CONFIGURED - Jobs queue has no active configure jobs - therefore we can be certain config is complete
 *
 */
enum SpaceConfigureState {
  PENDING = 'PENDING',
  CONFIGURING = 'CONFIGURING',
  CONFIGURED = 'CONFIGURED',
}

/**
 * Initializer component used to perform the initial fetch of resources needed
 * to power the spaces-UI. The set of initial-resources fetched are used to
 * hydrate caches - which prevents unnecessary fetching when the remainder of the
 * application loads. Each resource is kept up to date long-term via event streams
 * associated to each specific resource (see useFlatfileQuery)
 */
const SpaceHydrator = ({ children }: { children: React.ReactNode }) => {
  const { spaceId, workbookId, sheetId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { clearTimer, setTimer } = useTimerRef()
  const { activeJobs } = useContext(JobsContext)
  const [hydrated, setHydrated] = useState<boolean>(false)
  const [firstEventReceived, setFirstEventReceived] = useState<boolean>(false)
  const [spaceConfigured, setSpaceConfigured] = useState<SpaceConfigureState>(
    SpaceConfigureState.PENDING
  )
  const { data: initialResources, isRefetching: isFetchingInitialResources } =
    useQueryInitialResources(spaceId!, {
      staleTime: Infinity,
      onError: (error) => {
        setSpaceConfigured(SpaceConfigureState.CONFIGURED)
        setHydrated(true)
        logger.error('SpaceHydrator: Unable to fetch initial resources', error)
      },
    })
  const { fetchPubnubMessages } = usePubnubMutations(spaceId!)
  const { checkAndGetEarliestToken, didReplay, broadcastEvent } = useContext(
    PubNubConnectionContext
  )

  const permissions = useMemo(() => {
    return initialResources?.permissions ?? {}
  }, [initialResources?.permissions])

  const invokeParagon = async (paragonToken: string) => {
    // this should be called in the background, no need to wait for it
    paragon
      .authenticate(import.meta.env.VITE_PARAGON_PROJECT_ID, paragonToken)
      .catch((e) => {
        logger.error('SpaceHydrator:Failed to authenticate with Paragon', e)
      })
  }

  const relayMessages = useCallback(
    (event: any, msg: Record<string, any>) => {
      const features = initialResources?.environment?.features as Features
      const enableLogs = features?.pubnubEventLogs
      setFirstEventReceived(true)
      broadcastEvent(event, msg, true, enableLogs)
    },
    [initialResources, broadcastEvent]
  )

  const getHistoricalMessages = useCallback(() => {
    const isEmbedded = window !== window.parent
    const date = new Date()
    if (
      initialResources?.space &&
      initialResources?.environment &&
      isEmbedded &&
      !didReplay.current &&
      date.getTime() - initialResources.space.createdAt.getTime() < 60000
    ) {
      didReplay.current = true
      const features = initialResources?.environment?.features as Features
      const enableLogs = features?.pubnubEventLogs
      const checkDate = new Date()
      const timeToken = checkDate.getTime() + '9999'
      const earliestTimeToken = checkAndGetEarliestToken(timeToken)
      fetchPubnubMessages({
        request: {
          channels: [`space.${spaceId}`],
          start: earliestTimeToken,
        },
        callback: relayMessages,
        enableLogs,
      })
    } else {
      const features = initialResources?.environment?.features as Features
      const enableLogs = features?.pubnubEventLogs
      if (isEmbedded && enableLogs) {
        logger.info('PubNub Info: Fetch of prior messages skipped', {
          currentTime: date.getTime(),
          initialResources,
          didReplay: didReplay.current,
        })
      }
    }
  }, [initialResources, spaceId, fetchPubnubMessages, relayMessages])

  useEffect(() => {
    if (spaceConfigured === SpaceConfigureState.CONFIGURED && spaceId) {
      if (initialResources && !isFetchingInitialResources) {
        const {
          documents,
          environment,
          workbooks,
          entitlements,
          space,
          paragonToken,
          actor,
        } = initialResources
        if (documents) {
          const docsKey = documentsQueries.getSpaceDocuments.generateQueryKey({
            spaceId,
          })
          queryClient.setQueryData(docsKey, {
            data: documents,
          })
          documents.forEach((dc: Document) => {
            const documentKey =
              documentsQueries.getDocumentById.generateQueryKey({
                documentId: dc.id,
              })
            queryClient.setQueryData(documentKey, {
              data: dc,
            })
          })
        }

        if (workbooks) {
          const workbooksKey =
            workbooksQueries.getAllWorkbooks.generateQueryKey({
              spaceId,
            })
          queryClient.setQueryData(workbooksKey, {
            data: workbooks,
          })
          workbooks.forEach((wb: Workbook) => {
            const workbookKey =
              workbooksQueries.getWorkbookById.generateQueryKey({
                workbookId: wb.id,
              })
            queryClient.setQueryData(workbookKey, {
              data: wb,
            })
          })
        }

        if (environment && environment.id) {
          const environmentsKey =
            environmentsQueries.getEnvironmentById.generateQueryKey({
              environmentId: environment.id,
            })
          queryClient.setQueryData(environmentsKey, { data: environment })
        }

        if (entitlements) {
          queryClient.setQueryData(
            ['getEntitlementsForResource', { resourceId: spaceId }],
            {
              data: entitlements,
            }
          )
        }

        if (space) {
          const spaceKey = spacesQueries.getSpaceById.generateQueryKey({
            spaceId: space.id,
          })
          queryClient.setQueryData(spaceKey, {
            data: space,
          })
        }

        if (workbookId && workbooks) {
          const currentWorkbook = workbooks.find(
            (wb: Workbook) => wb.id === workbookId
          )
          currentWorkbook && resources.set(workbookId, currentWorkbook)

          if (sheetId && currentWorkbook && currentWorkbook.sheets) {
            const currentSheet = currentWorkbook.sheets.find(
              (sh: Sheet) => sh.id === sheetId
            )
            const sheetKey = sheetsQueries.getSheet.generateQueryKey({
              sheetId,
            })
            currentSheet && resources.set(sheetId, currentSheet)
            queryClient.setQueryData(sheetKey, {
              data: currentSheet,
            })
          }
        }

        if (paragonToken) {
          invokeParagon(paragonToken)
        }

        if (actor) {
          logger.setContext({
            user: actor.id,
            account:
              'accountId' in actor ? actor.accountId : environment?.accountId,
            environment: environment?.id,
            space: space.id,
          })
          datadogRum.setUser({
            user: actor.id,
            account:
              'accountId' in actor ? actor.accountId : environment?.accountId,
            environment: environment?.id,
            space: space.id,
          })
        }
        setHydrated(true)
      }
    }
  }, [spaceConfigured, spaceId, initialResources, isFetchingInitialResources])

  useEffect(() => {
    if (activeJobs && spaceConfigured !== SpaceConfigureState.CONFIGURED) {
      const configureJob = activeJobs?.find(
        (job) => job.operation === JobOperationEnum.Configure
      )

      if (
        configureJob &&
        (configureJob.status === JobStatusEnum.Executing ||
          configureJob.status === JobStatusEnum.Ready)
      ) {
        setSpaceConfigured(SpaceConfigureState.CONFIGURING)
      } else {
        setSpaceConfigured(SpaceConfigureState.CONFIGURED)
      }
    }
  }, [activeJobs, spaceConfigured])

  /**
   * When a space is set to AutoConfigure (i.e. Listeners are running to update the space during
   * initial configuration) - Then we will block the rest of the UI from loading while the configure
   * job remains in progress. We do this as there are changes to the space config which could be running -
   * such as setting the defaultPage - that will influence the initial view the user sees. Thus, we must
   * wait for the configure job to complete - and take the appropriate action depending on the outcome
   */
  useEffect(() => {
    if (
      initialResources &&
      spaceConfigured !== SpaceConfigureState.CONFIGURED
    ) {
      if (initialResources.space && initialResources.space.autoConfigure) {
        const inProgress = activeJobs?.some(
          (job) =>
            job.operation === JobOperationEnum.Configure &&
            job.status !== JobStatusEnum.Complete &&
            job.type === 'space'
        )

        if (!inProgress) {
          if (spaceConfigured === SpaceConfigureState.CONFIGURING) {
            queryClient.invalidateQueries(['getInitialResources', spaceId])
          }
        }
      }
    }
  }, [initialResources, activeJobs, spaceConfigured])

  /* c8 ignore start */

  /**
   * When a space is set to AutoConfigure (i.e. Listeners are running to update the space during
   * initial configuration) - Then there is an expectation that within a certain duration of 10s,
   * the a Configure Job with a status other than Ready should exist - if it doesn't it is most likely
   * that the listener has not been created, and we re-direct the user to an informative view
   */
  useEffect(() => {
    let idTimeout: any
    if (
      initialResources &&
      spaceConfigured !== SpaceConfigureState.CONFIGURED
    ) {
      if (
        !suppressDevWarnings &&
        initialResources.space &&
        initialResources.space.autoConfigure
      ) {
        idTimeout = setTimeout(() => {
          const acknowledged = activeJobs?.some(
            (job) =>
              job.operation === JobOperationEnum.Configure &&
              job.status !== JobStatusEnum.Ready &&
              job.type === 'space'
          )
          if (!acknowledged) {
            navigate('/empty')
          }
        }, 10000)
      }
    }
    return () => {
      clearTimeout(idTimeout)
    }
  }, [activeJobs, spaceConfigured, initialResources])

  useEventSubscriber('*', relayMessages)

  /**
   * When the first event is received, we will attempt to fetch historical messages. This ensures
   * that the timestamp used for fetching historical messages is back-dated from the first received event
   * which eliminates the possibility of duplicates being fetched/relayed
   */
  useEffect(() => {
    if (firstEventReceived) {
      getHistoricalMessages()
    }
  }, [firstEventReceived, getHistoricalMessages])

  /** The fetching of historical messages is driven via the "first received event" to mitigate the possibility
   * of fetching events based on an incorrect OS time set by the client - However, after a period of 5 seconds
   * if no events have been received, then we will attempt to fetch historical messages for the current time
   */
  useEffect(() => {
    setTimer(
      setTimeout(() => {
        if (!didReplay.current) {
          getHistoricalMessages()
        }
      }, 5000)
    )

    return () => {
      clearTimer()
    }
  }, [getHistoricalMessages])

  /* c8 ignore stop */

  /** Necessary to perform hydration of Space-Configure - However, we perform similar
   * logic within the useJobsToast logic. The hook at it's dependencies however, require a
   * known SPACE (for translations) - We need to consider some refactoring to evaluate exactly
   * where it makes the most sense to handle these pre-configure style jobs and their outcomes
   **/
  useEventSubscriber(
    [
      EventTopic.Jobready,
      EventTopic.Jobupdated,
      EventTopic.Jobcompleted,
      EventTopic.Jobfailed,
    ],
    async (topic, event) => {
      const { payload: job } = event
      if (job.operation === JobOperationEnum.Configure) {
        const jobsResource = await resources.getWithPromise<any>(
          event.context.jobId,
          true
        )
      }
    }
  )

  return (
    <PermissionsContext.Provider value={permissions}>
      <ParagonContext.Provider value={initialResources?.paragonToken}>
        <UserContextProvider actor={initialResources?.actor}>
          {hydrated ? (
            children
          ) : (
            <SpacesUISkeleton
              title={
                spaceConfigured === SpaceConfigureState.CONFIGURING
                  ? initialResources?.space.name
                  : ''
              }
              message={
                spaceConfigured === SpaceConfigureState.CONFIGURING
                  ? 'Preparing Space...'
                  : ''
              }
            />
          )}
        </UserContextProvider>
      </ParagonContext.Provider>
    </PermissionsContext.Provider>
  )
}

export default SpaceHydrator
