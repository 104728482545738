import { Icon, IconName, Typography } from '@flatfile/design-system'
import React from 'react'
import styled from 'styled-components'

/**
 * StatusButton that resides in PrimaryHeader & reveals a panel when open
 * Currently being used for Jobs, Versions & Event Logs
 *
 */

interface StatusButton {
  name?: string // used only for testing
  label?: string
  isActive: boolean
  onClick: () => void
  children?: React.ReactNode
  icon: IconName
  showHoverState: boolean
  setShowHoverState: React.Dispatch<React.SetStateAction<boolean>>
  $bgColor?: string
}

export const StatusButton = ({
  name,
  children,
  isActive,
  onClick,
  label,
  icon,
  showHoverState,
  setShowHoverState,
  $bgColor,
}: StatusButton) => {
  const showLabel = (showHoverState || isActive) && label
  return (
    <StyledButton
      data-testid={`${name}-status`}
      $hover={showHoverState || isActive}
      onMouseEnter={() => setShowHoverState(true)}
      onMouseLeave={() => setShowHoverState(false)}
      onClick={onClick}
      $bgColor={$bgColor}
    >
      {children}
      {showLabel && <Typography type='b1'>{label}</Typography>}
      <Icon name={icon} />
    </StyledButton>
  )
}

const StyledButton = styled.button<{
  $hover: boolean
  $status?: string
  $bgColor?: string
}>`
  background: ${(props) => props.$bgColor};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: var(--pill-border-radius);
  width: fit-content;
  height: 28px;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    fill: ${(props) =>
      props.$hover ? 'var(--color-title)' : 'var(--color-text-ultralight)'};
  }

  div {
    font-weight: 600;
    font-family: var(--text-font);
  }
`
