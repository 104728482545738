import { EventTopic } from '@flatfile/api'
import { QueryClient } from '@tanstack/react-query'
import { FlatfileRequestType } from './types'

export const workbooksQueries = {
  getAllWorkbooks: {
    generateQueryKey: (params: FlatfileRequestType<'getAllWorkbooks'>) => [
      'getAllWorkbooks',
      params,
    ],
    // TODO add influencing events once refetchWorkbooks() is removed from EventSubscriber inside SpaceApp
    influencingEvents: {},
  },
  getWorkbookById: {
    generateQueryKey: (params: FlatfileRequestType<'getWorkbookById'>) => {
      return ['getWorkbookById', params]
    },
    influencingEvents: {
      [EventTopic.Workbookupdated]: (event: any, client: QueryClient) => {
        const workbookId = event.origin.id
        client.invalidateQueries(['getWorkbookById', { workbookId }])
      },
      // TODO: Emit a workbook:updated event on delete of a sheet instead of relying on sheet:deleted
      [EventTopic.Sheetdeleted]: (event: any, client: QueryClient) => {
        const workbookId = event.origin.id
        client.invalidateQueries(['getWorkbookById', { workbookId }])
      },
    },
  },
}
