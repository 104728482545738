import { SpacesUISkeleton } from '@/components/SpacesUISkeleton'
import { useNavigate } from '@/hooks/useNavigate'
import { convertDates } from '@/utils/convertDates'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

/**
 * To optimize performance of embedded portals, Flatfile customers will have the option to "pre-load" iFrames which
 * point to this particular route of Spaces-UI (/space-init). This unauthenticated route ensures to iFrame can
 * download/parse/render spaces-UI assets while the iFrame remains hidden - when the portal is "activated" users are
 * immediately be presented with the "Skeleton" experience while the new space, workbook, and guestToken are generated
 *
 * Once the space is "ready" - the SDK dispatches a postMessage which is then used by this route to navigate to the associated
 * space *
 * @returns Initializer route used by embedded Portals
 */
export function SpaceInitializer() {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const handlePostMessage = (event: any) => {
    const { flatfileEvent } = event.data
    if (!flatfileEvent) return
    if (
      flatfileEvent.topic === 'portal:initialize' &&
      flatfileEvent.payload.status === 'complete' &&
      flatfileEvent.payload.spaceUrl
    ) {
      if (flatfileEvent.payload.initialResources) {
        const { space } = flatfileEvent.payload.initialResources
        const formattedDateResources = convertDates(
          flatfileEvent.payload.initialResources,
          ['options']
        )

        queryClient.setQueryData(
          ['getInitialResources', space.id],
          formattedDateResources
        )
      }
      const { pathname, search } = new URL(flatfileEvent.payload.spaceUrl)
      const cleansedPathname = pathname.startsWith('/s/')
        ? pathname.slice(2)
        : pathname
      nav(cleansedPathname + search)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handlePostMessage, false)
    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, [])

  return <SpacesUISkeleton />
}
