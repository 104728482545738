import { AuthOutcome } from '@/auth/useAuth'
import { HTTPService } from '@/components/HttpClient'
import { getRuntimeParameters } from '@/components/RuntimeParameters'
import { SpacesUISkeleton } from '@/components/SpacesUISkeleton'
import { useNavigate } from '@/hooks/useNavigate'
import { Icon } from '@flatfile/design-system'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-page-gutter);
  height: 100%;
  width: 100%;
  background-color: var(--color-page-dark);
`
const ContentContainer = styled.div`
  margin-top: var(--spacing-page-gutter);
  background-color: #ffffff;
  padding: var(--spacing-actions-gutter);
  width: 500px;
  border-radius: var(--layout-border-radius);
`

const Content = styled.div`
  margin-top: 1em;
  color: var(--color-text);
  .email {
    font-weight: 600;
    background-color: var(--color-electric-100);
    padding: 2px 6px;
  }
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-text-ultralight);
  font-weight: 600;
  font-size: var(--size-h1);
`

const LinkIcon = styled(Icon)`
  color: var(--color-border-strong);
`

const FinePrint = styled.p`
  font-size: var(--size-small);
  color: var(--color-text-light);
`

export const TokenExpirationPage = () => {
  const navigate = useNavigate()
  const { guestEmail, spaceId } = getRuntimeParameters()
  const [emailSent, setEmailSent] = useState(false)
  const { t } = useTypedTranslation()
  const { mutate: exchangeEmail } = useMutation(
    ['exchangeInvitation'],
    () => {
      return HTTPService.exchangeInvitation({
        exchangeInvitationRequest: {
          email: guestEmail,
          spaceId,
        },
      })
    },
    {
      onSuccess: () => {
        setEmailSent(true)
      },
      onError: () => {
        navigate(`/unauthorized?outcome=${AuthOutcome.UNAUTHORIZED}`)
      },
    }
  )

  useEffect(() => {
    if (guestEmail && spaceId) {
      exchangeEmail()
    } else {
      navigate(`/unauthorized?outcome=${AuthOutcome.UNAUTHORIZED}`)
    }
  }, [])

  return (
    <>
      {!emailSent ? (
        <SpacesUISkeleton />
      ) : (
        <PageWrapper>
          <ContentContainer>
            <ContentHeader>
              <span>{t('guestInvite.getNewAccessLink.heading')}</span>
              <LinkIcon name='link' />
            </ContentHeader>
            <Content>
              <p>
                {t('guestInvite.getNewAccessLink.content')}{' '}
                <span className='email'>{guestEmail}</span>
              </p>
              <FinePrint>
                {t('guestInvite.getNewAccessLink.finePrint')}
              </FinePrint>
            </Content>
          </ContentContainer>
        </PageWrapper>
      )}
    </>
  )
}
