import { UsageBanner as SharedUsageBanner } from '@flatfile/shared-ui'
import { useContext } from 'react'

import { EnvironmentsContext } from '../contexts/EnvironmentsContext'
import { usePaymentAccess } from '../hooks/usePaymentAccess'

const Banner = () => {
  const { usageBannerProps } = usePaymentAccess()
  return <SharedUsageBanner {...usageBannerProps} />
}

export const UsageBanner = () => {
  const { environment } = useContext(EnvironmentsContext)
  const showUsageBanner = environment?.isProd

  return showUsageBanner ? <Banner /> : null
}
