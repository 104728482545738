import {
  getRuntimeParameters,
  resetRuntimeParameters,
} from '@/components/RuntimeParameters'
import { parseJWT } from '@/utils/parseJWT'
import { AuthOutcome } from './useAuth'

export class Auth {
  static TOKEN?: string

  public static setToken(token: string) {
    this.TOKEN = token
  }

  private static authTimeoutId: NodeJS.Timeout | undefined

  /**
   * Set a timer to re-authenticate the user 5 minutes before their token expires.
   */
  public static initiateToken(token: string) {
    const { isGuest, guestEmail, returnUrl } = getRuntimeParameters()
    if (this.authTimeoutId) {
      clearTimeout(this.authTimeoutId)
    }

    this.setToken(token)
    const expirationTime = this.getSecondsUntilExpiration(token)

    // Less than 5 minutes logout immediately
    if (expirationTime <= 5 * 60) {
      /**
       * Expired guests should NOT be directed to the traditional login experience
       * -- Guests with an email should receive a new email verification (which should supply an updated token)
       * -- Guests without an email (i.e. embed or shared_link) will simply have their session expired
       */
      if (isGuest) {
        if (guestEmail) {
          window.location.href = `/unauthorized?outcome=${AuthOutcome.EMAIL}`
        } else {
          window.location.href = `/unauthorized?outcome=${AuthOutcome.SESSION_EXPIRED}`
        }
      } else {
        resetRuntimeParameters()
        if (returnUrl?.length) {
          window.location.href = `${new URL(returnUrl).origin}/account/logout`
        }
      }
    }

    // Set a timer for 5m before expiration
    this.authTimeoutId = setTimeout(() => {
      this.initiateToken(token)
    }, (expirationTime - 5 * 60) * 1000)
  }

  /**
   * Utility function which returns the time (in seconds) remaining until the
   * JWT token expires
   *
   * @returns Time (in seconds) until JWT expires
   */
  public static getSecondsUntilExpiration(token: string): number {
    const decodedToken = parseJWT(token)
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const expirationTime = decodedToken?.exp ?? 0
    const timeUntilExpiration = expirationTime - currentTimestamp

    if (currentTimestamp >= expirationTime) {
      return 0
    }

    return timeUntilExpiration
  }
}
