import styled from 'styled-components'

export interface BadgeProps {
  'data-required'?: boolean
  'data-unique'?: boolean
  'data-computed'?: boolean
}

export const Badge = styled.span<BadgeProps>`
  border-radius: var(--layout-border-radius);
  border: 1px solid var(--color-border);
  font-style: normal;
  font-weight: 600;
  font-size: var(--size-small);
  line-height: 1;
  padding: 0.25em 0.5em;
  word-break: normal;

  &[data-required] {
    color: var(--color-danger);
  }

  &[data-unique] {
    color: var(--color-text-light);
  }
`
