import { useDownloadFiles } from '@/apps/FilesApp/hooks/useDownloadFiles'
import { setColumnVisibility } from '@/apps/WorkbookApp/utils/setColumnVisibility'
import { STRIPPED_MARKDOWN_OPTIONS, preprocessMarkdown } from '@/utils/markdown'
import { Action, Job, JobStatusEnum } from '@flatfile/api'
import { Icon, Spinner, getFont } from '@flatfile/design-system'
import {
  CheckmarkIcon,
  Panel,
  PanelContent,
  PanelItem,
  PanelSectionHeader,
  WarningIcon,
} from '@flatfile/shared-ui'
import { format } from 'date-fns'
import i18next from 'i18next'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { default as styled } from 'styled-components'
import { useResource } from '../../api/resources'
import { guard } from '../../api/resources/GuardResources'
import { JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ActionLink } from '../../elements/ActionLink'
import { useCancelJob } from '../../hooks/useCancelJob'
import { useRetryJob } from '../../hooks/useRetryJob'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { differenceInTime } from '../../utils/differenceInTime'
import { downloadFromURL } from '../../utils/downloadFromURL'
import { typedT } from '../../utils/typedTranslation'
import { TruncatedSpan } from '../TruncatedSpan'
import { CopyIdButton } from './CopyIdButton'
import { Countdown } from './Countdown'
import { LinkIcon } from './LinkIcon'
import {
  extractJobOutcomeDetails,
  getJobOutcomeTexts,
  getResourceUrl,
} from './useJobsToast'
import { getEstimatedCompletionAt } from './utils'

export const CancelledIcon = styled(Icon)`
  align-self: center;
  stroke: var(--color-danger);
`

const PanelActionLink = styled(ActionLink)<{ color?: string }>`
  color: ${({ color }) => color ?? 'var(--color-primary)'};
  font-size: 14px;
  font-family: var(--text-font);
`

type StringValueOf<T> = T[keyof T] & string

const ActionButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`

export const JobsPanel = ({ onClose }: { onClose: () => void }) => {
  const { space } = useContext(SpaceContext)
  const { activeJobs } = useContext(JobsContext)
  const { t, i18n } = useTypedTranslation()

  const jobs = activeJobs?.sort((a, b) => {
    return b.createdAt.getTime() - a.createdAt.getTime()
  })
  const groupedJobsByDate = jobs?.reduce((acc, job) => {
    const dateObj = new Date(job.createdAt)
    const formattedDate = new Intl.DateTimeFormat(i18n.language, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(dateObj)
    if (!acc[formattedDate]) {
      acc[formattedDate] = []
    }
    acc[formattedDate].push(job)
    return acc
  }, {} as { [key: string]: Job[] })

  const { downloadFiles } = useDownloadFiles()

  return (
    <Panel heading={t('jobs.panel.heading')} onClose={onClose}>
      <PanelContent>
        {jobs?.length === 0 && (
          <div data-testid='jobs-empty-state'>{t('jobs.panel.empty')}</div>
        )}
        {Object.entries(groupedJobsByDate ?? []).map(([date, jobsGroup]) => (
          <div key={date}>
            <PanelSectionHeader
              $color='var(--color-text-ultralight)'
              $borderColor='var(--color-border-light)'
            >
              {date}
            </PanelSectionHeader>
            {jobsGroup.map((job) => (
              <JobDetail
                key={job.id}
                job={job}
                spaceId={space.id}
                downloadFiles={downloadFiles}
              />
            ))}
          </div>
        ))}
      </PanelContent>
    </Panel>
  )
}

const JobStatusContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const JobStatusContent = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`

const JobInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  //hardcoded to make space for one action button
  padding-right: 24px;

  .job-status {
    ${getFont('b0')}
    font-family: var(--text-font);
    font-weight: 600;
  }

  .job-data {
    ${getFont('b1')}
    font-family: var(--text-font);
    line-height: 21px;
    font-weight: 600;
    color: var(--color-text-light);
  }

  .job-times {
    ${getFont('b1')}
    font-family: var(--text-font);
    line-height: 21px;
  }

  .job-starttime {
    margin-right: 8px;
  }
`

const IconWrapper = styled.div`
  width: 20px;

  svg {
    width: 20px;
  }
`

const DownloadLink = styled.a`
  ${getFont('b1')}
  line-height: 21px;
  font-weight: 600;
  color: var(--color-primary);
  cursor: pointer;
`
const NextActionLink = styled.a`
  ${getFont('b1')}
  font-family: var(--text-font);
  line-height: 21px;
  font-weight: 600;
  color: var(--color-primary);
  cursor: pointer;
`

const JobActionIcons = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
`

const JobOutcomeTypography = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  line-height: 21px;
  overflow-wrap: break-word;

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`

const builtinActionNames = [
  'delete-records',
  'extract',
  'map',
  'export',
  'mutate-records',
]
const builtinStatusLines = {
  'delete-records': {
    running: 'jobs.panel.status.delete.inProgress',
    failed: 'jobs.panel.status.delete.failed',
    complete: 'jobs.panel.status.delete.complete',
    canceled: 'jobs.panel.status.delete.cancelled',
    waiting: 'jobs.panel.status.delete.waiting',
  },
  extract: {
    running: 'jobs.panel.status.extract.inProgress',
    failed: 'jobs.panel.status.extract.failed',
    complete: 'jobs.panel.status.extract.complete',
    canceled: 'jobs.panel.status.extract.cancelled',
    waiting: 'jobs.panel.status.extract.waiting',
  },
  map: {
    running: 'jobs.panel.status.mapping.inProgress',
    failed: 'jobs.panel.status.mapping.failed',
    complete: 'jobs.panel.status.mapping.complete',
    canceled: 'jobs.panel.status.mapping.cancelled',
    waiting: 'jobs.panel.status.mapping.waiting',
  },
  export: {
    running: 'jobs.panel.status.export.inProgress',
    failed: 'jobs.panel.status.export.failed',
    complete: 'jobs.panel.status.export.complete',
    canceled: 'jobs.panel.status.export.cancelled',
    waiting: 'jobs.panel.status.export.waiting',
  },
  'mutate-records': {
    running: 'jobs.panel.status.mutate-records.inProgress',
    failed: 'jobs.panel.status.mutate-records.failed',
    complete: 'jobs.panel.status.mutate-records.complete',
    canceled: 'jobs.panel.status.mutate-records.cancelled',
    waiting: 'jobs.panel.status.mutate-records.waiting',
  },
}

const customStatusLines = (operation: string) => {
  return {
    running: (
      <span>
        {`${typedT('jobs.panel.status.default.inProgress')} `}
        <span className='emphasized'>{i18next.t(operation)}</span>
      </span>
    ),
    failed: (
      <span>
        {`${typedT('jobs.panel.status.default.failed.prefix')} `}
        <span className='emphasized'>{i18next.t(operation)}</span>
        {` ${typedT('jobs.panel.status.default.failed.suffix')}`}
      </span>
    ),
    complete: (
      <span>
        {`${typedT('jobs.panel.status.default.complete.prefix')} `}
        <span className='emphasized'>{i18next.t(operation)}</span>
        {` ${typedT('jobs.panel.status.default.complete.suffix')}`}
      </span>
    ),
    canceled: (
      <span>
        {`${typedT('jobs.panel.status.default.canceled.prefix')} `}
        <span className='emphasized'>{i18next.t(operation)}</span>
        {` ${typedT('jobs.panel.status.default.canceled.suffix')}`}
      </span>
    ),
    waiting: (
      <span>
        {`${typedT('jobs.panel.status.default.waiting')} `}
        <span className='emphasized'>{i18next.t(operation)}</span>
      </span>
    ),
  }
}

const statusCases: {
  [k in StringValueOf<typeof JobStatusEnum> | 'waiting']:
    | 'complete'
    | 'failed'
    | 'running'
    | 'canceled'
    | 'waiting'
} = {
  created: 'running',
  planning: 'running',
  scheduled: 'running',
  ready: 'running',
  executing: 'running',
  complete: 'complete',
  failed: 'failed',
  canceled: 'canceled',
  waiting: 'waiting',
}

const icons = {
  complete: <CheckmarkIcon data-testid='success' name='checkmark' />,
  failed: <WarningIcon data-testid='failure' name='alertTriangle' />,
  running: <Spinner color='var(--color-primary)' />,
  canceled: <CancelledIcon data-testid='cancel' name='ban' />,
  waiting: <Icon name='hourglass' />,
}

export const JobDetail = ({
  job,
  spaceId,
  downloadFiles,
}: {
  job: Job
  spaceId: string
  downloadFiles: (files: { fileId: string; fileName?: string }[]) => void
}) => {
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()

  const associatedResourceId =
    (job.operation === 'map' || job.operation === 'export') && job.destination
      ? job.destination
      : job.source

  const [_, resourceObservable] = useResource<{
    id: string
    name: string
    updatedAt: Date
  }>(associatedResourceId, false)

  const actions =
    (resourceObservable.data as any)?.actions ||
    (resourceObservable.data as any)?.config?.actions ||
    []

  const actionName: string =
    actions.find((action: Action) => action.operation === job.operation)
      ?.label ?? job.operation!

  const statusCase = statusCases[job.status!]
  const icon = icons[statusCase]

  const resourceName = guard(resourceObservable, {
    loadingContent: <code>{job.source}</code>,
    errorContent: () => <div>{typedT('jobs.panel.errorLoadingJob')}</div>,
  }) ?? (
    <TruncatedSpan
      text={
        resourceObservable.ensured.data.name
          ? customT(resourceObservable.ensured.data.name)
          : ''
      }
    />
  )

  const fallbackStatusLine = customStatusLines(actionName)[statusCase]
  const statusLine =
    job.operation && builtinActionNames.includes(job.operation) ? (
      <span className='emphasized'>
        {customT(
          builtinStatusLines[job.operation as keyof typeof builtinStatusLines][
            statusCase
          ]
        )}
      </span>
    ) : (
      fallbackStatusLine
    )

  const action = actionForJob(job, spaceId)

  const { cancelJob } = useCancelJob()
  const { retryJob } = useRetryJob()

  const translatedUnits = {
    hours: typedT('jobs.panel.time.units.hour'),
    minutes: typedT('jobs.panel.time.units.minute'),
    seconds: typedT('jobs.panel.time.units.second'),
  }

  const resource = resourceObservable?.data
  const estimatedCompletionAt = getEstimatedCompletionAt(job)
  const {
    outcomeMessage,
    url,
    urlLabel,
    fileName,
    idLabel,
    retryLabel,
    viewLabel,
  } = useMemo(() => getJobOutcomeTexts(job, resource), [job, resource])

  const { files, hidden, sheetId } = extractJobOutcomeDetails(job)

  return (
    <PanelItem key={job.id} $borderColor='var(--color-border-light)'>
      <JobStatusContainer>
        <JobStatusContent>
          <IconWrapper>{icon}</IconWrapper>
          <JobInfo>
            <span data-testid='job-status' className='job-status'>
              {job.status === 'executing' &&
                job.progress !== undefined &&
                job.progress > 0 && (
                  <span data-testid='progress'>{`${job.progress}% `}</span>
                )}
              {statusLine}
            </span>
            <div className='job-times'>
              <span className='job-starttime'>
                {(job.startedAt || job.createdAt) &&
                  t('jobs.panel.time.started', {
                    time: format(job.startedAt || job.createdAt, 'h:mm:ss aa'),
                  })}
              </span>
              <span>
                {job.startedAt &&
                  job.finishedAt &&
                  (job.status === 'failed'
                    ? t('jobs.panel.time.failed')
                    : t('jobs.panel.time.completed')) +
                    differenceInTime(
                      job.startedAt,
                      job.finishedAt,
                      translatedUnits
                    )}
              </span>
              <span>
                {!job.finishedAt && estimatedCompletionAt && (
                  <span>
                    <Countdown
                      targetDate={estimatedCompletionAt}
                      translatedUnits={translatedUnits}
                      translatedPostfix={t('jobs.panel.time.remaining')}
                    />
                  </span>
                )}
              </span>
            </div>
            <div className='job-data'>{resourceName}</div>
            {job.operation === 'export' &&
              job.status !== 'failed' &&
              job.fileId && (
                <div>
                  <NextActionLink
                    onClick={() => downloadFiles([{ fileId: job.fileId! }])}
                  >
                    {t('jobs.panel.action.export')}
                  </NextActionLink>
                </div>
              )}
            {action && resourceObservable.data && (
              <div>
                <PanelActionLink
                  data-testid='action-link'
                  to={action?.to}
                  type='actionSm'
                >
                  {customT(action?.label) /*?? 'Go to resource'*/}
                </PanelActionLink>
              </div>
            )}
            {job.outcome && (
              <JobOutcomeTypography>
                <ReactMarkdown {...STRIPPED_MARKDOWN_OPTIONS}>
                  {preprocessMarkdown(outcomeMessage)}
                </ReactMarkdown>
              </JobOutcomeTypography>
            )}
            {job.outcome?.next?.type === 'url' && (
              <span>
                <NextActionLink
                  href={url}
                  data-testid='next-url-link'
                  target='_blank'
                  rel='noreferrer'
                >
                  {urlLabel} <LinkIcon size={16} name='arrowUpRight' />
                </NextActionLink>
              </span>
            )}
            {job.outcome?.next?.type === 'download' && (
              <div>
                <NextActionLink
                  data-testid={'next-download'}
                  onClick={() => downloadFromURL(url, fileName)}
                >
                  {urlLabel}
                </NextActionLink>
              </div>
            )}
            {job.outcome?.next?.type === 'files' && files && (
              <div>
                <NextActionLink
                  data-testid={'next-files'}
                  onClick={() => downloadFiles(files)}
                >
                  {urlLabel}
                </NextActionLink>
              </div>
            )}
            {job.outcome?.next?.type === 'view' && hidden && sheetId && (
              <div>
                <NextActionLink
                  data-testid={'next-files'}
                  onClick={() =>
                    setColumnVisibility({ fields: hidden, sheetId })
                  }
                >
                  {viewLabel}
                </NextActionLink>
              </div>
            )}
            {job.outcome?.next?.type === 'id' && (
              <span>
                <a data-testid={'next-id'} href={getResourceUrl(job)}>
                  {idLabel}
                </a>
              </span>
            )}
            {job.outcome?.next?.type === 'retry' && job.status === 'failed' && (
              <PanelActionLink
                onClick={() => retryJob(job.id)}
                data-testid='next-retry'
                to='#'
              >
                {retryLabel}
              </PanelActionLink>
            )}

            {(job.status === 'ready' || job.status === 'executing') && (
              <PanelActionLink
                onClick={() => cancelJob(job.id)}
                data-testid='cancel-job'
                color={'var(--color-danger)'}
                to='#'
              >
                {'Cancel'}
              </PanelActionLink>
            )}
          </JobInfo>
          <ActionButtonWrapper>
            <CopyIdButton jobId={job.id} />
          </ActionButtonWrapper>
          <JobActionIcons></JobActionIcons>
        </JobStatusContent>
      </JobStatusContainer>
    </PanelItem>
  )
}

/**
 * todo: make this part of the jobs API
 */
export const actionForJob = (
  job: Job,
  spaceId: string
): { label: string; to: string } | undefined => {
  if (job.status !== 'complete') return undefined
  switch (job.operation) {
    case 'map':
      return {
        label: 'jobs.panel.action.mapping',
        to: `/space/${spaceId}/workbook/${job.destination}`,
      }
    case 'extract':
      return {
        label: 'jobs.panel.action.extract',
        to: `/space/${spaceId}/files/${job.source}/import`,
      }
    case 'delete-records':
    default:
      return undefined
  }
}
