import { truncated } from '@flatfile/design-system'
import { OuterTableElement } from '@flatfile/turntable'
import styled from 'styled-components'
import { TabStripInner, TabStripOuter } from '../../components/TabStrip'

export const SheetContainer = styled.div<{ $height?: string | number }>`
  position: relative;
  max-width: none !important;
  display: flex;
  flex-direction: column;

  ${({ $height }) => {
    const height = !$height
      ? '100vh'
      : typeof $height === 'string'
      ? $height
      : $height + 'px'
    return `
      height: ${height};
      max-height: ${height};
    `
  }}
`

export const SheetTableContainer = styled.div<{ $embedded?: boolean }>`
  flex: 1;
  position: relative;
  ${OuterTableElement} {
    ${({ $embedded }) => {
      return !$embedded ? 'border-left: none;' : undefined
    }}
  }
`

export const SheetHeaderContainer = styled.div`
  padding-top: var(--spacing-content-gutter);
  padding-left: var(--spacing-content-gutter);
  padding-right: var(--spacing-content-gutter);
`

export const SheetTabsContainer = styled.div`
  ${TabStripOuter} {
    margin-bottom: 0px;
  }
  ${TabStripInner} {
    padding-left: var(--spacing-content-gutter);
    padding-right: var(--spacing-content-gutter);
  }
`

export const SheetTabName = styled.span`
  ${truncated}
`

export const SheetTabIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 4px;
`

export const PrimaryActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
