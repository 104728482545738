import styled from 'styled-components'

export const ActionContent = styled.div`
  background-color: var(--color-page);
  padding: var(--spacing-actions-gutter) var(--spacing-page-gutter);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`
