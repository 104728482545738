import { fromMaybe } from '@flatfile/shared-ui'
import { renderToStaticMarkup } from 'react-dom/server'
import ReactMarkdown from 'react-markdown'
import stripMarkdown from 'strip-markdown'

/* Strips whitespace from newlines to allow indented template literals to be displayed properly */
export const preprocessMarkdown = (markdown: string) => {
  const processed = markdown
    .split('\n')
    .map((line) => line.trim())
    .join('\n')
  return processed
}

/* Renders markdown as html */
export const renderMarkdownAsHtml = (markdown: string) => {
  return renderToStaticMarkup(
    <ReactMarkdown>{preprocessMarkdown(markdown)}</ReactMarkdown>
  ).replace(/[\r\n]+/g, '') // strips the extra newlines adaded by react-dom
}

/* Remark plugin to strip most markdown from a string */
export const stripMarkdownPlugin = () =>
  stripMarkdown({
    keep: ['link', 'inlineCode', 'strong', 'emphasis', 'delete'],
    remove: [
      ['image', imageHandler],
      ['imageReference', imageHandler],
    ],
  })

export const STRIPPED_MARKDOWN_OPTIONS = {
  remarkPlugins: [stripMarkdownPlugin],
  components: {
    a: LinkRenderer,
  },
}

/* Renders markdown as html, stripping most markdown */
export const renderStrippedMarkdown = (markdown: string) => {
  return renderToStaticMarkup(
    <ReactMarkdown {...STRIPPED_MARKDOWN_OPTIONS}>
      {preprocessMarkdown(markdown)}
    </ReactMarkdown>
  )
}

/* Custom handler for replacing images by a link */
function imageHandler(node: any) {
  const title = fromMaybe(node?.title, '')
  const value = fromMaybe(node.alt, fromMaybe(title, ''))
  return {
    type: 'link',
    title: node.title,
    url: node.url,
    children: [
      {
        type: 'text',
        value: value,
      },
    ],
  } as any
}

/* Renders links so they open in a new tab */
export function LinkRenderer(props: any) {
  return (
    <a href={props.href} target='_blank' rel='noreferrer'>
      {props.children}
    </a>
  )
}
