import { useCreateViewMutation } from '@/api/mutations/views.mutations'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { UserContext } from '@/contexts/UserContext'
import { logger } from '@/utils/logging'
import { Sheet, View, ViewConfig } from '@flatfile/api'
import { Icon, IconName } from '@flatfile/design-system'
import {
  PopoverContext,
  PopoverMessageFullWidth,
  copyToClipboard,
} from '@flatfile/shared-ui'
import { Key, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface UseSavedViewsProps {
  sheet?: Sheet
  isEntitled: boolean
}

export type SavedViewOptionsGroup = {
  name: string
  options: SavedViewsOption[]
}

export type SavedViewsOption = {
  key: string
  label: string
  icon: IconName
  config: {
    sortField?: string
    sortDirection?: string
    filter?: string
    filterField?: string
    q?: string
  }
}

export const useSavedViews = ({ sheet, isEntitled }: UseSavedViewsProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const { actor } = useContext(UserContext)
  const { data } = useFlatfileQuery(
    'getViews',
    { sheetId: sheet?.id ?? '' },
    { enabled: isEntitled }
  )
  const [_, setSearchParams] = useSearchParams()
  const { mutateAsync: mutateCreateView } = useCreateViewMutation()
  const { mutate: updateView } = useCreateViewMutation()
  const { mutate: deleteView } = useCreateViewMutation()
  const { showPopover } = useContext(PopoverContext)
  const { t } = useTranslation()

  const savedViews = useMemo(() => {
    const groupedViews = []
    const myOptions: SavedViewOptionsGroup = {
      name: t('sheet.toolbar.savedViews.groups.myViews'),
      options: [],
    }
    const allOptions: SavedViewOptionsGroup = {
      name: t('sheet.toolbar.savedViews.groups.allViews'),
      options: [],
    }
    data?.data?.forEach((view: View) => {
      const option = {
        key: view.id,
        label: view.name,
        config: view.config,
        icon: 'link',
        tooltip: {
          active: t('sheet.toolbar.savedViews.tooltip'),
        },
      } as SavedViewsOption

      if (actor && view.createdBy === actor.id) {
        myOptions.options.push(option)
      } else {
        allOptions.options.push(option)
      }
    })

    if (myOptions.options.length > 0) {
      groupedViews.push(myOptions)
    }
    if (allOptions.options.length > 0) {
      groupedViews.push(allOptions)
    }

    return groupedViews
  }, [data])

  /** Handler which takes a View ID and matches it against the set of saved
   * views. If a matching view is found the keys within the config of that
   * view are then applied to the URL search params, resulting in the
   * filters to be applied
   */
  const activateView = (selectedViewKey: Key | Set<Key>) => {
    const viewsList = savedViews.flatMap((group) => group.options)
    const foundView =
      typeof selectedViewKey === 'string'
        ? viewsList?.find((view) => view.key === selectedViewKey)
        : undefined

    if (foundView) {
      const cleansedConfig: { [key: string]: string } = {}
      const { origin, pathname } = window.location

      for (const [key, value] of Object.entries(foundView.config)) {
        if (value !== undefined) {
          cleansedConfig[key] = value
        }
      }

      const urlParams = new URLSearchParams(cleansedConfig)
      const shareURL = new URL(`${origin}${pathname}?${urlParams}`)

      copyToClipboard(shareURL.href)
      setSearchParams(cleansedConfig)
      showPopover(
        {
          icon: <Icon name='clipboardCheck' />,
          message: (
            <PopoverMessageFullWidth>
              {t('sheet.toolbar.savedViews.viewAppliedPopover')}
            </PopoverMessageFullWidth>
          ),
        },
        true
      )
    } else {
      logger.error(`Saved View could not be activated`, {
        selectedViewKey,
      })
    }
  }

  /** Async handler used to create a new shared view from a provided
   * config and name
   */
  const createView = async ({
    name,
    config,
  }: {
    name: string
    config: ViewConfig
  }) => {
    return mutateCreateView({
      name,
      sheetId: sheet?.id ?? '',
      config,
    })
  }

  /**
   * Toggles the save-view action to open
   */
  const openSaveView = useCallback(() => {
    setOpen(true)
  }, [])

  /**
   * Toggles the save-view action to closed
   */
  const closeSaveView = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    savedViews,
    activateView,
    createView,
    updateView,
    deleteView,
    openSaveView,
    closeSaveView,
    isSaveViewOpen: open,
  }
}
