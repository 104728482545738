import { Spinner } from '@flatfile/design-system'
import { useBlockingJobs } from '../hooks/useBlockingJobs'

export const ElementOrSpinner = ({
  id,
  children,
  color = 'var(--color-sidebar-text)',
}: {
  id: string
  children?: JSX.Element
  color?: string
}) => {
  const blockingJobs = useBlockingJobs([id])
  const locked = blockingJobs.length > 0
  return locked ? <Spinner size={16} color={color} /> : children ?? null
}
