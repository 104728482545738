import { useCallback, useRef } from 'react'

export const useTimerRef = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const setTimer = useCallback(
    (timer: NodeJS.Timeout) => {
      timerRef.current = timer
    },
    [timerRef]
  )

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [timerRef])

  return {
    timerRef,
    setTimer,
    clearTimer,
  }
}
