import { useDownloadFiles } from '@/apps/FilesApp/hooks/useDownloadFiles'
import { preprocessMarkdown } from '@/utils/markdown'
import {
  Button,
  ModalButtonRow,
  ModalHeader,
  getFont,
} from '@flatfile/design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useResource } from '../../api/resources'
import { JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useRetryJob } from '../../hooks/useRetryJob'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { downloadFromURL } from '../../utils/downloadFromURL'
import {
  extractJobOutcomeDetails,
  getJobOutcomeTexts,
  getResourceUrl,
} from './useJobsToast'
import { setColumnVisibility } from '@/apps/WorkbookApp/utils/setColumnVisibility'

const OutcomeMessage = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`
export const AcknowledgeModalContent = ({
  job,
  locallyOutcomeAck,
}: {
  job: any
  locallyOutcomeAck: (jobId: string) => void
}) => {
  const [resource] = useResource<{
    id: string
    name: string
    updatedAt: Date
  }>(job.source, false)
  const navigate = useNavigate()
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const { httpClient } = useContext(SpaceContext)
  const { jobsRequest } = useContext(JobsContext)
  const { retryJob } = useRetryJob()
  const onAck = (id: string) => async () => {
    locallyOutcomeAck(id)
    await httpClient.acknowledgeJobOutcome({
      jobId: id,
    })
    jobsRequest?.reload()
  }

  const {
    outcomeHeading,
    outcomeMessage,
    url,
    urlLabel,
    fileName,
    idLabel,
    retryLabel,
    viewLabel,
  } = getJobOutcomeTexts(job, resource)

  const { downloadFiles } = useDownloadFiles()
  const buttonText =
    customT(job?.outcome?.buttonText) ||
    t(
      'jobs.customActions.modals.outcomeModals.acknowledgementModal.defaultContinueButton'
    )

  const hasNext = !!job?.outcome?.next
  const isUrl = job?.outcome?.next?.type === 'url'
  const isDownload = job?.outcome?.next?.type === 'download'
  const isFiles = job?.outcome?.next?.type === 'files'
  const isId = job?.outcome?.next?.type === 'id'
  const isRetry = job?.outcome?.next?.type === 'retry'
  const isView = job?.outcome?.next?.type === 'view'
  const hideDefaultButton = job?.outcome?.hideDefaultButton
  const { files, hidden, sheetId } = extractJobOutcomeDetails(job)

  return (
    <>
      <ModalHeader>{outcomeHeading}</ModalHeader>

      <OutcomeMessage>
        <ReactMarkdown>{preprocessMarkdown(outcomeMessage)}</ReactMarkdown>
      </OutcomeMessage>

      <ModalButtonRow>
        <>
          {(!hideDefaultButton || !hasNext) && (
            <Button
              variant={hasNext ? 'secondary' : 'primary'}
              onPress={onAck(job.id)}
              label={buttonText}
              data-testid='acknowledge-button'
            />
          )}

          {isUrl && (
            <Button
              variant='primary'
              onPress={async () => {
                await onAck(job.id)()
                window.open(url)
              }}
              label={urlLabel!}
              data-testid='next-url'
              iconRight='arrowUpRight'
            />
          )}
          {isDownload && (
            <Button
              variant='primary'
              onPress={async () => {
                downloadFromURL(url, fileName)
                onAck(job.id)()
              }}
              label={urlLabel!}
              data-testid='next-download'
            />
          )}
          {isFiles && files && (
            <Button
              variant='primary'
              onPress={async () => {
                downloadFiles(files)
                onAck(job.id)()
              }}
              label={urlLabel!}
              data-testid='next-files'
            />
          )}

          {isId && (
            <Button
              variant='primary'
              onPress={async () => {
                await onAck(job.id)()
                navigate(getResourceUrl(job))
              }}
              label={idLabel}
              data-testid='next-id'
            />
          )}
          {isRetry && (
            <Button
              variant='primary'
              onPress={() => {
                //no need to ack here, because the status will change
                retryJob(job.id)
              }}
              label={retryLabel}
              data-testid='next-retry'
            />
          )}
          {isView && hidden && sheetId && (
            <Button
              variant='primary'
              onPress={async () => {
                await onAck(job.id)()
                setColumnVisibility({ fields: hidden, sheetId })
              }}
              label={viewLabel}
              data-testid='next-retry'
            />
          )}
        </>
      </ModalButtonRow>
    </>
  )
}
