import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { resources } from '@/api/resources'
import { Sheet, Workbook } from '@flatfile/api'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContentSkeleton } from '../../components/SpacesUISkeleton'
import { SpaceContext } from '../../contexts/SpaceContext'
import { getRouteForSpace } from './Space/SpaceUtils'

export function SpaceRouteRedirector() {
  const navigate = useNavigate()
  const { space, metadata } = useContext(SpaceContext)
  const { data: documents } = useFlatfileQuery('getSpaceDocuments', {
    spaceId: space.id,
  })
  const { data: workbooks } = useQueryGetAllWorkbooks({ spaceId: space.id })

  useEffect(() => {
    if (documents?.data && workbooks?.data) {
      const { path, workbookId, sheetId } = getRouteForSpace({
        space,
        workbooks: workbooks.data,
        defaultPage: metadata?.sidebarConfig?.defaultPage || {},
        documents: documents.data,
      })

      // Hydrate Resource Cache With Data from Initial Resources
      if (workbookId) {
        const currentWorkbook = workbooks.data.find(
          (wb: Workbook) => wb.id === workbookId
        )
        currentWorkbook && resources.set(workbookId, currentWorkbook)

        if (currentWorkbook && currentWorkbook.sheets) {
          if (sheetId) {
            const currentSheet = currentWorkbook.sheets.find(
              (sh: Sheet) => sh.id === sheetId
            )
            currentSheet && resources.set(sheetId, currentSheet)
          }
          if (currentWorkbook.sheets[0]) {
            resources.set(
              currentWorkbook.sheets[0].id,
              currentWorkbook?.sheets[0]
            )
          }
        }
      }

      if (space && path) {
        navigate(`/space/${space.id}${path}`)
      }
    }
  }, [space, documents, workbooks])
  return ContentSkeleton() // always loading until navigate() takes us away
}
