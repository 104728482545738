import { Banner, ProgressBar } from '@flatfile/design-system'
import { useEffect, useState } from 'react'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

import { useAssistant } from '@/hooks/useAssistant'
import { CommandMenuPanelFooter } from '../CommandMenuPanelFooter'
import { CommandMenuMainPanel } from '../CommandMenuMainPanel'
import { parseCommand, PanelType } from '../utils'
import { CommandMenuAiQueryPanelProps } from '../types'
import { EllipsisMessage } from './EllipsisMessage'
import { CommandMenuLine, Title } from '../elements'
import { PanelState, messages } from './constants'

export const CommandMenuAiQueryPanel = ({
  value,
  sheetId,
  onSearchByValue,
  dismissHandler,
  setInputDisabled,
  setInputFocus,
  keyboardSubmitActivated,
  deactivateKeyboardSubmit,
}: CommandMenuAiQueryPanelProps) => {
  const { t } = useTypedTranslation()
  const [panelState, setPanelState] = useState(PanelState.ReadyToAsk)

  const { generateQuery, isError, queryResponse, queryIsSuccess } =
    useAssistant({ sheetId })

  const query = queryResponse?.data?.query

  const resetPanelState = () => {
    if (panelState === PanelState.ReadyToAsk) {
      return
    }
    setPanelState(PanelState.ReadyToAsk)
    setInputDisabled(false)
    setInputFocus()
  }

  useEffect(() => {
    if (keyboardSubmitActivated && panelState === PanelState.ReadyToAsk) {
      askForQuery()
      deactivateKeyboardSubmit()
    }
  }, [keyboardSubmitActivated, panelState])

  // Advance the panel state when an operation succeeds
  useEffect(() => {
    if (panelState === PanelState.Asking && queryIsSuccess) {
      applyQuery()
    }
  }, [panelState, queryIsSuccess])

  // If the user changes the value, reset the panel state
  useEffect(() => {
    resetPanelState()
  }, [value])

  // When there's an error, re-enable the input
  useEffect(() => {
    if (isError) {
      setInputDisabled(false)
    }
  }, [isError])

  const askForQuery = () => {
    /* c8 ignore next */
    if (!value) return

    setPanelState(PanelState.Asking)
    setInputDisabled(true)

    const { prompt } = parseCommand(value)
    generateQuery(prompt)
  }

  // What happens when we tell the assistant to apply the mutation?
  const applyQuery = async () => {
    if (query && panelState === PanelState.Asking) {
      // Apply query
      onSearchByValue({ q: query })

      // Close the command menu
      dismissHandler()
    }
  }

  return (
    <>
      {panelState === PanelState.ReadyToAsk && (
        <CommandMenuMainPanel value={'/' + PanelType.QUERY} />
      )}

      {isError && panelState === PanelState.Asking && (
        <Banner
          title={t('commandMenu.aiAssist.query.errorBanner.generalError')}
          variant='error'
        />
      )}

      {panelState === PanelState.Asking && !isError && (
        <>
          <CommandMenuLine>
            <Title>
              <EllipsisMessage messages={messages} interval={7000} />
            </Title>
          </CommandMenuLine>
          <ProgressBar />
          <CommandMenuPanelFooter onCancel={resetPanelState} />
        </>
      )}
    </>
  )
}
