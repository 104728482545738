import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { sortWorkbooks } from '@/utils/workbooks'
import { AMP_TOOLTIP_ID, Icon } from '@flatfile/design-system'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getNonEphemeralDocuments } from '../../apps/SpaceApp/Space/SpaceUtils'
import { checklistIcon } from '../../assets/icons/checklist'
import { fileIcon } from '../../assets/icons/file'
import {
  EnvironmentsContext,
  Features,
} from '../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useConnections } from '../../hooks/useConnections'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { getRuntimeParameters } from '../RuntimeParameters'
import { SidebarSkeleton } from '../SpacesUISkeleton/SpacesUISkeleton'
import { logout } from '../logout'
import { SidebarHeading } from './components/SidebarHeading'
import { SidebarItem, SidebarWorkbookItem } from './components/SidebarItems'
import { SidebarLogo } from './components/SidebarLogo'
import {
  SidebarContents,
  SidebarDivider,
  SidebarFooter,
  SidebarFooterLink,
  SidebarHeader,
  SidebarItemText,
  SidebarList,
  SidebarPill,
} from './elements'
import { usePermissions } from '@/contexts/PermissionsContext'

export const Sidebar = () => {
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const { environment } = useContext(EnvironmentsContext)
  const spaceContext = useContext(SpaceContext)
  const { hasFullPermission } = usePermissions()
  const spaceRoot = `/space/${spaceContext.space.id}`
  const { isGuest } = getRuntimeParameters()
  const logoutClick = logout.bind(undefined, location)
  const [showDataChecklist, setShowDataChecklist] = useState(true)
  const [showConnections, setShowConnections] = useState(false)
  const [showActivityLogs, setShowActivityLogs] = useState(false)
  const dashboardUrl = import.meta.env.VITE_BASE_DASHBOARD_URL
  const { openMerge } = useConnections()

  const { data: workbooks, isLoading: isLoadingWorkbooks } =
    useQueryGetAllWorkbooks({ spaceId: spaceContext.space.id })
  const { data: documents, isLoading: isLoadingDocuments } = useFlatfileQuery(
    'getSpaceDocuments',
    { spaceId: spaceContext.space.id }
  )

  const showAdminPages = hasFullPermission('secret')
  const showGuestInvite = hasFullPermission('guest')

  const sortedWorkbooks = useMemo(
    () =>
      sortWorkbooks(
        workbooks?.data,
        spaceContext.space.settings?.sidebarConfig?.workbookSidebarOrder
      ),
    [workbooks?.data]
  )

  useEffect(() => {
    const showChecklist =
      spaceContext.metadata?.sidebarConfig?.showDataChecklist
    const features = environment?.features as Features
    const connectionsEnabled = features?.connections
    const activityLogsEnabled = features?.activityLogs
    if (
      showChecklist !== undefined &&
      showChecklist !== showDataChecklist &&
      isGuest
    )
      setShowDataChecklist(showChecklist)
    if (connectionsEnabled) {
      setShowConnections(true)
    }
    if (activityLogsEnabled) {
      setShowActivityLogs(true)
    }
  }, [spaceContext.metadata?.sidebarConfig, environment])

  const nonEphemeralDocuments = getNonEphemeralDocuments(documents?.data)

  if (isLoadingWorkbooks || isLoadingDocuments) {
    return SidebarSkeleton()
  }
  performance.mark('fmp')
  return (
    <>
      <SidebarContents data-testid='sidebar-contents'>
        <SidebarHeader>
          <SidebarLogo logo={spaceContext.metadata?.theme?.sidebar?.logo} />
          <SidebarHeading spaceContext={spaceContext} />
        </SidebarHeader>
        <SidebarList>
          {nonEphemeralDocuments && (
            <>
              {nonEphemeralDocuments.map((doc) => (
                <SidebarItem
                  key={doc.id}
                  to={`${spaceRoot}/document/${doc.id}`}
                >
                  <SidebarItemText>{customT(doc.title)}</SidebarItemText>
                </SidebarItem>
              ))}
              {nonEphemeralDocuments.length > 0 && <SidebarDivider />}
            </>
          )}
          {showDataChecklist && (
            <SidebarItem to={`${spaceRoot}/checklist`}>
              {checklistIcon}
              {t('sidebar.checklist')}
            </SidebarItem>
          )}
          <SidebarItem to={`${spaceRoot}/files`}>
            {fileIcon}
            {t('sidebar.files')}
          </SidebarItem>
          {showConnections && (
            <SidebarItem onClick={openMerge} data-testid='add-connection'>
              <a href='#'>
                <Icon name='connection' />
                {t('sidebar.addConnection')}
              </a>
            </SidebarItem>
          )}
          {showAdminPages && (
            <SidebarItem to={`${spaceRoot}/secrets`} data-testid='secrets'>
              <Icon name='key' />
              Secrets
              <AdminPill />
            </SidebarItem>
          )}
          {showGuestInvite && (
            <SidebarItem
              data-testid='guest-link'
              to={`${spaceRoot}/manage`}
              title='Visible to admins only'
            >
              <Icon name='userGroup' />
              Manage guests
              <AdminPill />
            </SidebarItem>
          )}
          {showActivityLogs && !isGuest && (
            <SidebarItem
              data-testid='activity-logs-link'
              to={`${spaceRoot}/activity`}
              title='Visible to admins only'
            >
              <Icon
                name='calendar'
                width='20'
                height='20'
                viewBox='0 0 20 20'
              />
              Activity logs
              <AdminPill />
            </SidebarItem>
          )}
          <SidebarDivider />
          {sortedWorkbooks &&
            sortedWorkbooks.map((workbook) => (
              <SidebarWorkbookItem
                key={workbook.id}
                workbook={workbook}
                spaceRoot={spaceRoot}
                single={sortedWorkbooks.length === 1}
              />
            ))}
        </SidebarList>
      </SidebarContents>
      <SidebarFooter>
        {!isGuest && (
          <SidebarFooterLink
            href={dashboardUrl}
            target='_top'
            data-testid='back-to-dashboard'
          >
            &larr; Back to Dashboard
          </SidebarFooterLink>
        )}
        {!isGuest && (
          <SidebarFooterLink href='#' onClick={logoutClick}>
            Logout &rarr;
          </SidebarFooterLink>
        )}
      </SidebarFooter>
    </>
  )
}

export const AdminPill = () => {
  return (
    <SidebarPill
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content='This is an admin only feature'
    >
      Admin
    </SidebarPill>
  )
}
