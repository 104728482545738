import { typedT } from '@/utils/typedTranslation'
import { Filter } from '@flatfile/api'
import {
  BulkRowSelection,
  BulkRowSelectionType,
  CellData,
  FilterCounts,
} from '@flatfile/turntable'
import i18next from 'i18next'
import { SearchFields } from '../types'
import { getCurrentRowCount } from './tableUtils'

type KeysUnder<T, K extends PropertyKey> = T extends object
  ? {
      [P in keyof T]-?: (P extends K ? keyof T[P] : never) | KeysUnder<T[P], K>
    }[keyof T]
  : never

export type ActionType = KeysUnder<ActionTenses, 'verb'>

export interface ActionSuccessMeta {
  action: ActionType
  count: number | undefined
  type?: 'record' | 'cell'
}

export interface BulkRowActionTooltipText {
  active?: string
  disabled?: string
}

export interface BulkRowActionModalText {
  heading: string
  description: string
  confirmButtonText: string
  loadingText: string
  errorMessage?: string
  cancelButtonText?: string
}

type BulkRowActionModalTexts = {
  [K in ActionType]: BulkRowActionModalText
}

export const actionTenses = {
  verb: {
    download: {
      past: 'downloaded',
      present: 'download',
      progressive: 'downloading',
    },
    delete: {
      past: 'deleted',
      present: 'delete',
      progressive: 'deleting',
    },
    copy: {
      past: 'copied',
      present: 'copy',
      progressive: 'copying',
    },
  },
}

type ActionTenses = typeof actionTenses

export const determineFilter = (
  selection: BulkRowSelection,
  searchFields: SearchFields
) => {
  const filter =
    searchFields.filterField && !searchFields.filter
      ? Filter.Error
      : searchFields.filter
  if (
    selection.type === BulkRowSelectionType.NONE &&
    !selection.exceptions.length
  ) {
    return filter
  }
  return selection.type === BulkRowSelectionType.NONE
    ? selection.type
    : filter || selection.type
}

export const getRowsSelectedCount = ({
  selection,
  searchFields,
  filterCounts,
}: {
  selection: BulkRowSelection
  searchFields: SearchFields
  filterCounts: FilterCounts
}) => {
  const counts: { [K in ActionType]: number | undefined } = {
    download: 0,
    delete: 0,
    copy: 0,
  }

  const getCount = (action: ActionType) => {
    //if rows are selected individually
    if (
      selection.type === BulkRowSelectionType.NONE &&
      selection.exceptions.length > 0
    ) {
      return selection.exceptions.length
    }
    const rowCount = getCurrentRowCount(searchFields, filterCounts)
    //if no rows are selected
    if (
      selection.type === BulkRowSelectionType.NONE &&
      selection.exceptions.length === 0
    ) {
      if (action === 'delete') return 0
      return rowCount
    }
    //if rows are all selected
    return rowCount - selection.exceptions.length
  }

  const exceptionsErrorRecords = selection.exceptionsData.filter((record) =>
    record.cells.some((cell: CellData) => !!cell?.status?.error)
  )
  const errorCount =
    selection.type === BulkRowSelectionType.NONE
      ? exceptionsErrorRecords.length
      : filterCounts.error - exceptionsErrorRecords.length

  return {
    download: getCount('download'),
    delete: getCount('delete'),
    copy: 0,
    error: errorCount,
    errorsTotal: filterCounts.error as number,
    total: filterCounts.total as number,
  }
}

export const getActionSuccessMessage = ({
  action,
  count,
  type,
}: ActionSuccessMeta) => {
  const actionOnType = type ?? 'record'
  return count
    ? action === 'delete'
      ? typedT('sheet.toolbar.buttons.delete.popover.successMessage', {
          count,
        })
      : action === 'copy' && actionOnType === 'cell'
      ? typedT('sheet.popovers.copyCell', { count })
      : action === 'copy' &&
        actionOnType === 'record' &&
        typedT('sheet.popovers.copyRecord', { count })
    : action === 'delete' &&
        typedT('sheet.toolbar.buttons.delete.popover.successMessageNoCount')
}

export const getBulkActionModalText = ({
  count,
  filter,
  selection,
}: {
  count: { [K in ActionType]: number } | undefined
  filter: Filter | undefined
  selection: BulkRowSelection
}) => {
  const actions = actionTenses.verb
  const allText = {} as BulkRowActionModalTexts
  Object.keys(actions).forEach((action) => {
    const getDescription = () => {
      const nothingSelected =
        selection.type === BulkRowSelectionType.NONE &&
        selection.exceptions.length === 0
      const selectedFilter = filter
        ? filter === Filter.Valid
          ? filter
          : 'invalid'
        : ''
      const countString =
        count && count[action as ActionType]
          ? count[action as ActionType].toLocaleString()
          : undefined

      const descriptionText =
        action === 'delete' || action === 'download'
          ? typedT(`sheet.toolbar.buttons.${action}.modal.description.prefix`) +
            (countString ? ` ${countString} ` : '') +
            (nothingSelected
              ? ''
              : ` ${typedT(
                  `sheet.toolbar.buttons.${action}.modal.description.infix`
                )} `) +
            (selectedFilter !== ''
              ? ` ${typedT(
                  `sheet.toolbar.buttons.${action}.modal.description.infixType.${selectedFilter}`
                )} `
              : '') +
            typedT(`sheet.toolbar.buttons.${action}.modal.description.suffix`, {
              count:
                count && count[action as ActionType]
                  ? count[action as ActionType]
                  : 0,
            })
          : ''

      return descriptionText.replace(/\s+/g, ' ')
    }
    if (action === 'delete' || action === 'download') {
      allText[action as ActionType] = {
        heading: i18next.t(`sheet.toolbar.buttons.${action}.modal.heading`, {
          count: count ? count[action as ActionType] : 0,
        }),
        description: getDescription(),
        confirmButtonText: typedT(
          `sheet.toolbar.buttons.${action}.modal.confirmButton`
        ),
        cancelButtonText: typedT(
          `sheet.toolbar.buttons.${action}.modal.cancelButton`
        ),
        loadingText: typedT(`sheet.toolbar.buttons.${action}.modal.inProgress`),
        errorMessage: typedT(
          `sheet.toolbar.buttons.${action}.modal.errorMessage`
        ),
      }
    }
  })
  return allText
}
