import React from 'react'
import { Navigate } from 'react-router-dom'
import { SpacesUISkeleton } from '../components/SpacesUISkeleton'
import { AuthOutcome, useAuth } from './useAuth'

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { authorized, isAuthorizing, authOutcome } = useAuth()

  if (isAuthorizing) {
    return <SpacesUISkeleton />
  }

  if (!isAuthorizing && !authorized) {
    if (authOutcome === AuthOutcome.EMAIL) {
      return <Navigate to={`/unauthorized?outcome=${AuthOutcome.EMAIL}`} />
    }

    if (authOutcome === AuthOutcome.SESSION_EXPIRED) {
      return (
        <Navigate to={`/unauthorized?outcome=${AuthOutcome.SESSION_EXPIRED}`} />
      )
    }

    if (authOutcome === AuthOutcome.UNAUTHORIZED) {
      return (
        <Navigate to={`/unauthorized?outcome=${AuthOutcome.UNAUTHORIZED}`} />
      )
    }

    return <Navigate to={`/unauthorized?outcome=${AuthOutcome.UNAUTHORIZED}`} />
  }

  return <>{children}</>
}

export default AuthGuard
