export function SheetIconInvert() {
  return (
    <svg
      width='20'
      height='14'
      viewBox='0 0 20 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 5H19M1 9H19M10 5V13M3 13H17C18.1046 13 19 12.1046 19 11V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13Z'
        stroke='#616A7D'
        strokeWidth='1.5'
      />
    </svg>
  )
}
