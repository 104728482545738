import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react'

const MAX_OPEN_SHEETS = 10

interface BlocksContextType {
  openSheetIds: string[]
  setOpenSheetIds: Dispatch<SetStateAction<string[]>>
  maxOpenSheets: number
}

const useOpenSheets = () => {
  const [openSheetIds, setOpenSheetIds] = useState<string[]>([])
  return {
    openSheetIds,
    setOpenSheetIds,
    maxOpenSheets: MAX_OPEN_SHEETS,
  }
}

export const BlocksContext = createContext<BlocksContextType>({
  openSheetIds: [],
  setOpenSheetIds: () => {},
  maxOpenSheets: MAX_OPEN_SHEETS,
})

export const BlocksContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const props = useOpenSheets()
  return (
    <BlocksContext.Provider value={{ ...props }}>
      {children}
    </BlocksContext.Provider>
  )
}
