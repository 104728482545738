import { FlatfileRequestType } from './types'

export const snapshotsQueries = {
  getSnapshot: {
    generateQueryKey: (params: FlatfileRequestType<'getSnapshot'>) => [
      'getSnapshot',
      params,
    ],
    influencingEvents: {},
  },
  getSnapshotRecords: {
    generateQueryKey: (params: FlatfileRequestType<'getSnapshotRecords'>) => [
      'getSnapshotRecords',
      params,
    ],
    influencingEvents: {},
  },
}
