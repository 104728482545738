import { FileController } from '@/api/controllers/FileController'
import { useController } from '@/api/controllers/useController'
import { useAction } from '@/api/observable'
import { SpaceContext } from '@/contexts/SpaceContext'
import { downloadFile } from '@/utils/downloadFile'
import { useContext } from 'react'

export const useDownloadFiles = () => {
  const { space, httpClient } = useContext(SpaceContext)
  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [downloadFiles] = useAction(
    fileController.downloadFiles(),
    (data) => {
      data.forEach((data) => {
        downloadFile(data)
      })
    },
    'downloadFiles'
  )
  return { downloadFiles }
}
