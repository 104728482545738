import { TokenExpirationPage } from '@/apps/SpaceApp/TokenExpirationPage'
import { ErrorState } from '@/components/EmptyState'
import { DefaultTranslationsProvider } from '@/components/Translations/DefaultTranslationsProvider'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { useSearchParams } from 'react-router-dom'
import { AuthOutcome } from '../useAuth'

export const UnauthorizedViewContent = () => {
  const { t } = useTypedTranslation()
  const [search] = useSearchParams()
  const outcome: AuthOutcome =
    (search.get('outcome') as unknown as AuthOutcome) ??
    AuthOutcome.UNAUTHORIZED

  return (
    <>
      {outcome === AuthOutcome.EMAIL && <TokenExpirationPage />}
      {outcome === AuthOutcome.SESSION_EXPIRED && (
        <ErrorState message={t('errors.sessionExpired')} />
      )}
      {outcome === AuthOutcome.UNAUTHORIZED && (
        <ErrorState message={t('errors.unauthorized')} />
      )}
    </>
  )
}

export const UnauthorizedView = () => {
  return (
    <DefaultTranslationsProvider>
      <UnauthorizedViewContent />
    </DefaultTranslationsProvider>
  )
}
