import {
  Button,
  Modal,
  ModalHeader,
  ModalText,
  ModalButtonRow,
  ModalCloseButton,
} from '@flatfile/design-system'

interface EndProjectProps {
  onClose: () => void
  onConfirm: () => void
}

export const EndProjectModal = ({ onClose, onConfirm }: EndProjectProps) => {
  const content = (
    <>
      <ModalHeader>End Project?</ModalHeader>
      <ModalText>
        When you end a Project, all guests invited to the Space will no longer
        have access to the Space.
      </ModalText>
      <ModalText>
        You and any other admin users will still have access to this Space and
        all the data in it.
      </ModalText>
      <ModalButtonRow>
        <ModalCloseButton onClose={onClose} label='Cancel' />
        <Button
          onPress={onConfirm}
          iconLeft='mail'
          label='Yes, contact support'
          variant='warning'
        />
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='end-project-modal' />
}
