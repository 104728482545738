import { useContext } from 'react'

import { SheetContext } from '../contexts/SheetContext'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'

/**
 * Hook used to perform on-demand retrieval of a column's distinct
 * values (up to a maximum of 50)
 *
 * @param enable - Performs reactQuery request when true
 * @param fieldKey - Key for the column (i.e. first_name)
 * @param searchValue - search string used to fuzzy match results
 * @returns
 */
export const useDistinctColumnValues = (
  enable: boolean,
  fieldKey: string,
  searchValue?: string
) => {
  const { sheetId } = useContext(SheetContext)

  let validSearchValue = searchValue === '' ? undefined : searchValue

  const { data, isLoading } = useFlatfileQuery(
    'getCellValues',
    {
      sheetId,
      fieldKey,
      searchValue: validSearchValue,
      pageNumber: 1,
      pageSize: 50,
      distinct: true,
      includeCounts: false,
    },
    {
      enabled: enable,
    }
  )

  return { data, isLoading }
}
