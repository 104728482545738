import { SpaceContext } from '@/contexts/SpaceContext'
import {
  AddRecordsRequest,
  CreateEventRequest,
  DeleteRecordsRequest,
  Filter,
  GetRecordsCsvRequest,
  GetRecordsRequest,
  UpdateRecordsRequest,
} from '@flatfile/api'
import {
  ColumnConfigProps,
  DataChangeEvent,
  RowChangeDetail,
} from '@flatfile/turntable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { formatTurntableRows, recordData } from '../utils/tableUtils'
import { PAGE_SIZE } from './useSheetViewData'

export interface RecordDataPayload {
  [key: string]: { value: any }
}

export interface GetRecordsParams {
  pageSize?: number
  pageNumber?: number
  sinceVersionId?: string
  searchFields?: Record<string, string>
}

export const getRecordsQueryKey = 'getRecords'
export const updateRecordsQueryKey = 'updateRecords'
export const addRecordsQueryKey = 'addRecords'
export const deleteRecordsQueryKey = 'deleteRecords'
export const downloadRecordsQueryKey = 'downloadRecords'
export const createEventQueryKey = 'createEvent'

export function useRecordMutation(sheetId: string, workbookId: string) {
  const { httpClient } = useContext(SpaceContext)
  const queryClient = useQueryClient()

  const { mutateAsync: mutateUpdate } = useMutation(
    [updateRecordsQueryKey, workbookId, sheetId],
    async (payload: UpdateRecordsRequest) => {
      return await httpClient.updateRecords(payload)
    }
  )

  const { mutateAsync: mutateAdd } = useMutation(
    [addRecordsQueryKey, workbookId, sheetId],
    async (payload: AddRecordsRequest) => {
      return await httpClient.addRecords(payload)
    }
  )

  const { mutateAsync: deleteRecords } = useMutation(
    [deleteRecordsQueryKey, workbookId, sheetId],
    async (payload: DeleteRecordsRequest) => {
      return await httpClient.deleteRecords(payload)
    }
  )

  const { mutateAsync: downloadRecords } = useMutation(
    [downloadRecordsQueryKey, workbookId, sheetId],
    async (payload: GetRecordsCsvRequest) => {
      return await httpClient.getRecordsCsv(payload)
    }
  )

  const { mutateAsync: createEvent } = useMutation(
    [createEventQueryKey, workbookId, sheetId],
    async (payload: CreateEventRequest) => {
      return await httpClient.createEvent(payload)
    }
  )

  const addRecords = async (
    changes: RowChangeDetail[],
    columnConfig: ColumnConfigProps[]
  ) => {
    const convertedChanges = changes.map((change: RowChangeDetail) =>
      recordData(change, columnConfig)
    )
    return await mutateAdd({
      sheetId,
      recordsData: convertedChanges,
    })
  }

  const updateRecords = async (
    changes: any,
    columnConfig: ColumnConfigProps[]
  ) => {
    const convertedChanges = changes.map((change: any) => ({
      id: change.id,
      values: recordData(change, columnConfig),
    }))

    return await mutateUpdate({
      sheetId,
      recordsUpdates: convertedChanges,
    })
  }

  /**
   * Creates an awaitable GET request for records which is
   * cancellable in circumstances where redundant fetches for
   * records begin to queue up
   */
  const getRecords = async (payload: GetRecordsRequest) => {
    // Get records should always retrieve the latest - So we can start each fetch
    // by cancelling any which are actively in progress to mitigate risk of
    // bottlenecked fetches getting returned out of order
    const queryKeyParams = {
      sheetId: payload.sheetId,
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
    }
    queryClient.cancelQueries({
      queryKey: [getRecordsQueryKey, queryKeyParams],
    })

    return queryClient.fetchQuery({
      queryKey: [getRecordsQueryKey, queryKeyParams],
      queryFn: async ({ signal }) =>
        await httpClient.getRecords(payload, { signal }),
    })
  }

  const fetchRecords = async (
    params: GetRecordsParams,
    columnConfig: ColumnConfigProps[]
  ) => {
    const page = params.pageNumber ?? 0
    const size = params.pageSize ?? PAGE_SIZE
    const filters = params.searchFields ?? {}

    if (filters.filterField && filters.filter === Filter.Valid) {
      return []
    }

    /*
      We determine the filter manually in this case as the response is not always
      reliable when combining filterField with no filter property.
    */
    const filter =
      filters.filterField && filters.filter !== Filter.Error
        ? Filter.Error
        : filters.filter

    const result = await getRecords({
      sheetId: sheetId!,
      pageSize: size,
      pageNumber: page + 1,
      includeMessages: true,
      sinceVersionId: params.sinceVersionId,
      ...filters,
      filter: filter as Filter,
    })

    const records = result?.data?.records ?? []
    const skip = page * size

    return formatTurntableRows(records, skip, columnConfig)
  }

  const addOrUpdateRecords = async (
    { changes }: DataChangeEvent,
    columnConfig: ColumnConfigProps[]
  ) => {
    const addRecordsArray = changes.filter((record) => !record.id)
    const updateRecordsArray = changes.filter((record) => record.id)
    queryClient.cancelQueries({ queryKey: [getRecordsQueryKey] })

    if (updateRecordsArray.length) {
      await updateRecords(updateRecordsArray, columnConfig)
    }
    if (addRecordsArray.length) {
      await addRecords(addRecordsArray, columnConfig)
    }
    return true
  }

  return {
    addRecords,
    updateRecords,
    deleteRecords,
    downloadRecords,
    fetchRecords,
    addOrUpdateRecords,
    createEvent,
  }
}
