import { FlatfileRequestType } from './types'

export const getViewsKey = 'getViews'
export const getViewKey = 'getView'

export const viewsQueries = {
  getViews: {
    generateQueryKey: (params: FlatfileRequestType<'getViews'>) => [
      getViewsKey,
      params,
    ],
    influencingEvents: {},
  },
  getView: {
    generateQueryKey: (params: FlatfileRequestType<'getView'>) => [
      getViewKey,
      params,
    ],
    influencingEvents: {},
  },
}
