import 'i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { merge } from 'lodash'
import { initReactI18next } from 'react-i18next'
import * as localDe from '../public/locales/de/translation.json'
import * as localEnCA from '../public/locales/en-CA/translation.json'
import * as localEnGB from '../public/locales/en-GB/translation.json'
import * as localEnZA from '../public/locales/en-ZA/translation.json'
import * as localEn from '../public/locales/en/translation.json'
import * as localEs from '../public/locales/es/translation.json'
import * as localFrCA from '../public/locales/fr-CA/translation.json'
import * as localFrFR from '../public/locales/fr-Fr/translation.json'
import * as localFr from '../public/locales/fr/translation.json'
import * as localId from '../public/locales/id/translation.json'
import * as localIt from '../public/locales/it/translation.json'
import * as localJp from '../public/locales/jp/translation.json'
import * as localKr from '../public/locales/kr/translation.json'
import * as localPtBR from '../public/locales/pt-BR/translation.json'
import * as localPt from '../public/locales/pt/translation.json'
import * as localTr from '../public/locales/tr/translation.json'
import * as localVi from '../public/locales/vi/translation.json'
import * as localZh from '../public/locales/zh/translation.json'
import { isFilename } from './utils/isFilename'
import { logger } from './utils/logging'

const supportedLanguages = [
  'de',
  'en',
  'en-GB',
  'en-CA',
  'en-ZA',
  'es',
  'fr',
  'fr-CA',
  'fr-FR',
  'id',
  'it',
  'jp',
  'kr',
  'pt',
  'pt-BR',
  'tr',
  'vi',
  'zh',
]

const pathPrefix = import.meta.env.VITE_SPACES_UI_PATH_PREFIX ?? ''

type SupportedLanguage = (typeof supportedLanguages)[number]

type Translations = typeof localEn

type StripEndings<K> = K extends `${infer R}_one`
  ? R
  : K extends `${infer R}_other`
  ? R
  : K extends `${infer R}_zero`
  ? R
  : K

type PathKeys<T, P extends string = ''> = {
  [K in keyof T]: K extends string | number
    ? T[K] extends object
      ? StripEndings<`${P}${K}`> | PathKeys<T[K], `${P}${K}.`>
      : StripEndings<`${P}${K}`>
    : never
}[keyof T]

export type TranslationsKeys = PathKeys<Translations>

export const localTranslations: Record<SupportedLanguage, Translations | any> =
  {
    // the en file will often be in front of the other translation files so typing to Translations no longer works here
    de: localDe,
    en: localEn,
    'en-GB': localEnGB,
    'en-CA': localEnCA,
    'en-ZA': localEnZA,
    es: localEs,
    fr: localFr,
    'fr-CA': localFrCA,
    'fr-FR': localFrFR,
    id: localId,
    it: localIt,
    jp: localJp,
    kr: localKr,
    pt: localPt,
    'pt-BR': localPtBR,
    tr: localTr,
    vi: localVi,
    zh: localZh,
  }

export const finalTranslations: Record<SupportedLanguage, Translations | any> =
  Object.assign({}, localTranslations)

export const getPath = async (
  lng: string[],
  supportedLanguages: readonly string[],
  loadPath?: string,
  override?: string
): Promise<string> => {
  let languageToUse = 'en'
  const usersLanguage = override ?? lng?.[0] ?? languageToUse

  if (supportedLanguages.includes(usersLanguage)) {
    languageToUse = usersLanguage
  }
  // Check if the base language is supported when region is not supported (e.g., en for en-US)
  else if (usersLanguage.includes('-')) {
    const [baseLng] = usersLanguage.split('-')
    if (supportedLanguages.includes(baseLng)) {
      languageToUse = baseLng
    }
  }

  /**
   * Translation path used for default translations that are bundled with the application and
   * served from the platform domain if no custom translations are configured for the space
   */
  const localPath = `${pathPrefix}/locales/${languageToUse}/translation.json`

  if (!loadPath) {
    return localPath
  }

  /**
   * Translation path used for custom translations that have been configured by customer. These
   * translations are stored outside of Platform infrastructure in a separately managed domain
   */
  const finalLoadPath = loadPath.replace(
    /\/locales\/.*\/translation.json/,
    `/locales/${languageToUse}/translation.json`
  )

  try {
    new URL(finalLoadPath)
    const response = await fetch(finalLoadPath)
    if (!response.ok) {
      throw new Error(
        `Translations path error: Failed to fetch the translations - ${finalLoadPath}`
      )
    }
    await response.json()
    return finalLoadPath
  } catch (error) {
    if (error instanceof TypeError) {
      logger.error(
        `Translations path error: not a valid URL - ${finalLoadPath} `,
        error
      )
    } else if (error instanceof SyntaxError) {
      logger.error(
        `Translations path error: not returning a valid JSON object - ${finalLoadPath}`,
        error
      )
    } else {
      logger.error(
        `Translations path error: Error fetching translations from translations path - ${finalLoadPath}`,
        error
      )
    }
  }

  return localPath
}

let determinedLoadPath: string

const getI18n = (loadPath?: string, override?: string) => {
  const loggedMissingKeys = new Set<string>()

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpBackend)
    .init({
      preload: [override ?? navigator.language, 'en'],
      fallbackLng: 'en',
      debug: false,
      backend: {
        crossDomain: true,
        allowMultiLoading: false,
        loadPath: async (lng: string[]) => {
          const path = await getPath(
            lng,
            supportedLanguages,
            loadPath,
            override
          )
          determinedLoadPath = path
          return path
        },
      },
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      saveMissing: true, //required for missing key handler
      missingKeyHandler: (
        lng: readonly string[],
        ns: string,
        key: string,
        fallbackValue: any
      ): void => {
        //check that key is not a regular string or filename
        if (
          !key.includes('.') ||
          key.match(/[\s\n\t]/) ||
          key.includes('...') ||
          isFilename(key) ||
          key.endsWith('.')
        ) {
          return
        }
        const regex = /\/locales\/(?!en\/)[^/]+\/translation\.json/
        // we expect missing keys for non english languages as there is a delay in getting things translated. This falls back to english so is not an actual missing key
        if (!loggedMissingKeys.has(key) && !regex.test(determinedLoadPath)) {
          logger.error(`Missing key: ${key}, loadPath: ${determinedLoadPath}`)
          loggedMissingKeys.add(key)
        }
      },
    })
  /* c8 ignore start */
  i18n.on('loaded', (loaded) => {
    Object.keys(loaded).forEach((lng) => {
      const loadedTranslations = i18n.getResourceBundle(
        override ?? lng,
        'translation'
      )
      let mergedTranslations = {}
      if (override && override !== 'en') {
        const englishTranslations = localTranslations.en
        if (determinedLoadPath !== `/locales/${override}/translation.json`) {
          mergedTranslations = merge(
            {},
            englishTranslations,
            localTranslations[override],
            loadedTranslations
          )
        } else {
          mergedTranslations = merge(
            {},
            englishTranslations,
            localTranslations[override]
          )
        }
      } else {
        mergedTranslations = merge(
          {},
          localTranslations[lng as SupportedLanguage],
          loadedTranslations
        )
      }
      Object.assign(finalTranslations, mergedTranslations)
      i18n.addResourceBundle(lng, 'translation', mergedTranslations)
    })
    resolveGlobalI18nLoaded()
  })
  /* c8 ignore end */
  return i18n
}

let resolveGlobalI18nLoaded: () => void

export const globalI18nLoaded = new Promise<void>(
  (resolve) => (resolveGlobalI18nLoaded = resolve)
)

export default getI18n
