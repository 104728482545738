import { HTTPService } from '@/components/HttpClient'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { EventTopic, RecordCounts } from '@flatfile/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { SheetContext } from './SheetContext'

interface SheetCountsContextType {
  counts?: RecordCounts
  isFetching: boolean
  isError: boolean
  dataUpdatedAt: number
  refetchCounts: () => void
}

interface SheetCountsContextProviderProps {
  children: React.ReactNode
}

const DEBOUNCE_TIME = 2000
export const DEBOUNCE_GET_COUNTS_KEY = 'getCounts'

export const SheetCountsContext = createContext<SheetCountsContextType>({
  isFetching: false,
  isError: false,
  dataUpdatedAt: 0,
  refetchCounts: () => {},
} as SheetCountsContextType)

export const SheetCountsContextProvider = (
  props: SheetCountsContextProviderProps
) => {
  const { sheetId } = useContext(SheetContext)
  const [debounceLoading, setDebounceLoading] = useState(false)
  const debounceLoadingTimerRef = useRef<NodeJS.Timeout | null>(null)
  const debouncedKeys = useRef<Set<string> | null>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    debouncedKeys.current = new Set()
  }, [])

  const triggerDebounceLoading = () => {
    setDebounceLoading(true)
    if (debounceLoadingTimerRef.current) {
      clearTimeout(debounceLoadingTimerRef.current)
    }
    debounceLoadingTimerRef.current = setTimeout(
      () => setDebounceLoading(false),
      DEBOUNCE_TIME
    )
  }

  const {
    data: countsResponse,
    isFetching: isFetchingCounts,
    refetch,
    isError,
    dataUpdatedAt,
  } = useQuery(
    [DEBOUNCE_GET_COUNTS_KEY, sheetId],
    async () => HTTPService.getCounts({ sheetId }),
    {
      staleTime: Infinity,
    }
  )

  const invalidateCountsQueries = (sheetId: string) => {
    queryClient.invalidateQueries([DEBOUNCE_GET_COUNTS_KEY, sheetId])
  }

  const refetchFunction = debounce(() => {
    if (debouncedKeys.current) {
      debouncedKeys.current.forEach((sheetId) =>
        invalidateCountsQueries(sheetId)
      )
      debouncedKeys.current.clear()
    }
  }, DEBOUNCE_TIME)

  useEventSubscriber(
    [
      EventTopic.Commitcreated,
      EventTopic.Layercreated,
      EventTopic.Recordsdeleted,
    ],
    (_, event) => {
      const commitSheet = event.origin.id
      if (commitSheet === sheetId) {
        triggerDebounceLoading()
      }
      if (debouncedKeys.current) {
        debouncedKeys.current.add(commitSheet)
        refetchFunction()
      }
    }
  )

  const isFetching = isFetchingCounts || debounceLoading
  const counts = countsResponse ? countsResponse.data?.counts : undefined
  const contextValue = useMemo(
    () => ({
      counts,
      isError,
      isFetching,
      dataUpdatedAt,
      refetchCounts: refetch,
    }),
    [counts, isError, isFetching, refetch, dataUpdatedAt]
  )

  return (
    <SheetCountsContext.Provider value={contextValue}>
      {props.children}
    </SheetCountsContext.Provider>
  )
}
