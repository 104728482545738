import { UserContext } from '@/contexts/UserContext'
import { useEntitlements } from '@/hooks/useEntitlements'
import { ReactNode, useContext, useEffect, useRef } from 'react'
import { useIntercom } from 'react-use-intercom'
import { EnvironmentsContext } from '../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { Container } from '../../elements/Container'
import { ContentContainer } from '../../elements/ContentContainer'
import { Expand } from '../../elements/Expand'
import { PoweredByFlatfile } from '../../elements/PoweredByFlatfile'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import DevBanner from '../DevBanner/DevBanner'
import { getRuntimeParameters } from '../RuntimeParameters'
import { Sidebar } from '../Sidebar'
import { SidebarExpandTrigger } from '../Sidebar/components/SidebarExpandTrigger'
import { SidebarContainer } from '../Sidebar/elements'
import { SizeWarningModal } from './SizeWarningModal'
import { SpacePageLayoutContainer } from './elements'
import { logger } from '@/utils/logging'

export function SpacePageLayout({ children }: { children: ReactNode }) {
  const { isGuest } = getRuntimeParameters()
  const { space, metadata, sidebarCollapsed, sidebarHiddenFromUser } =
    useContext(SpaceContext)
  const showSidebar = metadata?.sidebarConfig?.showSidebar !== false
  const { workbookId } = useRouteParams()
  const { environment } = useContext(EnvironmentsContext)
  const { hasEntitlement } = useEntitlements()
  const whitelabeling = hasEntitlement('whitelabeling')
  const intercom = hasEntitlement('intercom')
  const { t } = useTypedTranslation()
  const { actor } = useContext(UserContext)
  const { boot } = useIntercom()

  const collapsed = sidebarCollapsed?.get()
  const toggleSidebar = () => {
    /*
      The toggle is only for sheets, so it should always set the sidebar
      collapsed value for sheets when called.
    */
    sidebarCollapsed?.set(!collapsed, { sheet: true })
  }
  const contentContainerRef = useRef(null)

  useEffect(() => {
    if (intercom) {
      if (actor) {
        boot({
          userId: actor?.id,
          email: actor?.email,
          name: actor?.name,
          createdAt: actor?.createdAt
            ? Math.floor(new Date(actor?.createdAt).getTime() / 1000)
            : 0,
          verticalPadding: !whitelabeling ? 45 : 20,
        })
      } else {
        logger.error(`Intercom Boot Error: Actor is unknown`, {
          spaceId: space.id,
          environmentId: environment?.id,
        })
      }
    }
  }, [boot])

  return (
    <>
      {environment && <DevBanner environment={environment} />}
      <SpacePageLayoutContainer>
        {!sidebarHiddenFromUser && (
          <SidebarContainer collapsed={collapsed}>
            {!collapsed && <Sidebar />}
            {workbookId && (
              <SidebarExpandTrigger
                collapsed={collapsed}
                adminOnly={!isGuest && !showSidebar}
                onClick={toggleSidebar}
              />
            )}
          </SidebarContainer>
        )}
        <ContentContainer ref={contentContainerRef}>
          <Expand>
            <Container>
              {children}
              {!whitelabeling && <PoweredByFlatfile spaceId={space.id} />}
            </Container>
          </Expand>
        </ContentContainer>
      </SpacePageLayoutContainer>
      <SizeWarningModal
        componentRef={contentContainerRef}
        sizeLimit={768}
        dependencies={[space]}
      />
    </>
  )
}
