/**
 * Utility function parses and decodes the JWT token and returns it as JSON
 */
import { decodeBase64 } from './buffer'

export function parseJWT(token: string): any | undefined {
  const base64Url = token.split('.')[1]
  const base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/')
  try {
    const jsonPayload = decodeURIComponent(
      decodeBase64(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    return undefined
  }
}
