import { OnSearchValue, OnSort, SortOverride } from '@flatfile/turntable'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import { FieldsContext } from './FieldsContext'
import { SheetContext } from './SheetContext'
import { SearchFields } from '../types'
import {
  DEFAULT_FILTERS,
  DEFAULT_FILTER_BY_VALUE,
  DEFAULT_SORT_OVERRIDE,
  OnClickFilter,
  OnClickFilterField,
  OnFilterByValue,
  OnSearchParams,
  useSearchFilters,
} from '../hooks/useSearchFilters'
import { FilterByValueFields } from '../utils/filters'

const noop = () => {}

interface SearchFiltersContextType {
  searchFields: SearchFields
  sortOverride: SortOverride
  filterByValueFields: FilterByValueFields
  filteredResultsLoading: boolean
  searchByValueLoading: boolean
  isFiltered: boolean
  setSearchByValueLoading: Dispatch<SetStateAction<boolean>>
  setFilteredResultsLoading: Dispatch<SetStateAction<boolean>>
  handleClickFilter: OnClickFilter
  handleClickFilterField: OnClickFilterField
  handleFilterByValue: OnFilterByValue
  handleSearchByValue: OnSearchValue
  handleSearchParams: OnSearchParams
  handleSort: OnSort
}

interface SearchFiltersContextProviderProps {
  children: React.ReactNode
}

export const SearchFiltersContext = createContext<SearchFiltersContextType>({
  searchFields: DEFAULT_FILTERS,
  sortOverride: DEFAULT_SORT_OVERRIDE,
  filterByValueFields: DEFAULT_FILTER_BY_VALUE,
  filteredResultsLoading: false,
  searchByValueLoading: false,
  isFiltered: false,
  setSearchByValueLoading: noop,
  setFilteredResultsLoading: noop,
  handleClickFilter: noop,
  handleClickFilterField: noop,
  handleFilterByValue: noop,
  handleSearchByValue: noop,
  handleSearchParams: noop,
  handleSort: noop,
})

export const SearchFiltersContextProvider = (
  props: SearchFiltersContextProviderProps
) => {
  const { columnConfig, columnsState, unhideColumns } =
    useContext(FieldsContext)
  const { embedded } = useContext(SheetContext)

  const value = useSearchFilters({
    columnConfig,
    columnsState,
    embedded,
    unhideColumns,
  })

  return (
    <SearchFiltersContext.Provider value={value}>
      {props.children}
    </SearchFiltersContext.Provider>
  )
}
