import { ActionsModalContext } from '@/contexts/ActionsModalContext'
import { Action, JobTriggerEnum, JobTypeEnum } from '@flatfile/api'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkRowActionItem } from '../../apps/WorkbookApp/hooks/useBulkRowActions'
import { JobInput } from '../../apps/WorkbookApp/hooks/useCustomActions'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

type Resource = { id: string; actions?: Action[] }

export const useResourceActions = (resource: Resource, type: JobTypeEnum) => {
  const { httpClient } = useContext(SpaceContext)

  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const { setCurrentAction } = useContext(ActionsModalContext)

  const onClickCustomAction =
    (operation: string, resourceId: string) => async (input?: JobInput) => {
      await httpClient.createJob({
        jobConfig: {
          type,
          operation: operation,
          trigger: JobTriggerEnum.Immediate,
          source: resourceId,
          input: input,
        },
      })
    }

  const actions: BulkRowActionItem[] = (resource.actions || []).map(
    (action: Action) => {
      return {
        key: action.operation!,
        label: customT(action.label),
        primary: !!action.primary,
        action: onClickCustomAction(action.operation!, resource.id),
        preAction: function () {
          if (action.confirm || action.inputForm) {
            setCurrentAction(this)
          } else {
            onClickCustomAction(action.operation!, resource.id)(undefined)
          }
        },
        confirm: action.confirm,
        modalText: {
          heading: customT(action.label),
          description: action.description ? customT(action.description) : '',
          confirmButtonText: t(
            'jobs.customActions.modals.startModal.confirmButton'
          ),
          loadingText: t('jobs.customActions.modals.startModal.loadingText'),
          errorMessage: t('jobs.customActions.modals.startModal.errorMessage'),
        },
        tooltip: {
          active: action.tooltip,
        },
        inputForm: action.inputForm,
      }
    }
  )

  return {
    actions,
  }
}
