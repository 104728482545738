import styled from 'styled-components'
import { SidebarContainer } from '../Sidebar'

export const ContentSkeletonMessage = styled.span`
  font-size: 1.5em;
  font-weight: 600;
  color: var(--color-text-light);
`

export const ContentSkeletonOverlay = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 200px;
  text-align: center;
  z-index: 100;
`

export const SidebarSkeletonContainer = styled(SidebarContainer)`
  background-color: var(--color-page);

  h1 {
    margin-top: 0;
    margin-bottom: var(--spacing-sidebar-gutter);
  }

  hr {
    border: 0;
    margin: 0;
    height: 1px;
    background: var(--color-border);
  }

  p {
    margin: 0;
  }
`

export const SidebarSkeletonContents = styled.div`
  padding: 8px var(--spacing-sidebar-content-gutter);
  flex-grow: 1;
`

export const SidebarSkeletonSection = styled.div`
  padding: var(--spacing-sidebar-content-gutter) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sidebar-gutter);
`
