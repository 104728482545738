import { useFileUploadEnabled } from '@/apps/FilesApp/hooks/useFileUploadEnabled'
import { ErrorState } from '@/components/EmptyState'
import { ContentSkeleton } from '@/components/SpacesUISkeleton'
import { SpaceContext } from '@/contexts/SpaceContext'
import { ZIndex } from '@flatfile/design-system'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useFileDragAndDrop } from '../hooks/useFileDragAndDrop'
import { useFileUpload } from '../hooks/useFileUpload'

const Overlay = styled.div<{ isDragging: boolean; sidebarCollapsed: boolean }>`
  position: absolute;
  top: 1em;
  bottom: 1em;
  right: 1em;
  left: 1em;
  display: none;
  z-index: ${ZIndex.level1100};

  ${(props) =>
    props.isDragging &&
    css`
      display: inline-block;
      background-color: var(--color-primary-lightest);
      border: 2px solid var(--color-primary);
      border-radius: 4px;
    `}
`

const OverlayContainer = styled.div`
  height: 100%;
`

export const FileUploadOverlay = ({ children }: { children: JSX.Element }) => {
  const { space, sidebarCollapsed } = useContext(SpaceContext)
  const { loading, error, enableFileUpload } = useFileUploadEnabled()
  const { handleFileUpload } = useFileUpload(space)
  const { handleFileUpload: handleSilentFileUpload } = useFileUpload(
    space,
    true
  )

  const location = useLocation()
  const multiple = location.pathname.includes('files')
  const { dropRef, isDragging } = useFileDragAndDrop(
    handleFileUpload,
    space,
    multiple,
    handleSilentFileUpload
  )

  if (loading) return ContentSkeleton()
  if (error) return <ErrorState />
  if (!enableFileUpload) return <>{children}</>

  return (
    <OverlayContainer ref={dropRef}>
      <Overlay
        data-testid='file-upload-overlay'
        isDragging={isDragging}
        sidebarCollapsed={!!sidebarCollapsed?.get()}
      />
      {children}
    </OverlayContainer>
  )
}
