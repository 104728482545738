import { AMP_TOOLTIP_ID, Icon, IconProps } from '@flatfile/design-system'
import { useTypedTranslation } from '../hooks/useTranslationWrappers'

export const ReadOnlyIcon = (props: Omit<IconProps, 'name'>) => {
  const { t } = useTypedTranslation()
  return (
    <Icon
      name='penSlash'
      size={16}
      viewBox='0 0 16 16'
      data-testid='readonly-icon'
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content={t('sidebar.readOnlyIconTooltip')}
      {...props}
    />
  )
}
