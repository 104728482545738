import pluralize from 'pluralize'

export function formatBytes(bytes: number, decimals?: number) {
  if (bytes === 0) return '0 bytes'
  const k = 1024,
    dm = decimals === undefined ? 2 : decimals,
    sizes = [
      `${pluralize('byte', bytes)}`,
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
