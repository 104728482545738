import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { ContentSkeleton } from '@/components/SpacesUISkeleton'
import { preprocessMarkdown } from '@/utils/markdown'
import { Sheet, Workbook } from '@flatfile/api'
import {
  Badge,
  BadgeRadiusSize,
  BadgeVariant,
  Dropdown,
  DropdownTrigger,
  List,
  MenuType,
  OnSelectionChange,
} from '@flatfile/design-system'
import { fromMaybe } from '@flatfile/shared-ui'
import { Key, useContext, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { ErrorState } from '../../components/EmptyState'
import { PageHeader } from '../../components/PageHeader'
import { SpaceContext } from '../../contexts/SpaceContext'
import { MainContent } from '../../elements/MainContent'
import { useChecklistView } from '../../hooks/useChecklistView'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

const DropdownRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  > div {
    margin-right: 24px;
  }
`

const DropdownContainer = styled.div`
  min-width: 100px;
  max-width: 320px;
`

const FieldCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
  vertical-align: baseline;
`

export const ChecklistView = () => {
  const { space, sidebarCollapsed } = useContext(SpaceContext)
  const {
    data: workbooks,
    isLoading: workbooksLoading,
    isError: isWorkbooksError,
  } = useFlatfileQuery('getAllWorkbooks', { spaceId: space.id })
  const { t } = useTypedTranslation()
  const {
    selectedWorkbookKey,
    setSelectedWorkbookKey,
    selectedSheetKey,
    setSelectedSheetKey,
    hasNonFileWorkbooks,
    nonFileWorkbooks,
  } = useChecklistView(workbooks?.data)

  sidebarCollapsed?.set(false)
  useDocumentTitle(t('checklist.title'), space.name)

  const selectedWorkbook =
    workbooks &&
    workbooks.data &&
    workbooks.data.find((workbook) => workbook.id === selectedWorkbookKey)
  const sheets = selectedWorkbook?.sheets

  useMemo(() => {
    const hasSheets = workbooks && sheets && sheets[0] && sheets[0].id
    // default sheet selection to first one in workbook if there is one
    if (hasSheets) {
      setSelectedSheetKey(sheets![0].id)
    }
  }, [selectedWorkbook])

  useMemo(() => {
    if (nonFileWorkbooks && nonFileWorkbooks.length) {
      // default workbook selection to first one in space if there is one
      setSelectedWorkbookKey(nonFileWorkbooks[0].id)
    }
  }, [workbooks?.data])

  if (workbooksLoading) {
    return ContentSkeleton()
  }

  if (isWorkbooksError) {
    return <ErrorState title={t('checklist.errors.cannotLoadChecklist')} />
  }

  if (!hasNonFileWorkbooks) {
    return (
      <MainContent>
        <PageHeader title={t('checklist.title')} />
        <HeaderRow workbooks={[]} />
        <ErrorState
          title={t('checklist.errors.noWorkbooksTitle')}
          message={t('checklist.errors.noWorkbooksDescription')}
        />
      </MainContent>
    )
  }

  return (
    <MainContent>
      <PageHeader title={t('checklist.title')} />
      <HeaderRow
        workbooks={nonFileWorkbooks!}
        sheets={sheets}
        selectedWorkbook={selectedWorkbookKey}
        selectedSheet={selectedSheetKey}
        setSelectedWorkbook={setSelectedWorkbookKey}
        setSelectedSheet={setSelectedSheetKey}
      />
      {selectedWorkbook && (
        <InnerChecklistView
          workbook={selectedWorkbook}
          sheetId={selectedSheetKey}
        />
      )}
    </MainContent>
  )
}

export const disabledCheck = (value?: Workbook[] | Sheet[]): boolean => {
  if (!value || value.length <= 1) {
    return true
  }
  return false
}

export const HeaderRow = ({
  workbooks,
  sheets,
  selectedWorkbook,
  selectedSheet,
  setSelectedWorkbook,
  setSelectedSheet,
}: {
  workbooks: Workbook[]
  sheets?: Sheet[]
  selectedWorkbook?: Key
  selectedSheet?: Key
  setSelectedWorkbook?: (key: Key | undefined) => void
  setSelectedSheet?: (key: Key | undefined) => void
}) => {
  const { t } = useTypedTranslation()
  const options = workbooks.map((workbook) => ({
    key: workbook.id!,
    label: workbook.name!,
  }))

  const sheetOptions =
    sheets && sheets.length
      ? sheets?.map((sheet) => ({
          key: sheet.id!,
          label: sheet.name!,
        }))
      : []

  const currentWorkbook = workbooks.find((w) => w?.id === selectedWorkbook)
  const currentSheet = sheets?.find((s) => s?.id === selectedSheet)

  const handleWorkbookChange = (id?: Key) => {
    if (id && setSelectedWorkbook) {
      setSelectedWorkbook(id)
    }
  }

  const handleSheetChange = (id?: Key) => {
    if (id && setSelectedSheet) {
      setSelectedSheet(id)
    }
  }
  return (
    <DropdownRow data-testid='checklist-view-header-row'>
      <DropdownContainer
        data-testid='workbook-select'
        className='checklist-dropdown'
      >
        <Dropdown
          searchable
          data-testid='workbook-select'
          id='workbook-selection'
          borderType={MenuType.dropdown}
          onSelectionChange={handleWorkbookChange as OnSelectionChange}
          selectedKey={selectedWorkbook}
          label={t('checklist.dropdowns.headings.workbook')}
          isDisabled={disabledCheck(workbooks)}
          optionsList={options}
          autoFocus
          initializeEmptyInput
          customButton={<DropdownTrigger label={currentWorkbook?.name} />}
        />
      </DropdownContainer>
      <DropdownContainer
        data-testid='sheet-select'
        className='checklist-dropdown'
      >
        <Dropdown
          searchable
          id='sheet-selection'
          borderType={MenuType.dropdown}
          selectedKey={selectedSheet}
          onSelectionChange={handleSheetChange as OnSelectionChange}
          label={t('checklist.dropdowns.headings.sheet')}
          isDisabled={disabledCheck(sheets)}
          optionsList={sheetOptions}
          autoFocus
          initializeEmptyInput
          customButton={<DropdownTrigger label={currentSheet?.name} />}
        />
      </DropdownContainer>
    </DropdownRow>
  )
}

export const InnerChecklistView = ({
  workbook,
  sheetId,
}: {
  workbook: Workbook
  sheetId: Key | undefined
}) => {
  const { t } = useTypedTranslation()
  const headers = [
    {
      key: 'field',
      content: t('checklist.table.headings.field'),
      width: '290px',
    },
    {
      key: 'dataType',
      content: t('checklist.table.headings.dataType'),
      width: '120px',
    },
    { key: 'description', content: t('checklist.table.headings.description') },
  ]

  const getDataTypeString = (dataType: string) => {
    switch (dataType) {
      case 'string':
        return t('checklist.table.dataTypes.string')
      case 'boolean':
        return t('checklist.table.dataTypes.boolean')
      case 'enum':
        return t('checklist.table.dataTypes.enum')
      case 'number':
        return t('checklist.table.dataTypes.number')
      case 'date':
        return t('checklist.table.dataTypes.date')
      case 'reference':
        return t('checklist.table.dataTypes.reference')
      default:
        return t('checklist.table.dataTypes.unknown')
    }
  }

  const currentSheet = useMemo(() => {
    return workbook?.sheets?.find((sheet) => sheet.id === sheetId)
  }, [workbook, sheetId])

  const listData = useMemo(() => {
    const list = currentSheet?.config?.fields.map((field, i) => {
      const data = {
        field: (
          <FieldCell>
            <span>{field.label}</span>
            {field.constraints?.find((x) => x.type === 'required') && (
              <Badge
                variant={BadgeVariant.Pinata}
                radiusSize={BadgeRadiusSize.Small}
                label={t('checklist.table.fieldTagRequired')}
              />
            )}
            {field.constraints?.find((x) => x.type === 'unique') && (
              <Badge
                variant={BadgeVariant.Pigeon}
                radiusSize={BadgeRadiusSize.Small}
                label={t('checklist.table.fieldTagUnique')}
              />
            )}
            {field.constraints?.find((x) => x.type === 'computed') && (
              <Badge
                variant={BadgeVariant.Pigeon}
                radiusSize={BadgeRadiusSize.Small}
                label={t('checklist.table.fieldTagComputed')}
              />
            )}
          </FieldCell>
        ),
        dataType: (
          <Badge
            variant={BadgeVariant.Pigeon}
            label={getDataTypeString(field.type)}
            radiusSize={BadgeRadiusSize.Small}
          />
        ),
        description: (
          <div className='data-checklist-description'>
            <ReactMarkdown>
              {preprocessMarkdown(fromMaybe(field.description, ''))}
            </ReactMarkdown>
          </div>
        ),
      }
      return { data, key: field.key }
    })
    return list ?? []
  }, [currentSheet?.config])

  if (!currentSheet) {
    return <ErrorState title={t('checklist.errors.cannotLoadSheet')} />
  }

  return <List headers={headers} data={listData} verticalAlign={'baseline'} />
}
