import { Workbook } from '@flatfile/api'

export const isPinnedWorkbook = (workbook: Workbook) => {
  return (
    workbook.labels?.includes('primary') || workbook.labels?.includes('pinned')
  )
}
/**
 * Utility function to check if a Workbook is connected by checking the workbooks
 * metadata properties for a valid list of connections
 * @param workbook
 * @returns boolean
 */
export const isConnectedWorkbook = (workbook?: Workbook) => {
  const hasConnectionLabel = workbook?.labels?.includes('connection') ?? false
  const hasConnection: boolean = workbook?.metadata?.connections?.length > 0

  return hasConnectionLabel && hasConnection
}

export const sortWorkbooks = (
  workbooks: Workbook[] = [],
  workbookOrder: string[] = [] // List of workbook ids
) => {
  if (workbooks.length === 0) return []

  // Separate workbooks into pinned and non-pinned
  const pinnedWorkbooks = workbooks.filter(
    (workbook) =>
      isPinnedWorkbook(workbook) && !workbook.labels?.includes('file')
  )
  const nonPinnedWorkbooks = workbooks.filter(
    (workbook) =>
      !isPinnedWorkbook(workbook) && !workbook.labels?.includes('file')
  )

  // Sort pinned workbooks by their IDs so that they always get sorted
  // in the same order if multiple pinned workbooks are present
  pinnedWorkbooks.sort((a, b) => (a.id < b.id ? -1 : 1))

  // Sort workbooks by the provided order
  const orderedWorkbooks = workbookOrder
    ?.map((orderId) =>
      nonPinnedWorkbooks.find((workbook) => workbook.id === orderId)
    )
    // Filter out undefined values
    .filter((wb) => wb) as Workbook[]

  // Prepare a Set of IDs that have been ordered
  const orderedIds = new Set(orderedWorkbooks.map((wb) => wb.id))

  // Filter out the ordered workbooks and sort the rest alphabetically by their
  // names
  const otherWorkbooks = nonPinnedWorkbooks.filter(
    (workbook) => !orderedIds.has(workbook.id)
  )
  otherWorkbooks.sort((a, b) => a.name.localeCompare(b.name))

  // Concatenate the results: pinned first, then ordered, then the rest
  return [...pinnedWorkbooks, ...orderedWorkbooks, ...otherWorkbooks]
}

export const includesNonFileWorkbooks = (
  workbooks?: Workbook[]
): boolean | undefined => workbooks?.some((wb) => !wb.labels?.includes('file'))
