import {
  Button,
  Modal,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ClearRulesModal = ({
  onClose,
  onConfirm,
  loading,
}: {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      content={
        <>
          <DeleteModalContainer data-testid='delete-file-modal'>
            <ModalHeader>{t('mapping.clearFields.modalHeader')}</ModalHeader>
            <ModalText>{t('mapping.clearFields.modalText')}</ModalText>
          </DeleteModalContainer>
          <ModalButtonRow>
            <ModalCloseButton onClose={onClose} />
            <Button
              variant='warning'
              loading={loading}
              onPress={onConfirm}
              data-testid='clearMapping-confirm'
              label={t('mapping.clearFields.modalLabel')}
            />
          </ModalButtonRow>
        </>
      }
    />
  )
}
