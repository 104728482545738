import { paragon, AuthenticatedConnectUser } from '@useparagon/connect'
import { useCallback, useRef, useState } from 'react'
import { useFileUpload } from './useFileUpload'
import { Space } from '@flatfile/api'
import { logger } from '@/utils/logging'

export type ParagonIntegration = 'googledrive' | 'onedrive' | 'box'

type FileResponse = {
  fileName: string
  result: Result
  success: boolean
}

type Result = {
  errors: ErrorItem[]
}

type ErrorItem = {
  key: string
  message: string
}

interface GoogleDriveFile {
  id: string
  name: string
  mimeType: string
  [key: string]: any
}

const mimeMap = {
  'application/vnd.google-apps.spreadsheet': {
    exportMimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  },
  'application/vnd.google-apps.document': {
    exportMimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
  },
  'application/vnd.google-apps.presentation': {
    exportMimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: '.pptx',
  },
  'application/vnd.google-apps.photo': {
    exportMimeType: 'image/png',
    extension: '.png',
  },
  'application/vnd.google-apps.script': {
    exportMimeType: '	application/vnd.google-apps.script+json',
    extension: '.json',
  },
  'application/vnd.google-apps.drawing': {
    exportMimeType: 'image/png',
    extension: '.png',
  },
}

function getUploadProps(service: string, file: any) {
  let filesUrl = ''
  let fileName = file.name
  let mimeType = undefined

  if (service === 'box') {
    filesUrl = `https://api.useparagon.com/projects/${
      import.meta.env.VITE_PARAGON_PROJECT_ID
    }/sdk/proxy/box/files/${file.id}/content`
  } else if (service === 'googledrive') {
    const { name, exportMimeType } = getGoogleFileProps(file)
    const isExport = file.mimeType.includes('google-apps')
    fileName = name
    mimeType = exportMimeType
    filesUrl = `https://api.useparagon.com/projects/${
      import.meta.env.VITE_PARAGON_PROJECT_ID
    }/sdk/proxy/googledrive/files/${file.id}${
      isExport ? `/export?mimeType=` + mimeType : '?alt=media'
    }`
  }

  return { filesUrl, fileName, mimeType }
}

export const useParagonConnect = ({
  space,
  token,
  setLoading,
}: {
  space: Space
  token?: string
  setLoading: Function
}) => {
  const boxToken = useRef<string | undefined>()
  const { handleFileUpload } = useFileUpload(space)
  const { handleFileUpload: handleSilentFileUpload } = useFileUpload(
    space,
    true
  )
  const [showBoxFilePicker, setShowBoxFilePicker] = useState(false)

  const handleGoogleClick = async () => {
    ;(await userIsConnected('googledrive'))
      ? openGoogleFilePicker()
      : connectWithGoogle(true)
  }

  const handleBoxClick = async () => {
    ;(await userIsConnected('box')) ? openBoxFilePicker() : connectWithBox(true)
  }

  const userIsConnected = async (integration: ParagonIntegration) => {
    const user = await paragon.getUser()
    return !!(user as AuthenticatedConnectUser)?.integrations?.[integration]
      ?.enabled
  }

  const connectWithGoogle = useCallback(async (showPicker: boolean) => {
    await paragon.connect('googledrive', {
      /* c8 ignore start */
      overrideRedirectUrl: `${
        import.meta.env.VITE_BASE_API_URL
      }/webhook/paragon-redirect`,
      onSuccess: () => showPicker && openGoogleFilePicker(),
      onError: (error) => {
        setLoading(false)
        logger.error('Error connecting to paragon', error)
      },
    })
    /* c8 ignore end */
  }, [])

  const openGoogleFilePicker = async () => {
    setLoading(true)
    try {
      // required until paragon fix bug with tokens
      await paragon.request('googledrive', '/files', {
        method: 'GET',
        body: undefined,
        headers: undefined,
      })

      const picker = await new paragon.ExternalFilePicker('googledrive', {
        onFileSelect: async (files: any) => {
          await handleFileSelection(files.docs, 'googledrive')
        },
        allowMultiSelect: true,
      })

      await picker.init({
        developerKey: import.meta.env.VITE_GOOGLE_API_KEY,
      })

      picker.open()
    } catch (error) {
      logger.error('Error opening google drive file picker', error)
    } finally {
      setLoading(false)
    }
  }

  //box
  const openBoxFilePicker = async () => {
    const { accountAuth } = await paragon.getIntegrationAccount('box', {
      includeAccountAuth: true,
    })
    boxToken.current = accountAuth?.OAUTH_ACCESS_TOKEN
    //required until token bug is fixed
    await paragon.request('box', '/folders/0', {
      method: 'GET',
      body: undefined,
      headers: undefined,
    })
    if (boxToken.current) {
      setShowBoxFilePicker(true)
    }
  }

  const connectWithBox = async (showPicker: boolean) => {
    await paragon.connect('box', {
      /* c8 ignore start */
      onSuccess: async () => {
        showPicker && (await openBoxFilePicker())
      },
      onError: (error) => {
        logger.error('Error connecting to box via paragon', error)
      },
    })
    /* c8 ignore end */
  }

  const handleFileSelection = async (
    files: any[],
    service: ParagonIntegration
  ) => {
    setLoading(true)
    setShowBoxFilePicker(false)
    const fileObjects = await Promise.all(
      files.map(async (file: any) => {
        const { filesUrl, fileName, mimeType } = getUploadProps(service, file)

        return fetchAndPrepareFile({
          url: filesUrl,
          token: token!,
          fileName,
          mimeType,
        })
      })
    )

    const filteredFileObjects = fileObjects.filter(
      (file): file is File => file !== null
    )

    if (filteredFileObjects.length > 0) {
      await Promise.all(
        [...filteredFileObjects].map((fileObj, index) =>
          index === 0
            ? handleFileUpload(fileObj, service)
            : handleSilentFileUpload(fileObj, service)
        )
      )
    }

    setLoading(false)
  }

  return {
    userIsConnected,
    handleFileSelection,
    showBoxFilePicker,
    setShowBoxFilePicker,
    boxToken,
    handleGoogleClick,
    handleBoxClick,
    connectWithGoogle,
    connectWithBox,
  }
}

function getGoogleFileProps(file: GoogleDriveFile) {
  // Check if the MIME type is a Google Apps MIME type
  if (file.mimeType.includes('vnd.google-apps')) {
    if (file.mimeType in mimeMap) {
      const { exportMimeType, extension } =
        mimeMap[file.mimeType as keyof typeof mimeMap]
      return {
        exportMimeType,
        name: file.name.endsWith(extension)
          ? file.name
          : `${file.name}${extension}`,
      }
    }
    // MIME type is not found in the map, return as txt
    return { exportMimeType: 'text/plain', name: file.name + '.txt' }
  }
  return { exportMimeType: file.mimeType, name: file.name }
}

const fetchAndPrepareFile = async ({
  url,
  token,
  fileName,
  mimeType,
}: {
  url: string
  token: string
  fileName: string
  mimeType: string | undefined
}) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Paragon-Use-Raw-Response': 'true',
      },
    })

    if (!response.ok) {
      logger.error(
        `Received status code: ${response.status} for file: ${fileName}`,
        response
      )
      return null
    }

    const blob = await response.blob()
    return new File([blob], fileName, { type: mimeType })
  } catch (error) {
    const typedError = error instanceof Error ? error : new Error(String(error))
    logger.error(`Error fetching file: ${fileName}`, typedError)
    return null
  }
}
