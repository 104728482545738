import { useState, useContext, useCallback, createContext } from 'react'

export interface IPanelsContext {
  openPanel: string | null
  handleOpenPanel: (heading: 'Jobs' | 'Versions') => void
  handleClosePanel: () => void
}

const PanelsContext = createContext<IPanelsContext>({
  openPanel: null,
  handleOpenPanel: (heading) => {},
  handleClosePanel: () => {},
})

export const usePanelsContext = () => useContext(PanelsContext)

export const PanelsProvider = (props: { children: JSX.Element }) => {
  const [openPanel, setOpenPanel] = useState<string | null>(null)

  const handleOpenPanel = useCallback((heading: string) => {
    setOpenPanel(heading)
  }, [])

  const handleClosePanel = useCallback(() => {
    setOpenPanel('')
  }, [])

  return (
    <PanelsContext.Provider
      value={{ openPanel, handleOpenPanel, handleClosePanel }}
    >
      {props.children}
    </PanelsContext.Provider>
  )
}
