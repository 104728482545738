import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { useSheetHasJob } from '@/hooks/useSheetHasJob'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  CompositeUniqueConstraint,
  ErrorContext,
  EventTopic,
  Filter,
  Sheet,
  SheetConstraint,
} from '@flatfile/api'
import {
  Icon,
  MenuOptionProps,
  MenuOptionsList,
  useSynchronizedRef,
} from '@flatfile/design-system'
import {
  PopoverContext,
  PopoverMessage,
  WarningIcon,
  copyToClipboard,
  fromMaybe,
} from '@flatfile/shared-ui'
import {
  ColumnConfigProps,
  OnSearchValue,
  RowData,
  useDataBufferContext,
  useTableScroller,
} from '@flatfile/turntable'
import { isEqual } from 'lodash'
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ColumnsState, UnhideColumns } from '../ColumnManagement/types'
import {
  OnRefetchRows,
  OnRefetchTable,
  SearchFields,
  ToolbarRecordCounts,
} from '../types'
import { getCurrentRowCount, getToolbarCounts } from '../utils/tableUtils'
import { useRecordCountsData } from './useRecordCountsData'

interface UseSheetDataProps {
  sheetId: string
  sheet: Sheet | undefined
  workbookId: string
  columnConfig: ColumnConfigProps[]
  columnsState: ColumnsState
  searchFields: SearchFields
  readOnlySheet?: boolean
  canDeleteFromSheet: boolean
  canAddToSheet: boolean
  handleSearchByValue: OnSearchValue
  hiddenColumnsCount: number
  unhideColumns: UnhideColumns
}

export const PAGE_SIZE = 100

export const getHasSearchFilters = (filters: SearchFields) => {
  return Object.values(filters).some((f) => !!f)
}

export const eventMatchesSheetOrWorkbook = (
  sheet: Sheet | undefined,
  event: any | undefined
) => {
  const eventResponse = fromMaybe(
    JSON.parse(fromMaybe(event.message, '{}')),
    {}
  )
  const { context } = eventResponse
  return (
    context &&
    sheet &&
    (context.sheetId === sheet.id ||
      (context.workbookId && context.workbookId === sheet?.workbookId))
  )
}

export const useSheetViewData = ({
  sheetId,
  sheet,
  workbookId,
  columnConfig,
  columnsState,
  searchFields,
  readOnlySheet,
  canDeleteFromSheet,
  canAddToSheet,
  handleSearchByValue,
  hiddenColumnsCount,
  unhideColumns,
}: UseSheetDataProps) => {
  const [rowCount, setRowCount] = useState<number>(0)
  const [toolbarCounts, setToolbarCounts] = useState<
    ToolbarRecordCounts | undefined
  >(undefined)
  const [showEmptyOverlay, setShowEmptyOverlay] = useState(false)
  const { t } = useTypedTranslation()
  const versionIdsRef = useRef<{
    lastVersionId?: string
    errorCountsVersionId?: string
  }>({
    lastVersionId: undefined,
    errorCountsVersionId: undefined,
  })

  const isEmptyLoading = useRef(true)
  /*
    Saving handleSearchByValue and unhideColumns on the ref ensures that the value
    is always up to date with the current state, as opposed to state that exists when
    a popover is created.
  */
  const onSearchByValue = useSynchronizedRef(handleSearchByValue)
  const onUnhideColumns = useSynchronizedRef(unhideColumns)

  const { showPopover, hidePopover } = useContext(PopoverContext)
  const { buffer, initLoad, isLoading, isErrored, onLoadRows } =
    useDataBufferContext()
  const { scrollToTop } = useTableScroller()
  const sheetHasJob = useSheetHasJob(sheetId, workbookId)

  const hasBlockingJobs = useRef(sheetHasJob)

  useEffect(() => {
    hasBlockingJobs.current = sheetHasJob
  }, [sheetHasJob])

  const {
    counts,
    filteredCounts,
    isLoadingCounts,
    hasCountsError,
    refetchErrorsByFieldCounts,
    refetchCounts,
  } = useRecordCountsData({
    sheetId,
    ...searchFields,
  })

  const clearBufferHistories = (all?: boolean) => {
    /* Removes history from the buffer so that fresh data gets loaded */
    const bufferId = buffer._bufferId
    const bufferKeys = Array.from(buffer._recordsCache.keys())
    bufferKeys.forEach((key) => {
      buffer._pageCache.delete(key)

      if (all || key !== bufferId) {
        /*
          For the current page, we don't remove these values in order to prevent
          visual disruption.
        */
        buffer._cellHistoryCache.delete(key)
        buffer._recordsCache.delete(key)
        buffer._totalRecordsCache.delete(key)
      }
    })
  }

  const hideEmptyOverlay = useCallback(
    () => setShowEmptyOverlay(false),
    [setShowEmptyOverlay]
  )

  const handleEmptyOverlay = () => {
    if (!getHasSearchFilters(searchFields) && !buffer.bufferedRecords.length) {
      setShowEmptyOverlay(true)
    } else {
      setShowEmptyOverlay(false)
    }
  }

  /**
   * Utility function which causes a complete refresh of the table
   * by clearing the current buffer history and executing a complete
   * refetch of rows
   */
  const refetchTable: OnRefetchTable = async (all) => {
    clearBufferHistories(all)
    await refetchRows()
  }

  const refetchRows: OnRefetchRows = async () => {
    buffer.onDataLoadError = undefined
    await buffer.loadPages()

    buffer.onDataLoadError = handlePageLoadError
    handleEmptyOverlay()
  }

  const handleQueryLoadError = () => {
    const popover = {
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          {t('sheet.popovers.errors.invalidFFQL')}
        </PopoverMessage>
      ),
      action: {
        onClick: () => {
          onSearchByValue.current?.({ searchValue: '', searchField: '', q: '' })
          hidePopover()
        },
        label: 'Clear',
      },
      onClose: hidePopover,
    }
    showPopover(popover)
    return popover
  }

  const handlePageLoadError = () => {
    const popover = {
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          {t('sheet.popovers.errors.recordsLoadFailed')}
        </PopoverMessage>
      ),
      action: {
        onClick: async () => {
          hidePopover()
          await refetchRows()
        },
        label: 'Retry',
      },
      onClose: hidePopover,
    }
    showPopover(popover)
    return popover
  }

  const handleCellUpdateError = useCallback(
    (error: ErrorContext) => {
      /**
       * 304 Not Modified on cell edit means the update has not been made because it would
       * not modify the result. This can happen when agent code fills in a null value and the user
       * tries to delete the agent generated value. The back end compares null with null and determines
       * the update does not need to happen, so a 304 status is returned.
       */
      if (error.response?.status === 304) {
        return
      }
      const popover = {
        icon: <WarningIcon name='alertTriangle' />,
        message: (
          <PopoverMessage>
            {t('sheet.popovers.errors.cellUpdateFailed')}
          </PopoverMessage>
        ),
        onClose: hidePopover,
      }
      showPopover(popover)
    },
    [showPopover, hidePopover]
  )

  const handleInitLoad = async () => {
    await initLoad()
    isEmptyLoading.current = false
    handleEmptyOverlay()
    buffer.onDataLoadError = handlePageLoadError
    buffer.onDataUpdateError = handleCellUpdateError
  }

  useEffect(() => {
    if (isErrored && searchFields.q) {
      handleQueryLoadError()
    }
  }, [isErrored])

  /**
   * There are many influences (internal/external) which could cause a sheet and/or workbook to be
   * updated. In most cases, the updates should be "silent" with no impacts to the user's experience
   * or current operating position.
   *
   * The only reason we have currently identified for resetting a user's scrollPosition in the table
   * is if the current sheetId changes.
   */
  useEffect(() => {
    scrollToTop()
  }, [sheetId])

  useEffect(() => {
    const onLoad = async () => {
      handleEmptyOverlay()

      buffer.initialize({
        bufferId: `${fromMaybe(sheetId, '')}:${JSON.stringify(searchFields)}`,
        params: { searchFields },
        pageSize: PAGE_SIZE,
        columnConfig,
        totalRecords: rowCount,
      })
      buffer._lockActiveRecords = true

      if (!buffer.bufferedRecords.length) {
        isEmptyLoading.current = true
      } else {
        buffer.refreshCurrentPages()
      }

      await handleInitLoad()
    }
    onLoad()
  }, [searchFields, columnConfig, sheetId])

  useEffect(() => {
    buffer.setTotalRecords(rowCount)
    /* Removes cell edit history from the current buffer because the data shape has changed */
    buffer._cellHistoryCache.delete(buffer._bufferId)
  }, [rowCount])

  const hasError = !sheet || isErrored
  const hasNoData = hasError || isLoading || isLoadingCounts

  const handleVersionUpdated = async ({
    sinceVersionId,
  }: {
    sinceVersionId: string
  }) => {
    const bufferId = buffer._bufferId
    const result = await onLoadRows({ sinceVersionId }, columnConfig)
    const bufferedRecords = buffer.bufferedRecords
    const records = result?.reduce((memo: RowData[], record: RowData) => {
      const bufferedRecord = bufferedRecords.find(
        (r: RowData) => r.id === record.id
      )
      if (bufferedRecord) {
        memo.push({
          ...record,
          position: bufferedRecord.position,
          rowIndex: bufferedRecord.rowIndex,
        })
      }
      return memo
    }, [] as RowData[])
    buffer.addRecords({ records, bufferId })
  }

  const handleRecordsChangedEvent = async (event: any) => {
    const eventResponse = fromMaybe(JSON.parse(event.message), {})
    const { context } = eventResponse
    const correctSheet = context && context.sheetId === sheetId

    if (!correctSheet) {
      return
    }
    if (hasBlockingJobs.current) {
      return
    }

    if (context.versionId) {
      versionIdsRef.current.lastVersionId = context.versionId
    }
    /*
      Temporarily removing fetching with sinceVersionId, as a versionId can be
      provided when we want to make a full refetch.

      if (context.versionId) {
        clearBufferHistories()
        await handleVersionUpdated({ sinceVersionId: context.versionId })
      } else {
    */
    await refetchTable()
    /*
      }
    */
  }

  const handleJobCompletedEvent = async (event: any) => {
    const correctSheet = eventMatchesSheetOrWorkbook(sheet, event)
    if (!correctSheet) {
      return
    }
    await refetchTable()
  }

  const getErrorsByFieldCounts = async () => {
    if (
      !counts?.errorsByField ||
      versionIdsRef.current.lastVersionId !==
        versionIdsRef.current.errorCountsVersionId
    ) {
      await refetchErrorsByFieldCounts()
      versionIdsRef.current.errorCountsVersionId =
        versionIdsRef.current.lastVersionId
    }
  }

  useEventSubscriber(
    [
      EventTopic.Commitcreated,
      EventTopic.Layercreated,
      EventTopic.Sheetupdated,
      EventTopic.Recordsdeleted,
    ],
    handleRecordsChangedEvent
  )

  useEventSubscriber(
    [EventTopic.Jobcompleted, EventTopic.Jobfailed],
    handleJobCompletedEvent
  )

  useEffect(() => {
    if ((hasError && !isLoading) || (isLoading && isEmptyLoading.current)) {
      setRowCount(0)
    } else if (toolbarCounts && !hasError) {
      setRowCount(getCurrentRowCount(searchFields, toolbarCounts))
    }
  }, [toolbarCounts, searchFields, hasError, isLoading, isEmptyLoading])

  useEffect(() => {
    if (counts && filteredCounts) {
      const countsWithFiltered = getToolbarCounts(
        searchFields,
        counts,
        filteredCounts
      )

      if (!isEqual(countsWithFiltered, toolbarCounts)) {
        setToolbarCounts(countsWithFiltered)
      }
    }
  }, [counts, searchFields, filteredCounts])

  const additionalRowCount = useMemo(() => {
    const empty = hasNoData && isEmptyLoading.current
    if (empty || readOnlySheet || !canAddToSheet) {
      return undefined
    }
    if (!canDeleteFromSheet) {
      return 1
    }
    return 15
  }, [
    hasNoData,
    readOnlySheet,
    canAddToSheet,
    canDeleteFromSheet,
    rowCount,
    isEmptyLoading,
  ])

  const errorFields = useMemo(() => {
    const temp = columnConfig.reduce((memo, column) => {
      const count = counts?.errorsByField?.[column.value]
      if (count) {
        const hidden = columnsState[column.value]?.hidden
        memo.push({
          key: column.value,
          label: column.label ?? column.value,
          badge: count,
          tooltip: hidden
            ? { active: t('sheet.columnManagement.errorColumnTooltip') }
            : undefined,
        })
      }
      return memo
    }, [] as MenuOptionsList)
    // Add composite constraints to dropdown if available
    compositeUniques(sheet?.config.constraints)?.forEach((constraint) => {
      const count = counts?.errorsByField?.[constraint.name!]
      if (count) {
        temp.push({
          key: constraint.name!,
          label: `Composite ${constraint.name}`,
          badge: count,
        })
      }
    })
    return temp
  }, [columnConfig, columnsState, counts])

  const handleCopy = useCallback((text?: string) => {
    copyToClipboard(text)
    showPopover(
      {
        icon: <Icon name='clipboardCheck' />,
        message: <div>{t('sheet.info.copiedPopover')}</div>,
      },
      true
    )
  }, [])

  const handleHiddenErrorFields = useCallback(() => {
    /*
      If the user switches to the error tab, and has hidden errored columns,
      we want to inform them and give them the chance to unhide them.

      Therefore, if the user is not on the error tab, and has no hidden columns,
      we can ignore the rest of the logic.
    */
    if (searchFields.filter !== Filter.Error || !hiddenColumnsCount) {
      return
    }
    if (!errorFields.length) {
      /*
        If there are no error fields, we load them. This can be a heavy request,
        so we mitigate it by only calling it as needed.
      */
      getErrorsByFieldCounts()
    } else {
      /*
        Once error fields have loaded, we compare them against the hidden columns
        and only show a popover if there's overlap.
      */
      const hiddenErrorFieldKeys = errorFields.reduce(
        (memo: (string | Key)[], field: MenuOptionProps) => {
          if (columnsState[field.key]?.hidden) {
            memo.push(field.key)
          }
          return memo
        },
        []
      )

      if (hiddenErrorFieldKeys.length) {
        const popover = {
          icon: <Icon name='columns' />,
          message: (
            <>
              {t('sheet.columnManagement.toast.message', {
                count: hiddenErrorFieldKeys.length,
              })}
            </>
          ),
          action: {
            onClick: () => {
              onUnhideColumns.current?.(hiddenErrorFieldKeys)
              hidePopover()
            },
            label: t('sheet.columnManagement.toast.action'),
          },
        }
        showPopover(popover)
        return popover
      }
    }
  }, [searchFields.filter, hiddenColumnsCount, errorFields.length])

  useEffect(() => {
    handleHiddenErrorFields()
  }, [searchFields.filter, errorFields.length])

  return {
    counts: toolbarCounts,
    filteredCounts,
    isLoading,
    isLoadingCounts,
    isLoadingEmpty: (isLoading || isLoadingCounts) && isEmptyLoading.current,
    isEmpty: hasNoData,
    hasError,
    handleCopy,
    tableError: isErrored,
    hasCountsError,
    refetchTable,
    refetchRows,
    refetchCounts,
    rowCount,
    errorFields,
    additionalRowCount,
    showEmptyOverlay,
    hideEmptyOverlay,
    onVersionUpdated: handleVersionUpdated,
    onQueryLoadError: handleQueryLoadError,
    onRecordsChangedEvent: handleRecordsChangedEvent,
    onPageLoadError: handlePageLoadError,
    onCellUpdateError: handleCellUpdateError,
    onHiddenErrorFields: handleHiddenErrorFields,
    onJobCompletedEvent: handleJobCompletedEvent,
    getErrorsByFieldCounts,
  }
}

function compositeUniques(
  constraints?: SheetConstraint[]
): undefined | CompositeUniqueConstraint[] {
  return constraints?.filter(
    (c) => c.type === 'unique'
  ) as CompositeUniqueConstraint[]
}
