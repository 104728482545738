import { PlanController } from '@/api/controllers/PlanController'
import { Rule, RuleController } from '@/api/controllers/RuleController'
import { FieldOption } from '@/api/controllers/SheetController'
import { useController } from '@/api/controllers/useController'
import { Observable } from '@/api/observable'
import { MatchIcon } from '@/assets/icons/MatchIcon'
import { FieldWrapper } from '@/elements/FieldWrapper'
import { MatchField } from '@/elements/MatchField'
import { MatchIconWrap } from '@/elements/MatchIconWrap'
import { ReactSelectStyles } from '@/elements/ReactSelectStyles'
import { Row } from '@/elements/Row'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { preprocessMarkdown, renderMarkdownAsHtml } from '@/utils/markdown'
import { Plan, Property } from '@flatfile/api'
import { Spinner, Tooltip } from '@flatfile/design-system'
import { WarningIcon, fromMaybe } from '@flatfile/shared-ui'
import { FC, useMemo, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import ReactMarkdown from 'react-markdown'
import ReactSelect, { components } from 'react-select'
import styled from 'styled-components'
import { Code } from '../elements'

/* These label styles help the description tooltip appear correctly */
const LabelWrapper = styled.span`
  position: absolute;
  top: 6px;
`

const LabelText = styled.span`
  width: 100%;
  display: inline-block;
  height: 100%;
`

const htmlStringContainsLinks = (html: string) => {
  const regex = new RegExp('href=')
  return regex.test(html)
}

export const getOptionProps = (option?: FieldOption, menu?: boolean) => {
  if (!option) return {}
  const hasTooltip = option.description
  return {
    'data-tooltip-id': hasTooltip
      ? `${menu ? option.value : ''}field-description`
      : undefined,
    'data-tooltip-content': option.description,
    children: option.label + (option.required ? ' *' : ''),
  }
}

export function RuleCard({
  observable,
  rule,
  planController,
  onChange,
  onFocus,
  disabled = false,
  fieldAdded,
}: Props_RuleCard) {
  const [destVal, setDestVal] = useState<string | undefined | null>(rule.dest)
  const { t } = useTypedTranslation()

  const controller = useController(RuleController, rule, planController)
  const groups = useMemo(
    () => planController.getDestinationOptions(fieldAdded),
    [controller]
  )
  const options = useMemo(
    () => planController.dest.getFieldOptions(fieldAdded),
    [controller, fieldAdded]
  )
  const currentOption = useMemo(
    () => options.find((o) => o.value === destVal),
    [options, destVal]
  )

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onMenuOpen = () => setIsMenuOpen(true)
  const onMenuClose = () => setIsMenuOpen(false)

  return (
    <FieldWrapper
      key={rule.dest ? `dest_${rule.src}` : `src_${rule.src}`}
      onMouseEnter={() => {
        onFocus?.(controller)
      }}
      data-testid='edge-row'
    >
      <Row>
        <MatchField data-testid='source-field-label'>
          <RawLabel prop={controller.srcProp} />
        </MatchField>
        <MatchIconWrap>
          {observable?.isLoading ? (
            <Spinner />
          ) : observable?.error ? (
            <WarningIcon data-testid='warning-icon' name='alertCircle' />
          ) : (
            <MatchIcon />
          )}
        </MatchIconWrap>
        <MatchField data-testid='destination-field-select'>
          <ReactSelect
            isDisabled={disabled}
            aria-label='option-select'
            menuPortalTarget={document.body}
            isClearable
            options={groups}
            getOptionValue={(o) => o.value}
            value={options.find((o) => o.value === destVal)}
            minMenuHeight={300}
            menuPlacement='auto'
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            placeholder={t('mapping.dropdown.placeholder')}
            components={{
              IndicatorSeparator: () => null,
              SingleValue: ({ children, ...props }) => {
                const optionProps = getOptionProps(currentOption, false)
                const tooltipId = optionProps['data-tooltip-id']
                const html = renderMarkdownAsHtml(
                  fromMaybe(currentOption?.description, '')
                )

                const hasLinks = useMemo(
                  () => htmlStringContainsLinks(html),
                  [html]
                )
                const clickable = hasLinks ? '-clickable' : ''
                return (
                  <components.SingleValue {...props}>
                    <LabelWrapper
                      data-tooltip-id={tooltipId + clickable}
                      data-tooltip-html={!isMenuOpen ? html : undefined}
                    >
                      {optionProps.children}
                    </LabelWrapper>
                  </components.SingleValue>
                )
              },
              Option: ({ children, ...props }) => {
                const optionProps = getOptionProps(props.data, true)
                const tooltipId = optionProps['data-tooltip-id']
                const html = renderToStaticMarkup(
                  <ReactMarkdown>
                    {preprocessMarkdown(fromMaybe(props.data?.description, ''))}
                  </ReactMarkdown>
                )
                const hasLinks = useMemo(
                  () => htmlStringContainsLinks(html),
                  [html]
                )

                return (
                  <components.Option {...props}>
                    {tooltipId && (
                      <Tooltip
                        id={tooltipId}
                        place='right'
                        float
                        positionStrategy='fixed'
                        clickable={hasLinks}
                      />
                    )}
                    <LabelText {...optionProps} data-tooltip-html={html} />
                  </components.Option>
                )
              },
            }}
            styles={ReactSelectStyles}
            onChange={(newValue) => {
              setDestVal(newValue?.value)
              const newRule = {
                ...rule,
                dest: newValue?.isAddField ? rule.src : newValue?.value,
                isNewField: newValue?.isAddField,
              }
              onChange?.(newRule)
            }}
          />
        </MatchField>
      </Row>
    </FieldWrapper>
  )
}

const RawLabel: FC<{ prop?: Property | null }> = ({ prop }) => {
  if (!prop) {
    return <div>no source</div>
  }
  if (prop.label === prop.key) {
    return <Code>{prop.key}</Code>
  }
  return <span>{prop.label}</span>
}

type Props_RuleCard = {
  observable?: Observable<Plan>
  rule: Rule
  planController: PlanController
  onFocus?: (rule: RuleController) => void
  onChange?: (rule: Rule) => void
  disabled?: boolean
  fieldAdded?: boolean
}
