import { useTableScroller } from '@flatfile/turntable'
import { useCallback, useEffect, useState } from 'react'

export const useScrollTo = ({ changedColumnIndices, changedColumns }: any) => {
  const { scrollableRef } = useTableScroller()
  const [nextColumnIndex, setNextColumnIndex] = useState(-1)
  const [prevColumnIndex, setPrevColumnIndex] = useState(-1)

  const getNextChangedColumnIndex = () => {
    if (!scrollableRef.current) {
      return -1
    }
    const rightmostVisiblePosition =
      (scrollableRef.current as any).state.scrollLeft +
      (scrollableRef.current as any).props.width
    const index = (
      scrollableRef.current as any
    ).props.itemData.columnStyles.findIndex(
      (x: any, i: number) =>
        changedColumnIndices.includes(i) &&
        x.left + x.width > rightmostVisiblePosition
    )

    return index
  }

  const getPrevChangedColumnIndex = () => {
    if (!scrollableRef.current) {
      return -1
    }
    const leftmostVisiblePosition = (scrollableRef.current as any).state
      .scrollLeft

    const array = (scrollableRef.current as any).props.itemData.columnStyles

    const reversed = array.slice().reverse()
    const index = reversed.findIndex(
      (x: any, i: number) =>
        changedColumnIndices.includes(reversed.length - 1 - i) &&
        x.left - 50 < leftmostVisiblePosition
    )
    if (index > -1) {
      return array.length - index - 1
    }
    return -1
  }

  const listener = useCallback(() => {
    if (!scrollableRef.current) {
      return
    }
    const nextIndex = getNextChangedColumnIndex()
    setNextColumnIndex(nextIndex)
    const prevIndex = getPrevChangedColumnIndex()
    setPrevColumnIndex(prevIndex)
  }, [changedColumnIndices, changedColumns])

  const handleNextColumn = () => {
    if (!scrollableRef.current) {
      return
    }
    const index = getNextChangedColumnIndex()
    if (index >= 0) {
      const scrollLeft = getScrollLeftForIndex(index)
      scrollableRef?.current?.scrollTo({
        scrollLeft,
      })
    }
  }

  const handlePrevColumn = () => {
    if (!scrollableRef.current) {
      return
    }
    const index = getPrevChangedColumnIndex()
    if (index >= 0) {
      const scrollLeft = getScrollLeftForIndex(index)
      scrollableRef?.current?.scrollTo({
        scrollLeft,
      })
    }
  }

  const getScrollLeftForIndex = (columnIndex: number) => {
    if (scrollableRef.current) {
      return Math.max(
        0,
        (scrollableRef.current as any).props.itemData.columnStyles[columnIndex]
          .left +
          100 +
          -(scrollableRef.current as any).props.width * 0.5
      )
    }
  }

  useEffect(() => {
    setTimeout(listener, 0)
  }, [changedColumns])

  useEffect(() => {
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return {
    handleNextColumn,
    handlePrevColumn,
    nextColumnIndex,
    prevColumnIndex,
    listener,
  }
}
