import { JobModeEnum } from '@flatfile/api'
import { Modal } from '@flatfile/design-system'
import styled from 'styled-components'
import { ForegroundJobModalContent } from '../../components/Jobs/ForegroundJobModalContent'
import { useBlockingJobs } from '../../hooks/useBlockingJobs'
import { useResource } from './useResource'

const BlurOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Blur = styled.div<{ blurred: boolean }>`
  filter: ${(props) => (props.blurred ? 'blur(2px)' : undefined)};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: filter 0.5s ease-in-out, background-color 0.5s ease-in-out;
`

const BlurContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const BlurResource = ({
  id,
  children,
}: {
  id: string
  children: React.ReactNode
}) => {
  const modes: JobModeEnum[] = [JobModeEnum.Foreground]
  const blockingJobs = useBlockingJobs([id], modes)
  const locked = blockingJobs.length > 0

  const [resource] = useResource<any>(id, false)

  return (
    <BlurOuterContainer>
      <Blur blurred={locked}>
        <BlurContent>{children}</BlurContent>
      </Blur>

      {locked && (
        <Modal
          overlayType='limited'
          content={
            <ForegroundJobModalContent
              job={blockingJobs[0]}
              resource={resource}
            />
          }
        />
      )}
    </BlurOuterContainer>
  )
}
