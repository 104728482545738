import { useEntitlements } from '@/hooks/useEntitlements'
import { Fragment } from 'react'
import { AnchorLink } from '../../elements/ActionLink'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { CommandMenuItem } from './CommandMenuItem'
import { CommandMenuItemMessage, ReturnIcon } from './elements'
import { CommandMenuMainPanelProps } from './types'
import { preventPropagation } from './utils'

interface MenuGroupItem {
  id: string
  label: string | React.ReactNode
}

export const CommandMenuMainPanel = (props: CommandMenuMainPanelProps) => {
  const { canShowHeadings, value, onMenuItemSelection } = props
  const { t } = useTypedTranslation()
  const { hasEntitlement } = useEntitlements()
  const whitelabeling = hasEntitlement('whitelabeling')
  const commandItems = [
    {
      id: '/query',
      label: whitelabeling
        ? t('commandMenu.aiAssist.query.description.whitelabeled')
        : t('commandMenu.aiAssist.query.description.branded'),
    },
    {
      id: '/search',
      label: t('commandMenu.commands.search.global.description'),
    },
    {
      id: '/filter',
      label: (
        <>
          <span>
            {t(
              whitelabeling
                ? 'commandMenu.commands.filter.description.whitelabeled'
                : 'commandMenu.commands.filter.description.branded'
            )}
          </span>
          {!whitelabeling && (
            <AnchorLink
              href='https://flatfile.com/docs/developer-tools/flatfile_query_language'
              target='_blank'
              rel='noreferrer'
              type='b1'
              onClick={preventPropagation}
              data-testid='FFQL-link'
            >
              {t('commandMenu.commands.filter.learnHow')}
            </AnchorLink>
          )}
        </>
      ),
    },
  ]

  const menuGroups = [
    {
      items: commandItems,
    },
  ]

  const Message = () => {
    if (canShowHeadings) {
      return null
    }

    const icon = '⏎'
    const message = t('commandMenu.itemMessage', { icon })
    const iconIndex = message.indexOf(icon)
    const start = message.slice(0, iconIndex)
    const end = message.slice(iconIndex + icon.length, message.length)

    return (
      <CommandMenuItemMessage>
        {start}
        <ReturnIcon>{icon}</ReturnIcon>
        {end}
      </CommandMenuItemMessage>
    )
  }

  return (
    <>
      {menuGroups.reduce((memo, group, index) => {
        const items = group.items.filter((item) =>
          item.id.startsWith(value.toLowerCase())
        )
        if (items.length) {
          memo.push(
            <Fragment key={index}>
              {items.map((item: MenuGroupItem) => (
                <CommandMenuItem
                  onMenuItemSelection={onMenuItemSelection}
                  displayCommand={true}
                  key={item.id}
                  id={item.id}
                >
                  {item.label}
                  {<Message />}
                </CommandMenuItem>
              ))}
            </Fragment>
          )
        }
        return memo
      }, [] as React.ReactNode[])}
    </>
  )
}
