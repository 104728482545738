import { ActionsModalContext } from '@/contexts/ActionsModalContext'
import { JobModeEnum } from '@flatfile/api'
import { Modal } from '@flatfile/design-system'
import { useContext, useMemo, useState } from 'react'
import { useEventSubscriber } from '../../hooks/useEventSubscriber'
import { useFinishedJobs } from '../../hooks/useFinishedJobs'
import { useJobsWithNoResponse } from '../../hooks/useJobsWithNoResponse'
import { AcknowledgeModalContent } from './AcknowledgeModalContent'
import { ActionConfirmModalContent } from './ActionConfirmModalContent'
import { NoListenerModalContent } from './NoListenerModalContent'
import { PermanentJobModal } from './PermanentJobModal'
import { SnapshotViewWrapper } from './SnapshotViewWrapper'
import { useJobsDiff } from './useJobsDiff'
import { useJobsToast } from './useJobsToast'

const suppressDevWarnings =
  import.meta.env.VITE_SUPPRESS_DEV_WARNINGS === 'true'

export const JobModalWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const modes = [
    JobModeEnum.Foreground,
    JobModeEnum.Background,
    JobModeEnum.ToolbarBlocking,
  ]
  //keep local state of jobs that have been acknowledged for faster UX response
  const [outcomeAckdJobIds, setOutcomeAckdJobIds] = useState<string[]>([])
  const locallyOutcomeAck = (jobId: string) => {
    setOutcomeAckdJobIds((prev) => [...prev, jobId])
  }
  const locallyOutcomeUnAck = (jobId: string) => {
    setOutcomeAckdJobIds((prev) => prev.filter((id) => id !== jobId))
  }

  useEventSubscriber(['job:ready'], (_, event) => {
    if (outcomeAckdJobIds.includes(event.context.jobId)) {
      //a job is being retried, so we need to unacknowledge it
      locallyOutcomeUnAck(event.context.jobId)
    }
  })

  const finishedJobs = useFinishedJobs()

  const jobsToAcknowledge = useMemo(
    () =>
      finishedJobs.filter(
        (j) =>
          j.outcome &&
          j.outcome.acknowledge &&
          j.outcome?.next?.type !== 'wait' &&
          j.outcome?.next?.type !== 'snapshot' &&
          !j.outcomeAcknowledgedAt &&
          !outcomeAckdJobIds.includes(j.id)
      ),
    [finishedJobs, outcomeAckdJobIds]
  )
  const jobsThatNeedPermanentModal = useMemo(
    () =>
      finishedJobs.filter(
        (j) =>
          j.mode &&
          modes.includes(j.mode) &&
          (j?.outcome as any)?.next?.type === 'wait'
      ),
    [finishedJobs]
  )

  const jobsWithNoResponse = useJobsWithNoResponse()

  useJobsToast()
  const { snapshotViewContent, handleCloseSnapshot } = useJobsDiff()

  const { currentAction, resetCurrentAction, submitCurrentAction } =
    useContext(ActionsModalContext)

  return (
    <>
      {!suppressDevWarnings && jobsWithNoResponse.length > 0 && (
        <Modal
          content={<NoListenerModalContent job={jobsWithNoResponse[0]} />}
          data-testid='no-listener-modal'
        />
      )}

      {jobsThatNeedPermanentModal.map((job) => (
        <PermanentJobModal key={job.id} job={job} />
      ))}

      {jobsToAcknowledge.length > 0 && (
        <Modal
          content={
            <AcknowledgeModalContent
              job={jobsToAcknowledge[0]}
              locallyOutcomeAck={locallyOutcomeAck}
            />
          }
          data-testid='outcome-modal'
        />
      )}

      {snapshotViewContent ? (
        <SnapshotViewWrapper
          {...snapshotViewContent}
          onClose={handleCloseSnapshot}
        />
      ) : null}

      {currentAction && (
        <Modal
          content={
            <ActionConfirmModalContent
              closeModal={resetCurrentAction}
              onCancel={resetCurrentAction}
              onSubmit={submitCurrentAction}
              text={currentAction.modalText}
              action={currentAction}
            />
          }
          data-testid='action-confirm-modal'
        />
      )}

      {children}
    </>
  )
}
