import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { renderMarkdownAsHtml } from '@/utils/markdown'
import { Sheet } from '@flatfile/api'
import { IconButton, MentionInput } from '@flatfile/design-system'
import { fromMaybe } from '@flatfile/shared-ui'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PromptBarBox } from './elements'

const MAX_ENUM_VALUES = 50

export const PromptBar = ({
  sheet,
  isAsking,
  handleEnterPrompt,
  handleCancel,
}: {
  sheet: Sheet
  isAsking: boolean
  handleEnterPrompt: (value: string) => void
  handleCancel: () => void
}) => {
  const [warning, setWarning] = useState<string | undefined>()
  const { t } = useTranslation()
  const { data: promptsResponse, isFetching } = useFlatfileQuery('getPrompts', {
    pageNumber: 1,
    pageSize: 5,
  })
  const recentPromptData = {
    isFetching,
    prompts: promptsResponse?.data || [],
  }

  const mentions = useMemo(
    () =>
      sheet.config.fields.map((field) => {
        const hasManyEnumValues =
          field.type === 'enum' && field.config.options.length > MAX_ENUM_VALUES
        const htmlTooltip = renderMarkdownAsHtml(
          fromMaybe(field.description, '')
        )

        const tooltipMessage = hasManyEnumValues
          ? t('aiTransform.promptBar.categoricalWarning')
          : t('aiTransform.promptBar.fieldTooltip', {
              key: field.key,
              label: field.label || '',
              description: htmlTooltip || '',
            })

        return {
          key: field.key,
          label: field.label || '',
          warning: hasManyEnumValues,
          tooltip: tooltipMessage,
        }
      }),
    [sheet, t]
  )

  const handleOnMentionChange = (mentions: string[]) => {
    // todo: we should probably do the stripping inside the mention input
    // Get the key inside <@key>
    const regex = /<@([a-zA-Z0-9_-]+)>/g

    let matches
    const keys = []

    while ((matches = regex.exec(mentions.join(' '))) !== null) {
      keys.push(matches[1])
    }

    const result = keys.reduce(
      (acc, mentionKey) => {
        const field = sheet.config.fields.find(
          (field) => field.key === mentionKey
        )
        if (
          field &&
          field.type === 'enum' &&
          field.config.options.length > MAX_ENUM_VALUES
        ) {
          acc.count += 1
          acc.enums.push(mentionKey)
        }
        return acc
      },
      { count: 0, enums: [] as string[] }
    )

    // Use the result to set a warning if necessary
    if (result.count >= 1) {
      setWarning(
        t('aiTransform.promptBar.warning', {
          fieldName: result.enums[0],
        })
      )
    } else {
      setWarning(undefined)
    }
  }

  return (
    <PromptBarBox>
      <IconButton name='arrowLeft' onPress={handleCancel} />
      <MentionInput
        recentPromptData={recentPromptData}
        mentions={mentions}
        isLoading={isAsking}
        onSubmit={handleEnterPrompt}
        onMentionChange={handleOnMentionChange}
        warning={warning}
      />
    </PromptBarBox>
  )
}
