import { useEvents } from '@/api/events/useEvents'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { PanelsProvider } from '@/contexts/PanelsContext'
import { EventDomainEnum, EventTopic } from '@flatfile/api'
import { PopoverContext } from '@flatfile/shared-ui'
import { useContext, useEffect, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ErrorState } from '../../components/EmptyState'
import { HTTPService } from '../../components/HttpClient'
import { JobModalWrapper } from '../../components/Jobs/JobModalWrapper'
import { getRuntimeParameters } from '../../components/RuntimeParameters'
import { SpacePageLayout } from '../../components/SpacePageLayout'
import { SpacesUISkeleton } from '../../components/SpacesUISkeleton'
import { EnvironmentsContextProvider } from '../../contexts/EnvironmentsContext'
import { JobOperationEnum } from '../../contexts/JobsContext'
import {
  SpaceContextProvider,
  SpaceContextType,
} from '../../contexts/SpaceContext'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useEventSubscriber } from '../../hooks/useEventSubscriber'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useSidebarCollapsed } from '../../hooks/useSidebarCollapsed'
import { finalTranslations, globalI18nLoaded } from '../../i18n'
import { FileUploadOverlay } from '../FilesApp/components/FileUploadOverlay'
import { WorkbookCountsContextProvider } from '../WorkbookApp/contexts/WorkbookCountsContext'
import { setTheme, SpaceMeta } from './Theme/ThemeUtils'

const filterTranslationKeysForHost: string[] = ['host']

export function SpaceApp() {
  const { isGuest } = getRuntimeParameters()
  const navigate = useNavigate()
  const { spaceId } = useRouteParams()
  const { refetch: refetchWorkbooks } = useQueryGetAllWorkbooks(
    { spaceId: spaceId },
    { enabled: false }
  )

  if (!spaceId) {
    throw new Error('Space id missing from params')
  }

  useEvents()

  const {
    data: spaceData,
    isLoading: loading,
    isError: isError,
    isRefetching: refetching,
  } = useFlatfileQuery(
    'getSpaceById',
    { spaceId: spaceId },
    { enabled: !!spaceId, staleTime: Infinity }
  )

  const spaceMeta = spaceData?.data?.metadata as SpaceMeta

  const sidebarHiddenFromUser = useMemo(
    () => !!(isGuest && spaceMeta?.sidebarConfig?.showSidebar === false),
    [isGuest, spaceMeta?.sidebarConfig]
  )

  const isLoading = useMemo(() => loading || refetching, [loading, refetching])

  useEventSubscriber(
    [EventTopic.Jobcompleted, EventTopic.Jobcreated, EventTopic.Jobupdated],
    async (event) => {
      const eventResponse = JSON.parse(event.message) ?? {}
      const { context, payload, topic } = eventResponse

      if (
        context?.spaceId === spaceId &&
        payload?.type === EventDomainEnum.Space &&
        payload?.operation === JobOperationEnum.Configure
      ) {
        if (topic === EventTopic.Jobcompleted) {
          await refetchWorkbooks()
        }
      }
    }
  )

  useEventSubscriber(
    [
      EventTopic.Workbookcreated,
      EventTopic.Workbookdeleted,
      EventTopic.Workbookupdated,
      EventTopic.Sheetdeleted,
    ],
    async (event) => {
      const eventResponse = JSON.parse(event.message) ?? {}
      const { context } = eventResponse

      if (context?.spaceId === spaceId) {
        await refetchWorkbooks()
      }
    }
  )

  const sidebarCollapsed = useSidebarCollapsed(
    typeof sidebarHiddenFromUser === 'boolean'
      ? sidebarHiddenFromUser
      : undefined,
    isGuest
  )

  const { hideToast, hidePopover } = useContext(PopoverContext)

  const spaceContext = useMemo((): SpaceContextType => {
    return {
      httpClient: HTTPService,
      sidebarCollapsed,
      space: spaceData?.data!,
      setCurrentSpace: (id) => {
        hideToast()
        hidePopover()
        navigate(`/space/${id}`)
      },
      metadata: spaceMeta,
      sidebarHiddenFromUser,
    }
  }, [sidebarCollapsed, spaceMeta, sidebarHiddenFromUser])

  useDocumentTitle(spaceContext?.space?.name)

  useEffect(() => {
    setTheme(spaceMeta)
  }, [spaceMeta])

  useEffect(() => {
    ;(async () => {
      const localTranslations = Object.fromEntries(
        Object.entries(finalTranslations).map(([lang, translations]) => [
          lang,
          Object.fromEntries(
            Object.entries(translations).filter(([key]) =>
              filterTranslationKeysForHost.includes(key)
            )
          ),
        ])
      )
      await globalI18nLoaded
      window.parent.postMessage({ localTranslations }, '*')
    })()
  }, [])

  if (isError) {
    return <ErrorState title='Error fetching Space' />
  }

  if (isLoading) {
    return <SpacesUISkeleton />
  }

  return (
    <SpaceContextProvider value={spaceContext}>
      <WorkbookCountsContextProvider>
        <EnvironmentsContextProvider>
          <PanelsProvider>
            <JobModalWrapper>
              <SpacePageLayout>
                <FileUploadOverlay>
                  <Outlet />
                </FileUploadOverlay>
              </SpacePageLayout>
            </JobModalWrapper>
          </PanelsProvider>
        </EnvironmentsContextProvider>
      </WorkbookCountsContextProvider>
    </SpaceContextProvider>
  )
}
