import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import getI18n from '@/i18n'
import { EventTopic, Space } from '@flatfile/api'
import { i18n } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SpacesUISkeleton } from '../SpacesUISkeleton'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'

export const SpaceTranslationsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const spaceId = useParams().spaceId || ''
  const { data: spaceResponse, refetch: refetchSpace } = useFlatfileQuery(
    'getSpaceById',
    { spaceId },
    {
      enabled: !!spaceId,
    }
  )
  const [i18nInstance, setI18nInstance] = useState<i18n | null>()
  const translationsPathRef = useRef<string | undefined>()

  const fetchTranslations = async (space: Space) => {
    if (
      space.translationsPath !== translationsPathRef.current ||
      !i18nInstance
    ) {
      translationsPathRef.current = space.translationsPath
      const i18n = getI18n(space.translationsPath, space.languageOverride)
      setI18nInstance(i18n)
    }
  }

  useEffect(() => {
    if (spaceResponse?.data) {
      fetchTranslations(spaceResponse.data)
    }
  }, [spaceResponse])

  useEventSubscriber([EventTopic.Spaceupdated], (event) => {
    refetchSpace()
  })

  return (
    <>
      {i18nInstance ? (
        <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
      ) : (
        <SpacesUISkeleton />
      )}
    </>
  )
}
