import styled from 'styled-components'

export const ActionContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: row;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 104px;
`
