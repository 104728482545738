import i18next, { TOptions } from 'i18next'
import { useTranslation as originalUseTranslation } from 'react-i18next'
import { TranslationsKeys } from '../i18n'

export function useTypedTranslation(): {
  t: (k: TranslationsKeys, ...args: any) => string
  i18n: typeof i18next
} {
  const { t: originalT, i18n } = originalUseTranslation()

  const t: (k: TranslationsKeys, o?: TOptions | string) => string = (
    key,
    options
  ) => {
    if (options === undefined) {
      return originalT(key)
    }
    if (typeof options === 'string') {
      return originalT(key, options)
    } else if (options) {
      return originalT(key, options as TOptions)
    }
    return originalT(key)
  }

  return { t, i18n }
}
