import { Action, Job } from '@flatfile/api'
import { ProgressBar, Spinner, Typography } from '@flatfile/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { Countdown } from './Countdown'
import { getEstimatedCompletionAt } from './utils'

const OuterBox = styled.div``
const TextBox = styled.div`
  padding-bottom: 1.5em;
  display: flex;
`
const BottomBox = styled.div`
  padding-bottom: 1em;
`

const SpinnerBox = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: center;
`

const ProgressTextContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
`

export const ForegroundJobModalContent = ({
  job,
  resource,
}: {
  job: Job
  resource: any
}) => {
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const action =
    job && resource && resource.actions
      ? resource.actions.find((a: Action) => a.operation === job.operation)
      : undefined
  const jobName = action?.label
    ? customT(action.label)
    : t('jobs.customActions.modals.foregroundJobProgressModal.defaultJobName')
  const resourceName = resource?.name
    ? customT(resource?.name)
    : t(
        'jobs.customActions.modals.foregroundJobProgressModal.defaultResourceName'
      )

  const translatedUnits = {
    hours: t('jobs.panel.time.units.hour'),
    minutes: t('jobs.panel.time.units.minute'),
    seconds: t('jobs.panel.time.units.second'),
  }

  const estimatedCompletionAt = getEstimatedCompletionAt(job)

  return (
    <OuterBox>
      <TextBox>
        <SpinnerBox>
          <Spinner />
        </SpinnerBox>
        <div>
          {job.info ? (
            <Typography type='h5'>{customT(job.info)}</Typography>
          ) : (
            <Typography type='h5'>
              <span className='font-semibold'>{jobName}</span>{' '}
              {t(
                'jobs.customActions.modals.foregroundJobProgressModal.runningOn'
              )}{' '}
              <span className='font-semibold'>{resourceName}</span>
            </Typography>
          )}
        </div>
      </TextBox>
      <ProgressBar progress={job.progress} />
      <ProgressTextContainer>
        <div>
          {t(
            'jobs.customActions.modals.foregroundJobProgressModal.percentComplete',
            { percent: job.progress }
          )}
        </div>
        {estimatedCompletionAt ? (
          <div>
            <Countdown
              targetDate={estimatedCompletionAt}
              translatedUnits={translatedUnits}
              translatedPostfix={t('jobs.panel.time.remaining')}
            />
          </div>
        ) : (
          <div>{t('jobs.panel.time.calculating')}</div>
        )}
      </ProgressTextContainer>
      <BottomBox />
    </OuterBox>
  )
}
