import { resources } from '@/api/resources'
import { HTTPService } from '@/components/HttpClient'
import { logger } from '@/utils/logging'
import { datadogLogs } from '@datadog/browser-logs'
import { EventTopic } from '@flatfile/api'
import {
  PopoverContext,
  PopoverMessageFullWidth,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useQuery } from '@tanstack/react-query'
import { StatusEvent } from 'pubnub'
import { usePubNub } from 'pubnub-react'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { BaseEvent } from './types'

interface PubNubProviderProps {
  children: ReactNode
  spaceId: string
}

interface IPubNubConnectionsContext {
  broadcastEvent: (
    wrappedEvent: any,
    event: Record<string, any>,
    isRelay: boolean,
    enableLogs: boolean
  ) => void
  checkAndGetEarliestToken: (timetoken: string) => string
  didReplay: React.MutableRefObject<boolean>
  observedTimeToken: React.MutableRefObject<string | null>
}

const PubNubConnectionContext = createContext<IPubNubConnectionsContext>({
  broadcastEvent: () => {},
  checkAndGetEarliestToken: () => '',
  didReplay: { current: false },
  observedTimeToken: { current: null },
})

export const EventLogExclusions = [
  EventTopic.SheetcountsUpdated,
  EventTopic.Commitcompleted,
  EventTopic.Commitcreated,
  EventTopic.Commitupdated,
  EventTopic.Layercreated,
  EventTopic.Recordsupdated,
  EventTopic.Recordscreated,
  EventTopic.Recordsdeleted,
]

const PubNubConnectionProvider = ({
  spaceId,
  children,
}: PubNubProviderProps) => {
  const pubnub = usePubNub()
  const { showPopover, hidePopover } = useContext(PopoverContext)
  const observedTimeToken = useRef<string | null>(null)
  const didReplay = useRef(false)
  const { refetch: refetchToken } = useQuery(
    ['getPubnubRefreshToken', spaceId],
    () => HTTPService.getEventToken({ spaceId }),
    {
      enabled: false,
      onSuccess: (data) => handleRefreshTokenSuccess(data.data?.token),
      onError: (error) => handleRefreshTokenError(error),
      retry: 3,
    }
  )

  useEffect(() => {
    const channels = [`space.${spaceId}`]
    pubnub.subscribe({ channels })

    const listener = {
      status: (statusEvent: StatusEvent) => {
        /**
         * If the subscription expires (after 1 hour) we initiate a JIT fetch of
         * an updated refresh token and reconnection to PubNub
         */
        if (statusEvent.category === 'PNAccessDeniedCategory') {
          refetchToken()
        }
        /**
         * In the event of a network disruption - display an error to the user
         * informing them of our attempt to reconnect. Reconnection managed via SDK "restore"
         */
        if (
          statusEvent.category === 'PNNetworkIssuesCategory' ||
          statusEvent.category === 'PNNetworkDownCategory'
        ) {
          showPopover({
            icon: <WarningIcon name='alertTriangle' />,
            message: (
              <PopoverMessageFullWidth>
                Your connection has been interrupted - attempting to re-connect
              </PopoverMessageFullWidth>
            ),
          })
          logger.error('PubNub Error: Connection disrupted', statusEvent)
        }
        /**
         * In the event of a restored connection - hide any network related popovers
         * in case they are visible
         */
        if (statusEvent.category === 'PNNetworkUpCategory') {
          hidePopover()
          logger.info('PubNub Error: Connection reestablished', statusEvent)
        }
      },
    }

    pubnub.addListener(listener)

    return () => {
      pubnub.unsubscribeAll()
      pubnub.removeListener(listener)
      pubnub.stop()
    }
  }, [hidePopover, showPopover, pubnub, refetchToken, spaceId])

  const checkAndGetEarliestToken = (timetoken: string) => {
    if (!observedTimeToken.current) {
      observedTimeToken.current = timetoken
    }

    return observedTimeToken.current
  }

  /**
   * Handler function which dispatches an error to DataDog and informs the user of
   * the failure - requesting a hard refresh of the UI
   * @param error
   */
  const handleRefreshTokenError = (error: any) => {
    logger.error('PubNub Error: Unable to obtain refresh token', error)
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessageFullWidth>
          Unable to successfully get refresh token for Flatfile Events
        </PopoverMessageFullWidth>
      ),
      action: {
        label: 'Refresh',
        onClick: () => window.location.reload(),
      },
    })
  }

  /**
   * Handler which sets the new token and performs a reconnect with pubnub to
   * finalize the token update
   * @param newToken
   */
  const handleRefreshTokenSuccess = (newToken?: string) => {
    if (newToken) {
      pubnub.setToken(newToken)
      pubnub.reconnect()
      datadogLogs.logger.info(
        'PubNub Info: Successfully obtained new refresh token'
      )
    }
  }

  /**
   * Handler broadcasts any received event to listening services which are dependent on the spaces-ui
   * PubNub client
   * 1. Broadcasts events to the parent window (necessary for client-side listeners for portal customers)
   * 2. Broadcasts events to the resources service (exists "outside" of the React lifecycle and handles the fetching/refetching of resources)
   * @param wrappedEvent
   * @param event
   * @param isRelay
   * @param enableLogs
   */
  const broadcastEvent = useCallback(
    (
      wrappedEvent: any,
      event: Record<string, any>,
      isRelay: boolean,
      enableLogs: boolean
    ) => {
      // If an event hasn't been received, set the observed token
      const { topic } = event
      const earliestTimeToken = checkAndGetEarliestToken(wrappedEvent.timetoken)
      // If an event has been received/relayed, then make sure the timetoken of the incomoing event
      // is later than observed
      if (
        isRelay ||
        BigInt(wrappedEvent.timetoken) >= BigInt(earliestTimeToken)
      ) {
        resources.emitResourceEvent(event as BaseEvent)
        window.parent.postMessage({ flatfileEvent: event }, '*')
        if (event.topic === EventTopic.JoboutcomeAcknowledged) {
          // TODO: fallback for embedded wrappers to close the modal. We should be able to remove this once we update the embedded wrappers to use the new event system
          window.parent.postMessage(
            { topic: 'job:outcome-acknowledged', payload: event?.payload },
            '*'
          )
        }
        if (enableLogs && !EventLogExclusions.includes(topic)) {
          logger.info('PubNub Log: Event replayed to parent', {
            event,
          })
        }
      } else {
        if (enableLogs && !EventLogExclusions.includes(topic)) {
          logger.warn('PubNub Log: Event received but not relayed or emitted', {
            wrappedEvent,
            event,
            earliestTimeToken,
          })
        }
      }
    },
    []
  )

  const value: IPubNubConnectionsContext = {
    broadcastEvent,
    checkAndGetEarliestToken,
    didReplay,
    observedTimeToken,
  }

  return (
    <PubNubConnectionContext.Provider value={value}>
      {children}
    </PubNubConnectionContext.Provider>
  )
}

export { PubNubConnectionContext, PubNubConnectionProvider }
