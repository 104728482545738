import { isArray } from 'lodash'
import { ComponentProps, FC, ReactElement } from 'react'
import { ErrorState } from '../../components/EmptyState'
import { ContentSkeleton } from '../../components/SpacesUISkeleton'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { Observable } from '../observable'
import { OneOrMore } from '../observable/ContentLoader'
import { resources } from './resources'
import { useResourceObservable } from './useResource'

type GuardOptions = Partial<{
  loadingContent: ReactElement
  emptyContent: ReactElement
  errorContent: (err?: string) => ReactElement
  reloadInBackground: boolean
}>

/**
 * Guard the loading and ensure refreshing of the resource
 *
 * @param Component
 * @param guardedProps
 * @param options
 */
export function guardResources<T extends FC<any>>(
  Component: T,
  guardedProps: Array<keyof ComponentProps<T> | { routeParam: string }>,
  options?: GuardOptions
): T {
  const guardedRouteParams = guardedProps.filter(
    (p) => typeof p !== 'string'
  ) as Array<{ routeParam: string }>
  const guardedComponentProps = guardedProps.filter(
    (p) => typeof p === 'string'
  ) as Array<keyof ComponentProps<T>>
  const lookAtParams = guardedRouteParams.length > 0

  const wrapped: T = ((props: ComponentProps<T>) => {
    let reslist = []

    if (lookAtParams) {
      const params = useRouteParams()

      reslist.push(
        ...guardedRouteParams.map((p) =>
          resources.exchangeForResource(params[p.routeParam] as string)
        )
      )
    }
    reslist.push(
      ...guardedComponentProps.map((p) =>
        resources.exchangeForResource(props[p])
      )
    )
    // this may appear like a smell with react hooks inside an iterator but
    // because guardedProps is static this will always be the same number of
    // iterations and therefor safe to use
    reslist.forEach((r) => {
      return useResourceObservable(r.observable.awaken())
    })

    const observables = reslist.map((r) => r.observable)
    return guard(observables, options) ?? <Component {...props} />
  }) as T

  wrapped.displayName = `ResourceGuard--${guardedRouteParams
    .map((r) => `param:${r.routeParam}`)
    .concat(guardedComponentProps as string[])
    .join('_')}`

  if (!Component.displayName) {
    Component.displayName = 'GuardedComponent'
  }

  return wrapped as T
}

/**
 * @param observe
 * @param options
 */
export function guard(
  observe: OneOrMore<Observable>,
  options: Partial<{
    loadingContent: ReactElement
    emptyContent: ReactElement
    errorContent: (err?: string) => ReactElement
    reloadInBackground: boolean
    contentKey?: string
  }> = { reloadInBackground: true }
): ReactElement | null {
  const { t } = useTypedTranslation()
  const skeleton = ContentSkeleton()
  const allObservers = toMore(observe)
  if (
    allObservers.some(
      (a) => (a.isLoading && !a.data) || (!a.isAwake && !a.isLazy)
    )
  ) {
    return options.loadingContent ?? skeleton
  }

  if (allObservers.some((a) => a.error)) {
    const err = allObservers.find((a) => a.error)!
    return options.errorContent ? (
      options.errorContent(err.error!.message)
    ) : (
      <ErrorState title={err.error!.message} />
    )
  }

  if (allObservers.some((o) => isEmpty(o, options.contentKey))) {
    return options.emptyContent ? (
      options.emptyContent
    ) : (
      <ErrorState title={t('errors.notFound')} />
    )
  }

  return null
}

const isEmpty = (observable: Observable, emptyProp?: string) => {
  const a = observable.data
  if (a && emptyProp) {
    const prop = a[emptyProp]
    return (
      prop === undefined ||
      prop === null ||
      (isArray(prop) && prop.length === 0)
    )
  }
  return a === undefined || a === null || (isArray(a) && a.length === 0)
}

function toMore<T>(val: OneOrMore<T>): Array<T> {
  if (isArray(val)) {
    return val
  } else {
    return [val]
  }
}
