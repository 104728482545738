import {
  Button,
  CheckboxInput,
  Modal,
  ModalHeader,
  ModalButtonRow,
  ModalCloseButton,
  ModalText,
  Pagination,
  useListPagination,
} from '@flatfile/design-system'
import { Workbook } from '@flatfile/api'
import { useCallback, useMemo, useState } from 'react'
import { remove } from 'lodash'
import { SelectButtons } from '@/components/SelectButtons'
import { ISpaceUser } from '@/hooks/useGuestManagement'
import styled from 'styled-components'
import { LoadingSpinner } from '@/components/LoadingSpinner'

const PAGE_SIZE = 10

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const WorkbookList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: var(--color-title);
  }
`

interface EditWorkbookAccessProps {
  user: ISpaceUser
  workbooks: Workbook[] | undefined
  isWorkbooksError: boolean
  isLoadingWorkbooks: boolean
  onClose: () => void
  onSubmit: (user: ISpaceUser, workbooks: Workbook[]) => void
}

export const EditWorkbookAccessModal = ({
  user,
  workbooks,
  isWorkbooksError,
  isLoadingWorkbooks,
  onClose,
  onSubmit,
}: EditWorkbookAccessProps) => {
  const getInitWorkbooks = useCallback(() => {
    return user.workbooks?.length ? user.workbooks : workbooks
  }, [user, workbooks])

  const [selectedWorkbooks, setSelectedWorkbooks] = useState<
    Workbook[] | undefined
  >(getInitWorkbooks())
  const { currentPage, onChangePage } = useListPagination()

  const isWorkbookSelected = useCallback(
    (workbook: Workbook) => {
      return !!selectedWorkbooks?.find((w) => w.id === workbook.id)
    },
    [selectedWorkbooks]
  )

  const handleSelectAll = useCallback(
    () => setSelectedWorkbooks(workbooks),
    [workbooks, setSelectedWorkbooks]
  )

  const handleSelectNone = useCallback(
    () => setSelectedWorkbooks([]),
    [setSelectedWorkbooks]
  )

  const handleSelectWorkbook = useCallback(
    (workbook: Workbook) => {
      if (selectedWorkbooks) {
        if (isWorkbookSelected(workbook)) {
          const newSelectedWorkbooks = Array.from(selectedWorkbooks)
          remove(newSelectedWorkbooks, workbook)
          setSelectedWorkbooks(newSelectedWorkbooks)
        } else {
          setSelectedWorkbooks([...selectedWorkbooks, workbook])
        }
      }
    },
    [selectedWorkbooks]
  )

  const handleSubmit = useCallback(() => {
    if (selectedWorkbooks) onSubmit(user, selectedWorkbooks)
  }, [selectedWorkbooks])

  const workbookList = useMemo(() => {
    if (workbooks) {
      const workbooksArray = Array.from(workbooks)
      const startIndex = PAGE_SIZE * (currentPage - 1)
      const endIndex = startIndex + PAGE_SIZE

      return workbooksArray.slice(startIndex, endIndex)
    }
    return []
  }, [workbooks, currentPage])

  const isLoadingContent = <LoadingSpinner></LoadingSpinner>
  const isErrorContent = <>Error fetching workbooks</>
  const modalContent = (
    <>
      <ModalHeader>
        Edit workbook access for {user.name ?? user.email}
      </ModalHeader>
      <ModalText>
        Removing all workbooks from a user's account removes that user from the
        space.
      </ModalText>
      <ButtonsRow>
        <SelectButtons
          onSelectAll={handleSelectAll}
          onSelectNone={handleSelectNone}
        />
      </ButtonsRow>
      <WorkbookList>
        {workbookList.map((workbook) => (
          <CheckboxInput
            key={workbook.id}
            color='var(--color-primary)'
            checked={isWorkbookSelected(workbook)}
            onChange={() => handleSelectWorkbook(workbook)}
            label={workbook.name}
          />
        ))}
      </WorkbookList>
      {workbooks && workbooks.length > PAGE_SIZE && (
        <Pagination
          size='sm'
          onPageChange={onChangePage}
          totalCount={workbooks.length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      )}
      <ModalButtonRow>
        <ModalCloseButton
          onClose={onClose}
          label='Cancel'
          data-testid='access-close-button'
        />
        <Button
          onPress={handleSubmit}
          label='Save'
          data-testid='access-submit-button'
        />
      </ModalButtonRow>
    </>
  )

  const content = isLoadingWorkbooks
    ? isLoadingContent
    : isWorkbooksError
    ? isErrorContent
    : modalContent

  return <Modal content={content} data-testid='workbook-access-modal' />
}
