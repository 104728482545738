import { PillVariant } from '../../elements/Pill'

interface CommandPrompt {
  command: string
  prompt: string
}

export enum PanelType {
  FILTER = 'filter',
  SEARCH = 'search',
  QUERY = 'query',
}

export const ColorStylesById = {
  'build-with-ai': PillVariant.SUCCESS,
  '/query': PillVariant.SUCCESS,
  commands: PillVariant.PRIMARY,
  '/search': PillVariant.PRIMARY,
  '/filter': PillVariant.PRIMARY,
  '/in': PillVariant.PRIMARY,
}

type ColorKeys = keyof typeof ColorStylesById

export const getColorVariant = (id: string) => {
  return ColorStylesById[id as ColorKeys]
}

export const preventPropagation = (e?: React.MouseEvent) => {
  /* Prevents selecting the command menu item when clicking the link. */
  e?.stopPropagation()
}

export function parseCommand(value: string): CommandPrompt {
  // Turns e.g. "/query what is  my name" into { command: "/query", prompt: "what is  my name"}
  const [command, ...prompt] = value.split(' ')
  return { command, prompt: prompt.join(' ') }
}

/**
 * Simply utility function to cleanse out the /<command> string from the inputValue
 * in order to return the correct prompts needed for a given panel
 * @param inputValue string value to be cleansed
 * @param panelType PanelType which informs how the command should be split
 * @returns
 */
export function getCleansedCommandPrompt(
  inputValue: string,
  panelType: PanelType
): string {
  let cleansedCommandPrompt = inputValue

  if (panelType === PanelType.FILTER) {
    cleansedCommandPrompt = 'filter: ' + cleansedCommandPrompt.substring(8)
  }
  if (
    panelType === PanelType.SEARCH &&
    cleansedCommandPrompt.charAt(0) === '/'
  ) {
    cleansedCommandPrompt = cleansedCommandPrompt.substring(7)
  }

  return cleansedCommandPrompt
}

export const Checkers = {
  isQueryPanel: (value: string) => /^\/(query)/.test(value),
  isCommandsPanel: (value: string) => /^\/(filter|search)/.test(value),
  isFilterPanel: (value: string) => /^\/(filter)/.test(value),
  isSearchPanel: (value: string) => /^\/(search)/.test(value),
  isListPanel: (value: string) => /^[/]/.test(value),
}

export const getPanelType = (value: string): PanelType | undefined => {
  if (Checkers.isFilterPanel(value)) {
    return PanelType.FILTER
  } else if (Checkers.isSearchPanel(value)) {
    return PanelType.SEARCH
  } else if (Checkers.isQueryPanel(value)) {
    return PanelType.QUERY
  }
  return
}

export const getCurrentPanelId = (value: string) => {
  const panel = getPanelType(value)
  return panel ? `/${panel}` : undefined
}
