import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { Document as DocumentType } from '@flatfile/api'
import { IconButton } from '@flatfile/design-system'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getRouteForSpace } from '../../apps/SpaceApp/Space/SpaceUtils'
import { PrimaryActions } from '../../apps/WorkbookApp/PrimaryActions'
import { BlocksContextProvider } from '../../contexts/BlocksContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { DocumentContainer } from '../../elements/DocumentContainer'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useResourceActions } from '../Jobs/useResourceActions'
import { PageHeader } from '../PageHeader'
import { Markdown } from './Markdown'

export const Document = ({ document }: { document: DocumentType }) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const { space, metadata } = useContext(SpaceContext)
  const { data: workbooks } = useQueryGetAllWorkbooks({ spaceId: space.id })
  const { data: documents } = useFlatfileQuery('getSpaceDocuments', {
    spaceId: space.id,
  })
  useDocumentTitle(t(document.title))

  const translatedBody = t(document.body)
  const isEphemeral = document.treatments
    ? document.treatments.includes('ephemeral')
    : false

  const defaultPage = metadata?.sidebarConfig?.defaultPage
  const isDefaultPage = defaultPage?.documentId === document.id
  const { path } = getRouteForSpace({
    space,
    documents: documents?.data,
    workbooks: workbooks?.data,
    defaultPage: defaultPage || {},
    skipDefaultPage: isDefaultPage,
  })
  const { actions } = useResourceActions(document, 'document')

  return (
    <BlocksContextProvider>
      <DocumentContainer
        ref={containerRef}
        $isEphemeral={isEphemeral}
        data-testid='document-container'
      >
        {isEphemeral && (
          <div className='back-button'>
            <IconButton
              name='cross'
              to={`/space/${space.id}${path}`}
              aria-label='Back to Space'
              data-testid='back-button'
            />
          </div>
        )}
        <PageHeader action={<PrimaryActions actions={actions} />} />
        <Markdown body={translatedBody} />
      </DocumentContainer>
    </BlocksContextProvider>
  )
}
