import { Workbook } from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { useContext, useMemo } from 'react'
import { WorkbookCountsContext } from '../contexts/WorkbookCountsContext'

export type WorkbookCounts = {
  errors: number
  total: number
  isFetching: boolean
}

export const useWorkbookCountsData = (workbook: Workbook) => {
  const { workbookCounts } = useContext(WorkbookCountsContext)
  const { id: activeId } = workbook

  const errors = useMemo(
    () =>
      (workbookCounts &&
        Object.keys(workbookCounts)
          .map((id) =>
            workbookCounts[id].workbookId === activeId
              ? fromMaybe(workbookCounts[id].error, 0)
              : 0
          )
          .reduce((a, b) => a + b, 0)) ||
      0,
    [workbookCounts, activeId]
  )

  const total = useMemo(
    () =>
      workbookCounts &&
      Object.keys(workbookCounts)
        .map((id) =>
          workbookCounts[id].workbookId === activeId
            ? fromMaybe(workbookCounts[id].total, 0)
            : 0
        )
        .reduce((a, b) => a + b, 0),
    [workbookCounts, activeId]
  )

  const isFetching = useMemo(
    () =>
      (workbookCounts &&
        Object.values(workbookCounts).some(
          (item) => item.workbookId === activeId && item.isFetching
        )) ||
      false,
    [workbookCounts, activeId]
  )

  return { errors, total, isFetching } as WorkbookCounts
}
