import { HTTPService } from '@/components/HttpClient'
import { useQuery } from '@tanstack/react-query'
import { QueryMap } from '../queryMap'
import {
  FlatfileQuery,
  FlatfileRequestType,
  FlatfileResponseType,
  UseQueryOptionsType,
} from '../types'

const query: FlatfileQuery = 'getAllSpaces'

export const useQueryGetAllSpaces = (
  params: FlatfileRequestType<typeof query>,
  options?: UseQueryOptionsType<typeof query>
) => {
  return useQuery<FlatfileResponseType<typeof query>>(
    QueryMap[query].generateQueryKey(params),
    async () => HTTPService[query](params),
    options
  )
}
