import { Modal } from '@flatfile/design-system'
import { memo, useCallback } from 'react'
import { VersionAction } from '@/api/controllers/VersionsController'
import VersionDeleteAction from './VersionDeleteAction'
import { VersionActionsModalProps } from './types'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

const VersionActionsModal = memo(function VersionActionsModal({
  action,
  isOpen,
  onClosePanel,
  onCloseModal,
  promptVersion,
}: VersionActionsModalProps) {
  const { t } = useTypedTranslation()

  const renderContentForAction = useCallback(() => {
    switch (action) {
      case VersionAction.DELETE: {
        return (
          <VersionDeleteAction
            onCancel={onCloseModal}
            onComplete={onClosePanel}
            promptVersion={promptVersion!}
          />
        )
      }
      default: {
        return (
          <div data-testid='version-default-action'>
            {t('versionHistory.modals.default.description')}
          </div>
        )
      }
    }
  }, [action])

  return (
    <>
      {isOpen && promptVersion && <Modal content={renderContentForAction()} />}
    </>
  )
})

export default VersionActionsModal
