export function MatchIcon() {
  return (
    <svg
      width='31'
      height='14'
      viewBox='0 0 31 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.3'
        d='M1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8L24 8C24.5523 8 25 7.55228 25 7C25 6.44772 24.5523 6 24 6L1 6Z'
        fill='#DFE3EB'
      />
      <path
        d='M23.2929 12.2929C22.9024 12.6834 22.9024 13.3166 23.2929 13.7071C23.6834 14.0976 24.3166 14.0976 24.7071 13.7071L30.7071 7.70711C31.0857 7.32853 31.0989 6.71894 30.7372 6.32428L25.2372 0.324276C24.864 -0.0828428 24.2314 -0.110347 23.8243 0.262846C23.4172 0.636039 23.3897 1.26861 23.7628 1.67572L28.6159 6.96992L23.2929 12.2929Z'
        fill='#DFE3EB'
      />
    </svg>
  )
}
