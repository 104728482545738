import {
  Document,
  Event,
  Guest,
  Job,
  Sheet,
  Space,
  User,
  Workbook,
} from '@flatfile/api'
import { HTTPService } from '../../components/HttpClient'

type File = any
export type Response<T> = Promise<{ data?: T }>

// TODO refactor these fetchers to use our query hooks
export const fetchers = {
  ev: (eventId: string) => HTTPService.getEvent({ eventId }) as Response<Event>,
  fl: (fileId: string) => HTTPService.getFile({ fileId }) as Response<File>,
  sh: (sheetId: string) => HTTPService.getSheet({ sheetId }) as Response<Sheet>,
  ge: (guestId: string) =>
    HTTPService.getSpaceGuestById({ guestId }) as Response<Guest>,
  sp: (spaceId: string) =>
    HTTPService.getSpaceById({ spaceId }) as Response<Space>,
  usr: (userId: string) => HTTPService.getUser({ userId }) as Response<User>,
  jb: (jobId: string) => HTTPService.getJob({ jobId }) as Response<Job>,
  wb: (workbookId: string) =>
    HTTPService.getWorkbookById({ workbookId }) as Response<Workbook>,
  dc: (documentId: string) =>
    HTTPService.getDocumentById({ documentId }) as Response<Document>,
}

/**
 * Helper utility for getting the resource from the ID
 * @param id
 */
export function resourceFetcherFromId<T extends string>(
  id: T
): (typeof fetchers)[keyof typeof fetchers] {
  if (!id) {
    return () => Promise.reject(new Error('No ID provided')) as Response<any>
  }
  const type = id.split('_')[1]
  if (!(fetchers as any)[type]) {
    return () =>
      Promise.reject(
        new Error(`"${id}" is not a recognized resource type.`)
      ) as Response<any>
  }
  return fetchers[type as keyof typeof fetchers]
}

export function getTargetIdFromEvent(
  entity: string,
  event: any
): string | null {
  return (
    event.target || event.origin?.id || event.context?.[`${entity}Id`] || null
  )
}
