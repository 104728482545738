import { Job } from '@flatfile/api'
import { ReactElement } from 'react'
import { JobController } from '../../api/controllers/JobController'
import { useObservable } from '../../api/observable'
import { guard } from '../../api/resources/GuardResources'
import { DialogPortal } from '../../components/DialogPortal'
import { JobOperationEnum } from '../../contexts/JobsContext'
import { Container } from '../../elements/Container'
import { useRouteParams } from '../../hooks/useRouteParams'
import { MapGuard } from './map/MapGuard'

/**
 * Load the job and once loaded, present the right job handling UI
 *
 * @todo register some listeners here as well for job events related to this job
 * @todo if job enters execution mode it should go into progress
 * @todo if job is in progress mode and completes it should show a success
 * @todo if job is taking a while you should be able to request a notification
 * @todo job not found
 */
export function JobRouter() {
  const params = useRouteParams('jobId')
  const [job] = useObservable(
    JobController.getJob(),
    { jobId: params.jobId },
    `getJob`
  )

  /**
   * Handler to re-fire the GetJob observable using new params in the event of a downstream
   * event or activity (i.e. creation of new job after new header is selected)
   * @param jobId
   */
  const handleLoadJob = (jobId: string) => {
    job.setData()
    job.setParams({ jobId })
    job.reload()
  }

  return (
    <DialogPortal>
      <Container>
        {guard(job) ?? getJobHandler(job.ensured.data, handleLoadJob)}
      </Container>
    </DialogPortal>
  )
}

/**
 * Return the appropriate job handler UI component
 *
 * @param job
 * @private
 */
function getJobHandler(
  job: Job,
  handleLoadJob: (jobId: string) => void
): ReactElement {
  switch (job.operation!) {
    case JobOperationEnum.Map:
      return <MapGuard job={job} onLoadJob={handleLoadJob} />
    default:
      return (
        <div data-testid='generic-job-unhandled'>
          [todo] show something for generic jobs
        </div>
      )
  }
}
