import {
  EnvironmentMeta,
  InitialResourcesEnvironment,
} from '../contexts/EnvironmentsContext'

export const getShowSpaceInfo = (
  environment?: InitialResourcesEnvironment
): boolean => {
  /* 
    If no environment, we don't know what the user's setting is, so
    default to false.
  */
  if (!environment || environment.hasAccess === false) return false

  /* 
    TODO: replace use of metadata with environment settings once it's available
  */
  const metadata = environment.metadata as EnvironmentMeta
  const showInfo = metadata?.showSpaceInfo

  /*
    If the environment exists, but the user has not explicitly set the property 
    to false, we default to true.
  */
  return showInfo !== false
}
