import { getTurntableTheme } from '@/apps/SpaceApp/Theme/ThemeUtils'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Button } from '@flatfile/design-system'
import { Table, ToolbarContainer } from '@flatfile/turntable'
import { useContext, useMemo } from 'react'
import { DiffTableEmptyStates } from './DiffTableEmptyStates'
import { DiffTableFilters } from './DiffTableFilters'
import { DiffTableTitle } from './DiffTableTitle'
import {
  DiffTableToolbar,
  DiffViewButtons,
  DiffViewHeader,
  DiffViewSummary,
  DiffViewTableContainer,
  DiffViewTitleAndSummary,
} from './elements'
import useDiffTable from './hooks/useDiffTable'
import { DiffAction, DiffTableProps } from './types'
import { getSnapshotSummary } from './utils'

export const DiffTableContent = (props: DiffTableProps) => {
  const { t } = useTypedTranslation()
  const { metadata } = useContext(SpaceContext)
  const turntableTheme = useMemo(() => getTurntableTheme(metadata), [metadata])

  const {
    tableId,
    changeType,
    setChangeType,
    rowCount,
    isLoading,
    isErrored,
    isSubmitting,
    isCanceling,
    onCancel,
    onSubmit,
    onCopySuccess,
  } = useDiffTable(props)
  return (
    <>
      <DiffViewHeader>
        <DiffViewTitleAndSummary>
          <div>
            <DiffTableTitle
              action={props.action}
              sheet={props.sheet}
              snapshot={props.snapshot}
              workbook={props.workbook}
            />
            {props.summary ? (
              <DiffViewSummary data-testid='diff-table-summary'>
                {getSnapshotSummary(
                  props.summary,
                  props.action,
                  t,
                  props.columnConfig
                )}{' '}
                {t(`snapshot.${props.action}.message`, {
                  name: props.sheet?.name,
                })}
              </DiffViewSummary>
            ) : null}
            {props.description ? (
              <DiffViewSummary>{props.description}</DiffViewSummary>
            ) : null}
          </div>
          {props.action !== DiffAction.NONE && (
            <DiffViewButtons>
              <Button
                onPress={onCancel}
                loading={isCanceling}
                label={t(`snapshot.${props.action}.cancelButton`)}
                variant='secondary'
                data-testid='diff-cancel-button'
              />
              <Button
                onPress={onSubmit}
                loading={isSubmitting}
                label={t(`snapshot.${props.action}.submitButton`)}
                data-testid='diff-submit-button'
              />
            </DiffViewButtons>
          )}
        </DiffViewTitleAndSummary>
        {props.showFilters ? (
          <DiffTableToolbar>
            <ToolbarContainer theme={turntableTheme}>
              <DiffTableFilters
                activeTab={changeType}
                setActiveTab={setChangeType}
                action={props.action}
                counts={props.summary}
                isLoadingCounts={props.isLoadingSummary}
                diffDirection={props.diffDirection}
              />
            </ToolbarContainer>
          </DiffTableToolbar>
        ) : null}
      </DiffViewHeader>
      <DiffViewTableContainer>
        <Table
          columnConfig={props.columnConfig}
          emptyState={
            <DiffTableEmptyStates
              isErrored={isErrored}
              isLoading={isLoading}
              action={props.action}
              changeType={changeType}
            />
          }
          isDiffView
          diffDirection={props.diffDirection}
          readOnly
          tableId={tableId}
          theme={turntableTheme}
          totalRowCount={rowCount}
          onActionSuccess={onCopySuccess}
        />
      </DiffViewTableContainer>
    </>
  )
}
