import { FileController } from '@/api/controllers/FileController'
import { useController } from '@/api/controllers/useController'
import { useObservable } from '@/api/observable'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useEntitlements } from '@/hooks/useEntitlements'
import { isConnectedWorkbook } from '@/utils/workbooks'
import { FileMode, Workbook } from '@flatfile/api'
import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const importAction = {
  key: 'import',
  label: 'Import',
  primary: true,
  modalText: {
    heading: '',
    description: '',
    confirmButtonText: '',
    loadingText: '',
    errorMessage: '',
  },
}

export const useSheetFile = (workbook: Workbook) => {
  const { space, httpClient } = useContext(SpaceContext)
  const { hasEntitlement } = useEntitlements()
  const isSourceEditorEnabled = hasEntitlement('sourceEditor')

  const navigate = useNavigate()

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [uploads] = useObservable(fileController.getFiles(), {
    mode: FileMode.Import,
  })

  const isFile = useMemo(
    () => workbook.labels?.includes('file') ?? false,
    [workbook?.labels]
  )

  const isConnection = useMemo(() => {
    return isConnectedWorkbook(workbook)
  }, [workbook])

  const file = useMemo(() => {
    return uploads?.data?.files.find((file) => file.workbookId === workbook.id)
  }, [workbook.id, uploads?.data])

  const isStaticSheet = useMemo(
    () => isFile || isConnection,
    [isFile, isConnection]
  )

  const isEditCapable = (isFile && isSourceEditorEnabled) || !isStaticSheet

  const staticWorkbookActions = useMemo(() => {
    if (!file && isFile) return []
    else if (!isStaticSheet) return []

    //TODO: implement job input for sheet mounted actions
    const action = () => navigate(`/space/${space.id}/files/${file?.id}/import`)
    return [
      {
        ...importAction,
        action,
        preAction: action,
      },
    ]
  }, [file, space])

  return {
    isStaticSheet,
    isFile,
    isConnection,
    isEditCapable,
    file,
    staticWorkbookActions,
  }
}
