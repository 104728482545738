import { PlanController } from '@/api/controllers/PlanController'
import { Rule, RuleController } from '@/api/controllers/RuleController'
import { useController } from '@/api/controllers/useController'
import { MatchIcon } from '@/assets/icons/MatchIcon'
import { getDataTypeIcon } from '@/assets/icons/data-type-icons'
import EllipsisTooltip from '@/components/Shared/EllipsisTooltip'
import { FieldWrapper } from '@/elements/FieldWrapper'
import { MatchField } from '@/elements/MatchField'
import { MatchIconSpacer, MatchIconWrap } from '@/elements/MatchIconWrap'
import { ReactSelectStyles } from '@/elements/ReactSelectStyles'
import { Row } from '@/elements/Row'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { Primitive } from '@/utils/types'
import { Icon, Tooltip } from '@flatfile/design-system'
import { useMemo } from 'react'
import ReactSelect, { components } from 'react-select'
import styled, { css } from 'styled-components'
import {
  CardHeader,
  Content,
  CountPill,
  FieldCardContainer,
  IconWrapper,
  RuleHeader,
} from '../elements'

const SOption = styled.div<{ danger: boolean }>`
  display: flex;
  align-items: center;

  span {
    display: flex;
    flex: 1;
    ${(props) =>
      props.danger &&
      css`
        color: var(--color-pinata-900);
      `}
  }
`

const Option = (props: any) => {
  const { t } = useTypedTranslation()
  const { label, description } = props.data
  const isDangerColor =
    label ===
    t('mapping.mapEnums.destinationFields.dropdownOptions.doNotImport')
  const shouldKeepAsIs =
    label === t('mapping.mapEnums.destinationFields.dropdownOptions.keepAsIs')

  const tooltipContent = shouldKeepAsIs
    ? {
        'data-tooltip-content': t(
          'mapping.mapEnums.destinationFields.dropdownOptions.keepAsIsDisabledTooltip'
        ),
        'data-tooltip-id': 'enum-option-tooltip',
      }
    : description
    ? {
        'data-tooltip-content': description,
        'data-tooltip-id': 'enum-option-tooltip',
      }
    : {}
  return (
    <components.Option {...props}>
      <SOption danger={isDangerColor}>
        {shouldKeepAsIs && (
          <Tooltip
            id='enum-option-tooltip'
            place='right'
            positionStrategy='fixed'
          />
        )}
        <span
          data-testid={shouldKeepAsIs ? 'keep-as-is' : undefined}
          {...tooltipContent}
        >
          {label}
        </span>
        {props.isSelected && !props.isDisabled && <Icon name='checkmark' />}
      </SOption>
    </components.Option>
  )
}
const SingleValue = (props: any) => {
  const { t } = useTypedTranslation()
  const { label } = props.getValue()[0]
  const isDangerColor =
    label ===
    t('mapping.mapEnums.destinationFields.dropdownOptions.doNotImport')

  return (
    <components.SingleValue {...props}>
      <SOption danger={isDangerColor}>
        <span>{label}</span>
      </SOption>
    </components.SingleValue>
  )
}

const keepAsIsOptionValue = '18ccf61d533b10-Keep-0bbf5a963359223fv1'
let initialOrder: Primitive[] = []
/**
 * Renders a component that handles mapping the enum values for a specific rule.
 * This component will not change the field mapping, only the value mapping.
 *
 * @param props
 */
export const EnumCard = (props: Props_EnumCard) => {
  const ctrl = useController(RuleController, props.rule, props.planController)
  const rule = ctrl.rule
  const { t } = useTypedTranslation()

  const enumOptions = useMemo(
    () => props.planController.dest.getEnumOptions(rule.dest!),
    [rule]
  )

  const enumRules = useMemo(() => ctrl.enumRules(), [ctrl, rule])
  const mappedCount = useMemo(
    () => enumRules.filter((r) => r.dest).length,
    [enumRules]
  )

  if (!initialOrder.length || initialOrder.length !== enumRules.length) {
    initialOrder = enumRules.map((item) => item.src)
  }
  const getIndex = (v: Primitive) =>
    v === null ? Infinity : initialOrder.findIndex((value) => value === v)

  const sortedEnumRules = enumRules.sort((a, b) => {
    const valueA = getIndex(a.src)
    const valueB = getIndex(b.src)

    return valueA - valueB
  })

  return (
    <FieldCardContainer
      data-testid={'enum-group'}
      onMouseEnter={() => props.onFocus?.(ctrl)}
    >
      <CardHeader>
        <IconWrapper>{getDataTypeIcon('enum')}</IconWrapper>
        {ctrl.destProp?.label}
        <CountPill data-testid='mapped-count'>
          {t('mapping.mapEnums.progressPill', {
            mappedCount: mappedCount,
            count: enumRules.length,
          })}
        </CountPill>
      </CardHeader>
      <Content>
        <RuleHeader>
          <MatchField>
            {t('mapping.mapEnums.incomingFields.heading')}
          </MatchField>
          <MatchIconWrap>
            <MatchIconSpacer>&nbsp;</MatchIconSpacer>
          </MatchIconWrap>
          <MatchField>
            {t('mapping.mapEnums.destinationFields.heading')}
          </MatchField>
        </RuleHeader>
        {sortedEnumRules?.map(({ src: srcVal, dest: destVal }, index) => {
          const keepOptionDisabled =
            srcVal === null || enumOptions.find((item) => item.value === srcVal)

          const options = [
            ...enumOptions,
            {
              label: t(
                'mapping.mapEnums.destinationFields.dropdownOptions.keepAsIs'
              ),
              value: keepAsIsOptionValue,
              isDisabled: keepOptionDisabled,
              description: keepOptionDisabled
                ? undefined
                : t(
                    'mapping.mapEnums.destinationFields.dropdownOptions.keepAsIsDisabledTooltip'
                  ),
            },
            {
              label: t(
                'mapping.mapEnums.destinationFields.dropdownOptions.doNotImport'
              ),
              value: '$flatfile-null$',
            },
          ]
          return (
            <FieldWrapper
              key={`enum-${rule.src}-${srcVal}`}
              data-testid={'edge-row'}
            >
              <Row>
                <MatchField data-testid={'source-field-label'}>
                  {srcVal ? (
                    <EllipsisTooltip value={srcVal} id={index} lineClamp={3} />
                  ) : (
                    <div>
                      <Tooltip
                        id='empty-cell'
                        place='bottom'
                        float
                        positionStrategy='fixed'
                      />
                      <i
                        data-tooltip-content={t(
                          'mapping.mapEnums.incomingFields.noDataTooltip'
                        )}
                        data-tooltip-id='empty-cell'
                      >
                        {t('mapping.mapEnums.incomingFields.noData')}
                      </i>
                    </div>
                  )}
                </MatchField>
                <MatchIconWrap>
                  <MatchIcon />
                </MatchIconWrap>

                <MatchField data-testid={'destination-field-select'}>
                  <ReactSelect
                    aria-label={'option-select'}
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    isClearable={true}
                    options={options}
                    value={options.find((o) => o.value === destVal)}
                    placeholder={t('mapping.dropdown.placeholder')}
                    components={{
                      IndicatorSeparator: () => null,
                      SingleValue: SingleValue,
                      Option: Option,
                    }}
                    styles={ReactSelectStyles}
                    onChange={(val) => {
                      const value =
                        val?.value === keepAsIsOptionValue ? srcVal : val?.value
                      ctrl.mergeEnumMappingChange(srcVal, value)
                      props.onChange?.(ctrl.rule)
                    }}
                    minMenuHeight={300}
                    menuPlacement='auto'
                  />
                </MatchField>
              </Row>
            </FieldWrapper>
          )
        })}
      </Content>
    </FieldCardContainer>
  )
}

type Props_EnumCard = {
  rule: Rule
  planController: PlanController
  onFocus?: (rule: RuleController) => void
  onChange?: (rule: Rule, src?: string, dest?: string) => void
}
