import { includesNonFileWorkbooks } from '@/utils/workbooks'
import { Document, Space, Workbook } from '@flatfile/api'

interface DefaultPageProps {
  documentId?: string
  checklist?: boolean
  workbook?: {
    workbookId?: string
    sheetId?: string
  }
}

type DefaultPageResult = {
  path: string | undefined
  workbookId?: string
  sheetId?: string
  documentId?: string
}
/**
 * Utility function used to confirm if the current primaryWorkbookId assigned to the
 * Space is valid for the current logged in user by checking if the ID exists within
 * the set of available workbooks for the user.
 *
 * @param workbooks Workbooks[] list of workbooks for the current user
 * @param primaryWorkbookId primaryWorkbookId of the current space
 * @returns
 */
export const validatePrimaryWorkbook = (
  workbooks: Workbook[],
  primaryWorkbookId?: string
): boolean => {
  let workbook: Workbook | undefined

  if (primaryWorkbookId) {
    workbook = workbooks?.find((workbook) => workbook.id === primaryWorkbookId)
  }

  return workbook ? true : false
}

interface GetAndValidateDefaultPageParams {
  documents?: Document[]
  workbooks: Workbook[]
  defaultPageObj: DefaultPageProps
}
export const getAndValidateDefaultPage = ({
  documents,
  workbooks,
  defaultPageObj,
}: GetAndValidateDefaultPageParams): DefaultPageResult => {
  const {
    documentId,
    checklist,
    workbook: { sheetId, workbookId } = {
      sheetId: undefined,
      workbookId: undefined,
    },
  } = defaultPageObj

  let defaultData: DefaultPageResult = { path: undefined }

  const hasSheetsInFirstWorkbook = (): boolean => {
    const firstWorkbook = workbooks[0]
    return (firstWorkbook?.sheets && firstWorkbook?.sheets?.length > 0) || false
  }

  switch (true) {
    case Boolean(sheetId && workbookId):
      // return the single workbook that matches provided id
      const workbook = workbooks?.find((workbook) => workbook.id === workbookId)
      // validate that sheetId does exist in that workbook
      if (
        workbook &&
        workbook.id &&
        workbook.sheets?.some((sheet) => sheet.id === sheetId)
      ) {
        defaultData.path = `/workbook/${workbook?.id}/sheet/${sheetId}`
        defaultData.workbookId = workbookId
        defaultData.sheetId = sheetId
      }
      break
    case Boolean(workbookId):
      // validate the workbookId does exist in workbooks Array
      if (workbooks.some((workbook) => workbook.id === workbookId)) {
        defaultData.path = `/workbook/${workbookId}`
        defaultData.workbookId = workbookId
      }
      break

    case Boolean(documentId):
      // validate the document does exist on the space
      if (documents?.some((document) => document.id === documentId)) {
        defaultData.path = `/document/${documentId}`
        defaultData.documentId = documentId
      }
      break
    // validate the workbook has sheets before routing to checklist page
    case checklist && hasSheetsInFirstWorkbook():
      defaultData.path = `/checklist`
      break
    // if none of the above conditions are met, fallback to primary routing hierarchy
    default:
      break
  }

  return defaultData
}
/* c8 ignore stop */

export const getNonEphemeralDocuments = (documents?: Document[]) => {
  return documents?.filter((doc) => {
    if (doc.treatments) {
      return !doc.treatments.includes('ephemeral')
    } else {
      return true
    }
  })
}

export const getRouteForSpace = ({
  space,
  workbooks = [],
  defaultPage,
  documents = [],
  skipDefaultPage,
}: {
  space: Space | undefined
  workbooks: Workbook[] | undefined
  defaultPage: DefaultPageProps
  documents: Document[] | undefined
  skipDefaultPage?: boolean
}): DefaultPageResult => {
  if (!space || !documents || !workbooks) return { path: undefined }
  const { primaryWorkbookId } = space
  const defaultPageExists =
    defaultPage?.documentId || defaultPage?.checklist || defaultPage?.workbook

  const defaultRoute = getAndValidateDefaultPage({
    documents,
    workbooks,
    defaultPageObj: defaultPage,
  })

  const nonEphemeralDocuments = getNonEphemeralDocuments(documents)

  const hasNonFileWorkbooks = includesNonFileWorkbooks(workbooks)

  switch (true) {
    case Boolean(defaultPageExists && defaultRoute.path && !skipDefaultPage):
      return defaultRoute
    case Boolean(validatePrimaryWorkbook(workbooks, primaryWorkbookId)):
      return {
        path: `/workbook/${space.primaryWorkbookId}`,
        workbookId: space.primaryWorkbookId,
      }
    case Boolean(workbooks?.length && hasNonFileWorkbooks):
      return {
        path: `/workbook/${workbooks[0].id}`,
        workbookId: workbooks[0].id,
      }
    case Boolean(nonEphemeralDocuments && nonEphemeralDocuments.length):
      return {
        path: `/document/${nonEphemeralDocuments![0].id}`,
        documentId: nonEphemeralDocuments![0].id,
      }
    default:
      return { path: '/files' }
  }
}
