import * as Api from '@flatfile/api'
import {
  DefaultApi,
  GetJobsRequest,
  Job,
  JobConfig,
  Pagination,
  PipelineJobConfig,
  Sheet,
} from '@flatfile/api'
import { DEFAULT_PAGE_SIZE } from '@flatfile/design-system'
import { uniq } from 'lodash'
import { HTTPService } from '../../components/HttpClient'
import { Observable } from '../observable'

export const FILE_DELETED = 'File deleted'

export class JobController {
  constructor(public readonly job: Api.Job, private _httpClient?: DefaultApi) {}

  /**
   * Get an individual job
   */
  public static getJob(): Observable<Job> {
    return new Observable<Job, Api.GetJobRequest>((jobRequest) => {
      return HTTPService.getJob(jobRequest)
    })
  }

  /**
   * Get all jobs for space
   */
  public static getJobsForSpace() {
    return new Observable<Job[], GetJobsRequest>(
      async ({ spaceId, pageNumber, sortDirection, pageSize }) => {
        return HTTPService.getJobs({
          spaceId,
          sortDirection,
          pageNumber,
          pageSize: pageSize || DEFAULT_PAGE_SIZE,
          excludeChildJobs: true,
        })
      }
    )
  }

  /**
   * Returns just the Plan component of a JobPlan
   *
   * @todo this API response should only return the plan, not the job
   */
  public getPlan(): Observable<Api.Plan, Api.GetJobExecutionPlanRequest> {
    return new Observable(async (params) => {
      const planResponse = await HTTPService.getJobExecutionPlan(params)
      const { data } = planResponse
      return {
        data: data?.plan ?? {
          fieldMapping: [],
          unmappedDestinationFields: [],
          unmappedSourceFields: [],
        },
      }
    })
  }

  /**
   * Obtain the actual sheet configurations for the current matching job
   */
  public getSheets(): Observable<SheetMap> {
    if (!this.job.source || !this.job.destination) {
      throw new Error(
        'Sheets not available until sheet selection has completed'
      )
    }
    return new Observable(async () => {
      const [src, dest] = await Promise.all([
        HTTPService.getSheet({
          sheetId: (this.job.config as PipelineJobConfig)?.sourceSheetId,
        }),
        HTTPService.getSheet({
          sheetId: (this.job.config as PipelineJobConfig)?.destinationSheetId,
        }),
      ])
      return { data: { src: src.data!, dest: dest.data! } }
    })
  }

  /**
   * Get the preview records for the source sheet
   */
  getPreviewRecords(): Observable<Api.GetRecordsResponseData> {
    return new Observable(async () => {
      const subject = this.job.subject
      const query =
        subject && subject.type === 'collection' ? subject.query : {}
      const recordsResponse = await HTTPService.getRecords({
        sheetId: (this.job.config as PipelineJobConfig)?.sourceSheetId,
        pageSize: 25,
        ...query,
      })
      return recordsResponse
    })
  }

  /**
   * Get unfiltered records for the source sheet
   */
  getHeaderRecords(): Observable<Api.GetRecordsResponseData> {
    return new Observable(async () => {
      const recordsResponse = await HTTPService.getRecords({
        sheetId: (this.job.config as PipelineJobConfig)?.sourceSheetId,
        pageSize: 25,
      })
      return recordsResponse
    })
  }

  public buildPreview(records: Api.RecordsWithLinks): Record<string, string[]> {
    const preview: Record<string, string[]> = {}
    records.forEach((record) => {
      Object.entries(record.values).forEach(([key, { value }]) => {
        if (!preview[key]) {
          preview[key] = []
        }
        if (preview[key].length === 10) {
          return
        }
        if (value !== null && preview[key].length < 10) {
          preview[key] = uniq([...preview[key], value as string])
        }
      })
    })
    return preview
  }

  public static async create(jobConfig: JobConfig) {
    const res = await HTTPService.createJob({
      jobConfig,
    })

    return new this(res.data!)
  }
}

export type SheetMap = { src: Sheet; dest: Sheet }
export interface JobsWithPagination {
  jobs: Array<JobWithResourceName>
  pagination: Pagination
}
export type JobWithResourceName = {
  resourceName?: string
} & Job
