import { logger } from './logging'

export const downloadFromURL = async (
  url: string | undefined,
  fileName: string
) => {
  if (url) {
    try {
      const response = await fetch(url)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (error) {
      logger.error('Error in download from URL', error)
    }
  }
}
