import { DefaultApi, Space } from '@flatfile/api'
import { createContext } from 'react'
import { SpaceMeta } from '../apps/SpaceApp/Theme/ThemeUtils'

import { HTTPService } from '../components/HttpClient'
import { SidebarCollapsed } from '../hooks/useSidebarCollapsed'

export const mockSpace: Space = {
  id: '1',
  name: 'A Space',
  spaceConfigId: 'cfg_0',
  environmentId: 'env_0',
  createdAt: new Date('2020-01-01T00:00:00.000Z'),
  updatedAt: new Date('2020-01-01T00:00:00.000Z'),
  guestAuthentication: [],
}

export interface SpaceContextType {
  httpClient: DefaultApi
  sidebarCollapsed?: SidebarCollapsed
  space: Space
  setCurrentSpace: (spaceId: string) => void
  metadata?: SpaceMeta
  sidebarHiddenFromUser: boolean
}

export const SpaceContext = createContext<SpaceContextType>({
  httpClient: HTTPService,
  space: mockSpace,
  setCurrentSpace: () => {},
  metadata: undefined,
  sidebarHiddenFromUser: false,
})

export const SpaceContextProvider = (props: {
  children: JSX.Element
  value: SpaceContextType
}) => {
  return (
    <SpaceContext.Provider value={{ ...props.value }}>
      {props.children}
    </SpaceContext.Provider>
  )
}
