import { SpaceContext } from '@/contexts/SpaceContext'
import { QueryKey, useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { accountsQueries } from './accounts.queries'
import { documentsQueries } from './documents.queries'
import { entitlementQueries } from './entitlements.queries'
import { environmentsQueries } from './environments.queries'
import { sheetsQueries } from './sheets.queries'
import { workbooksQueries } from './workbooks.queries'

import { promptsQueries } from './prompts.queries'
import { snapshotsQueries } from './snapshots.queries'
import { spacesQueries } from './spaces.queries'
import {
  FlatfileRequestType,
  FlatfileResponseType,
  UseQueryOptionsType,
} from './types'
import { viewsQueries } from '@/api/queries/views.queries'

export const QueryMap = {
  ...documentsQueries,
  ...sheetsQueries,
  ...workbooksQueries,
  ...environmentsQueries,
  ...accountsQueries,
  ...entitlementQueries,
  ...snapshotsQueries,
  ...spacesQueries,
  ...promptsQueries,
  ...viewsQueries,
}

export type DefinedQuery = keyof typeof QueryMap

export const useFlatfileQuery = <Q extends DefinedQuery>(
  q: Q,
  params: FlatfileRequestType<Q>,
  options?: UseQueryOptionsType<Q>
) => {
  const { httpClient } = useContext(SpaceContext)
  const entry = QueryMap[q]
  const key = entry?.generateQueryKey(params as any) as QueryKey
  const queryFn = () =>
    (httpClient[q] as any)(params) as Promise<FlatfileResponseType<Q>>
  return useQuery<FlatfileResponseType<Q>>(key, queryFn, {
    staleTime: Infinity,
    ...options,
  })
}
