import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { PlacesType, PositionStrategy } from 'react-tooltip'
import { Primitive } from '@/utils/types'
import { AMP_TOOLTIP_ID, Tooltip } from '@flatfile/design-system'
import { debounce } from 'lodash'
import useResizeObserver from '@/hooks/useResizeObserver'

/**
 * Unique utility tooltip which should be used to conditionally display a tooltip
 * containing a specified value depending on whether or not the content of that
 * value is being truncated with an ellipsis based on the current size of the viewport
 * or the elements parent container
 *
 * By passing in the desired line-clamp, this element will display an ellipsis when that
 * condition is met - and will also render the expected Tooltip which will display on hover
 */

interface IEllipsisTooltip {
  // value to be rendered within the anchor, and tooltip
  value: Primitive
  // unique identifier to correctly relate the tooltip to the supplied value
  id?: number
  // maximum number of lines to display before the value will be truncated with the ellipsis
  lineClamp: number
  // Tooltip position
  place?: PlacesType
  // Node to render if different from value prop
  children?: React.ReactNode
}

export const EllipsisTooltip = ({
  children,
  id,
  lineClamp,
  place = 'right',
  value,
}: IEllipsisTooltip) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const cloneRef = useRef<HTMLDivElement | null>(null)
  const tooltipId = id === undefined ? AMP_TOOLTIP_ID : `ellipsis-tooltip-${id}`
  const tooltipContent = value ? value?.toString() : undefined
  const [isCloneRendered, setIsCloneRendered] = useState<boolean>(true)
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const reuseGlobalTooltip = tooltipId === AMP_TOOLTIP_ID

  const recomputeMeasurement = useCallback(
    debounce(() => setIsCloneRendered(true), 300),
    []
  )

  const Component = useMemo(() => {
    return lineClamp > 1
      ? EllipsisMultilineTooltipContent
      : EllipsisSingleLineTooltipContent
  }, [lineClamp])

  useResizeObserver(contentRef, recomputeMeasurement)

  const content = children || value

  const componentProps = useMemo(() => {
    if (isEllipsisActive) {
      return {
        'data-tooltip-id': tooltipId,
        'data-tooltip-content': tooltipContent,
        'data-tooltip-place': place,
        'data-tooltip-position-strategy': 'fixed' as PositionStrategy,
      }
    }
    return {}
  }, [isEllipsisActive, tooltipId, tooltipContent, place, reuseGlobalTooltip])

  /**
   * To determine if the ellipsis is active on the content, we render the content twice.
   * Once as usual, and then again in a "clone" without the text-overflow set. If the clone's
   * size is larger than the original, we can assume that the content is being truncated via
   * the text-overflow/clamp properties and then activate the tooltip.
   */
  useEffect(() => {
    if (isCloneRendered && cloneRef.current && contentRef.current) {
      let nextEllipsisActive = false
      if (lineClamp === 1) {
        nextEllipsisActive =
          cloneRef.current.clientWidth > contentRef.current.clientWidth
      } else {
        cloneRef.current.style.width = `${contentRef.current.clientWidth}px`
        nextEllipsisActive =
          cloneRef.current.clientHeight > contentRef.current.clientHeight
      }
      setIsEllipsisActive(nextEllipsisActive)
      setIsCloneRendered(false)
    }
    return () => {
      recomputeMeasurement.cancel()
    }
  }, [isCloneRendered])

  return (
    <>
      {!reuseGlobalTooltip && isEllipsisActive && (
        <Tooltip
          id={tooltipId}
          content={tooltipContent}
          place={place}
          positionStrategy='fixed'
        />
      )}
      {isCloneRendered && (
        <EllipsisHiddenMeasurementClone ref={cloneRef}>
          {content}
        </EllipsisHiddenMeasurementClone>
      )}
      <Component
        ref={contentRef}
        data-testid='ellipsis-tooltip-content'
        lineClamp={lineClamp}
        {...componentProps}
      >
        {content}
      </Component>
    </>
  )
}
const EllipsisHiddenMeasurementClone = styled.div`
  visibility: hidden;
  z-index: -1;
  position: absolute;
`

const EllipsisSingleLineTooltipContent = styled.div<{ lineClamp: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const EllipsisMultilineTooltipContent = styled.div<{ lineClamp: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
`

export default EllipsisTooltip
