import styled from 'styled-components'

export const SelectList = styled.ul`
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const SelectListItem = styled.li`
  padding: var(--size-base);

  label {
    cursor: pointer;
    display: flex;
    padding: 0.5em 2em;
    flex-direction: row;
    &:first-of-type {
      margin-top: 0.5em;
    }
  }

  label:hover {
    background-color: var(--color-hover);
  }

  label p {
    font-weight: 600;
    color: var(--color-title);
    margin-right: var(--size-base);
  }

  input[type='radio'] {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.9em;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  span {
    color: var(--color-text-light);
  }
`
