import { Snapshot } from '@flatfile/api'
import { AMP_TOOLTIP_ID } from '@flatfile/design-system'
import { Trans } from 'react-i18next'

import { PillVariant } from '@/elements/Pill'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { fromMaybe } from '@flatfile/shared-ui'
import { DiffViewTitle, DiffViewTitlePill } from './elements'
import { DiffAction, DiffTableTitleProps } from './types'

enum MetaType {
  AI = 'AI Transform',
  ACTION = 'Action',
}

interface Meta {
  label: MetaType
  variant: PillVariant
  text: string
}

export const getTitleMeta = (snapshot: Snapshot): Meta | undefined => {
  // TO DO: update this logic to not be based on string parsing once
  // we have more info from the API. Currently, this parsing will only
  // work for users whose language is set to English.

  if (!snapshot.label) return

  const isAiType = snapshot.label.indexOf(MetaType.AI) > -1

  if (!isAiType) return

  const promptIndex = snapshot.label.indexOf('"')

  let prompt = ''
  if (promptIndex > -1) {
    prompt = snapshot.label.slice(promptIndex, snapshot.label.length)
  }

  return {
    label: MetaType.AI,
    variant: PillVariant.SUCCESS,
    text: prompt,
  }
}

export const DiffTableTitle = (props: DiffTableTitleProps) => {
  const { t } = useTypedTranslation()

  const meta = getTitleMeta(props.snapshot)
  const action = meta?.label
  const aiAction = MetaType.AI
  const sheet = fromMaybe(props.sheet?.name, '')
  const sheetPillProps = {
    ['data-tooltip-id']: AMP_TOOLTIP_ID,
    ['data-tooltip-content']: t('snapshot.tooltips.sheet', {
      sheet,
      workbook: props.workbook.name,
    }),
  }

  if (props.action === DiffAction.NONE) {
    return (
      <DiffViewTitle data-testid='diff-table-title'>
        <Trans i18nKey={`snapshot.${DiffAction.NONE}.header.titleWithMeta`}>
          Review changes to
          <DiffViewTitlePill {...sheetPillProps}>{{ sheet }}</DiffViewTitlePill>
        </Trans>
      </DiffViewTitle>
    )
  }

  if (props.action === DiffAction.PREVIEW) {
    /* 
    This code makes it look like the text is in English, but the Trans component 
    will actually replace it with the correct, translated text. 
  */
    return (
      <DiffViewTitle data-testid='diff-table-title'>
        <Trans i18nKey={`snapshot.${DiffAction.PREVIEW}.header.titleWithMeta`}>
          Preview changes made in
          <DiffViewTitlePill {...sheetPillProps}>{{ sheet }}</DiffViewTitlePill>
          by
          <DiffViewTitlePill variant={PillVariant.SUCCESS}>
            {{ aiAction }}
          </DiffViewTitlePill>
          prompt
        </Trans>
      </DiffViewTitle>
    )
  }

  if (!meta || props.action === DiffAction.RESTORE) {
    return (
      <DiffViewTitle data-testid='diff-table-title'>
        {t(`snapshot.${props.action}.header.title`)}
        <DiffViewTitlePill {...sheetPillProps}>{sheet}</DiffViewTitlePill>
      </DiffViewTitle>
    )
  }

  /* 
    This code makes it look like the text is in English, but the Trans component 
    will actually replace it with the correct, translated text. 
  */
  return (
    <DiffViewTitle data-testid='diff-table-title'>
      <Trans i18nKey={`snapshot.${DiffAction.ACCEPT}.header.titleWithMeta`}>
        Changes made in
        <DiffViewTitlePill {...sheetPillProps}>{{ sheet }}</DiffViewTitlePill>
        by
        <DiffViewTitlePill variant={meta.variant}>
          {{ action }}
        </DiffViewTitlePill>
      </Trans>
      {meta.text}
    </DiffViewTitle>
  )
}
