import {
  AMP_TOOLTIP_ID,
  Button,
  Dropdown,
  MenuPlacement,
  ModalButtonRow,
  ModalHeader,
  ModalText,
  TextInput,
  Toggle,
  encodeKey,
  getFont,
} from '@flatfile/design-system'
import { Key, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BulkRowActionItem } from '../../apps/WorkbookApp/hooks/useBulkRowActions'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

type InputValue = string | boolean | number | Selection | undefined

const AnnotatedLabelWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`

const AnnotatedLabel = ({
  label,
  annotation,
  description,
}: {
  label: string
  annotation: string
  description?: string
}) => {
  return (
    <AnnotatedLabelWrapper>
      <Label>
        {label}
        <Annotation>{annotation}</Annotation>
      </Label>
      {description && <Description>{description}</Description>}
    </AnnotatedLabelWrapper>
  )
}
const Label = styled.label`
  ${getFont('actionMd')}
  font-family: var(--text-font);
`

const Annotation = styled.span`
  ${getFont('b1')}
  font-family: var(--text-font);
  color: var(--color-danger);
`

const Description = styled.div`
  ${getFont('b1')}
  font-family: var(--text-font);
  margin-bottom: 4px;
`

const TextAreaWrapper = styled.div`
  width: 465px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #cad0dc;
  background-color: #ffffff;
  gap: 8px;
  padding: 4px 12px;
  margin-top: 4px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1f2d3d;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
`

const InputWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
  &:first-child {
    margin-top: 8px;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
type Selection = Key | Set<Key>

const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export interface ModalProps {
  closeModal?: () => void
  onSubmit?: (arg?: any) => void
  onCancel?: () => void
  text?: {
    heading: string
    description: string
    confirmButtonText: string
    loadingText?: string
    errorMessage?: string
  }
  hasError?: boolean
  isSubmitting?: boolean
  action: BulkRowActionItem
}

export const ActionConfirmModalContent = (props: ModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const { text, onSubmit, action } = props
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()

  const handleSubmit = async () => {
    if (onSubmit) {
      setSubmitting(true)
      await onSubmit(typedInput)
      //TODO: handle error
      //setSubmitting(false)
    }
  }

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
    if (props.closeModal) {
      props.closeModal()
    }
  }

  const isSubmitting = submitting || props.isSubmitting

  const defaultValues = useMemo(
    () =>
      Object.fromEntries(
        (action.inputForm?.fields || []).map((field) => [
          field.key,
          field.defaultValue as InputValue,
        ])
      ),
    []
  )

  const [input, setInput] = useState<{
    [k: string]: InputValue
  }>(defaultValues)
  const handleInputChange =
    (key: string) => (value: string | boolean | number | Selection) => {
      setInput({ ...input, [key]: value })
    }

  const typedInput = useMemo(
    () =>
      Object.fromEntries(
        (action.inputForm?.fields || []).map((field) => {
          if (field.type === 'number') {
            const number = Number(input[field.key])
            return [field.key, isNaN(number) ? undefined : number]
          }
          if (field.type === 'boolean') {
            return [field.key, Boolean(input[field.key])]
          }
          return [field.key, input[field.key]]
        })
      ),
    [input]
  )

  const handleNativeInputChange =
    (key: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInput({ ...input, [key]: e.target.value })
    }

  const validationErrorsRequired = (action.inputForm?.fields || [])
    .filter(
      (field) =>
        field.constraints &&
        field.constraints.some((c) => c.type === 'required') &&
        (input[field.key] === undefined || input[field.key] === '')
    )
    .map((field) =>
      t('jobs.customActions.modals.confirmModal.isRequired', {
        field: customT(field.label),
      })
    )

  const validationErrorsNumber = (action.inputForm?.fields || [])
    .filter(
      (field) =>
        field.type === 'number' &&
        input[field.key] !== undefined &&
        input[field.key] !== '' &&
        !isNumeric(input[field.key])
    )
    .map((field) =>
      t('jobs.customActions.modals.confirmModal.mustBeNumber', {
        field: customT(field.label),
      })
    )

  const validationErrors = [
    ...validationErrorsRequired,
    ...validationErrorsNumber,
  ]

  const hasValidationError = validationErrors.length > 0
  return (
    <>
      <ModalHeader>
        <span data-testid='modal-header-content'>
          {text?.heading ? customT(text?.heading) : ''}
        </span>
      </ModalHeader>
      <ModalText>
        <span data-testid='modal-text-content'>
          {text?.description ? customT(text?.description) : ''}
        </span>
      </ModalText>
      {action.inputForm &&
        action.inputForm.type === 'simple' &&
        action.inputForm.fields && (
          <div>
            {action.inputForm.fields.map((entry) => {
              const optional = !(entry?.constraints || []).some(
                (c: any) => c.type === 'required'
              )
              const optionalString = optional ? '' : '*'
              const label = customT(entry.label)
              const description = entry.description
                ? customT(entry.description)
                : undefined
              return (
                <InputWrapper key={entry.key}>
                  {entry.type === 'textarea' && (
                    <div>
                      <AnnotatedLabel
                        label={label}
                        annotation={optionalString}
                        description={description}
                      />
                      <TextAreaWrapper>
                        <TextArea
                          value={(input[entry.key] as string) || ''}
                          onChange={handleNativeInputChange(entry.key)}
                          data-testid={`input-${entry.key}`}
                        />
                      </TextAreaWrapper>
                    </div>
                  )}
                  {entry.type === 'string' && (
                    <>
                      <AnnotatedLabel
                        label={label}
                        annotation={optionalString}
                        description={description}
                      />
                      <TextInput
                        size='md'
                        minWidth={10}
                        value={(input[entry.key] as string) || ''}
                        onChange={handleInputChange(entry.key)}
                        data-testid={`input-${entry.key}`}
                      />
                    </>
                  )}
                  {entry.type === 'number' && (
                    <>
                      <AnnotatedLabel
                        label={label}
                        annotation={optionalString}
                        description={description}
                      />
                      <TextInput
                        size='md'
                        minWidth={10}
                        value={(input[entry.key] as string) || ''}
                        onChange={handleInputChange(entry.key)}
                        data-testid={`input-${entry.key}`}
                      />
                    </>
                  )}
                  {entry.type === 'enum' && (
                    <>
                      <AnnotatedLabel
                        label={label}
                        annotation={optionalString}
                        description={description}
                      />
                      <Dropdown
                        minWidth={464}
                        maxWidth={464}
                        maxPopoverWidth='464px'
                        optionsList={
                          entry?.config?.options?.map((option) => ({
                            ...option,
                            label: option.label
                              ? customT(option.label)
                              : option.value.toString(),
                            key: option.value as Key,
                            tooltip: { active: option.description },
                          })) || []
                        }
                        selectedKey={
                          input[entry.key]
                            ? encodeKey(input[entry.key] as Key)
                            : null
                        }
                        placement={MenuPlacement.bottom}
                        data-testid={`input-${entry.key}`}
                        onSelectionChange={handleInputChange(entry.key)}
                      />
                    </>
                  )}
                  {entry.type === 'boolean' && (
                    <ToggleWrapper>
                      <AnnotatedLabel
                        label={label}
                        annotation={optionalString}
                        description={description}
                      />
                      <Toggle
                        id={entry.key}
                        checked={!!input[entry.key]}
                        onChange={handleInputChange(entry.key)}
                      />
                    </ToggleWrapper>
                  )}
                </InputWrapper>
              )
            })}
          </div>
        )}
      <ModalButtonRow>
        <Button
          variant='tertiary-border'
          onPress={handleCancel}
          isDisabled={isSubmitting}
          label={t('jobs.customActions.modals.confirmModal.cancelButton')}
          data-testid='cancel-button'
        />
        {isSubmitting ? (
          <Button
            label={
              text?.loadingText
                ? customT(text.loadingText)
                : t(
                    'jobs.customActions.modals.confirmModal.defaultLoadingButton'
                  )
            }
          />
        ) : (
          <span
            data-tooltip-id={AMP_TOOLTIP_ID}
            data-tooltip-content={
              hasValidationError ? validationErrors.join('\n') : undefined
            }
          >
            <Button
              type='submit'
              isDisabled={hasValidationError}
              onPress={handleSubmit}
              label={
                text?.confirmButtonText
                  ? customT(text?.confirmButtonText)
                  : t(
                      'jobs.customActions.modals.confirmModal.defaultConfirmButton'
                    )
              }
              data-testid='confirm-button'
            />
          </span>
        )}
      </ModalButtonRow>
    </>
  )
}
