import { Modal } from '@flatfile/design-system'
import { useEffect, useRef } from 'react'
import { ParagonIntegration } from '../hooks/useParagonConnect'
import { logger } from '@/utils/logging'

//manually created types for Box File Picker as no types are available
declare namespace Box {
  interface FilePickerOptions {
    container: HTMLElement | null
    chooseButtonLabel: string
    cancelButtonLabel: string
    canSetShareAccess: boolean
    canCreateNewFolder: boolean
    logoUrl: string
    onChoose: (files: FilePickerFile[]) => Promise<void>
    onCancel: () => void
  }

  interface FilePickerFile {
    id: string
    name: string
    size: number
    type: string
    [key: string]: any
  }

  class FilePicker {
    constructor()
    show(
      folderId: string,
      accessToken: string,
      options: FilePickerOptions
    ): void
  }
}

export const BoxFilePicker = ({
  boxToken,
  setShowBox,
  handleFileSelection,
  setLoading,
}: {
  boxToken: string
  setShowBox: Function
  handleFileSelection: (
    files: any[],
    source: ParagonIntegration
  ) => Promise<void>
  setLoading: Function
}) => {
  const pickerContainerRef = useRef(null)

  useEffect(() => {
    const initializePicker = () => {
      try {
        const filePicker = new Box.FilePicker()
        filePicker.show('0', boxToken, {
          container: pickerContainerRef.current,
          chooseButtonLabel: 'Click to upload files',
          cancelButtonLabel: 'Close file picker',
          canSetShareAccess: false,
          canCreateNewFolder: false,
          logoUrl:
            'https://images.ctfassets.net/hjneo4qi4goj/3W53R0jwfG2hHbOUAjEd0X/a2c5e1d755291408acb30a5a3e71f08e/flatfile-jewel.svg',
          onChoose: async (files: any[]) =>
            await handleFileSelection(files, 'box'),
          onCancel: () => {
            setShowBox(false)
            setLoading(false)
          },
        })
      } catch (error) {
        logger.error('Error initializing Box File Picker', error)
      }
    }

    initializePicker()
  }, [boxToken])

  return (
    <Modal
      data-testid='box-file-picker'
      size='lg'
      content={<div ref={pickerContainerRef} />}
    ></Modal>
  )
}
