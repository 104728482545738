import { JobOperationEnum } from '@/contexts/JobsContext'
import { EventDomainEnum, EventTopic } from '@flatfile/api'
import { QueryClient } from '@tanstack/react-query'
import { FlatfileRequestType } from './types'

export const documentsQueries = {
  getDocumentById: {
    generateQueryKey: (params: FlatfileRequestType<'getDocumentById'>) => [
      'getDocumentById',
      params,
    ],
    influencingEvents: {
      [EventTopic.Documentupdated]: (event: any, client: QueryClient) => {
        const documentId = event.origin.id
        client.invalidateQueries({
          queryKey: ['getDocumentById', { documentId: documentId }],
        })
      },
      [EventTopic.Documentdeleted]: (event: any, client: QueryClient) => {
        const documentId = event.origin.id
        client.invalidateQueries({
          queryKey: ['getDocumentById', { documentId: documentId }],
        })
      },
    },
  },
  getSpaceDocuments: {
    generateQueryKey: (params: FlatfileRequestType<'getSpaceDocuments'>) => [
      'getSpaceDocuments',
      params,
    ],
    influencingEvents: {
      [EventTopic.Jobcompleted]: (event: any, client: QueryClient) => {
        let eventResponse
        try {
          eventResponse = JSON.parse(event.message)
        } catch (e) {
          eventResponse = {}
        }
        const { context, payload } = eventResponse
        const spaceId = context?.spaceId
        if (
          spaceId &&
          payload?.type === EventDomainEnum.Space &&
          payload?.operation === JobOperationEnum.Configure
        ) {
          client.invalidateQueries({
            queryKey: ['getSpaceDocuments', { spaceId: spaceId }],
          })
        }
      },
      [EventTopic.Documentcreated]: (event: any, client: QueryClient) => {
        const spaceId = event?.context?.spaceId
        if (spaceId) {
          client.invalidateQueries({
            queryKey: ['getSpaceDocuments', { spaceId: spaceId }],
          })
        }
      },
      [EventTopic.Documentupdated]: (event: any, client: QueryClient) => {
        const spaceId = event?.context?.spaceId
        if (spaceId) {
          client.invalidateQueries({
            queryKey: ['getSpaceDocuments', { spaceId: spaceId }],
          })
        }
      },
      [EventTopic.Documentdeleted]: (event: any, client: QueryClient) => {
        const spaceId = event?.context?.spaceId
        if (spaceId) {
          client.invalidateQueries({
            queryKey: ['getSpaceDocuments', { spaceId: spaceId }],
          })
        }
      },
    },
  },
}
