import AuthGuard from '@/auth/AuthGuard'
import { Document, Sheet, Space, Workbook } from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { useQueryClient } from '@tanstack/react-query'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { resources } from '../../api/resources'
import { useRouteParams } from '../../hooks/useRouteParams'
import { ErrorState } from '../EmptyState'
import { SpacesUISkeleton } from '../SpacesUISkeleton'
import { DefaultTranslationsProvider } from '../Translations/DefaultTranslationsProvider'

const getHandlers = (
  resourceId: string | undefined,
  urlSuffix: string,
  navigate: NavigateFunction,
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
) => {
  return {
    sh: async () => {
      const sheetId = resourceId
      const sheet = await resources
        .getWithPromise<Sheet>(sheetId)
        .catch(() => ({ maybeData: undefined }))
      const workbookId = sheet.maybeData?.workbookId
      const workbook = await (!workbookId
        ? Promise.resolve({ maybeData: undefined })
        : resources
            .getWithPromise<Workbook>(workbookId)
            .catch(() => ({ maybeData: undefined })))
      const spaceId = workbook.maybeData?.spaceId
      if (sheetId && workbookId && spaceId) {
        navigate(
          `/space/${spaceId}/workbook/${workbookId}/sheet/${sheetId}` +
            urlSuffix,
          {
            replace: true,
          }
        )
      } else {
        setErrorMessage('errors.sheetNotFound')
      }
    },
    wb: async () => {
      const workbookId = resourceId
      const workbook = await resources.getWithPromise<Workbook>(workbookId)
      const spaceId = workbook.maybeData?.spaceId
      if (workbookId && spaceId) {
        navigate(`/space/${spaceId}/workbook/${workbookId}` + urlSuffix, {
          replace: true,
        })
      } else {
        setErrorMessage('errors.workbookNotFound')
      }
    },
    sp: async () => {
      const spaceId = resourceId
      const space = await resources
        .getWithPromise<Space>(spaceId)
        .catch(() => ({ maybeData: undefined }))
      if (space.maybeData) {
        navigate(`/space/${spaceId}` + urlSuffix, {
          replace: true,
        })
      } else {
        setErrorMessage('errors.spaceNotFound')
      }
    },
    fl: async () => {
      const fileId = resourceId
      const file = await resources
        .getWithPromise<Workbook>(fileId)
        .catch(() => ({ maybeData: undefined }))
      const spaceId = file.maybeData?.spaceId
      if (file && spaceId) {
        navigate(`/space/${spaceId}/files` + urlSuffix, {
          replace: true,
        })
      } else {
        setErrorMessage('errors.fileNotFound')
      }
    },
    dc: async () => {
      const docId = resourceId
      const doc = await resources
        .getWithPromise<Document>(docId)
        .catch(() => ({ maybeData: undefined }))
      const spaceId = doc.maybeData?.spaceId
      if (doc && spaceId) {
        navigate(`/space/${spaceId}/document/${docId}` + urlSuffix, {
          replace: true,
        })
      } else {
        setErrorMessage('errors.documentNotFound')
      }
    },
  }
}

export const ResourceRedirector: FC<{}> = () => {
  const { t } = useTranslation()
  const { resourceId } = useRouteParams()
  const { search } = useLocation()
  const client = useQueryClient()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const path = params.get('path')
  const trimmedPath = path?.replace(/^\/|\/$/g, '')
  const query = params.get('query')
  const urlSuffix =
    (trimmedPath ? `/${decodeURIComponent(trimmedPath)}` : '') +
    (query ? `?${decodeURIComponent(query)}` : '')

  const navigate = useNavigate()
  const [_, resourceType] = fromMaybe(resourceId, '').split('_')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const handlers = getHandlers(resourceId, urlSuffix, navigate, setErrorMessage)
  useEffect(() => {
    const fetchData = async () => {
      if (!['sh', 'wb', 'sp', 'fl', 'dc'].includes(resourceType)) {
        setErrorMessage('Not a valid ID')
      } else {
        const key = resourceType as 'sh' | 'wb' | 'sp' | 'fl' | 'dc'
        await handlers[key]()
      }
    }
    // The resource-redirector exists outside of the Space route - Thus, we need to invalidate queries and start fresh upon navigation to ensure
    // initial resource data is appropriately hydrated and does not use a stale data set during initialization
    client.invalidateQueries(['getInitialResources'])
    fetchData()
  }, [])

  if (errorMessage) {
    return <ErrorState title={t(errorMessage)} />
  }
  return <SpacesUISkeleton />
}

export const ResourceRedirectorView = () => {
  return (
    <AuthGuard>
      <DefaultTranslationsProvider>
        <ResourceRedirector />
      </DefaultTranslationsProvider>
    </AuthGuard>
  )
}
