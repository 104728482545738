import styled from 'styled-components'

export const MatchField = styled.div`
  flex-basis: 40%;
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    color: var(--color-text);
    font-size: var(--size-p);
    font-weight: 500;
  }
`
