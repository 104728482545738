import { Button } from '@flatfile/design-system'
import styled from 'styled-components'

import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

interface ICommandMenuPanelFooter {
  disabled?: boolean
  onCancel: () => void
}
export const CommandMenuPanelFooter = ({
  disabled,
  onCancel,
}: ICommandMenuPanelFooter) => {
  const { t } = useTypedTranslation()

  return (
    <FooterFlexWrapper>
      <Button
        onPress={onCancel}
        variant='warning'
        label={t('commandMenu.buttons.cancel')}
        isDisabled={disabled}
      />
    </FooterFlexWrapper>
  )
}

const FooterFlexWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`
