import { ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'
import flatfileIcon from '../assets/icons/flatfile-icon'
import { useTypedTranslation } from '../hooks/useTranslationWrappers'

const BottomRightBranding = styled.a`
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: ${ZIndex.level1200};
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  border-radius: var(--pill-border-radius);
  color: black;
  border: 1px solid black;
  background-color: var(--color-white);
  box-shadow: 0px 19px 6px -17px rgba(0, 0, 0, 0.25),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  :hover {
    text-decoration: none;
    background-color: var(--color-primary-light);
  }

  svg {
    color: var(--color-primary);
  }
`
export const PoweredByFlatfile = ({ spaceId }: { spaceId: string }) => {
  const { t } = useTypedTranslation()
  return (
    <BottomRightBranding
      href={`https://flatfile.com?utm_source=full_space&utm_medium=in_app&utm_campaign=referrer&utm_content=${spaceId}`}
      target='_blank'
      data-testid='referrer-link'
      rel='noreferrer'
    >
      {t('utils.poweredByFlatfile')} {flatfileIcon}
    </BottomRightBranding>
  )
}
