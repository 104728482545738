import { useEffect, useState } from 'react'
import { differenceInTime } from '../../utils/differenceInTime'

export const Countdown = ({
  targetDate,
  translatedUnits,
  translatedPostfix,
}: any) => {
  const [time, setTime] = useState(
    differenceInTime(new Date(), targetDate, translatedUnits)
  )
  const targetDateInPast = targetDate < new Date()

  useEffect(() => {
    setTime(differenceInTime(new Date(), targetDate, translatedUnits))
    const interval = setInterval(() => {
      setTime(differenceInTime(new Date(), targetDate, translatedUnits))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [targetDate])

  if (targetDateInPast) {
    return <span data-testid='empty-countdown'></span>
  }
  return (
    <span>
      {time} {translatedPostfix}
    </span>
  )
}
