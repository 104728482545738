import {
  Button,
  Modal,
  ModalButtonRow,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import { DependencyList, MutableRefObject, useCallback, useState } from 'react'
import useResizeObserver from '../../hooks/useResizeObserver'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

const ACCEPT_SIZE_TOO_SMALL = 'acceptSizeTooSmall'

export const SizeWarningModal = ({
  componentRef,
  sizeLimit,
  dependencies,
}: {
  componentRef: MutableRefObject<null>
  sizeLimit: number
  dependencies: DependencyList
}) => {
  const [isTooSmall, setIsTooSmall] = useState(false)
  const [acceptTooSmall, setAcceptTooSmall] = useState(
    sessionStorage.getItem(ACCEPT_SIZE_TOO_SMALL) === 'true'
  )

  const resizeCallback = useCallback(
    (element: HTMLElement, entry: ResizeObserverEntry) => {
      const contentBox = Array.isArray(entry.contentBoxSize)
        ? entry.contentBoxSize[0]
        : entry.contentBoxSize

      if (
        contentBox &&
        typeof contentBox.inlineSize === 'number' &&
        contentBox.inlineSize < sizeLimit
      ) {
        setIsTooSmall(true)
      } else {
        setIsTooSmall(false)
      }
    },
    dependencies
  )

  const onClickAcceptTooSmall = () => {
    sessionStorage.setItem(ACCEPT_SIZE_TOO_SMALL, 'true')
    setAcceptTooSmall(true)
  }

  useResizeObserver(componentRef, resizeCallback)

  const { t } = useTypedTranslation()

  return (
    <>
      {isTooSmall && !acceptTooSmall ? (
        <Modal
          data-testid='size-warning-modal'
          content={
            <>
              <ModalHeader>{t('utils.modals.sizeWarning.title')}</ModalHeader>
              <ModalText>{t('utils.modals.sizeWarning.body')}</ModalText>
              <ModalButtonRow>
                <Button
                  label={t('utils.modals.sizeWarning.button')}
                  onPress={onClickAcceptTooSmall}
                  data-testid='accept-too-small-button'
                />
              </ModalButtonRow>
            </>
          }
        />
      ) : null}
    </>
  )
}
