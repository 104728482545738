import { logger } from '@/utils/logging'
import PubNub from 'pubnub'
import { HTTPService } from './HttpClient'

export class EventSubscriber {
  public static async getClient(spaceId: string) {
    try {
      const eventToken = await HTTPService.getEventToken({ spaceId })
      if (eventToken.data) {
        const pubnub = new PubNub({
          subscribeKey: eventToken.data.subscribeKey!,
          uuid: eventToken.data.accountId!,
          restore: true, // Will automatically restore subscription to SpaceId channel on disconnect (https://www.pubnub.com/docs/general/setup/connection-management#restore-channels-subscription_)
        })
        pubnub.setToken(eventToken.data.token!)
        return { pubnub, token: eventToken.data.token }
      }
    } catch (e) {
      logger.warn('Unable to get event token: ', e)
    }
  }
}
