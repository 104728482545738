import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

export const RevealContent = styled.div`
  opacity: 0;
  transition: opacity 0.25s ease;

  &[data-reveal] {
    opacity: 1;
  }
`

export function Reveal({
  children,
  delay = 0,
}: {
  children: ReactNode
  delay?: number
}) {
  const content = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if (content.current) {
        content.current.setAttribute('data-reveal', 'true')
      }
    }, delay)
  }, [])
  return <RevealContent ref={content}>{children}</RevealContent>
}
