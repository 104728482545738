import { Workbook } from '@flatfile/api'
import { Icon, Tooltip } from '@flatfile/design-system'

import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

/**
 * Utility component which evaluates the format of the connection icon
 * based on the status, date, and validity of the Workbook's metadata connections
 *
 * Based on the workbook metadata, the icon could be 1 of 3 states
 * - "connection" icon means the the connection is valid and was last-syncd < 24 hrs
 * - "connectionSlash" icon means the the connection is valid but was last-syncd > 24 hrs
 * - "alert" the metadata contains connections, but they are not structured per expectations
 */
export const ConnectionIcon = ({ workbook }: { workbook: Workbook }) => {
  const { t } = useTypedTranslation()
  const connectionsData = workbook?.metadata?.connections
  const connection = connectionsData[0]
  let icon

  if (connection?.lastSyncedAt) {
    const lastSync = new Date(connection?.lastSyncedAt)
    const oneDay = 60 * 60 * 24 * 1000
    const isOneDayOld = Date.now() - lastSync.getTime() >= oneDay
    const syncCurrentContent = t(
      'sidebar.connections.tooltips.connectionFresh',
      {
        source: connection?.source ?? 'unknown',
        service: connection?.service ?? 'unknown',
      }
    )
    const syncStaleContent = t('sidebar.connections.tooltips.connectionStale', {
      source: connection?.source ?? 'unknown',
    })

    icon = (
      <>
        <Icon
          name={isOneDayOld ? 'connectionSlash' : 'connection'}
          data-testid={`${isOneDayOld ? 'connectionSlash' : 'connection'}-icon`}
          data-tooltip-id={`connection-tooltip-${workbook.id}`}
        />
        <Tooltip id={`connection-tooltip-${workbook.id}`}>
          {isOneDayOld ? syncStaleContent : syncCurrentContent}
        </Tooltip>
      </>
    )
  } else {
    icon = (
      <>
        <Icon
          name='alertTriangle'
          data-testid='connection-alert-icon'
          data-tooltip-id={`connection-alert-${workbook.id}`}
        />
        <Tooltip id={`connection-alert-${workbook.id}`}>
          {t('sidebar.connections.tooltips.connectionError')}
        </Tooltip>
      </>
    )
  }

  return icon
}
