import { DataBufferProvider, TableProviders } from '@flatfile/turntable'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { DiffTable } from './DiffTable'
import { DiffViewProps } from './types'

/* c8 ignore start */
const noop = () => null
const MemoizedDiffTable = memo(DiffTable)
/* c8 ignore stop */

export const DiffView = ({ onLoadRows, snapshot, ...props }: DiffViewProps) => {
  const { i18n } = useTranslation()
  return (
    <DataBufferProvider onLoadRows={onLoadRows} onUpdateRows={noop}>
      <TableProviders tableId={snapshot.id} i18n={i18n}>
        <MemoizedDiffTable key={snapshot.id} snapshot={snapshot} {...props} />
      </TableProviders>
    </DataBufferProvider>
  )
}
