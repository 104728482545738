import { JobStatusEnum } from '@flatfile/api'
import { useContext, useMemo } from 'react'
import { JobsContext } from '../contexts/JobsContext'

const runningStatuses: JobStatusEnum[] = [
  JobStatusEnum.Ready,
  JobStatusEnum.Executing,
]

export const useRunningJobs = () => {
  const { activeJobs } = useContext(JobsContext)
  const runningJobs = useMemo(
    () =>
      Object.values(activeJobs ?? []).filter(
        (j) => j.status && runningStatuses.includes(j.status)
      ),
    [activeJobs]
  )
  return runningJobs
}
