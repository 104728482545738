import { runtimeStorageKey } from '@/auth/useAuth'
import { storage } from '@flatfile/shared-ui'

export type RuntimeParams = {
  isGuest: boolean
  accessToken?: string
  guestEmail?: string
  returnUrl?: string
  spaceId?: string
}

export function getRuntimeParameters() {
  const storedRuntimeParameters = JSON.parse(
    localStorage.getItem(runtimeStorageKey) ?? '{}'
  )

  return storedRuntimeParameters as RuntimeParams
}

export function setRuntimeParameters(runtime: RuntimeParams) {
  storage(runtimeStorageKey).set(JSON.stringify(runtime))
}

export function clearRuntimeToken() {
  const runtime = getRuntimeParameters()
  delete runtime.accessToken

  storage(runtimeStorageKey).set(JSON.stringify(runtime))
}

export function resetRuntimeParameters() {
  storage(runtimeStorageKey).clear()
}
