import { getFont, FontType } from '@flatfile/design-system'
import styled from 'styled-components'

export enum PillVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
}

interface PillStyle {
  color: string
  backgroundColor: string
}

interface PillProps {
  type?: FontType
  variant?: PillVariant
  $color?: string
  $backgroundColor?: string
}

export const PillStyles: Record<PillVariant, PillStyle> = {
  [PillVariant.DEFAULT]: {
    color: 'var(--color-title)',
    backgroundColor: 'var(--color-page-100)',
  },
  [PillVariant.PRIMARY]: {
    color: 'var(--color-primary-darkest)',
    backgroundColor: 'var(--color-primary-light)',
  },
  [PillVariant.SUCCESS]: {
    color: 'var(--color-success-darkest)',
    backgroundColor: 'var(--color-success-light)',
  },
  [PillVariant.WARNING]: {
    color: 'var(--color-warning-light)',
    backgroundColor: 'var(--color-warning)',
  },
}

export const Pill = styled.div<PillProps>`
  ${({ type }) => getFont(type ?? 'actionSm')}
  font-family: var(--text-font);
  ${({ variant, $color, $backgroundColor }) => {
    const styles = PillStyles[variant ?? PillVariant.DEFAULT]
    return `
      color: ${$color ?? styles.color};
      background-color: ${$backgroundColor ?? styles.backgroundColor};
    `
  }}
  padding: 2px 10px;
  border-radius: 4px;
  display: inline-block;
`
