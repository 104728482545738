import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { SpaceContext } from '@/contexts/SpaceContext'
import {
  Filter,
  GetRecordsRequest,
  RecordCounts,
  RecordCountsResponse,
} from '@flatfile/api'
import { useMutation } from '@tanstack/react-query'
import { MutableRefObject, useContext, useMemo, useRef } from 'react'
import { SheetCountsContext } from '../contexts/SheetCountsContext'
import { getHasSearchFilters } from './useSheetViewData'

export const getRecordCountsQueryKey = 'getCounts'

export function useRecordCountsData(params: GetRecordsRequest) {
  const { httpClient } = useContext(SpaceContext)
  const lastFetched = useRef<number>(0)
  const filteredParams = params.filterField
    ? { ...params, filter: Filter.Error }
    : params
  const { sheetId, filter, ...searchFields } = filteredParams
  const hasSearchFilters = getHasSearchFilters(searchFields)
  const {
    counts: countsData,
    isFetching: isFetchingSheetCounts,
    isError: hasCountsError,
    dataUpdatedAt: countsUpdatedAt,
    refetchCounts,
  } = useContext(SheetCountsContext)

  const sheetParams = { sheetId: params.sheetId }
  const {
    data: filteredCountsData,
    isError: hasFilteredCountsError,
    isRefetching: isRefetchingFilteredCounts,
    isLoading: isLoadingFilteredCounts,
  } = useFlatfileQuery(getRecordCountsQueryKey, filteredParams, {
    retry: false,
    enabled: hasSearchFilters,
  })

  const { mutateAsync: refetchErrorsByFieldCounts, data: errorByFieldCounts } =
    useMutation(
      async () => await httpClient.getCounts({ ...sheetParams, byField: true }),
      {
        onSuccess: () => {
          lastFetched.current = Date.now()
        },
      }
    )

  const counts = useMemo(() => {
    return determineCounts({
      isFetchingSheetCounts,
      countsData,
      errorByFieldCounts,
      lastFetched,
      countsUpdatedAt,
    })
  }, [countsData, isFetchingSheetCounts, hasCountsError, errorByFieldCounts])

  const filteredCounts = useMemo(() => {
    if (isLoadingFilteredCounts && hasSearchFilters) return undefined
    return filteredCountsData?.data?.counts ?? counts
  }, [filteredCountsData?.data?.counts, isLoadingFilteredCounts, counts])

  return {
    counts,
    filteredCounts,
    hasCountsError: hasCountsError || hasFilteredCountsError,
    isLoadingCounts:
      isFetchingSheetCounts ||
      (isLoadingFilteredCounts && hasSearchFilters) ||
      isFetchingSheetCounts ||
      (isRefetchingFilteredCounts && hasSearchFilters),
    refetchErrorsByFieldCounts,
    refetchCounts,
  }
}

//returns the counts object that was most recently fetched (countsData or errorByFieldCounts)
export const determineCounts = ({
  isFetchingSheetCounts,
  countsData,
  errorByFieldCounts,
  lastFetched,
  countsUpdatedAt,
}: {
  isFetchingSheetCounts: boolean
  countsData: RecordCounts | undefined
  errorByFieldCounts: RecordCountsResponse | undefined
  lastFetched: MutableRefObject<number>
  countsUpdatedAt: number
}) => {
  if (isFetchingSheetCounts) return undefined
  const counts = countsData
  const errorCounts = errorByFieldCounts?.data?.counts

  if (!errorCounts && !lastFetched.current) return counts
  if (countsUpdatedAt > lastFetched.current) return counts

  if (lastFetched.current > countsUpdatedAt) {
    return errorCounts
  }
}
