import { useMemo } from 'react'
import { MOCKS } from '../test/mocks'

export function useEventCallbacks<T extends EventHandlerDict>(
  ns: string,
  props: T,
  listeners: any[] = []
): T {
  return useMemo(() => {
    if (MOCKS[`EVENT_HANDLER_${ns}`]) {
      return MOCKS[`EVENT_HANDLER_${ns}`](props)
    }

    return props
  }, listeners)
}

export type EventHandlerDict = Record<string, (...args: any[]) => any>
