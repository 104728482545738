import { JobModeEnum, Workbook } from '@flatfile/api'
import { JobOperationEnum } from '../contexts/JobsContext'
import { useRunningJobs } from './useRunningJobs'

export const useIsWorkbookLocked = (
  workbook: Workbook,
  modes: JobModeEnum[] = [JobModeEnum.Foreground, JobModeEnum.ToolbarBlocking]
) => {
  const sheetIds = workbook.sheets?.map((sheet) => sheet.id) ?? []
  const ids = [workbook.id, ...sheetIds]
  const runningJobs = useRunningJobs()
  const blockingJobs = runningJobs
    .filter((j) => j.mode && modes.includes(j.mode))
    .filter((j) =>
      ids.some(
        (id) =>
          (j.operation === JobOperationEnum.Map &&
            id &&
            j.destination === id) ||
          (id && j.source === id)
      )
    )

  return blockingJobs.length > 0
}
