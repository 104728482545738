import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { CellValueWithCounts, Value } from '@flatfile/api'
import {
  forceArray,
  forceString,
  isDebouncedLoadPending,
  useDebounce,
} from '@flatfile/design-system'
import {
  ColumnConfigProps,
  LookupOption,
  getCellValueIsEmpty,
} from '@flatfile/turntable'
import { isArray } from 'lodash'
import { Key, useContext, useState } from 'react'
import styled from 'styled-components'
import { FieldsContext } from '../contexts/FieldsContext'
import { SearchFiltersContext } from '../contexts/SearchFiltersContext'
import { useDistinctColumnValues } from './useSheetFilters'

type OptionsMap = {
  [key: string]: LookupOption
}

const NoDataLabel = styled.span`
  color: var(--color-text-ultralight) !important;
  font-style: italic;
`

export const NoData = () => {
  const { t } = useTypedTranslation()
  return (
    <NoDataLabel className='filterByValue'>
      {t('sheet.table.contextMenu.filterByValue.noData')}
    </NoDataLabel>
  )
}

export const EMPTY_OPTION = { key: '', label: <NoData />, divider: true }

export const getOptionKey = (value?: Key | Value): string => {
  return forceString<Key | Value>(value)
}

export const getValueKeys = (keys?: Key[]) => {
  /* 
    Performs a basic sort on the keys so that they're always in the 
    same order when submitted. This way if you submit keys in the order 
    [a, b] in one search and [b, a] in the next search, we don't change
    the filter value or re-fetch rows unnecessarily.
  */
  return forceArray<Key>(keys).sort()
}

const buildOptionsMap = (fieldConfig: ColumnConfigProps) => {
  if (fieldConfig.type !== 'enum') return undefined
  if (fieldConfig.options && !isArray(fieldConfig.options)) return undefined
  const optionMap: OptionsMap = {}
  fieldConfig.options.forEach((option: LookupOption) => {
    optionMap[option.key] = option
  })
  return optionMap
}

export const buildValueOptions = (
  options?: CellValueWithCounts[],
  selectedValues?: Key[],
  searchValue?: string,
  isPending?: boolean,
  optionsMap?: OptionsMap
) => {
  if (isPending || !options) return []
  let hasEmptySelectedOption = false
  let optionsList = []

  if (!searchValue) {
    selectedValues?.forEach((value) => {
      if (getCellValueIsEmpty(value)) {
        hasEmptySelectedOption = true
        return
      }

      optionsList.push({
        key: getOptionKey(value),
        label: optionsMap?.[value] ? optionsMap[value].label : value,
        selected: true,
      })
    })

    optionsList.push({
      ...EMPTY_OPTION,
      selected: hasEmptySelectedOption,
    })
  }

  options.forEach((option: CellValueWithCounts) => {
    /* 
        Don't show empty or default selected values in the main list because we 
        persist them at the top.
    */
    if (getCellValueIsEmpty(option.value)) {
      return
    }
    const selected = !!selectedValues?.find((v) => v === option.value)
    if (!searchValue && selected) {
      return
    }
    const optionLabel = optionsMap?.[option.value as any]
      ? optionsMap[option.value as any].label
      : option.value
    optionsList.push({
      key: getOptionKey(option.value),
      label: optionLabel,
      selected,
    })
  })

  return optionsList
}

type SearchValue = string | undefined
/*
  NOTE: This hook is responsible for managing the filter by value behavior
  for an individual field. It fetches the values and formats them for 
  a menu. It also passes the function to submit the selected values.

  This hook is NOT responsible for managing any higher-level state.
*/
export const useFilterByValue = ({ field }: { field: string }) => {
  const [enableFetch, setEnableFetch] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<SearchValue>()
  const { columnConfig } = useContext(FieldsContext)
  const fieldConfig = columnConfig.find((conf) => conf.value === field)
  const optionsMap = fieldConfig ? buildOptionsMap(fieldConfig) : {}
  const { filterByValueFields, handleFilterByValue } =
    useContext(SearchFiltersContext)

  const debouncedSearchValue = useDebounce<SearchValue>(searchValue, 300)

  const { data, isLoading } = useDistinctColumnValues(
    enableFetch,
    field,
    debouncedSearchValue
  )

  const isPending = isDebouncedLoadPending<SearchValue>(
    searchValue,
    debouncedSearchValue,
    isLoading
  )
  const selectedValues = filterByValueFields[field]
  const valueOptionsList = buildValueOptions(
    data?.data?.[field],
    selectedValues,
    searchValue,
    isPending,
    optionsMap
  )

  const onFilterByValue = (keys?: Key[]) => {
    handleFilterByValue(field, getValueKeys(keys))
  }

  return {
    enableFetch,
    isPending,
    onFilterByValue,
    searchValue,
    setEnableFetch,
    setSearchValue,
    valueOptionsList,
  }
}
