import { FieldConfig } from '@flatfile/api'

type Header = {
  metadata: {
    fieldRef: string
  }
  key: string
  type: string
}

export const isHeaderRowSelectionEnabled = (metadata: any): boolean => {
  return Boolean(
    metadata?.headers?.some((header: any) => header.metadata?.fieldRef)
  )
}

export const transformSourceRecords = (
  records: Record<string, any>[],
  headers: Header[]
): Record<string, any>[] => {
  const fieldMapping = new Map(
    headers.map((header) => [header.metadata.fieldRef, header.key])
  )

  return records.map((record) => {
    const transformedRecord: Record<string, any> = {}
    for (const [key, value] of Object.entries(record)) {
      const mappedKey = fieldMapping.get(key)
      if (mappedKey) {
        transformedRecord[mappedKey] = value
      }
    }
    return transformedRecord
  })
}

export const transformSourceFields = (
  fields: FieldConfig[],
  headers: Header[]
): Record<string, any>[] => {
  const fieldMapping = new Map(
    headers.map((header) => [header.metadata.fieldRef, header])
  )

  return fields.map((field) => {
    const mappedField = fieldMapping.get(field.key)
    return mappedField
      ? { key: mappedField.key, type: mappedField.type }
      : field
  })
}
