import { parseISO } from 'date-fns'
import i18next from 'i18next'

export const formatTime = (date?: string | number | Date) => {
  if (!date) return ''

  const parsed = typeof date === 'string' ? parseISO(date) : date

  try {
    return new Intl.DateTimeFormat(i18next.language, {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(parsed)
  } catch (error) {
    return ''
  }
}
