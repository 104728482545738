import { Job } from '@flatfile/api'
import { Spinner, Typography } from '@flatfile/design-system'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusButton } from '../../apps/WorkbookApp/StatusButton'
import { JobStatus, useJobStatus } from '../../hooks/useJobStatus'
import { JobsPanel } from './JobsPanel'

interface JobStatusWrapperProps {
  status: JobStatus
  hover: boolean
}

export const JobsStatus: React.FC<{
  isActive: boolean
  onClose: () => void
  onOpen: () => void
}> = ({ isActive, onClose, onOpen }) => {
  const { t } = useTranslation()
  const { status, currentDisplayState, currentlyRunningJobs } = useJobStatus()
  const [showHoverState, setShowHoverState] = useState<boolean>(false)
  const averageProgress = useMemo(
    () => calculateAverageProgress(currentlyRunningJobs),
    [currentlyRunningJobs]
  )

  const getBgColor = backgroundColor({
    hover: showHoverState || isActive,
    status,
  })

  return (
    <div>
      <StatusButton
        isActive={isActive}
        onClick={onOpen}
        icon='truck'
        name='jobs'
        showHoverState={showHoverState}
        setShowHoverState={setShowHoverState}
        $bgColor={getBgColor}
      >
        {status === 'active' && (
          <>
            <Spinner color='var(--color-primary)' size={16} />
            {!!averageProgress && (
              <span className='progress'>{averageProgress}%</span>
            )}
          </>
        )}
        <span data-testid={`status-${status}`}>
          <Typography type='b1'>
            {currentlyRunningJobs.length === 0 &&
            !currentDisplayState?.text &&
            (showHoverState || isActive) &&
            status === 'none'
              ? t('jobs.pill.hoverText')
              : ''}

            {currentlyRunningJobs.length > 1 && status === 'active'
              ? t('jobs.pill.multipleJobsRunning', {
                  count: currentlyRunningJobs.length,
                })
              : currentDisplayState?.text
              ? t(currentDisplayState?.text)
              : ''}
          </Typography>
        </span>
      </StatusButton>
      {isActive && <JobsPanel onClose={onClose} />}
    </div>
  )
}

export const backgroundColor = (props: JobStatusWrapperProps) => {
  if (props.hover) return 'var(--color-page-100)'
  switch (props.status) {
    case 'active':
      return 'var(--color-primary-light)'
    case 'complete':
      return 'var(--color-success-light)'
    case 'failed':
      return 'var(--color-danger-light)'
    case 'none':
      return 'transparent'
  }
}

export const calculateAverageProgress = (currentlyRunningJobs: Job[]) => {
  const progresses = currentlyRunningJobs
    .map((job) => job.progress)
    .filter((value): value is number => !!value)
  return Math.floor(progresses.reduce((a, b) => a + b, 0) / progresses.length)
}
