import { JobController } from '@/api/controllers/JobController'
import { EnvironmentsContext } from '@/contexts/EnvironmentsContext'
import { JobsContext } from '@/contexts/JobsContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  Action,
  BaseActionConstraintTypeEnum,
  EventDomainEnum,
  EventTopic,
  JobSubjectType,
  JobTypeEnum,
  Sheet,
  Workbook,
} from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { ActionStatus, useBulkRowActionsContext } from '@flatfile/turntable'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkRowActionItem } from './useBulkRowActions'
import { JobQuery } from './useJobQuery'
import { useRecordMutation } from './useRecordMutation'
import { WorkbookCounts } from './useWorkbookCountsData'

export type JobInput = object | undefined

export const hasConstraint = (
  action: Action,
  type: BaseActionConstraintTypeEnum,
  deprecatedKey?: keyof Action
) =>
  Boolean(
    action.constraints?.some((c) => c.type === type) ||
      (deprecatedKey && action[deprecatedKey])
  )

export const getTranslatedMessages = (
  action: Action,
  t: (s: string) => string
) => {
  let infoMessages = []
  let errorMessages = []
  const messages = fromMaybe(action.messages, [])
  for (const message of messages) {
    if (message.type === 'error') {
      errorMessages.push(t(message.content))
    } else {
      infoMessages.push(t(message.content))
    }
  }
  return { infoMessages, errorMessages }
}

export interface UseCustomActionsProps {
  spaceId: string
  environmentId?: string
  workbook: Workbook
  sheet?: Sheet
  getJobQuery: () => JobQuery
  rowsSelectedCount: {
    download: number
    delete: number
    copy: number
    error: number
    errorsTotal: number
    total: number
  }
  setCurrentAction: Dispatch<SetStateAction<BulkRowActionItem | null>>
  workbookCounts: WorkbookCounts
}

export const useCustomActions = ({
  environmentId,
  workbook,
  sheet,
  spaceId,
  getJobQuery,
  rowsSelectedCount,
  setCurrentAction,
  workbookCounts,
}: UseCustomActionsProps) => {
  const { accountId } = useContext(EnvironmentsContext)
  const { addOriginatedJobId } = useContext(JobsContext)
  const { setActionStatus } = useBulkRowActionsContext()
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()

  const { createEvent } = useRecordMutation(sheet?.id ?? '', workbook.id)
  const sheetSlug = sheet?.config?.slug ?? ''
  const sheetKey = sheetSlug.includes('/') ? sheetSlug.split('/')[1] : sheetSlug

  const eventPayload = (actionName: string) => {
    const workbookId = workbook.id
    const sheetId = sheet?.id!
    const query = getJobQuery()
    const payload = {
      eventConfig: {
        domain: EventDomainEnum.Workbook,
        topic: 'action:triggered' as EventTopic,
        context: {
          accountId: accountId ?? '',
          spaceId,
          actionName: `${sheetKey}:${actionName}`,
          sheetId,
          environmentId: environmentId ?? '',
          workbookId,
          sheetSlug,
          slugs: {
            sheet: sheetSlug,
          },
        },
        payload: {
          actionName: `${sheetKey}:${actionName}`,
          ...query,
        },
      },
    }
    return payload
  }
  const handleAction = async (
    domain: JobTypeEnum,
    resource: Sheet | Workbook,
    action: Action,
    input: JobInput
  ) => {
    setActionStatus(ActionStatus.inProgress)
    try {
      // Todo: move this to the backend as a legacy event
      if (action.slug) {
        await createEvent(eventPayload(action.slug))
      } else {
        //when there is no slug there must be an operation
        const operation = action.operation!
        const query = getJobQuery()

        const isSheet = (item: Sheet | Workbook): item is Sheet =>
          (item as Sheet).workbookId !== undefined

        const subject = isSheet(resource)
          ? {
              type: JobSubjectType.Collection,
              resource: 'records',
              query,
              params: {
                sheetId: resource.id,
              },
            }
          : {
              type: JobSubjectType.Resource,
              id: resource.id,
            }

        const jobController = await JobController.create({
          type: domain,
          fromAction: action,
          source: resource.id,
          operation: operation,
          mode: action.mode,
          subject,
          input,
        })

        const jobId = jobController.job.id
        addOriginatedJobId(jobId)
      }

      setActionStatus(ActionStatus.success)
      return { success: true }
    } catch (error) {
      setActionStatus(ActionStatus.failed)
      return { success: false }
    }
  }

  const customActions: BulkRowActionItem[] =
    sheet?.config?.actions?.map((action) => {
      const { errorMessages: agentErrorMessages, infoMessages } =
        getTranslatedMessages(action, customT)

      const hasAgentErrorMessages = agentErrorMessages.length > 0

      const noSelectionCheckmarks = rowsSelectedCount.delete === 0
      const requireSelection = hasConstraint(
        action,
        'hasSelection',
        'requireSelection'
      )

      const requireAllValid = hasConstraint(
        action,
        'hasAllValid',
        'requireAllValid'
      )
      const requireHasData = hasConstraint(action, 'hasData')

      const errorCount =
        !requireSelection && noSelectionCheckmarks
          ? rowsSelectedCount.errorsTotal
          : rowsSelectedCount.error

      const hasErrors = errorCount > 0
      const hasData = rowsSelectedCount.total > 0

      const disabled =
        hasAgentErrorMessages ||
        (requireHasData && !hasData) ||
        (requireSelection && noSelectionCheckmarks) ||
        (requireAllValid && hasErrors)

      const activeTooltip = [
        ...(action.tooltip ? [customT(action.tooltip)] : []),
        ...infoMessages,
      ].join('\n')

      const errorMessages = [
        ...agentErrorMessages,
        ...(requireHasData && !hasData
          ? [t('sheet.workbookActions.disabledTooltip.hasData')]
          : []),
        ...(requireSelection && noSelectionCheckmarks
          ? [
              t(
                'sheet.toolbar.customActionsDropdown.disabledTooltip.requireSelection'
              ),
            ]
          : []),
        ...(requireAllValid && hasErrors
          ? [t('sheet.workbookActions.disabledTooltip.requireAllValid')]
          : []),
      ]

      const errorTooltip = errorMessages.join('\n')

      return {
        key: customT(action.label),
        label: customT(action.label),
        disabled,
        primary: !!action.primary,
        action: (input: JobInput) =>
          handleAction('sheet', sheet, action, input),
        preAction: function () {
          if (action.confirm || action.inputForm) {
            setCurrentAction(this)
          } else {
            handleAction('sheet', sheet, action, undefined)
          }
        },
        icon: action.icon,
        modalText: {
          heading: customT(action.label),
          description: action.description ? customT(action.description) : '',
          confirmButtonText: t(
            'jobs.customActions.modals.startModal.confirmButton'
          ),
          loadingText: t('jobs.customActions.modals.startModal.loadingText'),
          errorMessage: t('jobs.customActions.modals.startModal.errorMessage'),
        },
        confirm: action.confirm,
        inputForm: action.inputForm,
        tooltip: {
          active: activeTooltip,
          disabled: errorTooltip,
        },
      }
    }) ?? []

  const workbookActions =
    workbook?.actions?.map((action) => {
      const { errorMessages: agentErrorMessages, infoMessages } =
        getTranslatedMessages(action, customT)

      const hasCountsPending = workbookCounts.isFetching

      const hasErrors = workbookCounts.errors > 0

      const requireHasData = hasConstraint(action, 'hasData')

      const hasData = workbookCounts.total > 0

      const requireAllValid = hasConstraint(
        action,
        'hasAllValid',
        'requireAllValid'
      )
      const hasAgentErrorMessages = agentErrorMessages.length > 0

      const hasBlockingConstraint = requireHasData || requireAllValid
      const hasPendingValidations = hasCountsPending && hasBlockingConstraint

      const disabled =
        hasAgentErrorMessages ||
        hasPendingValidations ||
        (requireHasData && !hasData) ||
        (requireAllValid && hasErrors)

      const activeTooltip = [
        ...(action.tooltip ? [customT(action.tooltip)] : []),
        ...infoMessages,
      ].join('\n')

      const errorMessages = [
        ...agentErrorMessages,
        ...(hasPendingValidations
          ? [t('sheet.workbookActions.disabledTooltip.hasPendingValidation')]
          : []),
        ...(!hasPendingValidations && requireHasData && !hasData
          ? [t('sheet.workbookActions.disabledTooltip.hasData')]
          : []),
        ...(!hasPendingValidations && requireAllValid && hasErrors
          ? [t('sheet.workbookActions.disabledTooltip.requireAllValid')]
          : []),
      ]

      const errorTooltip = errorMessages.join('\n')

      return {
        key: action.operation || action.slug!,
        label: customT(action.label),
        disabled,
        primary: !!action.primary,
        action: (input: JobInput) =>
          handleAction('workbook', workbook, action, input),
        preAction: async function () {
          if (action.confirm || action.inputForm) {
            setCurrentAction(this)
          } else {
            await handleAction('workbook', workbook, action, undefined)
          }
        },
        confirm: action.confirm,
        modalText: {
          heading: customT(action.label),
          description: action.description ? customT(action.description) : '',
          confirmButtonText: t(
            'jobs.customActions.modals.startModal.confirmButton'
          ),
          loadingText: t('jobs.customActions.modals.startModal.loadingText'),
          errorMessage: t('jobs.customActions.modals.startModal.errorMessage'),
        },
        tooltip: {
          active: activeTooltip,
          disabled: errorTooltip,
        },
        inputForm: action.inputForm,
      }
    }) ?? []

  return {
    customActions,
    workbookActions,
    handleAction,
    eventPayload,
  }
}
