import { Sheet, Workbook } from '@flatfile/api'
import { ColumnConfigProps } from '@flatfile/turntable'
import { MutableRefObject, createContext, useRef } from 'react'

import { ColumnsState, UnhideColumns } from '../ColumnManagement/types'
import { useColumnManagement } from '../ColumnManagement/useColumnManagement'
import { useFieldData } from '../hooks/useFieldData'
import { JobQuery } from '../hooks/useJobQuery'

interface FieldsContextType {
  allColumnsHidden: boolean
  columnConfig: ColumnConfigProps[]
  columnsState: ColumnsState
  columnConfigWithState: ColumnConfigProps[]
  hiddenColumnsCount: number
  setColumnsState: (state: ColumnsState) => void
  unhideColumns: UnhideColumns
  getJobQueryRef: MutableRefObject<() => JobQuery>
}

interface FieldsContextProviderProps {
  children: React.ReactNode
  sheet?: Sheet
  workbook: Workbook
}

const noop = () => {}

export const DEFAULT_FIELDS_CONTEXT = {
  allColumnsHidden: false,
  columnConfig: [],
  columnsState: {},
  columnConfigWithState: [],
  hiddenColumnsCount: 0,
  setColumnsState: noop,
  unhideColumns: noop,
  getJobQueryRef: { current: () => ({}) } as MutableRefObject<() => JobQuery>,
}

export const FieldsContext = createContext<FieldsContextType>(
  DEFAULT_FIELDS_CONTEXT
)

export const FieldsContextProvider = (props: FieldsContextProviderProps) => {
  const { sheet, workbook } = props

  //Using a ref here to resolve a cyclical data dependency. The value needs to be set inside a Component downstream.
  const getJobQueryRef = useRef<() => JobQuery>(() => ({}))

  const { columnConfig } = useFieldData({ sheet, workbook, getJobQueryRef })

  const columnManagement = useColumnManagement({
    columnConfig,
    sheetId: sheet?.id!,
    workbookId: workbook.id,
  })

  return (
    <FieldsContext.Provider
      value={{ columnConfig, ...columnManagement, getJobQueryRef }}
    >
      {props.children}
    </FieldsContext.Provider>
  )
}
