import AuthGuard from '@/auth/AuthGuard'
import { SpaceTranslationsProvider } from '@/components/Translations/SpaceTranslationsProvider'
import { ActionsModalContextProvider } from '@/contexts/ActionsModalContext'
import { JobsContextProvider } from '@/contexts/JobsContext'
import PubNubProvider from '@/pubnub/PubnubProvider'
import { IntercomProvider } from 'react-use-intercom'
import SpaceHydrator from './SpaceHydrator'
import { SpaceApp } from './app'

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export function SpaceView() {
  return (
    <AuthGuard>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <PubNubProvider>
          <JobsContextProvider>
            <ActionsModalContextProvider>
              <SpaceHydrator>
                <SpaceTranslationsProvider>
                  <SpaceApp />
                </SpaceTranslationsProvider>
              </SpaceHydrator>
            </ActionsModalContextProvider>
          </JobsContextProvider>
        </PubNubProvider>
      </IntercomProvider>
    </AuthGuard>
  )
}
