import { FlatfileRequestType } from './types'

export const accountsQueries = {
  getCurrentAccount: {
    generateQueryKey: (params: FlatfileRequestType<'getCurrentAccount'>) => [
      'getCurrentAccount',
    ],
    influencingEvents: {},
  },
}
