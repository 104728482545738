const fileExtensions = [
  '7z',
  'accdb',
  'arff',
  'arff',
  'avro',
  'bat',
  'bmp',
  'com',
  'csv',
  'csv',
  'csv2',
  'db',
  'dbf',
  'dif',
  'dll',
  'doc',
  'docx',
  'dsv',
  'dta',
  'exe',
  'feather',
  'gif',
  'gz',
  'hdf5',
  'iso',
  'jpeg',
  'jpg',
  'json',
  'mat',
  'mdb',
  'mp3',
  'mp4',
  'npz',
  'ods',
  'parquet',
  'pdf',
  'pickle',
  'png',
  'ppt',
  'pptx',
  'prn',
  'psv',
  'rar',
  'RData',
  'sas7bdat',
  'sav',
  'slk',
  'spss',
  'sql',
  'ssv',
  'stata',
  'tar',
  'tif',
  'tiff',
  'tsv',
  'txt',
  'xla',
  'xlam',
  'xlam',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
  'xlt',
  'xltm',
  'xlw',
  'xml',
  'xps',
  'yaml',
  'yml',
  'zip',
]

export const isFilename = (str: string) => {
  const extensionsPattern = fileExtensions.join('|')

  const filenameRegex = new RegExp(
    `^[^\\\\/?%*:|"<>]+\\.(${extensionsPattern})$`,
    'i'
  )
  return filenameRegex.test(str)
}
