import { EventTopic, Job, Sheet, Workbook } from '@flatfile/api'
import { useCallback, useContext, useState } from 'react'
import { resources } from '../../api/resources'
import { DiffAction } from '../../apps/WorkbookApp/VersionHistory/SnapshotView/types'
import { JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useEventSubscriber } from '../../hooks/useEventSubscriber'
import { SnapshotViewOutcomeProps } from './SnapshotViewWrapper'

export const useJobsDiff = () => {
  const { isOriginatedJob } = useContext(JobsContext)
  const { httpClient } = useContext(SpaceContext)

  const [snapshotViewContent, setSnapshotViewContent] = useState<
    SnapshotViewOutcomeProps | undefined
  >()

  const handleCloseSnapshot = useCallback(
    () => setSnapshotViewContent(undefined),
    []
  )

  useEventSubscriber(
    [EventTopic.Jobcompleted, EventTopic.Jobfailed],
    async (_, event) => {
      const jobId = event.context.jobId
      const jobsResource = await resources.getWithPromise<Job>(jobId, true)
      const job = jobsResource.maybeData

      if (
        job &&
        isOriginatedJob(job.id) &&
        job.outcome &&
        job.outcome.next &&
        job.outcome.next.type === 'snapshot'
      ) {
        const sheetId = job.outcome.next.sheetId
        const snapshotId = job.outcome.next.snapshotId

        const sheetRes = await resources.getWithPromise<Sheet>(sheetId)
        const sheet = sheetRes?.maybeData

        const workbookId = sheet?.workbookId
        const workbookRes = await resources.getWithPromise<Workbook>(workbookId)
        const workbook = workbookRes?.maybeData

        const snapResponse = await httpClient.getSnapshot({ snapshotId })
        const snapshot = snapResponse.data
        if (snapshot && workbook && sheet) {
          setSnapshotViewContent({
            action: DiffAction.ACCEPT,
            snapshot,
            workbook,
            sheet,
          })
        }
      }
    }
  )

  return { snapshotViewContent, handleCloseSnapshot }
}
