import { Success } from '@flatfile/api'
import {
  CreateVersionProps,
  DeleteVersionProps,
  GetVersionProps,
  Version,
  VersionController,
  Versions,
} from '@/api/controllers/VersionsController'
import { useController } from '@/api/controllers/useController'
import { LazyObservable, useAction } from '@/api/observable'

const useVersions = () => {
  const versionController = useController(VersionController)

  /**
   * Action generator for the getVersions request which creates an action, and associated observable
   * with an optional callback which fires on successful retrieval of versions
   *
   * @param cb optional callback function to be fired on successful fetch
   * @returns
   */
  const generateFetchVersions = (
    cb?: () => void
  ): [
    (props?: GetVersionProps) => void,
    LazyObservable<Versions, GetVersionProps>
  ] => {
    const [onFetchVersions, fetchVersionObservable] = useAction(
      versionController?.getVersions,
      async () => {
        cb && cb()
      }
    )
    return [onFetchVersions, fetchVersionObservable]
  }

  /**
   * Action generator for the createVersion request which creates an action, and associated observable
   * with an optional callback which fires on successful creation of a version
   *
   * @param cb optional callback function to be fired on successful create
   * @returns
   */
  const generateCreateVersion = (
    cb?: () => void
  ): [
    (props?: CreateVersionProps) => void,
    LazyObservable<Version, CreateVersionProps>
  ] => {
    const [onCreateVersion, createVersionObservable] = useAction(
      versionController?.createVersion,
      async () => {
        cb && cb()
      }
    )
    return [onCreateVersion, createVersionObservable]
  }

  /**
   * Action generator for the deleteVersion request which creates an action, and associated observable
   * with an optional callback which fires on successful delete of a version
   *
   * @param cb optional callback function to be fired on successful delete
   * @returns
   */
  const generateDeleteVersion = (
    cb?: () => void
  ): [
    (props?: DeleteVersionProps) => void,
    LazyObservable<Success, DeleteVersionProps>
  ] => {
    const [onDeleteVersion, deleteVersionObservable] = useAction(
      versionController?.deleteVersion,
      async () => {
        cb && cb()
      }
    )
    return [onDeleteVersion, deleteVersionObservable]
  }

  return {
    generateDeleteVersion,
    generateFetchVersions,
    generateCreateVersion,
  }
}

export default useVersions
