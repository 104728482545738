import Skeleton from 'react-loading-skeleton'
import { Container } from '../../elements/Container'
import { ContentContainer } from '../../elements/ContentContainer'
import { Expand } from '../../elements/Expand'
import { SpacePageLayoutContainer } from '../SpacePageLayout'
import { ContentSkeleton } from './ContentSkeleton'
import {
  SidebarSkeletonContainer,
  SidebarSkeletonContents,
  SidebarSkeletonSection,
} from './elements'

const Sidebar = () => {
  return (
    <SidebarSkeletonContainer>
      <SidebarSkeletonContents data-testid='loading-skeleton'>
        <SidebarSkeletonSection>
          <h1>
            <Skeleton baseColor='var(--color-skeleton)' />
          </h1>
          <p>
            <Skeleton baseColor='var(--color-skeleton)' />
          </p>
        </SidebarSkeletonSection>
        <hr />
        <SidebarSkeletonSection>
          <p>
            <Skeleton baseColor='var(--color-skeleton)' />
          </p>
          <p>
            <Skeleton baseColor='var(--color-skeleton)' />
          </p>
        </SidebarSkeletonSection>
        <hr />
        <SidebarSkeletonSection>
          <p>
            <Skeleton baseColor='var(--color-skeleton)' />
          </p>
          <p>
            <Skeleton baseColor='var(--color-skeleton)' />
          </p>
        </SidebarSkeletonSection>
      </SidebarSkeletonContents>
    </SidebarSkeletonContainer>
  )
}

export const SidebarSkeleton = () => (
  <SpacePageLayoutContainer>
    <Sidebar />
  </SpacePageLayoutContainer>
)

export const SpacesUISkeleton = ({
  title,
  message,
  sidebarHiddenFromUser = true,
}: {
  title?: string
  message?: string
  sidebarHiddenFromUser?: boolean
}) => {
  performance.mark('fcp')
  const content = ContentSkeleton(title, message)
  return (
    <SpacePageLayoutContainer>
      {!sidebarHiddenFromUser && <Sidebar />}
      <ContentContainer>
        <Expand>
          <Container>{content}</Container>
        </Expand>
      </ContentContainer>
    </SpacePageLayoutContainer>
  )
}
