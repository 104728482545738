import { FC, ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'

export const DialogPortal: FC<{ children: ReactNode }> = (props) => {
  const dialogPortal = useMemo(() => {
    let portal = document.getElementById('dialog-ui')
    if (!portal) {
      portal = document.createElement('div')
      portal.setAttribute('id', 'dialog-ui')
      document.body.appendChild(portal)
    }
    return portal
  }, [])
  return createPortal(props.children, dialogPortal)
}
