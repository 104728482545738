import { Colors } from '@flatfile/design-system'
import styled from 'styled-components'

export const PromptBarWrapper = styled.div`
  width: 100%;
`

export const PreviewLabel = styled.span`
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  //styleName: Heading/H6 Proxima;
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`

export const ResourceLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  //styleName: Heading/H6 Proxima;
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`

export const SampleLabel = styled.span`
  font-family: var(--text-font);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: ${Colors.Pigeon600};
`

export const ToggleLabel = styled.span`
  font-family: var(--text-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${Colors.MidnightBrand};
`

export const SearchBarWrapper = styled.div`
  flex-grow: 1;

  & > div {
    width: 100%;
    & > div {
      width: 100%;
      position: relative;
    }
  }
`

export const LeftIconButtonWrapper = styled.div<{ isDisabled?: boolean }>`
  & button {
    border-radius: 0;
    ${({ isDisabled }) =>
      isDisabled &&
      // shave off one pixel from the right side using clip-path to get clean corners
      `
      clip-path: polygon(0 0, calc(100% - 1px) 0, calc(100% - 1px) 100%, 0 100%, 0 0);

    `}
  }
`

export const RightIconButtonWrapper = styled.div`
  & button {
    border-radius: 0;
    transform: translateX(-1px);
  }
`

export const PreviewDiffViewTableContainer = styled.div`
  flex-grow: 1;
  position: relative;
`

export const ErrorOverlayContent = styled.div`
  background-color: ${Colors.Pigeon200};
  width: 400px;
  padding: 24px;
`

export const ErrorContentColumns = styled.div`
  display: flex;
  flex-direction: row;
`

export const ErrorContentTitle = styled.div`
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: ${Colors.MidnightBrand};
`

export const ErrorContentSubtitle = styled.div`
  font-family: var(--text-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${Colors.MidnightBrand};
`

export const IconWrapper = styled.span`
  margin-right: 16px;
`

export const DiffViewSubHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
`

export const FlexBox = styled.div`
  display: flex;
`

export const ToggleWrapper = styled.div`
  margin-right: 8px;
  transform: translateY(3.5px);
`

export const ResourcesBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 40%;
`

export const PromptBarBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-content: center;
  margin-bottom: 32px;
  place-items: center;
`

export const DiffViewSubHeaderFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`

export const ColumnInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`

export const ChangedColumnsText = styled.span`
  fontsize: 12px;
  font-weight: 600;
  color: ${Colors.MidnightBrand};
`

export const ScrollInfoContainer = styled(ColumnInfoContainer)`
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.MidnightBrand};
`

export const ScrollInfoText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.MidnightBrand};
`
