import { FileController } from '@/api/controllers/FileController'
import { useController } from '@/api/controllers/useController'
import { LinkContainer } from '@/components/Jobs/LinkContainer'
import { JobOperationEnum, JobsContext } from '@/contexts/JobsContext'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { downloadFile } from '@/utils/downloadFile'
import {
  ActionInputForm,
  EventTopic,
  Filter,
  JobTriggerEnum,
  JobTypeEnum,
  RecordCounts,
  Sheet,
  Space,
  Workbook,
} from '@flatfile/api'
import { Typography } from '@flatfile/design-system'
import {
  CheckmarkIcon,
  PopoverContext,
  PopoverMessage,
  fromMaybe,
} from '@flatfile/shared-ui'
import {
  ActionStatus,
  BulkRowSelection,
  ColumnConfigProps,
  useBulkRowActionsContext,
} from '@flatfile/turntable'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { SearchFields } from '../types'
import {
  ActionSuccessMeta,
  ActionType,
  BulkRowActionModalText,
  BulkRowActionTooltipText,
  getActionSuccessMessage,
  getBulkActionModalText,
  getRowsSelectedCount,
} from '../utils/bulkRowUtils'
import { JobInput, useCustomActions } from './useCustomActions'
import { useJobQuery } from './useJobQuery'
import { useRecordMutation } from './useRecordMutation'
import { eventMatchesSheetOrWorkbook } from './useSheetViewData'
import { WorkbookCounts } from './useWorkbookCountsData'

export interface UseBulkRowActionsProps {
  workbook: Workbook
  sheet?: Sheet
  space: Space
  columnConfig: ColumnConfigProps[]
  searchFields: SearchFields
  counts: RecordCounts | undefined
  exitSelectionState: (isSelecting?: boolean) => void
  getFormattedSelection: () => BulkRowSelection
  canDeleteFromSheet?: boolean
  workbookCounts: WorkbookCounts
  setCurrentAction: Dispatch<SetStateAction<BulkRowActionItem | null>>
}

export interface BulkRowActionItem {
  key: string
  label: string
  disabled?: boolean
  confirm?: boolean
  action: (input: JobInput) => void
  preAction?: () => void
  icon?: string
  primary?: boolean
  tooltip?: BulkRowActionTooltipText
  modalText: BulkRowActionModalText
  inputForm?: ActionInputForm
}

export const useBulkRowActions = ({
  workbook,
  sheet,
  space,
  searchFields,
  counts,
  exitSelectionState,
  getFormattedSelection,
  canDeleteFromSheet,
  workbookCounts,
  setCurrentAction,
}: UseBulkRowActionsProps) => {
  const sheetId = sheet?.id!
  const { setActionStatus, bulkRowActionsModal } = useBulkRowActionsContext()
  const { httpClient } = useContext(SpaceContext)
  const { deleteRecords } = useRecordMutation(sheetId, workbook.id)
  const { showPopover, showToast } = useContext(PopoverContext)
  const selection = getFormattedSelection()
  const { t } = useTypedTranslation()

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const { addOriginatedJobId, isOriginatedJob } = useContext(JobsContext)

  const showActionSuccessPopover = (meta: ActionSuccessMeta) => {
    const popover = {
      icon: <CheckmarkIcon name='checkmark' />,
      message: <PopoverMessage>{getActionSuccessMessage(meta)}</PopoverMessage>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }

  const rowsSelectedCount = useMemo(() => {
    if (counts) {
      return getRowsSelectedCount({
        selection,
        searchFields,
        filterCounts: counts,
      })
    }
    return {
      download: 0,
      delete: 0,
      copy: 0,
      error: 0,
      errorsTotal: 0,
      total: 0,
    }
  }, [selection, searchFields, counts])

  const handleBulkActionStart = (action: ActionType) => {
    // hide modal  when job has started
    setActionStatus(ActionStatus.success)
  }

  const handleDownload = async (fileId: string) => {
    if (!document.hidden) {
      const data = await fileController.downloadFile({ fileId: fileId })
      downloadFile(data)
    }
  }

  const handleBulkActionSuccess = async (
    action: ActionType,
    count: number | undefined,
    fileId?: string,
    jobId?: string
  ) => {
    if (action === 'delete') {
      showActionSuccessPopover({ action, count })
    }
    if (action === 'download' && fileId) {
      if (jobId && isOriginatedJob(jobId)) {
        await handleDownload(fileId)
        showToast(
          {
            heading: (
              <Typography type='h5' color='white'>
                {t('jobs.download.downloadStartedHeading')}
              </Typography>
            ),
            message: (
              <PopoverMessage>
                {t('jobs.download.downloadStartedMessage')}
                <LinkContainer data-testid='next-download'>
                  <a
                    data-testid='files-download-link'
                    href={`/space/${space.id}/files?mode=export`}
                  >
                    {t('jobs.download.seeAllDownloads')}
                  </a>
                </LinkContainer>
              </PopoverMessage>
            ),
          },
          true
        )
      }
    }
    exitSelectionState(true)
    bulkRowActionsModal.close()
  }

  const handleBulkActionFailure = () => {
    setActionStatus(ActionStatus.failed)
  }

  const { getJobQuery, getSyncedRecordIds } = useJobQuery({
    counts,
    searchFields,
    getFormattedSelection,
  })

  const handleDelete = async () => {
    /* If the number of rows to delete is 100 or less and we have the row ids, perform a sync update */
    const { ids, ...query } = getJobQuery()
    const syncedIds = getSyncedRecordIds()
    if (syncedIds?.length && syncedIds?.length <= 100) {
      handleBulkActionStart('delete')
      const { data } = await deleteRecords({
        sheetId,
        ids: syncedIds,
      })
      if (data?.success) {
        await handleBulkActionSuccess('delete', rowsSelectedCount.delete)
      } else if (data && !data.success) {
        handleBulkActionFailure()
      }
    } else if (selection.type) {
      /* Otherwise, perform a bulk update */
      await httpClient.createJob({
        jobConfig: {
          type: JobTypeEnum.Workbook,
          operation: JobOperationEnum.DeleteRecords,
          trigger: JobTriggerEnum.Immediate,
          source: workbook.id,
          config: {
            sheet: sheetId,
            exceptions: ids,
            ...query,
            filter: query.filter || Filter.None,
          },
          mode: 'foreground',
        },
      })
      handleBulkActionStart('delete')
    }
  }

  const exportToFile = async () => {
    const query = getJobQuery()
    const jobResponse = await httpClient.createJob({
      jobConfig: {
        type: JobTypeEnum.Sheet,
        operation: JobOperationEnum.Export,
        trigger: JobTriggerEnum.Immediate,
        source: sheetId,
        config: {
          options: query,
        },
      },
    })
    if (jobResponse.data?.id) {
      addOriginatedJobId(jobResponse.data.id)
    }
    handleBulkActionStart('download')
  }

  const handleJobSubscriberEvent = useCallback(
    async (event: any) => {
      const eventResponse = fromMaybe(JSON.parse(event?.message ?? '{}'), {})
      const { topic, payload } = eventResponse

      const isCorrectSheet = eventMatchesSheetOrWorkbook(sheet, event)
      if (!isCorrectSheet) return

      if (payload?.operation === JobOperationEnum.DeleteRecords) {
        if (topic === EventTopic.Jobcompleted) {
          await handleBulkActionSuccess('delete', rowsSelectedCount.delete)
        } else if (topic === EventTopic.Jobfailed) {
          handleBulkActionFailure()
        }
      } else if (payload?.operation === JobOperationEnum.Export) {
        if (topic === EventTopic.Jobcompleted) {
          await handleBulkActionSuccess(
            'download',
            rowsSelectedCount.delete,
            eventResponse.context?.fileId,
            eventResponse.context?.jobId
          )
        } else if (topic === EventTopic.Jobfailed) {
          handleBulkActionFailure()
        }
      } else if (topic === EventTopic.Jobcompleted) {
        exitSelectionState(true)
      }
    },
    [rowsSelectedCount]
  )

  // Subscribe to bulk action jobs
  useEventSubscriber(
    [EventTopic.Jobfailed, EventTopic.Jobcompleted],
    handleJobSubscriberEvent
  )

  const bulkRowActionsText = useMemo(
    () =>
      getBulkActionModalText({
        count: rowsSelectedCount,
        filter: searchFields.filter,
        selection,
      }),
    [selection, rowsSelectedCount, searchFields]
  )

  const activeDownloadTooltip = useMemo(() => {
    if (selection.type === 'none' && selection.exceptions.length === 0) {
      if (searchFields.filter) {
        return searchFields.filter === Filter.Valid
          ? t(
              'sheet.toolbar.buttons.download.tooltip.active.validFilterApplied'
            )
          : t(
              'sheet.toolbar.buttons.download.tooltip.active.invalidFilterApplied'
            )
      }
      return t(
        'sheet.toolbar.buttons.download.tooltip.active.noRecordsSelected'
      )
    }
    return t('sheet.toolbar.buttons.download.tooltip.active.recordsSelected')
  }, [selection, searchFields])

  const actions: BulkRowActionItem[] = [
    {
      key: 'delete',
      label: t('sheet.toolbar.dropdown.delete'),
      disabled: rowsSelectedCount.delete === 0 || !canDeleteFromSheet,
      action: handleDelete,
      preAction: handleDelete,
      icon: 'trash',
      primary: true,
      tooltip: {
        active: t('sheet.toolbar.buttons.delete.tooltip.active'),
        disabled: canDeleteFromSheet
          ? t('sheet.toolbar.buttons.delete.tooltip.disabled.noRecordsSelected')
          : t('sheet.toolbar.buttons.delete.tooltip.disabled.deleteDisabled'),
      },
      modalText: bulkRowActionsText.delete,
    },
    {
      key: 'download',
      label: t('sheet.toolbar.dropdown.download'),
      disabled: rowsSelectedCount.download === 0,
      action: exportToFile,
      preAction: exportToFile,
      icon: 'download',
      primary: true,
      tooltip: {
        active: activeDownloadTooltip,
        disabled: t('sheet.toolbar.buttons.download.tooltip.disabled'),
      },
      modalText: bulkRowActionsText.download,
    },
  ]

  const { customActions, workbookActions } = useCustomActions({
    environmentId: workbook.environmentId,
    spaceId: space.id,
    workbook,
    sheet,
    getJobQuery,
    rowsSelectedCount,
    setCurrentAction,
    workbookCounts,
  })

  const allActions = actions.concat(customActions)
  const bulkRowActions = allActions
    .filter((x) => x.primary)
    .concat(allActions.filter((x) => !x.primary))

  return {
    handleDelete,
    handleDownload: exportToFile,
    handleSubscriberEvent: handleJobSubscriberEvent,
    showActionSuccessPopover,
    getJobQuery,
    bulkRowActions,
    rowsSelectedCount,
    workbookActions,
  }
}
