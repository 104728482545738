import { TableProviders } from '@flatfile/turntable'
import { useTranslation } from 'react-i18next'
import {
  DiffViewFullScreenContainer,
  DiffViewOverlay,
} from '../VersionHistory/SnapshotView/elements'

import { TransformPreviewContent } from './TransformPreviewContent'
import { TransformPreviewProps } from './types'

export const EPHEMERAL_DIFF = 'ephemeral_diff'
export const PREVIEW_PAGE_SIZE = 10000

export const TransformPreview = (props: TransformPreviewProps) => {
  const { i18n } = useTranslation()

  return (
    <TableProviders tableId={EPHEMERAL_DIFF} i18n={i18n}>
      <DiffViewOverlay data-testid='diff-table-overlay'>
        <DiffViewFullScreenContainer>
          <TransformPreviewContent {...props} />
        </DiffViewFullScreenContainer>
      </DiffViewOverlay>
    </TableProviders>
  )
}
