import { Job } from '@flatfile/api'
import {
  Button,
  Modal,
  ModalHeader,
  ModalText,
  Portal,
} from '@flatfile/design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useResource } from '../../api/resources'
import { SpaceContext } from '../../contexts/SpaceContext'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { getRuntimeParameters } from '../RuntimeParameters'
import { getJobOutcomeTexts } from './useJobsToast'
const { isGuest } = getRuntimeParameters()

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div:nth-child(2) {
    margin: 8px 0 20px;
  }
`

export const PermanentJobModal = ({ job }: { job: Job }) => {
  const [resource] = useResource<{
    id: string
    name: string
    updatedAt: Date
  }>(job.source, false)
  const { httpClient } = useContext(SpaceContext)
  const { t } = useTypedTranslation()
  const { t: customT } = useTranslation()
  const onCelebrate = (id: string) => async () => {
    await httpClient.acknowledgeJobOutcome({
      jobId: id,
    })
  }

  const { outcomeHeading, outcomeMessage } = getJobOutcomeTexts(job, resource)
  const buttonText = job?.outcome?.buttonText
    ? customT(job?.outcome?.buttonText)
    : t(
        'jobs.customActions.modals.outcomeModals.permanentOutcomeModal.defaultContinueButton'
      )

  if (!isGuest && job.outcomeAcknowledgedAt !== undefined) {
    return null
  }

  return (
    <Portal>
      <Modal
        overlayType='opaque'
        content={
          <Container>
            <ModalHeader>{outcomeHeading}</ModalHeader>
            <ModalText>{outcomeMessage}</ModalText>
            <Button
              variant='primary'
              onPress={onCelebrate(job.id)}
              label={buttonText}
            />
          </Container>
        }
        data-testid='permanent-job-modal'
      />
    </Portal>
  )
}
