import { SheetController } from '@/api/controllers/SheetController'
import { useUpdateSheetHeader } from '@/api/mutations/sheets.mutations'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { Workbook } from '@flatfile/api'
import {
  PopoverContext,
  PopoverMessageFullWidth,
  WarningIcon,
} from '@flatfile/shared-ui'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FileController } from '../../api/controllers/FileController'
import { useController } from '../../api/controllers/useController'
import { Observable, useAction, useObservable } from '../../api/observable'
import { ContentLoader } from '../../api/observable/ContentLoader'
import { ErrorState } from '../../components/EmptyState'
import { Reveal } from '../../components/Reveal'
import { SheetActionBar } from '../../components/SheetActionBar'
import { SheetSelector } from '../../components/SheetSelector'
import { ContentSkeleton } from '../../components/SpacesUISkeleton'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ActionContainer } from '../../elements/ActionContainer'
import { ActionContent } from '../../elements/ActionContent'
import { Container } from '../../elements/Container'
import { useNavigate } from '../../hooks/useNavigate'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

export const ImportFileView = () => {
  const { t } = useTypedTranslation()
  const { space, httpClient } = useContext(SpaceContext)
  const { showPopover } = useContext(PopoverContext)
  const { fileId } = useRouteParams() as { fileId: string }
  const [selectedSheetId, setSelectedSheetId] = useState<string>()
  const [sourceSheetId, setSourceSheetId] = useState<string>()
  const navigate = useNavigate()
  const controller = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )
  const [request, file] = useObservable(controller.getFile(fileId))
  const [confirmAndContinue, continueAction] = useAction(
    controller.mapToSheet(),
    (job) => {
      navigate(`/space/${space.id}/job/${job.id}`)
    }
  )
  const { data: sourceSheetResponse } = useFlatfileQuery(
    'getSheet',
    { sheetId: sourceSheetId ?? '' },
    {
      enabled: !!sourceSheetId,
    }
  )
  const {
    data: workbooks,
    refetch,
    isLoading: isWorkbooksLoading,
  } = useQueryGetAllWorkbooks({ spaceId: space.id })
  const { mutateAsync: mutateSheetHeader, error: SheetHeaderError } =
    useUpdateSheetHeader(selectedSheetId)

  const selectedWorkbookId = useMemo(
    () => findWorkbookBySheetId(workbooks?.data, selectedSheetId),
    [workbooks?.data, selectedSheetId]
  )
  const sourceSheetMeta =
    (sourceSheetResponse?.data?.metadata as { [key: string]: any }) ?? {}
  const sourceSheetRowHeaders = sourceSheetMeta?.['rowHeaders']

  useEffect(() => {
    if (file?.workbook?.sheets?.[0]) {
      setSourceSheetId(file.workbook!.sheets?.[0]!.id)
    }
  }, [file])

  useEffect(() => {
    if (continueAction.error?.message || SheetHeaderError) {
      showPopover(
        {
          icon: <WarningIcon name='alertTriangle' />,
          message: (
            <PopoverMessageFullWidth>
              {t('mapping.sheetSelection.cannotStartMapping', {
                reason:
                  continueAction.error?.message ?? SheetHeaderError?.message,
              })}
            </PopoverMessageFullWidth>
          ),
        },
        true
      )
    }
  }, [continueAction.error, SheetHeaderError])

  // @todo if importFile is set and only one workbook + sheet, go straight to mapping

  /**
   * When importing files which do not have rowHeaders assigned, this means the headers are
   * being evaluated exclusively via the extraction process. However, for customers with the
   * `headerSelection` entitlement, they can assign rowHeaders manually. Additionally, the
   * `sourceEditor` entitlement will give customers the ability to manipulate their sheets
   * in ways which could influence the headers (i.e. changing header names, adding columns, etc).
   * Due to this ability, we need to always perform a patch workbook using current rowHeaders
   * prior to mapping to account for the possibility of integrity of a file changing prior
   * to the mapping process which could result in a misalignment of sheetMetadata.
   */
  const initiateFileImport = async () => {
    if (sourceSheetId && sourceSheetRowHeaders) {
      await mutateSheetHeader({
        sheetId: sourceSheetId,
        rowHeaders: sourceSheetRowHeaders,
      })
    }

    confirmAndContinue({
      sourceWorkbookId: file.workbookId!,
      sourceSheetId: sourceSheetId!,
      destinationWorkbookId: selectedWorkbookId!,
      destinationSheetId: selectedSheetId!,
    })
  }

  const renderFiles = useCallback(() => {
    if (request.isLoading || isWorkbooksLoading) {
      return ContentSkeleton()
    } else if (file && workbooks) {
      return (
        <SheetSelector
          fileName={file.name}
          sourceWorkbook={file.workbook!}
          onDestinationChange={(_w, sheetId) => setSelectedSheetId(sheetId)}
          onSourceChange={(_w, sheetId) => setSourceSheetId(sheetId)}
          workbooks={workbooks!.data!}
          workbooksRefetch={refetch}
        />
      )
    } else {
      return (
        <ErrorState
          title={t('mapping.sheetSelection.cannotLoadFile')}
          dataTestId='empty-error'
        />
      )
    }
  }, [file, request, selectedSheetId, setSelectedSheetId])

  return (
    <Container>
      <TopActionBar
        continueAction={continueAction}
        confirmAndContinue={initiateFileImport}
        continueDisabled={!selectedSheetId || !sourceSheetId}
      />
      <ActionContainer>
        <ActionContent>
          <Reveal>
            <ContentLoader
              observe={request}
              errorContent={
                <ErrorState
                  title={t('mapping.sheetSelection.cannotLoadFile')}
                  dataTestId='loading-error'
                />
              }
            >
              {renderFiles}
            </ContentLoader>
          </Reveal>
        </ActionContent>
      </ActionContainer>
    </Container>
  )
}

/**
 * Get the first workbook holding this SheetId
 *
 * @param workbooks
 * @param sheetId
 */
export function findWorkbookBySheetId(
  workbooks?: Workbook[],
  sheetId?: string
) {
  return workbooks?.find((x) => x.sheets?.some((s) => s.id === sheetId))?.id
}

/**
 * The top full screen header for the mapping scene
 *
 * @todo this should be more universal
 *
 * @param spaceUrl
 * @param continueAction
 * @param confirmAndContinue
 * @param continueDisabled
 * @constructor
 */
const TopActionBar: FC<{
  continueAction: Observable
  confirmAndContinue: () => void
  continueDisabled?: boolean
}> = ({ continueAction, confirmAndContinue, continueDisabled }) => {
  const navigate = useNavigate()
  const { t } = useTypedTranslation()

  return (
    <SheetActionBar
      headerText={t('mapping.sheetSelection.title')}
      back={{
        label: t('mapping.headerButtons.back'),
        onPress: () => {
          navigate(-1)
        },
      }}
      next={{
        label: t('mapping.headerButtons.next'),
        onPress: confirmAndContinue,
        loading: continueAction.isLoading,
        disabled: continueDisabled,
      }}
    />
  )
}
