import { useMemo } from 'react'
import { MOCKS } from '../../test/mocks'

export function useController<T extends GenericConstructor>(
  struct: T,
  ...args: ClassParams<T>
): ClassInstance<T> {
  return useMemo(
    () => MOCKS[`CONTROLLER_${struct.name}`] ?? new struct(...args),
    args
  )
}

export type GenericConstructor = new (...args: any[]) => any

type ClassParams<T> = T extends new (...args: infer P) => any ? P : never

export type ClassInstance<T> = T extends new (...args: any[]) => infer P
  ? P
  : never
