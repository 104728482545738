import MicroEmitter from 'micro-emitter'
import { Observable } from '../observable'
import { resourceFetcherFromId } from './utils'

export class Resource<
  T extends { updatedAt: Date } = any
> extends MicroEmitter {
  /**
   * Data fresh as of this date, do not accept older data
   */
  lastUpdate: Date | 0 = 0

  /**
   * True if an event has indicated this resource has newer values on server
   */
  latestAvailable: Date | 0 = 0

  /**
   * Reference the observable instance that can/did/will fetch data for this
   * resource
   */
  public readonly observable: Observable<T>

  constructor(public readonly id: string, data?: T) {
    super()
    const fetcher = resourceFetcherFromId(id)
    const callback = (params: any) => {
      return fetcher(params.id) as Promise<{ data?: T }>
    }
    this.observable = new Observable<T>(callback).setParams({ id })

    // track the last updated
    this.observable.addEventListener('data', () => {
      const data = this.observable.data
      const updatedAt = data?.updatedAt

      if (updatedAt) {
        this.lastUpdate = updatedAt

        if (!this.latestAvailable) {
          this.latestAvailable = this.lastUpdate
        }

        // todo: implement some protection around infinite loading
        if (
          this.lastUpdate instanceof Date &&
          this.latestAvailable > this.lastUpdate
        ) {
          this.observable.reload()
        }
      }
    })

    if (data) {
      this.apply(data)
    }
  }

  /**
   * Return the data for the resource. An error will be thrown if not ready.
   */
  public get data(): T {
    return this.observable.ensured.data
  }

  /**
   * Return the data for the resource
   */
  public get maybeData(): T | undefined {
    return this.observable.data
  }

  /**
   * Triggered from events when you know a new version is available for a
   * specific timestamp. This will not reload if it already has this version.
   *
   * @todo make sure update operations on a resource block a reload if
   *       they're in progress or else a wasteful fetch may occur
   *
   * @todo debounce
   *
   * @param updatedAt
   */
  public reloadIfStale(updatedAt?: Date | string) {
    if (typeof updatedAt === 'string') {
      updatedAt = new Date(updatedAt)
    }

    if (!updatedAt) {
      return
    }

    if (updatedAt > this.latestAvailable) {
      this.latestAvailable = updatedAt
    }
    // allowing equality in case millisecond resolution is not enough
    if (new Date(this.lastUpdate) <= updatedAt) {
      this.observable.reload()
    }
  }

  /**
   * Apply new data when available return boolean true if anything changed
   *
   * @param data
   */
  public apply(data: T): this {
    // todo: verify eTag
    this.observable.setData(data)
    // this.controller.hydrateCache(data)
    return this
  }
}
