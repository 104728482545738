import { AMP_TOOLTIP_ID } from '@flatfile/design-system'
import styled from 'styled-components'

const TooltipWrapperInner = styled.div`
  pointer-events: none;
`

export const getJobTooltip = (
  hasToolbarBlockingJobs: boolean,
  toolbarBlockingJobsTooltip: string,
  hasUncompletedCommits: boolean,
  uncompletedCommitsTooltip: string,
  tableIsEditing: boolean,
  tableIsEditingTooltip: string
) =>
  hasToolbarBlockingJobs
    ? toolbarBlockingJobsTooltip
    : hasUncompletedCommits
    ? uncompletedCommitsTooltip
    : tableIsEditing
    ? tableIsEditingTooltip
    : undefined

export const JobTooltipWrapper = ({
  children,
  tooltipContent,
}: {
  children: React.ReactNode
  tooltipContent?: string
}) => {
  if (!tooltipContent) return <>{children}</>
  return (
    <div
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content={tooltipContent}
      data-tooltip-float={true}
      data-testid='job-tooltip'
    >
      <TooltipWrapperInner>{children}</TooltipWrapperInner>
    </div>
  )
}
