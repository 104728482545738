import { ColumnsState } from '../ColumnManagement/types'
import { storage } from '@flatfile/shared-ui'
import { getStorageId } from '../ColumnManagement/useColumnManagement'

interface SetColumnVisibilityProps {
  sheetId: string
  fields: string[]
}

export const setColumnVisibility = ({
  fields,
  sheetId,
}: SetColumnVisibilityProps) => {
  const storageId = getStorageId(sheetId)
  const currentStateString = storage(storageId).get()
  const currentState = currentStateString ? JSON.parse(currentStateString) : {}
  if (Object.keys(currentState).length) {
    const fieldsSet = new Set(fields)
    const newColumnState = Object.keys(currentState as ColumnsState).reduce(
      (memo: Record<string, any>, key: string) => {
        memo[key] = {
          ...currentState[key],
          hidden: fieldsSet.has(key),
        }
        return memo
      },
      {} as Record<string, any>
    )
    storage(storageId).set(JSON.stringify(newColumnState))
    window.dispatchEvent(new Event('storage'))
  }
}
