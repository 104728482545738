import { getColorVariant } from './utils'
import { Pill } from '../../elements/Pill'

export const CommandMenuPill = ({ id }: { id?: string }) => {
  if (!id) return null
  return (
    <Pill data-testid={`pill-${id}`} variant={getColorVariant(id)}>
      {id}
    </Pill>
  )
}
