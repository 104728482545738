import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import {
  Banner,
  Button,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import useVersions from '../hooks/useVersions'
import { VersionActionProps } from './types'

enum ModalState {
  CONFIRM = 'confirm',
  COMPLETE = 'complete',
  ERROR = 'error',
}

const VersionDeleteAction = ({
  onCancel,
  onComplete,
  promptVersion,
}: VersionActionProps) => {
  const { t } = useTypedTranslation()
  const { generateDeleteVersion } = useVersions()
  const [step, setStep] = useState<ModalState>(ModalState.CONFIRM)
  const [deleting, setDeleting] = useState(false)
  const [onDeleteVersion, deleteVersionObservable] = generateDeleteVersion(
    () => {
      setDeleting(false)
      setStep(ModalState.COMPLETE)
    }
  )

  useEffect(() => {
    if (deleteVersionObservable.error) {
      setDeleting(false)
      setStep(ModalState.ERROR)
    }
  }, [deleteVersionObservable.error])

  /**
   * Handles confirmation of a version DELETE and performs the correct sequence
   * of actions to delete the record and update UI state
   */
  const handleConfirmDelete = () => {
    setDeleting(true)
    onDeleteVersion({
      deleteVersionId: promptVersion.id,
    })
  }

  /**
   * Utility component which displays the relevant content when the user is prompted
   * to confirm their desire to perform a version restore (i.e. CONFIRM state)
   */
  const ConfirmVersionDelete = (
    <>
      <ModalHeader>
        {t('versionHistory.modals.delete.confirm.heading')}
      </ModalHeader>
      <ModalText>
        {t('versionHistory.modals.delete.confirm.description')}
      </ModalText>
      <ModalButtonRow>
        <ModalCloseButton
          data-testid='version-confirm-cancel'
          isDisabled={deleteVersionObservable.isLoading || deleting}
          onClose={onCancel}
          label={t('versionHistory.modals.delete.confirm.closeButton')}
        />
        <Button
          data-testid='version-confirm-proceed'
          loading={deleteVersionObservable.isLoading || deleting}
          isDisabled={deleteVersionObservable.isLoading || deleting}
          variant='warning'
          onPress={handleConfirmDelete}
          label={t('versionHistory.modals.delete.confirm.proceedButton')}
        />
      </ModalButtonRow>
    </>
  )

  /**
   * Utility component which displays the relevant content when the user is prompted
   * after version delete is completed (i.e. COMPLETE state)
   */
  const VersionDeleteCompleted = (
    <>
      <ModalHeader>
        {t('versionHistory.modals.delete.success.heading')}
      </ModalHeader>
      <ModalText>
        {t('versionHistory.modals.delete.success.description')}
      </ModalText>
      <ModalButtonRow>
        <Button
          variant='ghost'
          onPress={onComplete}
          data-testid='version-delete-complete'
          label={t('versionHistory.modals.delete.success.closeButton')}
        />
      </ModalButtonRow>
    </>
  )

  /**
   * Utility component which displays the relevant content if the request to delete
   * a version fails
   */
  const VersionDeleteError = (
    <>
      <ModalHeader>
        {t('versionHistory.modals.delete.error.heading')}
      </ModalHeader>
      <ModalText>
        <Banner
          variant='error'
          message={t('versionHistory.modals.delete.error.description')}
        />
      </ModalText>
      <ModalButtonRow>
        <ModalCloseButton
          data-testid='version-confirm-cancel'
          isDisabled={deleteVersionObservable.isLoading || deleting}
          onClose={onCancel}
          label={t('versionHistory.modals.delete.confirm.closeButton')}
        />
        <Button
          data-testid='version-confirm-proceed'
          loading={deleteVersionObservable.isLoading || deleting}
          isDisabled={deleteVersionObservable.isLoading || deleting}
          variant='warning'
          onPress={handleConfirmDelete}
          label={t('versionHistory.modals.delete.error.retryButton')}
        />
      </ModalButtonRow>
    </>
  )

  return (
    <ModalContentContainer>
      {step === ModalState.CONFIRM && ConfirmVersionDelete}
      {step === ModalState.COMPLETE && VersionDeleteCompleted}
      {step === ModalState.ERROR && VersionDeleteError}
    </ModalContentContainer>
  )
}

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default VersionDeleteAction
