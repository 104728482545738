import { createContext, useCallback, useContext } from 'react'
import { PrivilegeName, PrivilegeResourceType } from '@flatfile/resources'

export type Permissions = Partial<{
  [key in PrivilegeName]: boolean
}>

export const PermissionsContext = createContext<Permissions>({})

export const usePermissions = () => {
  const permissions = useContext(PermissionsContext)

  //check for a single permission
  const hasPermission = useCallback(
    (permissionKey: PrivilegeName) => {
      return !!permissions[permissionKey]
    },
    [permissions]
  )

  //check the user has all permissions for a given resource type
  const hasFullPermission = useCallback(
    (keyStart: PrivilegeResourceType) => {
      const matchingKeys = Object.keys(permissions).filter((key) =>
        key.includes(keyStart)
      )
      if (matchingKeys.length === 0) {
        return false
      }
      return matchingKeys.every(
        (key) => permissions[key as PrivilegeName] === true
      )
    },
    [permissions]
  )

  return { permissions, hasPermission, hasFullPermission }
}
