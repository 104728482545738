import { Sheet, Space, Workbook } from '@flatfile/api'
import {
  DataBufferProvider,
  Table,
  TableProviders,
  Toolbar,
  useBulkRowSelection,
} from '@flatfile/turntable'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BlurResource } from '../../api/resources/BlurResource'
import { EnvironmentsContext } from '../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../contexts/SpaceContext'

import { ActionsModalContext } from '@/contexts/ActionsModalContext'
import { useEntitlements } from '@/hooks/useEntitlements'
import { usePaymentAccess } from '@/hooks/usePaymentAccess'
import { useKeepRefSynchronized } from '@flatfile/design-system'
import { fromMaybe } from '@flatfile/shared-ui'
import posthog from 'posthog-js'
import { CommandMenu } from '../../components/CommandMenu/CommandMenu'
import {
  JobTooltipWrapper,
  getJobTooltip,
} from '../../components/Jobs/JobTooltipWrapper'
import { PageHeader } from '../../components/PageHeader'
import { UsageBanner } from '../../components/UsageBanner'
import {
  toolbarBlockingModes,
  useBlockingJobs,
} from '../../hooks/useBlockingJobs'
import { useSheetAccess } from '../../hooks/useSheetAccess'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { useWorkbookHasCommits } from '../../hooks/useWorkbookHasCommits'
import { getShowSpaceInfo } from '../../utils/environments'
import { getTurntableTheme } from '../SpaceApp/Theme/ThemeUtils'
import { ColumnManagement } from './ColumnManagement'
import { WorkbookActions } from './PrimaryActions'
import { SaveViewModal } from './SavedViews/SaveViewModal'
import { SheetTabs } from './SheetTabs'
import { OnSetPreviewDiff } from './TransformPreview/types'
import {
  OnToggleSnapshotView,
  OnTriggerSnapshotView,
} from './VersionHistory/SnapshotView/types'
import { FieldsContext, FieldsContextProvider } from './contexts/FieldsContext'
import {
  SearchFiltersContext,
  SearchFiltersContextProvider,
} from './contexts/SearchFiltersContext'
import { SheetContextProvider } from './contexts/SheetContext'
import { SheetCountsContextProvider } from './contexts/SheetCountsContext'
import {
  SheetContainer,
  SheetHeaderContainer,
  SheetTableContainer,
} from './elements'
import { BulkRowActionItem, useBulkRowActions } from './hooks/useBulkRowActions'
import { useEmptyState } from './hooks/useEmptyState'
import { useFilterByValue } from './hooks/useFilterByValue'
import useFindAndReplace from './hooks/useFindAndReplace'
import { useJobQuery } from './hooks/useJobQuery'
import { useRecordMutation } from './hooks/useRecordMutation'
import { useSavedViews } from './hooks/useSavedViews'
import { useSheetFile } from './hooks/useSheetFile'
import { useSheetViewData } from './hooks/useSheetViewData'
import { useWorkbookCountsData } from './hooks/useWorkbookCountsData'
import { deriveSheetState } from './utils/deriveSheetState'
import { getWorkbookAndSpaceInfo } from './utils/getWorkbookAndSpaceInfo'

type SheetProps = {
  embedded?: boolean // Whether the sheet is embedded somewhere other than the main sheet route
  sheet?: Sheet
  sheetId: string
  workbook: Workbook
}

type SheetViewProps = SheetProps & {
  height?: string | number
  toggleSnapshotView?: OnToggleSnapshotView
  setPreviewDiff?: OnSetPreviewDiff
  pinningEnabled: boolean
  showColumnMenus: boolean
}

type SheetHeaderProps = {
  handleCopy: (text?: string) => void
  sheet?: Sheet
  space: Space
  toggleSnapshotView?: OnTriggerSnapshotView
  workbook: Workbook
  workbookActions: BulkRowActionItem[]
  workbookHasCommits: boolean
  tableIsEditing: boolean
}

const DEFAULT_COUNTS = { total: 0, error: 0, valid: 0 }

export const SheetView = (props: SheetViewProps) => {
  const { i18n } = useTranslation()
  const { fetchRecords, addOrUpdateRecords } = useRecordMutation(
    props.sheetId!,
    props.workbook.id
  )

  return (
    <DataBufferProvider
      onLoadRows={fetchRecords}
      onUpdateRows={addOrUpdateRecords}
    >
      <TableProviders tableId={props.sheetId} i18n={i18n}>
        <SheetProviders {...props}>
          <SheetContent {...props} />
        </SheetProviders>
      </TableProviders>
    </DataBufferProvider>
  )
}

const SheetProviders = (props: SheetProps & { children: React.ReactNode }) => {
  const { children, embedded, sheet, sheetId, workbook } = props
  return (
    <SheetContextProvider embedded={embedded} sheet={sheet} sheetId={sheetId}>
      <SheetCountsContextProvider>
        <FieldsContextProvider sheet={sheet} workbook={workbook}>
          <SearchFiltersContextProvider>
            {children}
          </SearchFiltersContextProvider>
        </FieldsContextProvider>
      </SheetCountsContextProvider>
    </SheetContextProvider>
  )
}

const SheetHeader = ({
  handleCopy,
  sheet,
  space,
  toggleSnapshotView,
  workbook,
  workbookActions,
  workbookHasCommits,
  tableIsEditing,
}: SheetHeaderProps) => {
  const { environment } = useContext(EnvironmentsContext)
  const showSpaceInfo = getShowSpaceInfo(environment)
  const { isStaticSheet, staticWorkbookActions } = useSheetFile(workbook)

  const { data, rawData } = useMemo(
    () => getWorkbookAndSpaceInfo(true, workbook, space, environment, sheet),
    [workbook, space, sheet]
  )

  return (
    <SheetHeaderContainer>
      <PageHeader
        title={workbook.name}
        titleInfo={
          showSpaceInfo
            ? {
                data,
                onCopy: () => handleCopy(rawData),
              }
            : undefined
        }
        toggleSnapshotView={toggleSnapshotView}
        action={
          <WorkbookActions
            workbook={workbook}
            workbookHasCommits={workbookHasCommits}
            tableIsEditing={tableIsEditing}
            actions={
              isStaticSheet
                ? staticWorkbookActions.concat(workbookActions as any)
                : workbookActions
            }
          />
        }
      />
      <UsageBanner />
    </SheetHeaderContainer>
  )
}

export const SheetContent = ({
  embedded,
  height,
  sheetId,
  sheet,
  workbook,
  toggleSnapshotView,
  setPreviewDiff,
  pinningEnabled,
  showColumnMenus,
}: SheetViewProps) => {
  const { space, metadata } = useContext(SpaceContext)
  const { features } = useContext(EnvironmentsContext)
  const { hasEntitlement } = useEntitlements()
  const whitelabelingEnabled = hasEntitlement('whitelabeling')
  const aiAssistEnabled = hasEntitlement('aiAssist')
  const savedViewsEnabled = hasEntitlement('savedViews')
  const { t } = useTypedTranslation()
  const [tableIsEditing, setTableIsEditing] = useState(false)

  const {
    allColumnsHidden,
    columnConfig,
    columnsState,
    columnConfigWithState,
    hiddenColumnsCount,
    setColumnsState,
    unhideColumns,
    getJobQueryRef,
  } = useContext(FieldsContext)

  const { exitSelectionState, getFormattedSelection } = useBulkRowSelection()

  const { isEditCapable, isFile } = useSheetFile(workbook)
  const { sheetIsLocked } = usePaymentAccess()
  const {
    readOnlySheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet,
    noAccessLimitations,
  } = useSheetAccess(sheet)

  const turntableTheme = useMemo(() => getTurntableTheme(metadata), [metadata])

  const {
    isFiltered,
    searchFields,
    sortOverride,
    filteredResultsLoading,
    searchByValueLoading,
    setFilteredResultsLoading,
    setSearchByValueLoading,
    handleClickFilter,
    handleClickFilterField,
    handleSearchByValue,
    handleSort,
  } = useContext(SearchFiltersContext)

  const {
    rowCount,
    additionalRowCount,
    isLoading,
    isLoadingCounts,
    isLoadingEmpty,
    isEmpty,
    hasCountsError,
    tableError,
    refetchTable,
    refetchRows,
    counts,
    filteredCounts,
    errorFields,
    showEmptyOverlay,
    hideEmptyOverlay,
    handleCopy,
    getErrorsByFieldCounts,
    refetchCounts,
  } = useSheetViewData({
    sheetId,
    sheet,
    workbookId: workbook.id,
    columnConfig,
    columnsState,
    searchFields,
    readOnlySheet,
    canDeleteFromSheet,
    canAddToSheet,
    handleSearchByValue,
    hiddenColumnsCount,
    unhideColumns,
  })

  const workbookCounts = useWorkbookCountsData(workbook)
  const selection = getFormattedSelection()

  const { setCurrentAction } = useContext(ActionsModalContext)

  const { getJobQuery } = useJobQuery({
    counts,
    searchFields,
    getFormattedSelection,
  })
  useKeepRefSynchronized(getJobQueryRef, getJobQuery)

  const {
    bulkRowActions,
    workbookActions,
    handleDownload,
    showActionSuccessPopover,
  } = useBulkRowActions({
    workbook,
    sheet,
    space,
    columnConfig,
    searchFields,
    counts,
    exitSelectionState,
    getFormattedSelection,
    canDeleteFromSheet,
    workbookCounts,
    setCurrentAction,
  })

  const { getFindAndReplaceProps, handleFindAndReplace } = useFindAndReplace({
    workbookId: workbook.id,
    sheetId: sheetId,
    refetchRows,
    columnConfig,
    searchFields,
    getJobQuery,
    isFiltered,
  })

  const {
    savedViews,
    isSaveViewOpen,
    openSaveView,
    closeSaveView,
    activateView,
    createView,
    updateView,
    deleteView,
  } = useSavedViews({
    sheet,
    isEntitled: savedViewsEnabled,
  })

  useEffect(() => {
    if (!isLoading && !isLoadingCounts) {
      setSearchByValueLoading(false)
      if (!isLoadingCounts) {
        setFilteredResultsLoading(false)
      }
    }
  }, [isLoading, isLoadingCounts])

  //if filter by error is active but all errors are resolved, remove filter field
  useEffect(() => {
    if (searchFields.filterField && counts?.error === 0) {
      handleClickFilterField()
    }
  }, [searchFields, counts])

  const toolbarBlockingJobs = useBlockingJobs(
    [sheetId, workbook.id],
    toolbarBlockingModes
  )

  const hasToolbarBlockingJobs = toolbarBlockingJobs.length > 0
  const hasUncompletedCommits = useWorkbookHasCommits(workbook)
  const toolbarLocked = hasToolbarBlockingJobs || hasUncompletedCommits
  const jobTooltip = getJobTooltip(
    hasToolbarBlockingJobs,
    t('sheet.toolbar.tooltips.lockedUntilJobCompletes'),
    hasUncompletedCommits,
    t('sheet.toolbar.tooltips.lockedUntilHookCompletes'),
    tableIsEditing,
    t('sheet.toolbar.tooltips.lockedUntilEditingCompletes')
  )

  const showInitialEmptyStateOverlay = useMemo(
    () =>
      !rowCount &&
      !isEmpty &&
      !isFiltered &&
      !readOnlySheet &&
      !!showEmptyOverlay,
    [
      rowCount,
      searchFields,
      isEmpty,
      isFiltered,
      readOnlySheet,
      showEmptyOverlay,
    ]
  )

  const { emptyState, emptyStateOverlay } = useEmptyState({
    space,
    sheet,
    tableError,
    isLoading: isLoading || isLoadingCounts,
    readOnlySheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet: canImportIntoSheet && !embedded,
    noAccessLimitations: noAccessLimitations && !embedded,
    sheetIsLocked,
    hideEmptyOverlay,
    allColumnsHidden,
  })

  /* c8 ignore start */
  const handleToggleSnapshotView: OnTriggerSnapshotView = (props) => {
    if (!toggleSnapshotView) return
    if (!props) {
      toggleSnapshotView()
      return
    }
    /* 
      We route the toggle snapshot trigger through the sheet in order pass
      the sheet props to the snapshot, which lives at a higher level.
    */
    toggleSnapshotView({
      ...props,
      sheet,
      refetchTable,
      columnConfig,
    })
  }

  const handleSetPreviewDiff = () => {
    posthog.capture('ai_transform_button_clicked', {
      button_name: 'ai_transform',
      message: 'Click on AI Transform button in toolbar',
    })
    if (!setPreviewDiff) return
    if (!sheet) return

    const hiddenColumnKeys = columnConfig
      .filter(
        (col) => !columnConfigWithState.some((col2) => col.value === col2.value)
      )
      .map((col) => col.value)

    const normalizedSearchFields = searchFields
    normalizedSearchFields.filter =
      searchFields.filterField && searchFields.filter !== 'error'
        ? 'error'
        : searchFields.filter

    setPreviewDiff({
      columnConfig,
      hiddenColumnKeys,
      sheet,
      workbook,
      searchFields: normalizedSearchFields,
      selection,
      counts: counts!,
    })
  }

  /**
   * When a sheet is embedded, we handle showing the command menu with
   * a button that triggers a react state change instead with CMD + K.
   * This is because a user can potentially embed many sheets on a single
   * page, and so a keyboard command like CMD + K would not know which
   * sheet to target.
   */
  const [showCommandMenu, setShowCommandMenu] = useState(false)
  const [commandMenuValue, setCommandMenuValue] = useState('')

  const handleShowCommandMenu = aiAssistEnabled
    ? (value: string | undefined) => {
        const initialInput = fromMaybe(value, '')
        if (typeof initialInput === 'string') {
          setShowCommandMenu(true)
          setCommandMenuValue(initialInput)
        }
      }
    : undefined
  /* c8 ignore stop */

  const totalCounts = counts?.total ?? 0
  const aiAssistTransformDisabled =
    totalCounts === 0 ||
    totalCounts > 1000000 ||
    sheet?.config?.readonly ||
    sheet?.lockedAt

  const sheetState = deriveSheetState(sheet!, totalCounts)

  return (
    <>
      {aiAssistEnabled && (!embedded || showCommandMenu) && (
        <CommandMenu
          columnsState={columnsState}
          isOpen={showCommandMenu}
          sheetId={sheetId}
          onOpenChange={setShowCommandMenu}
          onSearchByValue={handleSearchByValue}
          value={commandMenuValue}
          setValue={setCommandMenuValue}
        />
      )}

      {isSaveViewOpen && (
        <SaveViewModal
          searchFields={searchFields}
          onCreateView={createView}
          onClose={closeSaveView}
        />
      )}

      <SheetContainer data-testid='expandable-container' $height={height}>
        {!embedded && (
          <SheetHeader
            handleCopy={handleCopy}
            sheet={sheet}
            space={space}
            toggleSnapshotView={handleToggleSnapshotView}
            workbook={workbook}
            workbookActions={workbookActions}
            workbookHasCommits={hasUncompletedCommits}
            tableIsEditing={tableIsEditing}
          />
        )}

        <>
          <BlurResource id={workbook.id}>
            {!embedded && (
              <SheetTabs
                workbook={workbook}
                path={`/space/${space.id}/workbook/${workbook.id}/sheet`}
              />
            )}
            <BlurResource id={sheetId}>
              {isEditCapable && (
                <JobTooltipWrapper tooltipContent={jobTooltip}>
                  <Toolbar
                    counts={hasCountsError ? counts ?? {} : counts}
                    filteredCounts={
                      hasCountsError ? DEFAULT_COUNTS : filteredCounts
                    }
                    disabled={toolbarLocked || isLoadingEmpty}
                    theme={turntableTheme}
                    errorColumns={errorFields}
                    actionsList={bulkRowActions}
                    searchFields={searchFields}
                    onRefetchCounts={refetchCounts}
                    onClickFilter={handleClickFilter}
                    onClickFilterField={handleClickFilterField}
                    onShowCommandMenu={handleShowCommandMenu}
                    onSearchValue={handleSearchByValue}
                    loadingCountsError={hasCountsError}
                    loadingResults={isLoadingCounts || filteredResultsLoading}
                    loadingSearchResults={searchByValueLoading}
                    onOpenFilterByError={getErrorsByFieldCounts}
                    whitelabeling={whitelabelingEnabled}
                    onShowAiTransform={handleSetPreviewDiff}
                    aiAssistTransformDisabled={aiAssistTransformDisabled}
                    sheetState={sheetState}
                    onSaveView={openSaveView}
                    onUpdateView={updateView}
                    onDeleteView={deleteView}
                    onSelectView={activateView}
                    viewsList={savedViews}
                    viewsEnabled={savedViewsEnabled && !isFile}
                    filtersEnabled={!isFile}
                  >
                    {!features?.excludeColumnManagement && (
                      <ColumnManagement
                        columnConfig={columnConfig}
                        columnsState={columnsState}
                        hiddenColumnsCount={hiddenColumnsCount}
                        setColumnsState={setColumnsState}
                        disabled={toolbarLocked || isLoadingEmpty}
                      />
                    )}
                  </Toolbar>
                </JobTooltipWrapper>
              )}
              <SheetTableContainer $embedded={embedded}>
                {showInitialEmptyStateOverlay && emptyStateOverlay}
                <Table
                  readOnly={!isEditCapable || readOnlySheet || sheetIsLocked}
                  key={sheetId}
                  tableId={`${sheetId}-table`}
                  additionalRows={additionalRowCount}
                  columnConfig={columnConfigWithState}
                  onSort={handleSort}
                  sortOverride={sortOverride}
                  totalRowCount={rowCount}
                  emptyState={emptyState}
                  theme={turntableTheme}
                  onDownloadTable={handleDownload}
                  onFindAndReplace={handleFindAndReplace}
                  getFindAndReplaceModalProps={getFindAndReplaceProps}
                  rowSelectionType={isEditCapable ? 'bulk' : undefined}
                  pinningEnabled={pinningEnabled}
                  showColumnMenus={showColumnMenus}
                  onSearchValue={handleSearchByValue}
                  onActionSuccess={showActionSuccessPopover}
                  isFiltered={isFiltered}
                  disableColumnMenus={toolbarLocked}
                  useFilterByValue={useFilterByValue}
                  setTableIsEditing={setTableIsEditing}
                />
              </SheetTableContainer>
            </BlurResource>
          </BlurResource>
        </>
      </SheetContainer>
    </>
  )
}
