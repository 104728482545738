import type { Environment, Sheet, Space, Workbook } from '@flatfile/api'
import { getBrowser } from './getBrowser'
import i18next from 'i18next'

export const getWorkbookAndSpaceInfo = (
  includeDate: boolean, // need this to avoid flaky tests due to date discrepancies
  workbook: Workbook,
  space: Space,
  environment?: Environment,
  sheet?: Sheet
) => {
  let version
  if (import.meta.env.VITE_VERSION_NUMBER) {
    version = import.meta.env.VITE_VERSION_NUMBER
  }

  const inIframe = window.self !== window.top

  const data: { [key: string]: string } = {}
  data[i18next.t('sheet.info.space')] = `${space.name}: ${space.id}`
  data[i18next.t('sheet.info.workbook')] = `${workbook.name}: ${workbook.id}`
  data[i18next.t('sheet.info.sheet')] = `${sheet?.name}: ${sheet?.id}`
  data[i18next.t('sheet.info.environment')] = `${
    environment ? `${environment.name}:` : ''
  } ${workbook.environmentId}`
  data[i18next.t('sheet.info.browser')] = `${getBrowser()}`
  data[i18next.t('sheet.info.inFrame')] = `${inIframe ? 'Yes' : 'No'}`
  data[i18next.t('sheet.info.referrer')] = `${document.referrer}`

  if (includeDate) {
    // Only include the date if the flag is set to true
    data[i18next.t('sheet.info.date')] = `${getCondensedDateTime()}`
  }

  if (version) {
    data[i18next.t('sheet.info.release')] = `${version}`
  }

  return {
    data,
    rawData: JSON.stringify(data), // stringify to handle onCopy
  }
}
const getCondensedDateTime = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // +1 because months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}`
}
