import { useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'

const useScrollToLink = (
  linkTo: string,
  direction?: 'vertical' | 'horizontal'
) => {
  const match = useMatch(linkTo)
  const ref = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (match && ref.current) {
      switch (direction) {
        case 'vertical': {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          })
          break
        }
        case 'horizontal': {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          })
          break
        }
        default: {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        }
      }
    }
  }, [match, direction])

  return ref
}
export default useScrollToLink
