// @ts-nocheck Ideally we would check for feature detection per browser but this gives support a generic clue into which browser our customers are using
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers

type BrowserType =
  | 'Opera'
  | 'Firefox'
  | 'Safari'
  | 'Internet Explorer'
  | 'Edge'
  | 'Chrome'
  | 'Edge Chromium'
  | 'Blink'
  | 'Unknown Browser'

export function getBrowser(): BrowserType {
  if (
    window.opr?.addons ||
    window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0
  ) {
    return 'Opera'
  }

  if (typeof window.InstallTrigger !== 'undefined') {
    return 'Firefox'
  }

  if (
    /constructor/i.test(window.HTMLElement as any) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window['safari'] || window.safari?.pushNotification)
  ) {
    return 'Safari'
  }

  if (/*@cc_on!@*/ false || !!document.documentMode) {
    return 'Internet Explorer'
  }

  if (!document.documentMode && window.StyleMedia) {
    return 'Edge'
  }

  if (window.chrome && (window.chrome.webstore || window.chrome.runtime)) {
    return 'Chrome'
  }

  if (window.chrome && navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge Chromium'
  }

  if ((window.chrome || window.opr?.addons) && window.CSS) {
    return 'Chrome: Blink'
  }

  return 'Unknown Browser'
}
