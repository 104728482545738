import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useRouteParams } from '@/hooks/useRouteParams'
import { useSheetAccess } from '@/hooks/useSheetAccess'
import { useLocation } from 'react-router-dom'

export const useFileUploadEnabled = () => {
  const { workbookId, sheetId } = useRouteParams()
  const location = useLocation()
  const isFilesView = location.pathname.includes('files')
  const {
    data: workbook,
    isLoading: isLoadingWorkbook,
    isFetching: isFetchingWorkbook,
    isError: isErrorWorkbook,
  } = useFlatfileQuery(
    'getWorkbookById',
    { workbookId: workbookId! },
    { enabled: !!workbookId && !isFilesView }
  )
  const currentWorkbook = workbook?.data
  const currentSheet = currentWorkbook?.sheets?.find((s) => s.id === sheetId)
  const { noAccessLimitations, canImportIntoSheet } =
    useSheetAccess(currentSheet)

  //return true early if we are in the files view or there is no workbook id
  if (isFilesView || !workbookId) {
    return { loading: false, error: false, enableFileUpload: true }
  }

  const enableFileUpload =
    location.pathname.includes('sheet') &&
    currentSheet &&
    (noAccessLimitations || canImportIntoSheet)

  return {
    loading: isLoadingWorkbook && isFetchingWorkbook,
    error: isErrorWorkbook,
    enableFileUpload,
  }
}
