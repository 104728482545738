import { useEffect } from 'react'

const originalTitle = document.title

export function useDocumentTitle(...title: Array<string | undefined>) {
  useEffect(() => {
    document.title = [...title, originalTitle].filter((v) => v).join(' • ')

    return () => {
      document.title = originalTitle
    }
  }, title)
}
