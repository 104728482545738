import { getFont } from '@flatfile/design-system'
import styled from 'styled-components'

export const EmbeddedSheetViewContainer = styled.div`
  transition: background-color 0.2s ease;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
`

export const EmbeddedSheetHeader = styled.button<{
  $unknown?: boolean
  $expanded?: boolean
  $canExpand?: boolean
}>`
  ${getFont('actionLg')}
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
  border-radius: 2px;
  padding: 8px 16px;
  background-color: var(--color-page);
  width: 100%;
  border: none;
  transition: background-color 0.2s ease;

  ${({ $unknown }) =>
    $unknown
      ? `
    color: var(--color-disabled);
    font-style: italic;
    font-weight: 400;
  `
      : `
    color: var(--color-title);
  `}

  ${({ $expanded }) => `
    svg {
      transition: ease-in-out transform 0.1s;
      transform: rotate(${$expanded ? 0 : -90}deg);
    }
  `}

  ${({ $canExpand, $expanded }) =>
    ($canExpand || $expanded) &&
    `
      cursor: pointer;
  `}

  &:hover {
    &,
    & + ${EmbeddedSheetViewContainer} {
      background-color: var(--color-hover);
    }
  }

  &:active {
    &,
    & + ${EmbeddedSheetViewContainer} {
      background-color: var(--color-page-200);
    }
  }
`

export const EmbeddedSheetContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`
