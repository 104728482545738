import {
  AMP_TOOLTIP_ID,
  IconButton,
  useOnClickOutside,
} from '@flatfile/design-system'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { ColumnManagementPanel } from './ColumnManagementPanel'
import { ColumnManagementProps } from './types'

const Container = styled.div`
  position: relative;
`

export const ColumnManagement = ({
  columnsState,
  columnConfig,
  hiddenColumnsCount,
  setColumnsState,
  disabled,
}: ColumnManagementProps) => {
  const [showPanel, setShowPanel] = useState(false)

  const { t } = useTypedTranslation()
  const containerRef = useRef<HTMLDivElement>(null)

  const handleToggle = useCallback(() => {
    setShowPanel((show) => !show)
  }, [showPanel])

  const handleClose = useCallback(() => {
    setShowPanel(false)
  }, [showPanel])

  useOnClickOutside(containerRef, handleClose)

  return (
    <Container ref={containerRef}>
      <IconButton
        onPress={handleToggle}
        size={16}
        name='columns'
        aria-label='manage columns'
        variant={hiddenColumnsCount ? 'primary' : 'tertiary'}
        data-testid='column-management-trigger'
        data-tooltip-id={AMP_TOOLTIP_ID}
        data-tooltip-content={t('sheet.columnManagement.tooltip', {
          count: hiddenColumnsCount,
        })}
        isDisabled={disabled}
      />
      {showPanel && (
        <ColumnManagementPanel
          columns={columnsState}
          columnConfig={columnConfig}
          setColumns={setColumnsState}
          onClose={handleClose}
        />
      )}
    </Container>
  )
}
