import { Auth } from '@/auth/Auth'
import { convertDates } from '@/utils/convertDates'
import {
  Document,
  Environment,
  Guest,
  Space,
  User,
  Workbook,
} from '@flatfile/api'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'

const queryKey = 'getInitialResources'

// TODO: Replace hardcoded type with imported type from Platform
export interface SpaceInitObject {
  workbooks: Workbook[] | null
  documents: Document[] | null
  space: Space
  actor: User | Guest | undefined
  entitlements: any[]
  environment: Partial<Environment> & {
    hasAccess: boolean
  }
  paragonToken?: string
  permissions: Permissions | undefined
}

const fetchInitialResources = async (spaceId: string) => {
  return await axios
    .get(
      `${
        import.meta.env.VITE_BASE_API_URL
      }/internal/spaces/${spaceId}/initial-resources`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Auth.TOKEN}`,
        },
      }
    )
    .then((response: any) => {
      const resources = response.data
      if (resources.data) {
        return convertDates(resources.data, ['options'])
      }
    })
}

export const useQueryInitialResources = (
  spaceId: string,
  options?: UseQueryOptions<any>
) => {
  return useQuery<SpaceInitObject>(
    [queryKey, spaceId],
    async () => fetchInitialResources(spaceId),
    options
  )
}
