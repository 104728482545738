import { FileController } from '@/api/controllers/FileController'
import { useController } from '@/api/controllers/useController'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { usePaymentAccess } from '@/hooks/usePaymentAccess'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { FileOrigin, Space } from '@flatfile/api'
import { Spinner, useSynchronizedRef } from '@flatfile/design-system'
import {
  CheckmarkIcon,
  InfoIcon,
  PopoverContext,
  PopoverMessage,
  PopoverMessageFullWidth,
  PopoverMessageWrapper,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

const EXTRACTION_WAIT_TIME = 5000

type FileUploadState = 'idle' | 'uploading' | 'waiting' | 'extracting'

export interface UploadProgress {
  progress: number
  speed: number
}

interface FileUpload {
  name?: string
  progress?: number
  error?: string
  complete?: boolean
}

export type FileUploads = Record<string, FileUpload>

const formatSpeed = (speedKBps: number) => {
  if (speedKBps < 1024) {
    return speedKBps + ' KB/s'
  } else if (speedKBps < 1024 * 1024) {
    return (speedKBps / 1024).toFixed(1) + ' MB/s'
  } else {
    return (speedKBps / (1024 * 1024)).toFixed(1) + ' GB/s'
  }
}

export const useFileUpload = (space: Space, silent: boolean = false) => {
  const location = useLocation()
  const isUploadToSheet = location.pathname.includes('sheet')
  const isUploadToSheetRef = useSynchronizedRef(isUploadToSheet)
  const { fileUploadLocked } = usePaymentAccess()
  const { showPopover, hidePopover } = useContext(PopoverContext)
  const { httpClient } = useContext(SpaceContext)
  const [fileUploads, setFileUploads] = useState<FileUploads>({})
  const { t } = useTypedTranslation()
  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
    progress: 0,
    speed: 0,
  })
  const [fileName, setFileName] = useState<string | undefined>()
  const fileIdRef = useRef('')

  const [fileUploadState, _setFileUploadState] =
    useState<FileUploadState>('idle')
  const fileUploadStateRef = useSynchronizedRef(fileUploadState)

  const setFileUploadState = useCallback(
    (state: FileUploadState) => {
      _setFileUploadState(state)
    },
    [_setFileUploadState]
  )

  const onClose = useCallback(() => {
    setFileUploadState('idle')
    hidePopover()
  }, [hidePopover, setFileUploadState])

  useEffect(() => {
    return () => {
      hidePopover()
    }
  }, [hidePopover])

  useEffect(() => {
    if (fileName && uploadProgress.progress < 100) {
      if (!silent) {
        showPopover({
          icon: <Spinner />,
          message: (
            <>
              <PopoverMessageWrapper>
                <PopoverMessage>
                  {t('files.uploadFile.popovers.uploadInProgress', {
                    fileName: fileName,
                  })}
                </PopoverMessage>
                <span>
                  {`(${uploadProgress.progress}%) ${formatSpeed(
                    uploadProgress.speed
                  )}`}
                </span>
              </PopoverMessageWrapper>
            </>
          ),
          onClose: undefined,
        })
      }
    } else if (fileName && uploadProgress.progress === 100) {
      if (!silent) {
        showPopover(
          {
            icon: <CheckmarkIcon name='checkmark' />,
            message: (
              <PopoverMessage>
                {t('files.uploadFile.popovers.uploadComplete', {
                  fileName: fileName,
                })}
              </PopoverMessage>
            ),
            onClose,
          },
          true
        )
      }
    }
  }, [uploadProgress, fileName, showPopover])

  useEffect(() => {
    if (fileUploadState === 'waiting') {
      showWaitingMessage(t('files.uploadFile.popovers.waitingForExtraction'))
    }
  }, [fileUploadState])

  const showErrorPopover = (errorMessage: string) => {
    if (!silent) {
      showPopover({
        icon: <WarningIcon name='alertTriangle' />,
        message: (
          <PopoverMessageFullWidth>{errorMessage}</PopoverMessageFullWidth>
        ),
      })
    }
  }

  const showWaitingMessage = (message: string) => {
    if (!silent) {
      showPopover({
        icon: <Spinner />,
        message: <PopoverMessageFullWidth>{message}</PopoverMessageFullWidth>,
        onClose,
      })
    }
  }

  const showConfirmationMessage = (message: string) => {
    if (!silent) {
      showPopover({
        icon: <CheckmarkIcon name='checkmark' />,
        message: <PopoverMessageFullWidth>{message}</PopoverMessageFullWidth>,
        onClose,
      })
    }
  }

  const showInfoPopover = (message: string) => {
    if (!silent) {
      showPopover({
        icon: <InfoIcon name='info' />,
        message: <PopoverMessageFullWidth>{message}</PopoverMessageFullWidth>,
        onClose,
      })
    }
  }

  const triggerExtractionTimer = () => {
    setTimeout(async () => {
      if (fileUploadStateRef.current === 'waiting') {
        // double check if there really is no job for this file
        const jobsResponse = await httpClient.getJobs({
          fileId: fileIdRef.current,
        })
        const hasJob = jobsResponse.data?.length

        if (!hasJob) {
          if (isUploadToSheetRef.current) {
            showErrorPopover(t('files.uploadFile.popovers.unsupportedFile'))
          } else {
            showInfoPopover(t('files.uploadFile.popovers.unsupportedFileInfo'))
          }
        }
        setFileUploadState('idle')
      }
    }, EXTRACTION_WAIT_TIME)
  }

  useEventSubscriber(['job:created'], (_, event) => {
    if (
      event.context.fileId === fileIdRef.current &&
      fileUploadStateRef.current === 'waiting'
    ) {
      setFileUploadState('extracting')
      showWaitingMessage(t('files.uploadFile.popovers.extracting'))
    }
  })

  useEventSubscriber(['job:completed'], (_, event) => {
    if (event.context.fileId === fileIdRef.current) {
      setFileUploadState('idle')
      showConfirmationMessage(
        t('files.uploadFile.popovers.extractionCompleted')
      )
    }
  })

  useEventSubscriber(['job:failed'], (_, event) => {
    if (event.context.fileId === fileIdRef.current) {
      setFileUploadState('idle')
      showErrorPopover(t('files.uploadFile.popovers.extractionFailed'))
    }
  })

  const handleFileInputChange = useCallback(
    async (file: File | undefined, origin?: FileOrigin) => {
      if (fileUploadLocked) {
        showErrorPopover(t('files.uploadFile.popovers.uploadsDisabled'))
        return
      }

      setFileName(undefined)
      setUploadProgress({
        progress: 0,
        speed: 0,
      })

      if (file) {
        setFileName(file.name)
        setFileUploadState('uploading')
        try {
          const fileResult = await fileController.uploadFileToSpace(
            file,
            setUploadProgress,
            origin
          )
          fileIdRef.current = fileResult.id
          setFileUploadState('waiting')
          triggerExtractionTimer()
          if (fileResult?.id) {
            const updatedFileUploads = Object.assign({}, fileUploads)
            updatedFileUploads[fileResult.id] = {
              ...updatedFileUploads[fileResult.id],
              name: file.name,
            }
            setFileUploads(updatedFileUploads)
          }
        } catch (error) {
          showErrorPopover(
            t('files.uploadFile.popovers.errorUploading', {
              fileName: file.name,
            })
          )
        }
      }
    },
    [fileController, fileUploads, fileUploadLocked]
  )

  return {
    handleFileUpload: handleFileInputChange,
    setUploadProgress,
  }
}
