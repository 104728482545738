import { AMP_TOOLTIP_ID } from '@flatfile/design-system'
const maxLength = 27
const endPartLength = 6
const cutoffLocation = maxLength - endPartLength - 1 // extra 1 for ellipsis

export const TruncatedSpan = ({ text }: { text: string }) => {
  if (text.length <= 27) {
    return <span>{text}</span>
  }
  const text1 = text.slice(
    0,
    Math.min(cutoffLocation, text.length - endPartLength)
  )
  const text2 = text.slice(text.length - endPartLength, text.length)
  return (
    <span data-tooltip-content={text} data-tooltip-id={AMP_TOOLTIP_ID}>
      {text1}
      {'…'}
      {text2}
    </span>
  )
}
