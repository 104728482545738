import { Sheet } from '@flatfile/api'

export const deriveSheetState = (sheet: Sheet, totalCounts: number) => {
  if (sheet?.lockedAt) {
    return 'locked'
  } else if (sheet?.config?.readonly) {
    return 'readOnly'
  } else if (totalCounts === 0) {
    return 'noRows'
  } else if (totalCounts > 1000000) {
    return 'tooManyRows'
  } else {
    return 'active'
  }
}
