import { FlatfileRequestType } from './types'

export const getPromptsKey = 'getPrompts'

export const promptsQueries = {
  getPrompts: {
    generateQueryKey: (params: FlatfileRequestType<'getPrompts'>) => [
      'getPrompts',
      params,
    ],
    influencingEvents: {},
  },
}
