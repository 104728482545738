import { HTTPService } from '@/components/HttpClient'
import { SheetResponse } from '@flatfile/api'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

export const UPDATE_SHEET_KEY = 'updateSheet'

export interface UpdateSheetHeaderRequest {
  sheetId: string
  rowHeaders: number[]
}
/**
 * Wrapper around the mutation needed to update a sheet's header row indices
 * Upon applying the new rowHeader assignment, the fields that define a sheet's
 * header will be re-evaluated, which will ensure the correct values/assignments
 * are used during mapping
 *
 * @returns SheetResponse
 */
export const useUpdateSheetHeader = (
  sheetId?: string,
  options?: UseMutationOptions<SheetResponse, Error, UpdateSheetHeaderRequest>
) => {
  return useMutation<SheetResponse, Error, UpdateSheetHeaderRequest>(
    [UPDATE_SHEET_KEY, sheetId],
    async (updateHeaderRequest: UpdateSheetHeaderRequest) => {
      return await HTTPService.updateSheet({
        sheetId: updateHeaderRequest.sheetId,
        sheetUpdateRequest: {
          metadata: {
            rowHeaders: updateHeaderRequest.rowHeaders,
          },
        },
      })
    },
    options
  )
}
