import { PAGE_SIZE } from '@/apps/WorkbookApp/hooks/useSheetViewData'
import {
  ActionSuccessMeta,
  getActionSuccessMessage,
} from '@/apps/WorkbookApp/utils/bulkRowUtils'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { GetSnapshotRecordsChangeTypeEnum as ChangeType } from '@flatfile/api'
import {
  CheckmarkIcon,
  PopoverContext,
  PopoverMessage,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useDataBufferContext } from '@flatfile/turntable'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DiffAction, DiffTableProps } from '../types'

/**
 * Loads the record data into the data buffer.
 */
const useDiffTable = ({
  action,
  snapshot,
  summary,
  columnConfig,
  onSubmit,
  onCancel,
  onClose,
  sheet,
}: DiffTableProps) => {
  const { t } = useTypedTranslation()
  const { showPopover } = useContext(PopoverContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [rowCount, setRowCount] = useState<number>(0)
  const [changeType, setChangeType] = useState<ChangeType>(
    ChangeType.UpdatedSince
  )
  const { buffer, initLoad, isLoading, isErrored } = useDataBufferContext()

  const canSetRowCount = useRef(false)

  const tableId = `${snapshot.id}:${changeType}`
  const currentRowCount = summary?.[changeType]?.total

  useEffect(() => {
    canSetRowCount.current = false
    const onLoad = async () => {
      buffer.initialize({
        bufferId: tableId,
        params: { changeType },
        pageSize: PAGE_SIZE,
        columnConfig,
        totalRecords: PAGE_SIZE,
      })
      setRowCount(0)
      await initLoad()

      canSetRowCount.current = true
      setRowCount(currentRowCount ?? buffer?.bufferedRecords?.length ?? 0)
      buffer.onDataLoadError = showPageLoadErrorPopover
    }
    onLoad()
  }, [tableId, changeType, columnConfig])

  useEffect(() => {
    if (currentRowCount !== undefined) {
      buffer.setTotalRecords(currentRowCount)

      /* c8 ignore start */
      if (canSetRowCount.current) {
        setRowCount(currentRowCount)
      }
      /* c8 ignore stop */
    }
  }, [currentRowCount])

  const showCopySuccessPopover = useCallback((meta: ActionSuccessMeta) => {
    const popover = {
      icon: <CheckmarkIcon name='checkmark' />,
      message: <PopoverMessage>{getActionSuccessMessage(meta)}</PopoverMessage>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const showPageLoadErrorPopover = useCallback(() => {
    const popover = {
      icon: <WarningIcon name='checkmark' />,
      message: (
        <PopoverMessage>
          {t('sheet.popovers.errors.recordsLoadFailed')}
        </PopoverMessage>
      ),
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const showCancelSuccessPopover = useCallback(() => {
    const popover = {
      icon: <CheckmarkIcon name='checkmark' />,
      message: <>{t(`snapshot.${DiffAction.ACCEPT}.cancelSuccess`)}</>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const showCancelErrorPopover = useCallback(() => {
    const popover = {
      icon: <WarningIcon name='checkmark' />,
      message: <>{t(`snapshot.${DiffAction.ACCEPT}.cancelError`)}</>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const showSubmitSuccessPopover = useCallback(() => {
    // Don't show this popover if the action is PREVIEW.
    // We have a different toast that runs at the end of the job.
    /* c8 ignore next 3 */
    if (action === DiffAction.PREVIEW) {
      return
    }

    const popover = {
      icon: <CheckmarkIcon name='checkmark' />,
      message: <>{t(`snapshot.${action}.submitSuccess`)}</>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const showSubmitErrorPopover = useCallback(() => {
    const popover = {
      icon: <WarningIcon name='checkmark' />,
      message: <>{t(`snapshot.${action}.submitError`)}</>,
      onClose: undefined,
    }
    showPopover(popover, true)
  }, [])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await onSubmit()
      setIsSubmitting(false)
      showSubmitSuccessPopover()
      onClose()
    } catch (e) {
      showSubmitErrorPopover()
      setIsSubmitting(false)
    }
  }, [isSubmitting])

  const handleCancel = useCallback(async () => {
    if (action !== DiffAction.ACCEPT) {
      onCancel?.()
      onClose()
      return
    }

    setIsCanceling(true)
    try {
      await onCancel?.()
      setIsCanceling(false)
      showCancelSuccessPopover()
      onClose()
    } catch (e) {
      showCancelErrorPopover()
      setIsCanceling(false)
    }
  }, [isCanceling])

  return {
    tableId,
    rowCount,
    changeType,
    setChangeType,
    isLoading,
    isErrored,
    isSubmitting,
    isCanceling,
    onCancel: handleCancel,
    onSubmit: handleSubmit,
    onCopySuccess: showCopySuccessPopover,
    onPageLoadError: showPageLoadErrorPopover,
  }
}

export default useDiffTable
