import { Snapshot } from '@flatfile/api'
import { IconButton, Typography, getFont } from '@flatfile/design-system'
import { PanelItem } from '@flatfile/shared-ui'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VersionAction } from '@/api/controllers/VersionsController'
import { Row } from '@/elements/Row'

const VersionItem = function ({
  version,
  onActionSelected,
}: {
  version: Snapshot
  onActionSelected: (version: Snapshot, action: VersionAction) => void
}) {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const dateLabelFormatter = new Intl.DateTimeFormat(lang, {
    day: '2-digit',
    month: 'long',
  })
  const dateTimeFormatter = new Intl.DateTimeFormat(lang, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  })

  return (
    <PanelItem
      data-testid='version-panel-item'
      $borderColor='var(--color-border-light)'
    >
      <VersionItemHeadingRow>
        <Typography color={'var(--color-text-dark)'} type='actionLg'>
          {`${dateLabelFormatter.format(
            version.createdAt
          )}, ${dateTimeFormatter.format(version.createdAt)}`}
        </Typography>
        <Row $gap='4px'>
          <IconButton
            name='arrowRotateLeft'
            size={16}
            onPress={() => onActionSelected(version, VersionAction.RESTORE)}
          />
          <IconButton
            name='trash'
            size={16}
            onPress={() => onActionSelected(version, VersionAction.DELETE)}
          />
        </Row>
      </VersionItemHeadingRow>
      <VersionItemLabel>{version.label}</VersionItemLabel>
    </PanelItem>
  )
}

const VersionItemLabel = styled.div`
  ${getFont('b0')}
  font-size: 14px;
  line-height: 150%;
  color: var(--color-text-dark);
  padding: 0px 24px;
`

const VersionItemHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
`

export default VersionItem
