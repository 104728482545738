import styled, { css } from 'styled-components'

export const ButtonRow = styled.div<{ centered?: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 1em;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
`
