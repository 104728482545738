import { SpaceContextType } from '@/contexts/SpaceContext'
import { Space } from '@flatfile/api'
import {
  AMP_TOOLTIP_ID,
  Dropdown,
  Icon,
  Spinner,
  useIsTruncated,
} from '@flatfile/design-system'
import { useMemo, useRef } from 'react'
import {
  SidebarHeadingElement,
  SidebarSpaceName,
  SidebarSwitcher,
} from '../elements'
import { useQueryGetAllSpaces } from '@/api/queries/spaces/useQueryGetAllSpaces'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'

export const SidebarHeading = ({
  spaceContext,
}: {
  spaceContext: SpaceContextType
}) => {
  const { space, setCurrentSpace } = spaceContext
  const { data: availableSpaces, isLoading } = useQueryGetAllSpaces({})
  const spaceName = space.name ?? ''
  const spacesInEnv = useMemo(() => {
    return availableSpaces?.data?.filter(
      (s: Space) => s?.environmentId === space?.environmentId
    )
  }, [space, availableSpaces?.data])

  if (spacesInEnv && spacesInEnv.length > 1) {
    return (
      <SidebarSpaceSwitcher
        spaceName={spaceName}
        spaceId={space.id}
        spacesInEnv={spacesInEnv}
        setCurrentSpace={setCurrentSpace}
      />
    )
  }

  return (
    <SidebarHeadingElement data-testid='sidebar-heading-element'>
      <SpaceName name={spaceName} />
      {isLoading && <Spinner size={16} />}
    </SidebarHeadingElement>
  )
}

export const SidebarSpaceSwitcher = ({
  spaceName,
  spaceId,
  spacesInEnv,
  setCurrentSpace,
}: {
  spaceName: string
  spaceId: string
  spacesInEnv: Space[]
  setCurrentSpace: (s: string) => void
}) => {
  const { t } = useTypedTranslation()
  return (
    <SidebarSwitcher>
      <Dropdown
        minWidth={228}
        autoFocus
        searchable
        initializeEmptyInput
        placeholder={t('sidebar.spaceSwitcher.placeholder')}
        aria-label='spaces-selection'
        selectedKey={spaceId}
        optionsList={spacesInEnv?.map((s: Space) => ({
          key: s.id,
          label: `${s.name}`,
        }))}
        onSelectionChange={(s) => s && setCurrentSpace(s.toString())}
        customButton={
          <SidebarHeadingElement data-testid='sidebar-heading-dropdown'>
            <SpaceName name={spaceName} />
            <Icon name='chevronDown' size={16} className='heading-chevron' />
          </SidebarHeadingElement>
        }
      />
    </SidebarSwitcher>
  )
}

export const SpaceName = ({ name }: { name: string }) => {
  const nameRef = useRef(null)
  const [isTruncated] = useIsTruncated([nameRef])
  return (
    <SidebarSpaceName
      ref={nameRef}
      data-tooltip-id={isTruncated ? AMP_TOOLTIP_ID : undefined}
      data-tooltip-content={isTruncated ? name : undefined}
    >
      {name}
    </SidebarSpaceName>
  )
}
