import { Banner } from '@flatfile/design-system'
import { getQueryFields, isQueryValid } from '@flatfile/shared-ui'
import { formatSearchTerm } from '@flatfile/turntable'
import { useCallback, useEffect, useState } from 'react'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { Reveal } from '../Reveal'
import { CommandMenuMainPanel } from './CommandMenuMainPanel'
import { CommandMenuLine } from './elements'
import { CommandMenuCommandsPanelProps } from './types'
import { PanelType, getCleansedCommandPrompt, getPanelType } from './utils'

export const CommandMenuCommandsPanel = (
  props: CommandMenuCommandsPanelProps
) => {
  const {
    columnsState,
    inputValue,
    dismissHandler,
    onSearchByValue,
    keyboardSubmitActivated,
    deactivateKeyboardSubmit,
  } = props
  const { t } = useTypedTranslation()

  const panelType = getPanelType(inputValue) ?? PanelType.SEARCH
  const [hiddenFields, setHiddenFields] = useState<string[]>([])
  const [invalidQuery, setInvalidQuery] = useState(false)

  const getSearchParams = (value: string) => {
    const searchTerm = getCleansedCommandPrompt(value, panelType)
    return formatSearchTerm(searchTerm)
  }

  const searchItemSelected = async (inputValue: string) => {
    const newSearchParam = getSearchParams(inputValue)

    if (panelType === PanelType.FILTER) {
      const isValid = await isQueryValid(newSearchParam.q)
      if (!isValid) {
        setInvalidQuery(true)
        return
      }
    }

    onSearchByValue(newSearchParam)
    dismissHandler()
  }

  const setFields = useCallback(async () => {
    if (panelType !== PanelType.FILTER) {
      setHiddenFields([])
    }
    const { q } = getSearchParams(inputValue)
    const fields = await getQueryFields(q)
    const hidden = fields.filter((field: string) => columnsState[field]?.hidden)

    setHiddenFields(hidden)
  }, [inputValue, panelType, columnsState])

  useEffect(() => {
    if (keyboardSubmitActivated) {
      searchItemSelected(inputValue)
      deactivateKeyboardSubmit()
    }
  }, [keyboardSubmitActivated, inputValue])

  useEffect(() => {
    setFields()
    setInvalidQuery(false)
  }, [inputValue, panelType])

  return (
    <>
      {invalidQuery ? (
        <Reveal>
          <CommandMenuLine data-testid='cmdk-ffql-error'>
            <Banner
              variant='error'
              message={t('commandMenu.commands.filter.invalidFFQL')}
            />
          </CommandMenuLine>
        </Reveal>
      ) : hiddenFields.length ? (
        <Reveal>
          <CommandMenuLine data-testid='cmdk-ffql-columns-warning'>
            <Banner
              variant='warning'
              message={t('commandMenu.commands.filter.hiddenColumnsWarning', {
                count: hiddenFields.length,
                fields: hiddenFields.join(', '),
              })}
            />
          </CommandMenuLine>
        </Reveal>
      ) : null}
      <CommandMenuMainPanel value={'/' + panelType} />
    </>
  )
}
