import { Space } from '@flatfile/api'
import { Icon, IconName } from '@flatfile/design-system'
import { usePaymentAccess } from '@/hooks/usePaymentAccess'
import { ButtonRow } from '@/elements/ButtonRow'
import { EmptyState, ErrorState } from '@/components/EmptyState'
import { FileUploadButton } from './FileUploadButton'
import { FileMode } from '../types'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { ConnectionsButton } from './ConnectionsButton'

export const FilesEmptyState = ({
  fileMode,
  space,
}: {
  fileMode: FileMode
  space: Space
}) => {
  const { fileUploadLocked } = usePaymentAccess()
  const { t } = useTypedTranslation()
  const isUpload = fileMode === 'import'
  const emptyStateContent = {
    border: isUpload,
    iconName: isUpload ? 'documentAdd' : 'download',
    title: isUpload
      ? t('files.emptyState.uploads.heading')
      : t('files.emptyState.downloads.heading'),
    message: isUpload
      ? t('files.emptyState.uploads.description')
      : t('files.emptyState.downloads.description'),
  }

  if (isUpload && fileUploadLocked) {
    return (
      <ErrorState
        title={t('files.errors.uploadDisabledHeading')}
        refresh={false}
      >
        {t('files.errors.uploadDisabledDescription')}
      </ErrorState>
    )
  }

  return (
    <EmptyState
      border={emptyStateContent.border}
      icon={<Icon name={emptyStateContent.iconName as IconName} />}
      title={emptyStateContent.title}
      message={emptyStateContent.message}
    >
      {isUpload && (
        <>
          <ButtonRow centered>
            <FileUploadButton space={space} />{' '}
          </ButtonRow>
          <ButtonRow centered>
            {' '}
            <ConnectionsButton />
          </ButtonRow>
        </>
      )}
    </EmptyState>
  )
}
