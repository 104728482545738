import { Icon, IconButton } from '@flatfile/design-system'
import React, { useEffect, useRef, useState } from 'react'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { CommandMenuAiQueryPanel } from './CommandMenuAiAssist'
import { CommandMenuCommandsPanel } from './CommandMenuCommandsPanel'
import { CommandMenuMainPanel } from './CommandMenuMainPanel'
import { CommandMenuPill } from './CommandMenuPill'
import {
  CommandMenuInput,
  CommandMenuInputWrapper,
  CommandMenuList,
  CommandMenuRoot,
} from './elements'
import { CommandMenuProps } from './types'
import { Checkers, getCurrentPanelId } from './utils'

enum PanelState {
  MainPanel = 'main-panel',
  SearchPanel = 'search-panel',
  AiQueryPanel = 'ai-query-panel',
  CommandPanel = 'command-panel',
}

/**
 * Intakes the input value from the search and evaluates if a
 * command-specific panel should be displayed to facilitate
 * unique behaviors (i.e. Panel to inform user on how to use AI to
 * generate an FFQL query)
 * @param inputValue
 */
const getPanelState = (inputValue: string) => {
  switch (true) {
    case Checkers.isQueryPanel(inputValue): {
      return PanelState.AiQueryPanel
    }
    case Checkers.isCommandsPanel(inputValue): {
      return PanelState.CommandPanel
    }
    case Checkers.isListPanel(inputValue): {
      return PanelState.MainPanel
      /* c8 ignore start */
    }
    case !inputValue: {
      return PanelState.MainPanel
    }
    /* c8 ignore stop */
    default: {
      return PanelState.CommandPanel
    }
  }
}

const CommandMenuContent = ({
  columnsState,
  sheetId,
  onClose,
  onSearchByValue,
  value,
  setValue,
}: CommandMenuProps & { onClose: () => void } & any) => {
  const { t } = useTypedTranslation()

  // The state which determines which panel is displayed
  const [panelState, setPanelState] = useState(getPanelState(value))
  // The state of the input
  const [inputDisabled, setInputDisabled] = useState(false)
  const [activateKeyboardSubmit, setActivateKeyboardSubmit] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef(null)

  const onValueChange = (value: string) => {
    const panelState = getPanelState(value)
    setPanelState(panelState)
    setActivateKeyboardSubmit(false)
    setValue(value)
  }

  const onMenuItemSelection = (value: string) => {
    onValueChange(value)
    focusInputHandler()
  }

  /**
   * Simple handler to give child components the ability to give the input
   * field focus after performing asynchronous actions (i.e. requesting a query)
   */
  const focusInputHandler = () => {
    const input = inputRef.current
    input?.focus()
  }

  /* c8 ignore start */
  const handleClearInput = () => {
    onValueChange('')
    focusInputHandler()
  }
  /* c8 ignore stop */

  const currentPanelId = getCurrentPanelId(value)
  const inputValue = currentPanelId
    ? value.slice(currentPanelId.length + 1, value.length)
    : value

  const handleInputValueChange = (v: string) => {
    if (currentPanelId) {
      onValueChange(currentPanelId + ' ' + v)
    } else {
      onValueChange(v)
    }
  }

  /**
   * Handler for keyDown on the Input field to (1) evaluate if the
   * Keyboard-Submit state should be activated, (2) handle backspace
   * if the panel pill is shown.
   *
   * @param e KeyboardEvent | event emitted on press of any keyDown
   */
  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (panelState !== PanelState.MainPanel) {
        setActivateKeyboardSubmit(true)
      }
    } else if (e.key === 'Backspace') {
      if (
        currentPanelId &&
        /* c8 ignore next */
        (value === currentPanelId || value === currentPanelId + ' ')
      ) {
        e.preventDefault()
        e.stopPropagation()
        onValueChange(currentPanelId.slice(0, currentPanelId.length - 1))
      }
    }
  }

  return (
    <>
      <CommandMenuInputWrapper>
        <Icon size={16} name='search' color='var(--color-text-light)' />
        <CommandMenuPill id={currentPanelId} />
        <CommandMenuInput
          ref={inputRef}
          autoFocus
          placeholder={t('commandMenu.placeholder')}
          value={inputValue}
          onValueChange={handleInputValueChange}
          onKeyDown={handleInputKeyDown}
          onFocus={focusInputHandler}
          disabled={inputDisabled}
        />
        {value.length ? (
          <IconButton
            name='cross'
            size={16}
            onPress={handleClearInput}
            isDisabled={inputDisabled}
            data-testid='cmdk-input-clear'
          />
        ) : null}
      </CommandMenuInputWrapper>
      <CommandMenuList ref={listRef}>
        {panelState === PanelState.MainPanel && (
          <CommandMenuMainPanel
            canShowHeadings
            value={value}
            onMenuItemSelection={onMenuItemSelection}
          />
        )}
        {panelState === PanelState.CommandPanel && (
          <CommandMenuCommandsPanel
            columnsState={columnsState}
            dismissHandler={onClose}
            inputValue={value}
            onSearchByValue={onSearchByValue}
            keyboardSubmitActivated={activateKeyboardSubmit}
            deactivateKeyboardSubmit={() => setActivateKeyboardSubmit(false)}
          />
        )}

        {panelState === PanelState.AiQueryPanel && (
          <CommandMenuAiQueryPanel
            value={value}
            sheetId={sheetId}
            onValueChange={onValueChange}
            onSearchByValue={onSearchByValue}
            dismissHandler={onClose}
            setInputDisabled={setInputDisabled}
            setInputFocus={focusInputHandler}
            keyboardSubmitActivated={activateKeyboardSubmit}
            deactivateKeyboardSubmit={() => setActivateKeyboardSubmit(false)}
          />
        )}
      </CommandMenuList>
    </>
  )
}

export const CommandMenu = (props: CommandMenuProps) => {
  const { isOpen, onOpenChange } = props

  // Is the menu open
  const [open, setOpen] = useState(!!isOpen)

  // Open / close menu with cmd+k'
  useEffect(() => {
    const handleGlobalKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && event.metaKey) {
        event.preventDefault()
        setOpen((open) => {
          const newOpen = !open
          onOpenChange?.(newOpen)
          return newOpen
        })
      }
    }

    document.addEventListener('keydown', handleGlobalKeyDown)
    return () => document.removeEventListener('keydown', handleGlobalKeyDown)
  }, [])

  const handleOpenChange = (open: boolean) => {
    setOpen(open)
    onOpenChange?.(open)
  }

  const handleClose = () => {
    handleOpenChange(false)
  }

  useEffect(() => {
    handleOpenChange(!!isOpen)
  }, [isOpen])

  return (
    <>
      <div data-testid='command-menu' />
      <CommandMenuRoot
        open={open}
        onOpenChange={handleOpenChange}
        shouldFilter={false}
      >
        {open && <CommandMenuContent {...props} onClose={handleClose} />}
      </CommandMenuRoot>
    </>
  )
}
