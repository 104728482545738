import { JobOperationEnum } from '@/contexts/JobsContext'
import { capitalizeFirst } from '@/utils/capitalizeFirst'
import { AuditActivity, Activity } from '@flatfile/api'
import { Badge, BadgeVariant } from '@flatfile/design-system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type JobAuditActivities = {
  [K in keyof typeof AuditActivity]: K extends `Job${string}`
    ? (typeof AuditActivity)[K]
    : never
}[keyof typeof AuditActivity]

const DetailLink = styled(Link)`
  color: var(--color-action);
  text-decoration: underline;
`

const StyledLink = styled(Link)`
  color: var(--color-action);
  font-weight: 600;
`

const TriggerText = styled.div`
  color: var(--color-action);
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const ActorCell = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`
const ActorType = styled.span<{ type?: string }>`
  padding: 2px 10px;
  border-radius: var(--pill-border-radius);
  font-weight: 600;
  white-space: nowrap;
  font-size: 12px;
  background-color: ${({ type }) => getActorTypeBackgroundColor(type)};
  color: ${({ type }) => getActorTypeTextColor(type)};
`

const statusText = {
  [AuditActivity.Jobcompleted]: 'completed job',
  [AuditActivity.Jobready]: 'started job',
  [AuditActivity.Jobcreated]: 'triggered the job',
  [AuditActivity.Jobdeleted]: 'deleted',
  [AuditActivity.Jobscheduled]: 'scheduled',
  [AuditActivity.Jobfailed]: 'failed',
  [AuditActivity.JoboutcomeAcknowledged]: 'acknowledged the outcome of',
  [AuditActivity.JobpartsCompleted]: 'completed a part of',
}

const operationText = {
  [JobOperationEnum.Export]: `export records from sheet`,
  [JobOperationEnum.DeleteRecords]: `delete records from sheet`,
  [JobOperationEnum.Extract]: `extract on file`,
  [JobOperationEnum.Map]: `map`,
}

export const generateActorName = (log: Activity) => {
  return (
    <ActorCell>
      {log.actorName}{' '}
      {log?.actorType && (
        <ActorType type={log.actorType}>
          {capitalizeFirst(log?.actorType)}
        </ActorType>
      )}
    </ActorCell>
  )
}

export const generateEventType = (log: Activity) => {
  return log.activity ? (
    <Badge label={log.activity} variant={BadgeVariant.Pigeon}></Badge>
  ) : (
    <></>
  )
}

export const generateActivityResource = (
  log: Activity,
  spaceId: string,
  openJobsPanel: () => void
) => {
  return log.resourceType === 'job' ? (
    <TriggerText onClick={openJobsPanel}>
      {log.resourceName ?? log.resourceId}
    </TriggerText>
  ) : (
    <StyledLink to={getResourceLink(log, spaceId)} target='_blank'>
      {log.resourceName ?? log.resourceId}
    </StyledLink>
  )
}

export const getActorTypeBackgroundColor = (type: Activity['actorType']) => {
  switch (type) {
    case 'guest':
      return 'var(--color-success-light)'
    case 'user':
    case 'api-key':
      return 'var(--color-primary-light)'
    default:
      return 'var(--color-page-100)'
  }
}

export const getActorTypeTextColor = (type: Activity['actorType']) => {
  switch (type) {
    case 'guest':
      return 'var(--color-success-darkest)'
    case 'admin':
      return 'var(--color-primary-darkest)'
    default:
      return 'var(--color-midnight-primary)'
  }
}

const getIdFromContextBasedOnDomain = (activity: Activity) => {
  if (!activity.payload?.domain || !activity.context) {
    return ''
  }

  const domain = activity.payload.domain
  const context = activity.context

  for (const key in context) {
    if (key.toLowerCase() === domain.toLowerCase() + 'id') {
      return context[key as keyof Activity['context']]
    }
  }
  return ''
}

export const transformEventTopic = (activityName: AuditActivity) => {
  if (activityName.includes('guest'))
    return activityName
      .replace('space:', '')
      .split(/(?=[A-Z])/)
      .reverse()
      .join(' a ')
      .toLowerCase()

  return activityName.split(':').reverse().join(' ')
}

export const generateEventDetail = (event: Activity, spaceId: string) => {
  switch (event.activity) {
    case AuditActivity.Jobcompleted:
      return getJobDetails(event, spaceId, AuditActivity.Jobcompleted)

    case AuditActivity.Jobready:
      return getJobDetails(event, spaceId, AuditActivity.Jobready)

    case AuditActivity.Jobcreated:
      return getJobDetails(event, spaceId, AuditActivity.Jobcreated)

    case AuditActivity.Filecreated:
      return (
        <>
          {event.actorName} uploaded file{' '}
          <DetailLink to={getResourceLink(event, spaceId)} target='_blank'>
            {event.resourceName ?? event.resourceId}
          </DetailLink>
        </>
      )

    case AuditActivity.Commitcreated:
    case AuditActivity.Layercreated:
      return (
        <>
          {event.actorName} edited records in sheet{' '}
          <DetailLink to={getResourceLink(event, spaceId)} target='_blank'>
            {event.resourceName}
          </DetailLink>
        </>
      )

    default:
      return (
        <>
          {event.actorName}{' '}
          {transformEventTopic(event.activity as AuditActivity)}{' '}
          <DetailLink to={getResourceLink(event, spaceId)} target='_blank'>
            {event.resourceName ?? event.resourceId}
          </DetailLink>
        </>
      )
  }
}

const JobDetailLink = ({
  event,
  spaceId,
  contextKey,
}: {
  event: Activity
  spaceId: string
  contextKey: 'sheetId' | 'fileId' | 'workbookId'
}) => (
  <DetailLink
    target='_blank'
    to={
      contextKey === 'fileId'
        ? `/space/${spaceId}/files`
        : `/resource/${event.context?.[contextKey]}`
    }
  >
    {event.context?.[contextKey]}
  </DetailLink>
)

const getOperationText = (
  operation: JobOperationEnum,
  jobStatus: JobAuditActivities,
  actorName?: string
) => {
  return `${actorName} ${statusText[jobStatus]} ${
    (operationText[operation] || operation) ?? ''
  }`
}

const getJobDetails = (
  event: Activity,
  spaceId: string,
  jobStatus: JobAuditActivities
) => {
  const operation = event.payload?.operation
  const operationText = getOperationText(operation, jobStatus, event.actorName)

  switch (operation) {
    case JobOperationEnum.Export:
    case JobOperationEnum.DeleteRecords:
      return (
        <span>
          {operationText}{' '}
          <JobDetailLink event={event} spaceId={spaceId} contextKey='sheetId' />
        </span>
      )

    case JobOperationEnum.Map:
      return (
        <span>
          {operationText}{' '}
          <JobDetailLink event={event} spaceId={spaceId} contextKey='fileId' />
          {' to workbook '}
          <JobDetailLink
            event={event}
            spaceId={spaceId}
            contextKey='workbookId'
          />
        </span>
      )

    case JobOperationEnum.Extract:
      return (
        <span>
          {operationText}{' '}
          <JobDetailLink event={event} spaceId={spaceId} contextKey='fileId' />
        </span>
      )

    default:
      return (
        <>
          {operationText}
          {event.payload?.domain && (
            <>
              {' '}
              on{' '}
              <DetailLink to={getResourceLink(event, spaceId)} target='_blank'>
                {event.payload?.domain}: {getIdFromContextBasedOnDomain(event)}
              </DetailLink>
            </>
          )}
        </>
      )
  }
}

export const getResourceLink = (event: Activity, spaceId: string) => {
  const baseUrl = `/space/${spaceId}`
  let jobResourceId
  if (event.activity?.includes('job')) {
    jobResourceId = getIdFromContextBasedOnDomain(event)
  }

  if (event.activity?.includes('file') || jobResourceId?.includes('_fl_')) {
    return `${baseUrl}/files`
  }
  if (event.activity?.includes('guest') || jobResourceId?.includes('_g_')) {
    return `${baseUrl}/manage`
  }
  if (event.activity?.includes('secret') || jobResourceId?.includes('_sec_')) {
    return `${baseUrl}/secrets`
  }
  return jobResourceId === ''
    ? ''
    : `/resource/${jobResourceId || event.resourceId}`
}
