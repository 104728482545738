import { JobModeEnum } from '@flatfile/api'
import { JobOperationEnum } from '../contexts/JobsContext'
import { useRunningJobs } from './useRunningJobs'

export const toolbarBlockingModes = [JobModeEnum.ToolbarBlocking]
export const useBlockingJobs = (
  ids: string[],
  modes: JobModeEnum[] = [JobModeEnum.Foreground]
) => {
  const runningJobs = useRunningJobs()
  const blockingJobs = runningJobs.filter(
    (j) =>
      j.mode &&
      modes.includes(j.mode) &&
      (j.operation === JobOperationEnum.Map
        ? (ids as Array<string | undefined>).includes(j.destination)
        : ids.includes(j.source))
  )
  return blockingJobs
}
