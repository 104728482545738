import styled, { css } from 'styled-components'

export const DocumentContainer = styled.div<{
  $isEphemeral: boolean
}>`
  padding: var(--spacing-page-gutter);
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  color: var(--color-text);
  position: relative;

  ${({ $isEphemeral }) =>
    $isEphemeral &&
    css`
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-page);
    `}

  .back-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .page-header-container {
    float: right;
  }

  hr {
    border: none;
    height: 1px;
    background-color: var(--color-document-border);
  }

  a {
    color: var(--color-text-light);
    text-decoration: underline;
  }

  a:hover {
    color: var(--color-action);
  }

  & > * {
    transition: color 0.25s ease;
  }

  h1:first-of-type {
    margin-top: 0;
  }

  p,
  a,
  b,
  strong,
  bold {
    color: inherit;
    font-size: var(--size-p);
  }
`
