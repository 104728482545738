import {
  Job as APIJob,
  GetJobsRequest,
  JobStatusEnum,
  SortDirection,
} from '@flatfile/api'
import { createContext, useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { JobController } from '../api/controllers/JobController'
import { Observable, useObservable } from '../api/observable'
import { useResourceStream } from '../hooks/useResourceStream'

export const JobOperationEnum = {
  Extract: 'extract',
  Map: 'map',
  DeleteRecords: 'delete-records',
  Export: 'export',
  Configure: 'configure',
  FindReplace: 'find-replace',
}
export type JobOperationEnum =
  (typeof JobOperationEnum)[keyof typeof JobOperationEnum]

export type Job = APIJob

export type ActiveJobs = Job[] | undefined

export interface JobsContextType {
  activeJobs: ActiveJobs
  latestUpdatedJob?: Job
  latestUpdatedRunningJob?: Job
  jobsRequest?: Observable<APIJob[], GetJobsRequest>
  addOriginatedJobId: (id: string) => void
  isOriginatedJob: (id: string) => boolean
}

export const JobsContext = createContext<JobsContextType>({
  activeJobs: undefined,
  addOriginatedJobId: () => {},
  isOriginatedJob: () => false,
})

export const JobsContextProvider = (props: { children: JSX.Element }) => {
  const {
    activeJobs,
    latestJob,
    latestRunningJob,
    jobsRequest,
    addOriginatedJobId,
    isOriginatedJob,
  } = useJobsContext()
  return (
    <JobsContext.Provider
      value={{
        activeJobs,
        latestUpdatedJob: latestJob,
        latestUpdatedRunningJob: latestRunningJob,
        jobsRequest,
        addOriginatedJobId,
        isOriginatedJob,
      }}
    >
      {props.children}
    </JobsContext.Provider>
  )
}

const useJobsContext = () => {
  const { spaceId } = useParams()

  const [jobsRequest] = useObservable(JobController.getJobsForSpace, {
    spaceId: spaceId,
    sortDirection: SortDirection.Desc,
    pageSize: 100,
  })

  const jobsStream = useResourceStream('job', jobsRequest)
  const activeJobsStream = jobsStream?.filter(
    (job) =>
      job.status &&
      job.status !== JobStatusEnum.Planning &&
      job.status !== JobStatusEnum.Created &&
      !job.parentId &&
      //TODO remove this hack when mapping jobs are handled so they start in Planning state
      !(
        job.operation === JobOperationEnum.Map &&
        job.status === JobStatusEnum.Ready
      )
  )

  const { latestJob, latestRunningJob, activeJobs } = useMemo(() => {
    const activeUnacknowledgedJobs = activeJobsStream?.filter(
      (job) => !job.outcomeAcknowledgedAt
    )
    let latest = activeUnacknowledgedJobs?.[0]
    activeUnacknowledgedJobs?.forEach((job) => {
      if (
        latest &&
        new Date(job.updatedAt).getTime() > new Date(latest.updatedAt).getTime()
      ) {
        latest = job
      }
    })

    const runningJobs =
      activeJobsStream?.filter(
        (job) => job.status === 'executing' || job.status === 'ready'
      ) ?? []

    let latestRunning = runningJobs?.[0]
    runningJobs?.forEach((job) => {
      if (
        latestRunning &&
        new Date(job.updatedAt).getTime() >
          new Date(latestRunning.updatedAt).getTime()
      ) {
        latestRunning = job
      }
    })

    return {
      latestJob: latest,
      latestRunningJob: latestRunning,
      activeJobs: activeJobsStream,
    }
  }, [activeJobsStream])

  const originatedJobIdsRef = useRef<string[]>([])
  // bookkeep the pathname of job creation and whether the user has never navigated away
  const originatedJobIdsWithLocation = useRef(
    {} as Record<string, [string, boolean]>
  )
  const location = useLocation()

  const addOriginatedJobId = (jobId: string) => {
    originatedJobIdsRef.current.push(jobId)
    originatedJobIdsWithLocation.current[jobId] = [location.pathname, true]
  }

  const isOriginatedJob = (jobId: string) => {
    const entry = originatedJobIdsWithLocation.current[jobId]
    return !!entry && entry[1] === true
  }

  const handleLocationChange = () => {
    const currentPathname = location.pathname
    Object.entries(originatedJobIdsWithLocation.current).forEach(
      ([jobId, [pathname, neverMismatched]]) => {
        if (pathname !== currentPathname && neverMismatched) {
          originatedJobIdsWithLocation.current[jobId] = [pathname, false]
        }
      }
    )
  }

  useEffect(handleLocationChange, [location.pathname])

  return {
    activeJobs,
    latestJob,
    latestRunningJob,
    jobsRequest,
    addOriginatedJobId,
    isOriginatedJob,
  }
}

export const getCurrentlyRunningJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter(
    (job) => job.status === 'executing' || job.status === 'ready'
  ) ?? []

export const getCompletedJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter((job) => job.status === 'complete') ?? []

export const getFailedJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter((job) => job.status === 'failed') ?? []
