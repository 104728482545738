import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { DiffRecord } from '@flatfile/api'
import {
  Button,
  IconButton,
  Toggle,
  useDebounce,
} from '@flatfile/design-system'
import { fromMaybe } from '@flatfile/shared-ui'
import { DataBufferProvider } from '@flatfile/turntable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DiffViewButtons,
  DiffViewHeader,
  DiffViewSubHeader,
  DiffViewTitleAndSummary,
} from '../VersionHistory/SnapshotView/elements'
import {
  DiffAction,
  GetSnapshotRecordsParams,
} from '../VersionHistory/SnapshotView/types'
import { formatTurntableRows } from '../utils/tableUtils'
import ExitConfirmationModal from './ExitConfirmationModal'
import { PreviewDiffTable } from './PreviewDiffTable'
import { PromptBar } from './PromptBar'
import { PREVIEW_PAGE_SIZE } from './TransformPreview'
import {
  ChangedColumnsText,
  ColumnInfoContainer,
  DiffViewSubHeaderFlexContainer,
  DiffViewSubHeaderInner,
  FlexBox,
  LeftIconButtonWrapper,
  PreviewLabel,
  PromptBarWrapper,
  ResourceLabel,
  ResourcesBox,
  RightIconButtonWrapper,
  SampleLabel,
  ScrollInfoContainer,
  ScrollInfoText,
  ToggleLabel,
  ToggleWrapper,
} from './elements'
import { usePreviewRecords } from './hooks/usePreviewRecords'
import { useScrollTo } from './hooks/useScrollTo'
import { TransformPreviewProps } from './types'

const toSafeString = (value: any) => {
  if (value === null || value === undefined) {
    return ''
  } else {
    return value.toString()
  }
}

export const TransformPreviewContent = ({
  searchFields,
  selection,
  counts,
  onClose,
  sheet,
  workbook,
  hiddenColumnKeys,
  columnConfig,
}: TransformPreviewProps) => {
  const { t } = useTypedTranslation()
  const [records, setRecords] = useState<DiffRecord[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    isAsking,
    handleEnterPrompt,
    isSubmitting,
    onConfirmPreview,
    canConfirmPreview,
    hadMutation,
    hasError,
    errorTitle,
    errorContent,
  } = usePreviewRecords({
    workbook,
    sheet,
    searchFields,
    selection,
    onClose,
    setRecords,
  })

  const [filterByChanged, setFilterByChanged] = useState(false)
  const filterByChangedDelayed = useDebounce(filterByChanged, 200)

  const { changedColumns, usedRecords } = useMemo(() => {
    const changedRecords = records.filter((r: DiffRecord) =>
      Object.values(r.values).some(
        (v) => toSafeString(v.value) !== toSafeString(v.snapshotValue)
      )
    )
    const changedColumns = sheet.config.fields
      .map((f, i) => ({ ...f, index: i }))
      .filter((f) =>
        records.some((r: DiffRecord) => {
          const diff = r.values[f.key]
          return toSafeString(diff.value) !== toSafeString(diff.snapshotValue)
        })
      )

    const usedRecords = filterByChangedDelayed ? changedRecords : records
    return { changedColumns, usedRecords }
  }, [filterByChangedDelayed, records, sheet.config.fields])

  const changedColumnKeys = isAsking ? [] : changedColumns.map((x) => x.key)
  const changedColumnIndices = changedColumns.map((x) => x.index)
  const columnConfigOverride = columnConfig.filter(
    (f) =>
      changedColumnKeys.includes(f.value) || !hiddenColumnKeys.includes(f.value)
  )

  const showSampleMessage = counts?.filtered
    ? counts.filtered > PREVIEW_PAGE_SIZE
    : false

  const {
    handleNextColumn,
    handlePrevColumn,
    nextColumnIndex,
    prevColumnIndex,
    listener,
  } = useScrollTo({ changedColumnIndices, changedColumns })

  const onLoadRows = useCallback(
    (params: GetSnapshotRecordsParams) => {
      const page = fromMaybe(params.pageNumber, 0)
      const size = PREVIEW_PAGE_SIZE
      const skip = page * size

      const windowedRecords = usedRecords.slice(skip, skip + size)

      let formattedRows = formatTurntableRows(windowedRecords, 0, columnConfig)
      return Promise.resolve(formattedRows)
    },
    [usedRecords]
  )

  const noRecordsChanged = filterByChanged && usedRecords.length === 0

  // disable overscroll on the body
  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none'
    return () => {
      document.body.style.overscrollBehaviorX = ''
    }
  }, [])

  // escape key to close the preview with confirmation
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        setIsModalOpen(true)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      <ExitConfirmationModal
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        onConfirm={onClose}
      />

      <DiffViewHeader>
        <DiffViewTitleAndSummary>
          <PromptBarWrapper>
            <PromptBar
              sheet={sheet}
              isAsking={isAsking}
              handleEnterPrompt={handleEnterPrompt}
              handleCancel={onClose}
            />
          </PromptBarWrapper>

          <DiffViewButtons>
            <Button
              onPress={onConfirmPreview}
              loading={isSubmitting}
              label={t(`snapshot.${DiffAction.PREVIEW}.submitButton`)}
              data-testid='diff-submit-button'
              isDisabled={!canConfirmPreview}
            />
          </DiffViewButtons>
        </DiffViewTitleAndSummary>

        <DiffViewSubHeader>
          <DiffViewSubHeaderInner>
            <ResourcesBox>
              <PreviewLabel>Preview </PreviewLabel>
              <ResourceLabel>{workbook.name}</ResourceLabel>
              <div>{' / '}</div>
              <ResourceLabel> {sheet.name}</ResourceLabel>
            </ResourcesBox>

            {showSampleMessage && (
              <FlexBox>
                <SampleLabel>
                  {t('aiTransform.sampleMessage', { number: 10000 })}
                </SampleLabel>
              </FlexBox>
            )}
          </DiffViewSubHeaderInner>

          <DiffViewSubHeaderFlexContainer>
            <ColumnInfoContainer>
              <ChangedColumnsText>
                {t('aiTransform.changedColumnsText', {
                  count: changedColumnKeys.length,
                })}
              </ChangedColumnsText>

              <FlexBox>
                <ToggleWrapper>
                  <Toggle
                    id='filter-changed-toggle'
                    checked={filterByChanged}
                    onChange={setFilterByChanged}
                  />
                </ToggleWrapper>
                <ToggleLabel>{t('aiTransform.toggleLabel')}</ToggleLabel>
              </FlexBox>
            </ColumnInfoContainer>

            <ScrollInfoContainer>
              <ScrollInfoText>{t('aiTransform.scrollInfoText')}</ScrollInfoText>

              <FlexBox>
                <LeftIconButtonWrapper isDisabled={prevColumnIndex === -1}>
                  <IconButton
                    name='arrowLeft'
                    variant='tertiary-border-green'
                    isDisabled={prevColumnIndex === -1}
                    onPress={handlePrevColumn}
                    size={16}
                  />
                </LeftIconButtonWrapper>
                <RightIconButtonWrapper>
                  <IconButton
                    name='arrowRight'
                    variant='tertiary-border-green'
                    isDisabled={nextColumnIndex === -1}
                    onPress={handleNextColumn}
                    size={16}
                  />
                </RightIconButtonWrapper>
              </FlexBox>
            </ScrollInfoContainer>
          </DiffViewSubHeaderFlexContainer>
        </DiffViewSubHeader>
      </DiffViewHeader>

      <DataBufferProvider onLoadRows={onLoadRows} onUpdateRows={() => null}>
        <PreviewDiffTable
          columnConfig={columnConfig}
          columnConfigOverride={columnConfigOverride}
          changedColumnKeys={changedColumnKeys}
          listener={listener}
          isAsking={isAsking}
          usedRecords={usedRecords}
          hasError={hasError}
          errorTitle={errorTitle}
          errorContent={errorContent}
          noRecordsChanged={noRecordsChanged}
        />
      </DataBufferProvider>
    </>
  )
}
