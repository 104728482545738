import { useQueryGetAllWorkbooks } from '@/api/queries/workbooks/useQueryGetAllWorkbooks'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useEventSubscriber } from '@/hooks/useEventSubscriber'
import { EventTopic, Workbook } from '@flatfile/api'
import { useIsMutating } from '@tanstack/react-query'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  addRecordsQueryKey,
  updateRecordsQueryKey,
} from '../hooks/useRecordMutation'

export interface Counts {
  workbookId: string
  countsComputedAt: number
  isFetching: boolean
  total: number
  valid: number
  error: number
}
export interface WorkbookCountsBySheetId {
  [sheetId: string]: Counts
}

interface WorkbookCountsContextType {
  workbookCounts?: WorkbookCountsBySheetId
}

interface WorkbookCountsContextProviderProps {
  children: React.ReactNode
}

export const WorkbookCountsContext = createContext<WorkbookCountsContextType>({
  workbookCounts: {},
} as WorkbookCountsContextType)

const getInitialCountsFromSheets = (wbs: Workbook[]) => {
  const initialCounts: WorkbookCountsBySheetId = {}

  for (const wb of wbs) {
    const sheets = wb.sheets || []

    for (const sheet of sheets) {
      if (sheet?.recordCounts) {
        initialCounts[sheet.id] = {
          countsComputedAt: 0,
          isFetching: false,
          total: sheet.recordCounts.total,
          valid: sheet.recordCounts.valid,
          error: sheet.recordCounts.error,
          workbookId: wb.id,
        }
      } else {
        initialCounts[sheet.id] = {
          countsComputedAt: 0,
          isFetching: false,
          total: 0,
          valid: 0,
          error: 0,
          workbookId: wb.id,
        }
      }
    }
  }

  return initialCounts
}

export const WorkbookCountsContextProvider = (
  props: WorkbookCountsContextProviderProps
) => {
  const spaceContext = useContext(SpaceContext)
  const { data: workbookResponse } = useQueryGetAllWorkbooks({
    spaceId: spaceContext.space.id,
  })
  const [workbookCounts, setWorkbookCounts] = useState<
    WorkbookCountsBySheetId | undefined
  >()
  const { workbookId, sheetId } = useParams()
  const isMutatingUpdate = useIsMutating([updateRecordsQueryKey, workbookId])
  const isMutatingAdd = useIsMutating([addRecordsQueryKey, workbookId])

  const setFetchingCountsForSheet = (sheetId: string) => {
    setWorkbookCounts((prevCounts: WorkbookCountsBySheetId | undefined) => {
      const newCounts = { ...prevCounts }
      if (prevCounts?.[sheetId]) {
        newCounts[sheetId] = {
          ...newCounts[sheetId],
          isFetching: true,
        }
      }
      return newCounts
    })
  }

  useEffect(() => {
    if (workbookResponse?.data) {
      const initialCounts = getInitialCountsFromSheets(workbookResponse.data)
      setWorkbookCounts(initialCounts)
    }
  }, [workbookResponse])

  useEffect(() => {
    if (sheetId && (isMutatingAdd > 0 || isMutatingUpdate > 0)) {
      setFetchingCountsForSheet(sheetId)
    }
  }, [isMutatingUpdate, isMutatingAdd])

  const setNewCountsForSheet = (
    sheetId: string,
    counts: Counts,
    countsComputedAt: number
  ) => {
    setWorkbookCounts((prevCounts: WorkbookCountsBySheetId | undefined) => {
      if (
        prevCounts?.[sheetId] &&
        prevCounts[sheetId].countsComputedAt < countsComputedAt
      ) {
        const newCounts = { ...prevCounts }
        if (prevCounts[sheetId]) {
          newCounts[sheetId] = {
            ...newCounts[sheetId],
            ...counts,
            countsComputedAt,
            isFetching: false,
          }
        }

        return newCounts
      }
      return prevCounts
    })
  }

  useEventSubscriber(
    [
      EventTopic.Commitcreated,
      EventTopic.Layercreated,
      EventTopic.Recordsdeleted,
      EventTopic.Jobcompleted,
    ],
    (_, event) => {
      const sheetId =
        event.topic === EventTopic.Jobcompleted
          ? event.context.sheetId
          : event.origin.id
      setFetchingCountsForSheet(sheetId)
    }
  )

  useEventSubscriber([EventTopic.SheetcountsUpdated], (_, event) => {
    const { context, payload } = event
    const updatedSheetId = context?.sheetId || ''
    if (payload?.counts && payload?.countsComputedAt) {
      setNewCountsForSheet(
        updatedSheetId,
        payload.counts,
        payload.countsComputedAt
      )
    }
  })

  const memoWorkbookCounts = useMemo(
    () => ({ workbookCounts }),
    [workbookCounts]
  )

  return (
    <WorkbookCountsContext.Provider value={memoWorkbookCounts}>
      {workbookCounts && props.children}
    </WorkbookCountsContext.Provider>
  )
}
