import { Snapshot, Success } from '@flatfile/api'
import { HTTPService } from '../../components/HttpClient'
import { LazyObservable, Observable } from '../observable'

export class VersionController {
  constructor() {}

  public getVersions = (): LazyObservable<Versions, GetVersionProps> => {
    return new Observable<Versions>(async ({ sheetId }: GetVersionProps) => {
      const response = await HTTPService.getSnapshots({
        sheetId: sheetId,
      })
      return {
        data: { versions: response.data! },
      }
    }).lazy
  }

  public createVersion(): LazyObservable<Version, CreateVersionProps> {
    return new Observable<Version>(
      async ({ sheetId, label }: CreateVersionProps) => {
        const response = await HTTPService.snapshotSheet({
          snapshotSheetRequest: { label: label, sheetId: sheetId },
        })
        return {
          data: { version: response.data! },
        }
      }
    ).lazy
  }

  public restoreVersion(): LazyObservable<Version, RestoreVersionProps> {
    return new Observable<Version>(
      async ({ restoreVersionId }: RestoreVersionProps) => {
        const response = await HTTPService.restoreSnapshot({
          snapshotId: restoreVersionId,
        })
        return {
          data: { version: response.data! },
        }
      }
    ).lazy
  }

  public deleteVersion(): LazyObservable<Success, DeleteVersionProps> {
    return new Observable<Success>(
      async ({ deleteVersionId }: DeleteVersionProps) => {
        const response = await HTTPService.deleteSnapshot({
          snapshotId: deleteVersionId,
        })
        return response
      }
    ).lazy
  }
}

export interface GetVersionProps {
  sheetId: string
}

export interface CreateVersionProps {
  sheetId: string
  label?: string
}

export interface RestoreVersionProps {
  restoreVersionId: string
}

export interface DeleteVersionProps {
  deleteVersionId: string
}

export interface Versions {
  versions: Snapshot[]
}

export interface Version {
  version: Snapshot
}

export enum VersionAction {
  RESTORE = 'restore',
  ACCEPT = 'accept',
  DELETE = 'delete',
}
