import { getTurntableTheme } from '@/apps/SpaceApp/Theme/ThemeUtils'
import { SpaceContext } from '@/contexts/SpaceContext'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { WarningIcon } from '@flatfile/shared-ui'
import { Table, useDataBufferContext } from '@flatfile/turntable'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Overlay, VerticalSpacer } from '../SheetViewEmptyStates'
import { EPHEMERAL_DIFF, PREVIEW_PAGE_SIZE } from './TransformPreview'
import {
  ErrorContentColumns,
  ErrorContentSubtitle,
  ErrorContentTitle,
  ErrorOverlayContent,
  IconWrapper,
  PreviewDiffViewTableContainer,
} from './elements'
import { PreviewDiffTableProps } from './types'

export const PreviewDiffTable = ({
  columnConfig,
  columnConfigOverride,
  changedColumnKeys,
  listener,
  isAsking,
  usedRecords,
  hasError,
  errorTitle,
  errorContent,
  noRecordsChanged,
}: PreviewDiffTableProps) => {
  const { t } = useTypedTranslation()
  const { buffer, initLoad } = useDataBufferContext()
  const { metadata } = useContext(SpaceContext)
  const turntableTheme = useMemo(() => getTurntableTheme(metadata), [metadata])

  const canSetRowCount = useRef(false)

  const [version, setVersion] = useState(0)
  useEffect(() => {
    setVersion((prev) => prev + 1)
  }, [usedRecords])

  useEffect(() => {
    canSetRowCount.current = false
    const onLoad = async () => {
      buffer.initialize({
        bufferId: EPHEMERAL_DIFF,
        pageSize: PREVIEW_PAGE_SIZE,
        columnConfig,
        totalRecords: PREVIEW_PAGE_SIZE,
      })
      await initLoad()
      canSetRowCount.current = true
    }
    onLoad()
  }, [columnConfig, usedRecords])

  const clearBufferHistories = () => {
    const bufferKeys = Array.from(buffer._recordsCache.keys())
    bufferKeys.forEach((key) => {
      buffer._pageCache.delete(key)
    })
  }

  const refetchRows = async () => {
    buffer.onDataLoadError = undefined
    await buffer.loadPages()
  }

  const refetchTable = async () => {
    clearBufferHistories()
    await refetchRows()
  }

  useEffect(() => {
    if (usedRecords.length > 0) {
      refetchTable()
    }
  }, [usedRecords])

  return (
    <PreviewDiffViewTableContainer>
      {(hasError || noRecordsChanged) && (
        <Overlay data-testid='sheet-empty-overlay'>
          <VerticalSpacer />
          <ErrorOverlayContent>
            <ErrorContentColumns>
              <IconWrapper>
                <WarningIcon name='alertCircle' size={24} />
              </IconWrapper>
              <div>
                {hasError && (
                  <>
                    <ErrorContentTitle>{errorTitle}</ErrorContentTitle>
                    {errorContent && (
                      <ErrorContentSubtitle>
                        {errorContent}
                      </ErrorContentSubtitle>
                    )}
                  </>
                )}
                {!hasError && noRecordsChanged && (
                  <>
                    <ErrorContentTitle>
                      {t('aiTransform.errors.noresultsError.title')}
                    </ErrorContentTitle>
                    <ErrorContentSubtitle>
                      {t('aiTransform.errors.noresultsError.content')}
                    </ErrorContentSubtitle>
                  </>
                )}
              </div>
            </ErrorContentColumns>
          </ErrorOverlayContent>
        </Overlay>
      )}
      <Table
        key={version}
        columnConfig={columnConfigOverride}
        isDiffView
        readOnly
        tableId={EPHEMERAL_DIFF}
        theme={turntableTheme}
        totalRowCount={isAsking ? PREVIEW_PAGE_SIZE : usedRecords.length}
        changedColumns={changedColumnKeys}
        disableColumnMenus={true}
        onScroll={listener}
        isLoading={isAsking}
      />
      )
    </PreviewDiffViewTableContainer>
  )
}
