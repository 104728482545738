import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns'

export const differenceInTime = (
  start: Date,
  end: Date,
  units: {
    hours: string
    minutes: string
    seconds: string
  }
) => {
  let result = ''
  const hours = differenceInHours(end, start)
  if (hours > 0) {
    result += ` ${hours} ${units.hours}`
  }

  const minutes = differenceInMinutes(end, start) - hours * 60
  if (minutes > 0 || hours > 0) {
    result += ` ${minutes} ${units.minutes}`
  }

  const seconds = Math.max(
    0,
    differenceInSeconds(end, start) - hours * 3600 - minutes * 60
  )
  result += ` ${seconds} ${units.seconds}`
  return result
}
