import { Button, Spinner, ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

import { SpaceContext } from '@/contexts/SpaceContext'
import { useNavigate } from '@/hooks/useNavigate'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { noActivityIcon } from '../../assets/icons/no-activity'
import { ErrorState } from '../../components/EmptyState'
import { SpinnerContainer } from '../../elements/SpinnerContainer'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'
import { ConnectionsButton } from '../FilesApp/components/ConnectionsButton'
import { FileUploadButton } from '../FilesApp/components/FileUploadButton'

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndex.level800};
`

export const VerticalSpacer = styled.div`
  min-height: 30px;
  max-height: 80px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 40px);
`

export const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  paddingbottom: 10px;

  button {
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin: 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
`
const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
`
export const SheetEmptyStateOverlay = ({
  hideOverlay,
  canAddToSheet,
  canImportIntoSheet,
  noAccessLimitations,
}: {
  hideOverlay: () => void
  canAddToSheet: boolean
  canImportIntoSheet: boolean
  noAccessLimitations: boolean
}) => {
  const { t } = useTypedTranslation()
  const { space } = useContext(SpaceContext)
  const allowManualEdit = canAddToSheet || noAccessLimitations
  const allowImport = canImportIntoSheet || noAccessLimitations
  if (!allowManualEdit && !allowImport) {
    return null
  }
  return (
    <Overlay data-testid='sheet-empty-overlay'>
      <VerticalSpacer />
      <Content>
        {allowImport && (
          <>
            <ImageContainer>
              {/*<img alt='genericfile' src={GenericFile} />*/}
            </ImageContainer>
            <h3>{t('sheet.emptyView.uploadFile')}</h3>
            <ButtonRow>
              <FileUploadButton space={space} size='md' />
              <ConnectionsButton size='md' />
            </ButtonRow>
          </>
        )}
        {allowManualEdit && (
          <Button
            variant='tertiary-border'
            iconLeft='edit'
            onPress={hideOverlay}
            data-testid='manual-edit-button'
            label={t('sheet.emptyView.manualEntry')}
          />
        )}
      </Content>
    </Overlay>
  )
}

export const SheetEmptyState = ({
  hasError,
  hasSheet,
  isFirstLoad,
  readOnlySheet,
  canAddToSheet,
  canImportIntoSheet,
  sheetIsLocked,
  allColumnsHidden,
}: {
  hasError?: boolean
  hasSheet?: boolean
  isFirstLoad?: boolean
  readOnlySheet: boolean
  canAddToSheet: boolean
  canImportIntoSheet: boolean
  sheetIsLocked: boolean
  allColumnsHidden?: boolean
}) => {
  const { t } = useTypedTranslation()
  const { spaceId, workbookId } = useParams()
  const navigate = useNavigate()
  if (hasError && hasSheet) {
    return <ErrorState />
  }

  if (isFirstLoad) {
    return (
      <SpinnerContainer data-testid='sheet-loading-state'>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (!hasSheet) {
    return (
      <ErrorState
        title={t('sheet.emptyView.missingSheet.heading')}
        message={t('sheet.emptyView.missingSheet.description')}
        refresh={false}
      >
        <Button
          data-testid='emptyState-no-sheet-button'
          iconLeft='arrowLeft'
          variant='secondary'
          label={t('sheet.emptyView.missingSheet.buttonLabel')}
          onPress={() => navigate(`/space/${spaceId}/workbook/${workbookId}`)}
        />
      </ErrorState>
    )
  }

  if (allColumnsHidden) {
    return (
      <ErrorState
        icon={noActivityIcon}
        title={t('sheet.emptyView.noColumns.heading')}
        message={t('sheet.emptyView.noColumns.description')}
        refresh={false}
      />
    )
  }

  if (sheetIsLocked) {
    return (
      <ErrorState
        title={t('sheet.emptyView.sheetLocked.heading')}
        message={t('sheet.emptyView.sheetLocked.description')}
        refresh={false}
      />
    )
  }

  if (
    readOnlySheet ||
    (canAddToSheet === false && canImportIntoSheet === false)
  ) {
    return (
      <ErrorState
        title={t('sheet.emptyView.readOnly.heading')}
        message={t('sheet.emptyView.readOnly.description')}
        refresh={false}
      />
    )
  }

  return (
    <ErrorState
      icon={noActivityIcon}
      title={t('sheet.emptyView.defaultError.heading')}
      message={t('sheet.emptyView.defaultError.description')}
      refresh={false}
    />
  )
}
