import { Account, Environment } from '@flatfile/api'
import { createContext, useContext } from 'react'
import { SpaceContext } from './SpaceContext'
import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { getRuntimeParameters } from '@/components/RuntimeParameters'

export type Features = Record<string, boolean>

export type EnvironmentMeta = {
  flatfileDemoAgent?: boolean
  showSpaceInfo?: boolean
}

export type InitialResourcesEnvironment = Environment & {
  hasAccess?: boolean
}

export interface EnvironmentsContextType {
  environment?: InitialResourcesEnvironment
  accountId?: string
  account?: Account
  features?: Features
}

export const EnvironmentsContext = createContext<EnvironmentsContextType>({})

export const EnvironmentsContextProvider = (props: {
  children: JSX.Element
}) => {
  const { environment, features, accountId, account } = useEnvironmentsContext()
  return (
    <EnvironmentsContext.Provider
      value={{ environment, features, accountId, account }}
    >
      {props.children}
    </EnvironmentsContext.Provider>
  )
}

export const useEnvironmentsContext = () => {
  const { httpClient, space } = useContext(SpaceContext)
  const { isGuest } = getRuntimeParameters()

  const { data } = useFlatfileQuery(
    'getEnvironmentById',
    { environmentId: space?.environmentId },
    { enabled: !!space?.environmentId && !isGuest }
  )

  const { data: accountData } = useFlatfileQuery('getCurrentAccount', {})
  const environment = data?.data

  const account = accountData?.data

  return {
    environment: environment
      ? (environment as InitialResourcesEnvironment)
      : undefined,
    features: environment?.features as Features,
    accountId: environment?.accountId,
    account,
  }
}
