import { Filter, RecordCounts, Sheet } from '@flatfile/api'
import {
  BulkRowSelection,
  RowData,
  useDataBufferContext,
} from '@flatfile/turntable'
import { useCallback } from 'react'
import { SearchFields } from '../types'
import { determineFilter } from '../utils/bulkRowUtils'

interface UseJobQueryProps {
  sheet?: Sheet
  searchFields: SearchFields
  counts: RecordCounts | undefined
  getFormattedSelection: () => BulkRowSelection
}

export interface JobQuery {
  filter?: Filter
  filterField?: string
  searchField?: string
  searchValue?: string
  q?: string
  ids?: string[]
}

export const useJobQuery = ({
  sheet,
  searchFields,
  counts,
  getFormattedSelection,
}: UseJobQueryProps) => {
  const selection = getFormattedSelection()

  const { buffer } = useDataBufferContext()

  const getSyncedRecordIds = (): string[] | undefined => {
    if (selection.type === 'all' && counts?.total && counts?.total <= 100) {
      return buffer.bufferedRecords.reduce((memo: string[], row: RowData) => {
        if (row.id && !selection.exceptions.includes(row.id)) {
          memo.push(row.id)
        }
        return memo
      }, [] as string[])
    } else if (selection.type === 'none' && selection.exceptions.length > 0) {
      return selection.exceptions
    }
    return undefined
  }

  const getJobQuery = useCallback((): JobQuery => {
    const ids = getSyncedRecordIds()
    if (ids?.length) {
      return { ids }
    }
    return {
      filter: determineFilter(selection, searchFields),
      filterField: searchFields.filterField,
      searchField: searchFields.searchField,
      searchValue: searchFields.searchValue,
      q: searchFields.q,
      ids: selection.exceptions.length ? selection.exceptions : undefined,
    }
  }, [searchFields, selection, sheet, counts])

  return {
    getJobQuery,
    getSyncedRecordIds,
  }
}
