import { useCreatePromptMutation } from '@/api/mutations/prompts.mutations'
import { useAssistant } from '@/hooks/useAssistant'
import { useTypedTranslation } from '@/hooks/useTranslationWrappers'
import { useDelayedBoolean } from '@flatfile/design-system'
import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

export const usePreviewRecords = ({
  workbook,
  sheet,
  searchFields,
  selection,
  onClose,
  setRecords,
}: any) => {
  const { t } = useTypedTranslation()
  const [activePrompt, setActivePrompt] = useState<string | undefined>(
    undefined
  )
  const [isAsking, setIsAsking] = useState(false)

  const {
    ask,
    preview,
    mutationResponse,
    mutationIsError,
    mutationError,
    previewResponse,
    previewIsError,
    previewError,
    previewIsSuccess,
    isError,
    applyMutationAsync,
  } = useAssistant({ sheetId: sheet.id })

  const { mutate: createNewPrompt } = useCreatePromptMutation()

  const mutationData = mutationResponse?.data

  const mutationId = mutationData?.mutationId || ''

  const [hadMutation, setHadMutation] = useState(false)

  const [hasVersionError, setHasVersionError] = useState(false)

  useEffect(() => {
    if (mutationData?.mutateRecord) {
      setHadMutation(true)
    }
  }, [mutationData?.mutateRecord])

  const mutateRecord =
    mutationData?.mutateRecord || (hadMutation ? undefined : '')

  const previewDiffs = previewResponse

  const handleEnterPrompt = (value: string) => {
    posthog.capture('enter_prompt_button_clicked', {
      button_name: 'prompt_button',
      message: 'Prompt submitted in AI Transform Preview',
    })
    setActivePrompt(value)
    setHasVersionError(false)
    setIsAsking(true)
    ask(value)
  }

  useEffect(() => {
    if (!(mutateRecord || mutateRecord === '')) return
    preview(mutateRecord, mutationId, searchFields, selection)
  }, [mutateRecord, mutationId, searchFields, selection])

  useEffect(() => {
    if (previewIsSuccess) {
      setIsAsking(false)
      setRecords(previewDiffs)
    }
  }, [previewIsSuccess, previewDiffs])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const onConfirmPreview = async () => {
    posthog.capture('transform_data_button_clicked', {
      button_name: 'transform_data',
      message: 'Click on Transform Data button in diff view',
    })
    if (!mutateRecord) return
    setIsSubmitting(true)
    setHasVersionError(false)

    const aiSnapshotLabelBase = t('versionHistory.panel.generatedSnapshot')
    const markdownLabel = `${aiSnapshotLabelBase}\n\n"${activePrompt}"`

    await applyMutationAsync({
      mutateRecord: mutateRecord,
      mutationId: mutationId,
      searchFields,
      selection,
      snapshotLabel: markdownLabel,
    })

    // If active prompt is set - store it as a "historical" prompt to be recalled later
    if (activePrompt) {
      createNewPrompt({
        prompt: activePrompt,
        environmentId: workbook.environmentId,
        spaceId: workbook.spaceId,
      })
    }

    setIsSubmitting(false)
    onClose()
  }

  const canConfirmPreview = useDelayedBoolean({
    value: !!previewDiffs && !!mutateRecord,
    suppressTime: 500,
    forceTime: 1000,
  })

  const hasError =
    mutationIsError ||
    previewIsError ||
    isError ||
    (mutationData && !mutateRecord)

  useEffect(() => {
    if (hasError) {
      setIsAsking(false)
    }
  }, [hasError])

  // Default error message
  let errorTitle: string = t('aiTransform.errors.generalError.title')
  let errorContent = ''

  // Override the error message if we know what went wrong
  if (mutationData && !mutateRecord) {
    // Case 1: we generated an empty mutateRecord function, this usually means that the request was not comprehensible
    errorTitle = t('aiTransform.errors.noFunctionError.title')
    errorContent = t('aiTransform.errors.noFunctionError.content')
  } else if (mutationIsError) {
    if (
      mutationError?.message ===
      "Privilege 'environment:ai-assist' is not present"
    ) {
      // Case 2a: we got an entitlement error, this means the user does not have access to the AI assist feature
      errorTitle = t('aiTransform.errors.entilementError.title')
      errorContent = t('aiTransform.errors.entilementError.content')
    } else if (mutationError?.message === 'The prompt is too long') {
      // Case 2b: The prompt is too long
      errorTitle = t('aiTransform.errors.prompttoolongError.title')
      errorContent = t('aiTransform.errors.prompttoolongError.content')
    } else {
      // Case 2c: we got an error generating the mutation. this could mean network issues or openai is down.
      errorTitle = t('aiTransform.errors.generateMutationError.title')
    }
  } else if (
    previewIsError &&
    previewError?.message === 'Mutation must not change the keys of the record'
  ) {
    // Case 3: the mutation modified the keys of the record, which is not allowed
    errorTitle = t('aiTransform.errors.keysModifiedError.title')
  } else if (
    previewIsError &&
    previewError?.message === 'No records found matching the given filters'
  ) {
    // Case 4: Your filters excluded all records
    errorTitle = t('aiTransform.errors.noRecordsFoundError.title')
    errorContent = t('aiTransform.errors.noRecordsFoundError.content')
  } else if (previewIsError) {
    // Case 5: previewing the mutation threw some kind of error, most likely this means that there is
    // something wrong with the mutateRecord function. There are several ways this could happen.
    // a. the mutateRecord function is malformed (maybe we should check for this more explicitly)
    // b. the mutateRecord function threw an error (e.g. "if the first name is Anna, throw an error")
    // c. the mutateRecord function has invalid references (e.g. "replace the nickname with the result of getNickname()")
    errorTitle = t('aiTransform.errors.invalidFunctionError.title')
    errorContent = t('aiTransform.errors.invalidFunctionError.content')
  } else if (hasVersionError) {
    // Case 6: we got an error generating the snapshot.
    errorTitle = t('aiTransform.errors.versionError.title')
    errorContent = t('aiTransform.errors.versionError.content')
  }

  return {
    isAsking,
    handleEnterPrompt,
    previewDiffs,
    isSubmitting,
    onConfirmPreview,
    canConfirmPreview,
    hadMutation,
    hasError,
    errorTitle,
    errorContent,
  }
}
