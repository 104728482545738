import { getFont, ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

import { Pill } from '@/elements/Pill'

export const DiffViewOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${ZIndex.level1200};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--color-primary);
    opacity: 0.2;
  }
`

export const DiffViewContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: calc(100vw - 50px);
  background-color: var(--color-page);
  display: flex;
  flex-direction: column;
`

export const DiffViewFullScreenContainer = styled.div`
  position: fixed;
  top: 5px;
  bottom: 5px;
  right: 5px;
  height: calc(100vh- 10px);
  width: calc(100vw - 10px);
  background-color: var(--color-page);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 4px solid var(--color-primary);
`

export const DiffViewEmbeddedContainer = styled.div`
  background-color: var(--color-page);
  display: flex;
  flex-direction: column;
  height: 90vh;
`

export const DiffViewHeader = styled.div`
  padding: 24px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
`

export const DiffViewSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 26px;
`

export const DiffViewTitleAndSummary = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  cursor: default;
`

export const DiffViewTitle = styled.div`
  ${getFont('actionLg')}
  color: var(--color-title);
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
`

export const DiffViewTitlePill = styled(Pill).attrs({ type: 'actionLg' })`
  line-height: 16px;
  padding: 2px 8px;
`

export const DiffViewButtons = styled.div`
  display: flex;
  gap: 12px;
`

export const DiffViewSummary = styled.div`
  ${getFont('b1')}
`

export const DiffViewTableContainer = styled.div`
  flex-grow: 1;
  position: relative;
  margin-left: 24px;
`

export const DiffTableToolbar = styled.div`
  > div {
    paddind: 0px 0px 16px 0px;
    max-height: none;
  }
`
