import { Job } from '@flatfile/api'
import {
  Button,
  ModalButtonRow,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { SpaceContext } from '../../contexts/SpaceContext'

export const NoListenerModalContent = ({ job }: { job: Job }) => {
  const { httpClient } = useContext(SpaceContext)
  const { mutate: cancelJob } = useMutation<unknown, unknown, string>(
    ['cancelJob'],
    async (id: string) => {
      await httpClient.cancelJob({
        jobId: id,
        jobCancelDetails: { info: 'Cancelled by user' },
      })
    },
    {
      onSuccess: () => {},
    }
  )

  return (
    <>
      <ModalHeader>Job started</ModalHeader>
      <ModalText>
        A job with operation <code>{job.operation}</code> has been initiated
        from an action, but no listener has been configured to respond to it.{' '}
        <a
          href='https://flatfile.com/docs/guides/actions'
          target='_blank'
          rel='noreferrer'
        >
          Set up a listener
        </a>{' '}
        to define what happens next.
      </ModalText>
      <ModalButtonRow>
        <Button
          type='reset'
          variant='ghost'
          onPress={() => cancelJob(job.id)}
          label={'Cancel job'}
          data-testid='cancel-job-button'
        />
        <Button
          type='submit'
          onPress={() =>
            window.open(
              'https://flatfile.com/docs/guides/custom-actions',
              '_blank'
            )
          }
          label={'Learn more'}
          data-testid='learn-more-button'
        />
      </ModalButtonRow>
    </>
  )
}
