import { JobModeEnum, JobStatusEnum } from '@flatfile/api'
import { useContext } from 'react'
import { JobOperationEnum, JobsContext } from '../contexts/JobsContext'

export const useFinishedJobs = (id?: string) => {
  const { activeJobs } = useContext(JobsContext)
  const modes: JobModeEnum[] = [
    JobModeEnum.Foreground,
    JobModeEnum.Background,
    JobModeEnum.ToolbarBlocking,
  ]
  const completedJobs = (activeJobs ?? []).filter(
    (j) =>
      (j.status === JobStatusEnum.Complete ||
        j.status === JobStatusEnum.Failed) &&
      j.mode &&
      modes.includes(j.mode) &&
      (j.operation === JobOperationEnum.Map ||
      j.operation === JobOperationEnum.FindReplace
        ? false // no outcome modal for find-replace and mapping jobs
        : id
        ? j.source === id
        : true)
  )
  return completedJobs
}
