import { useFlatfileQuery } from '@/api/queries/flatfileQuery'
import { useNavigate } from '@/hooks/useNavigate'
import { FC, useEffect } from 'react'
import { ErrorState } from '../../components/EmptyState'
import { SpacesUISkeleton } from '../../components/SpacesUISkeleton'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTypedTranslation } from '../../hooks/useTranslationWrappers'

export const WorkbookRouteRedirector: FC<{}> = () => {
  const { t } = useTypedTranslation()
  const { workbookId } = useRouteParams()
  const navigate = useNavigate()
  const { data: workbookData, isError: isGetWorkbookError } = useFlatfileQuery(
    'getWorkbookById',
    { workbookId: workbookId! }
  )
  const workbook = workbookData?.data
  useEffect(() => {
    if (workbook && workbook.sheets?.length) {
      navigate(`./sheet/${workbook.sheets[0].id}`, { replace: true })
    }
  }, [workbook])

  if (isGetWorkbookError) {
    return <ErrorState title={t('errors.workbookNotFound')} />
  }

  if (workbook && !workbook.sheets?.length) {
    return (
      <ErrorState
        title={t('sheet.errors.noSheets.title')}
        message={t('sheet.errors.noSheets.description')}
      />
    )
  }
  return <SpacesUISkeleton />
}
